import React, { useState } from "react";
import SuspendAccountBar from "../../progressBar/SuspendAccount";

const SuspensionReason = ({
  id_R,
  suspendReasonOpen,
  handleCloseSuspendReason,
  handleOpenDocumentReason,
  selectedOption,
  setSelectedOption,
}) => {
  const [isInputsFilled, setIsInputsFilled] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setIsInputsFilled(true);
  };

  const handleNextButtonClick = () => {
    handleCloseSuspendReason();
    handleOpenDocumentReason();
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          suspendReasonOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className="w-[32%]">
              <div className="p-4 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-3">
                  Suspend Account
                </p>
              </div>
              <div className="mt-[10px] px-6">
                <SuspendAccountBar step={1} />
              </div>
            </div>
            <div className="w-[74%] flex flex-col">
              <div className="justify-end rounded-t flex items-end">
                <button
                  onClick={handleCloseSuspendReason}
                  type="button"
                  className="bg-transparent rounded-lg text-sm p-2.5 ml-auto inline-flex items-center"
                  data-modal-toggle="modal"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.84 12L5.7 8.9L3.9 12H0.2L3.88 6.3L0.1 0.839999H3.94L6.08 3.92L7.88 0.839999H11.58L7.84 6.46L11.68 12H7.84Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div className="w-[95%]">
                <div className="mt-10 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Reason for suspension
                  </p>
                  <div className="text-[#000] mt-2 mb-[10px] text-[15px] font-[300]">
                    <p>
                      Please choose the most appropriate reason for the
                      suspension of the account from the options below.
                    </p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="overflow-y-auto border border-1 border-[#000000] gap-[20px] flex flex-col p-[20px] w-[500px] rounded-[5px]">
                    {[
                      "Suspected fraud",
                      "Non-compliance",
                      "Violation of policies",
                      "Identity theft",
                      "Suspicious activity",
                      "Unpaid debts",
                      "Excess overdrafts or negative balance",
                      "Other",
                    ].map((reason) => (
                      <div
                        className="flex items-center gap-[10px]"
                        key={reason}
                      >
                        <input
                          type="radio"
                          value={reason}
                          checked={selectedOption === reason}
                          onChange={handleOptionChange}
                          className="mr-2 h-4 w-4"
                        />
                        <label>{reason}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex w-[94%] items-center justify-end mt-8 mb-8">
                <div className="flex items-center justify-end w-[15vw]">
                  <button
                    onClick={handleNextButtonClick}
                    type="button"
                    className={`text-white ${
                      isInputsFilled
                        ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                        : "bg-[#D9D9D9] pointer-events-none"
                    } block rounded-[5px] text-[16px] font-[500] px-8 py-2.5 text-center`}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuspensionReason;
