import React from "react";
import { Modal } from "rsuite";

const DeleteRoleUserSuccess = ({
  deleteRoleUserSuccessOpen,
  handleCloseDeleteRoleUserSuccess,
}) => {
  return (
    <Modal
      size="sm"
      open={deleteRoleUserSuccessOpen}
      onClose={handleCloseDeleteRoleUserSuccess}
    >
      <div className="h-[400px] w-full flex flex-col gap-[40px] items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="110"
          height="110"
          viewBox="0 0 110 110"
          fill="none"
        >
          <circle cx="55" cy="55" r="55" fill="#f00" />
          <path
            d="M19.5 57L39.5 74.0514L88.5 29.5"
            stroke="white"
            stroke-width="5"
          />
        </svg>
        <p>Success! Role User deleted successfully</p>
      </div>
    </Modal>
  );
};

export default DeleteRoleUserSuccess;
