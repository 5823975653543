import React, { useState } from "react";
import OTPInput from "react-otp-input";

const OtpEmail = ({
  id_R,
  otpEmailOpen,
  handleCloseEmailOtp,
  customerEditDetails,
  setCustomerEditDetails,
}) => {
  const [otp, setOtp] = useState("");

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);

    if (otp.length === 6) {
      // make a request
      console.log(otp);
    }
  };
  return (
    <>
      <div
        id={id_R}
        className={`${
          otpEmailOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
          <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto h-[75vh] w-[55vw]">
            <div
              onClick={handleCloseEmailOtp}
              className="absolute top-[5px] right-[10px] cursor-pointer text-[24px] flex items-center justify-center"
            >
              X
            </div>
            <div className="p-[20px]">
              <div className="flex items-center mb-[30px]">
                <p className="text-[20px] font-bold text-[#000]">
                  Enter and verify customer new email
                </p>
              </div>
              <p className="text-[#000] w-[80%] text-[15px] mb-[20px]">
                Kindly share customer’s primary email for the verification
                process. An OTP is sent to the email entered to verify the
                email. Enter the OTP sent to complete.
              </p>
              <div className="flex flex-col justify-around h-[400px] mb-[20px]">
                <div className="flex w-[70%] flex-col">
                  <label htmlFor="firstname" className="mb-[20px]">
                    Email
                  </label>
                  <div className="flex items-center gap-[10px]">
                    <input
                      type="text"
                      value={customerEditDetails.email}
                      onChange={(e) =>
                        setCustomerEditDetails((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                      className="w-[70%] h-[45px] px-[10px] border-[1px] border-[#9A9A9A] focus:outline-none"
                    />
                    <button className="h-[45px] w-[20%] text-white font-bold px-[20px] bg-[#17C6C6]">
                      Verify Email
                    </button>
                  </div>
                </div>
                <div className="flex flex-col mt-2">
                  <label htmlFor="firstname" className="mb-[20px]">
                    Email verification OTP
                  </label>
                  <div>
                    <OTPInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={6}
                      onPaste={true}
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{ width: "45px", height: "45px" }}
                        />
                      )}
                      renderSeparator={<span className="mx-2" />}
                      inputStyle="bg-white text-[20px] text-center text-[24px] text-[#000] border-[1px] border-[#000] rounded-[5px]"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-[20px]">
                <button
                  onClick={handleCloseEmailOtp}
                  className="bg-[#D9D9D9] h-[50px] px-[20px] rounded-[5px]"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpEmail;
