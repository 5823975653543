import { client } from "../client/client";
import axios from "axios";

export const emailEntry = async (payload) => {
  try {
    const { data } = await client.post(
      "/api/v1/customer/register-customer",
      payload
    );
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const createCustomer = async (payload) => {
  try {
    const { data } = await client.post("/api/v1/customer", payload);
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const createAddressInfo = async (payload) => {
  try {
    const { data } = await client.post("/api/v1/address", payload);
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getCustomers = async (
  limit,
  page,
  fromDate,
  toDate,
  query,
  status,
  location
) => {
  const response = await client.get(
    `/api/v1/customer?page=${page}&limit=${limit}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&q=${query}&location=${location}`
  );

  return response?.data;
};


export const getOldDate = async (page = 1) => {
  const response = await client.get(`/api/v1/customer`, {
    params: {
      page,
      limit: 10000, 
    },
  });
  return response?.data;
};

export const getCustomer = async (id) => {
  const response = await client.get(`/api/v1/customer/${id}`);

  return response?.data;
};

export const getPendingReasons = async (id) => {
  const response = await client.get(`/api/v1/customer/issues/${id}`);

  return response?.data;
};

export const getCustomerAccounts = async (id) => {
  const response = await client.get(`/api/v1/account/admincustomer/${id}`);

  return response?.data;
};

export const getCustomerTransactions = async (accountNumber) => {
  const response = await client.get(
    `/api/v1/transaction/account/${accountNumber}`
  );

  return response?.data;
};

export const totalSpentByCustomerPerMonth = async (accountNumber) => {
  const response = await client.get(
    `/api/v1/transaction/account/totalspent/${accountNumber}`
  );

  return response?.data;
};

export const getCustomerSingleAccounts = async (accountId, customerId) => {
  const response = await client.get(
    `/api/v1/account/adminaccount/${accountId}/${customerId}`
  );

  return response?.data;
};

export const updateCustomer = async (id, payload) => {
  try {
    const response = await client.patch(`/api/v1/customer/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};
export const createAccount = async ({ product_id, branch_id, customer_id }) => {
  try {
    const { data } = await client.post(`/api/v1/account`, {
      product_id,
      branch_id,
      customer_id,
    });
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const temporaryPassword = async ({ email }) => {
  try {
    const { data } = await client.post(`/api/v1/customer/temporary_password`, {
      email,
    });
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const updateCustomerAddress = async (addressId, payload) => {
  try {
    const response = await client.patch(
      `/api/v1/address/${addressId}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getAddressInfo = async (payload) => {
  try {
    const { data } = await client.post(`/api/v1/address`, payload);
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const deleteCustomer = async (id) => {
  const response = await client.delete(`/api/v1/customer/${id}`);

  return response.data;
};

export const suspendCustomer = async (id, status) => {
  const response = await client.patch(`/api/v1/customer/${id}`, { status });

  return response.data;
};

export const exportSelectedCustomers = async (type, customers) => {
  const response = await client.post(
    `/api/v1/customer/export/selected?format=${type}`,
    customers,
    { responseType: "arraybuffer" }
  );

  return response.data;
};

export const exportAllCustomers = async (format, limit, status) => {
  const params = new URLSearchParams({
    format,
    limit,
    status
  });

  try {
    const response = await client?.get(
      `api/v1/customer/export?${params?.toString()}`,
      {
        headers: {
          "Content-Type": format === "pdf" ? "application/pdf" : "text/csv",
          Accept: "*/*",
        },
        responseType: "arraybuffer",
      }
    );

    return response?.data;
  } catch (error) {
    // Handle errors appropriately
    console?.error("Error exporting customers:", error);
    throw error; // Rethrow the error for further handling
  }
};

export const uploadKenyaIdImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(
      "http://149.102.128.67:5300/api/v1/kyc-validation/kenya-id",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "*/*",
        },
      }
    );

    return response?.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      console.error("Error status:", error.response.status);
      console.error("Error headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error request:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    throw error;
  }
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await axios.post(
      "http://109.199.120.58:5300/api/v1/file-upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data?.data?.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const suspendAccount = async (payload) => {
  try {
    const { data } = await client.post(`/api/v1/account/suspend`, payload);
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

