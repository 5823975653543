import * as React from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Label,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

// Function to format Y-axis tick values
const formatYAxisTick = (value) => {
  if (value >= 1000000000) {
    return value / 1000000000 + "bn";
  } else if (value >= 1000000) {
    return value / 1000000 + "m";
  } else if (value >= 1000) {
    return value / 1000 + "k";
  } else {
    return value;
  }
};

// const datum = [
//   {
//     "label": "Nov 2023",
//     "count": 1000222000
//   },
//   {
//     "label": "Dec 2023",
//     "count": 3100222000
//   },
//   {
//     "label": "Jan 2024",
//     "count": 2100222000
//   },
//   {
//     "label": "Nov 2023",
//     "count": 4222002000
//   },
//   {
//     "label": "Dec 2023",
//     "count": 5300002000
//   },
//   {
//     "label": "Jan 2024",
//     "count": 620000100
//   }
// ]

const BasicArea = ({ datum }) => {
  const data = datum?.map((account) => ({
    name: account?.label,
    Accounts: account?.count,
  }));
  return (
    <ResponsiveContainer>
      <AreaChart
        width={630}
        height={240}
        data={data}
        margin={{ top: 5, right: 20, left: 0, bottom: 10 }}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor="rgba(39, 175, 233, 0.40)"
              stopOpacity={1}
            />
            <stop
              offset="100%"
              stopColor="rgba(255, 255, 255, 0.33)"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" axisLine={{ stroke: "#D9D9D9" }}>
          <Label
            value="Dates/Months"
            position="insideBottom"
            angle={0}
            offset={-10}
          />
        </XAxis>
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          axisLine={{ stroke: "#D9D9D9" }}
          tickFormatter={formatYAxisTick} // Use custom tick formatter
        >
          {/* Uncomment the following line if you want to display the Y-axis label */}
          {/* <Label value="Count of Accounts" angle={-90} position="insideLeft" /> */}
        </YAxis>
        <Tooltip />
        <Area
          type="monotone"
          dataKey="Accounts"
          stroke="#27AFE9"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default BasicArea;
