import React from "react";

const AccountApproval = ({
  id,
  isAccountApprovalOpen,
  closeAccountApproval,
}) => {
  const handleCancelButtonCLick = () => {
    closeAccountApproval();
  };
  return (
    <>
      <div
        id={id}
        className={`${
          isAccountApprovalOpen ? "flex" : "hidden"
        } flex items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="flex items-center justify-center w-full">
          <div className="relative bg-white shadow-lg rounded-sm w-[90%] max-w-[50%] mx-auto">
            <div className="mb-8 mx-auto items-center justify-center">
              <div className="flex items-center justify-between">
                <div className="text-[#000000] font-bold text-[20px] ml-5">
                  <p>Approve Account</p>
                </div>
                <button
                  onClick={closeAccountApproval}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm ml-auto inline-flex items-center "
                  data-modal-toggle={id}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div className="flex items-stretch mx-5">
                <div className="">
                  <svg
                    className="h-full"
                    width="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="10" height="100%" fill="#074073" />
                  </svg>
                </div>

                <div className="bg-[#D4EBFF] flex-grow py-2">
                  <div className="flex items-center mx-2">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.09826 19.2504H17.9033C19.3149 19.2504 20.1949 17.7195 19.4891 16.5004L12.5866 4.57453C11.8808 3.35536 10.1208 3.35536 9.41493 4.57453L2.51243 16.5004C1.8066 17.7195 2.6866 19.2504 4.09826 19.2504ZM11.0008 12.8337C10.4966 12.8337 10.0841 12.4212 10.0841 11.917V10.0837C10.0841 9.57953 10.4966 9.16703 11.0008 9.16703C11.5049 9.16703 11.9174 9.57953 11.9174 10.0837V11.917C11.9174 12.4212 11.5049 12.8337 11.0008 12.8337ZM11.9174 16.5004H10.0841V14.667H11.9174V16.5004Z"
                        fill="#AF0404"
                      />
                    </svg>

                    <div className="text-[#AF0404] text-[20px] font-[500] ml-3">
                      <p>Warning</p>
                    </div>
                  </div>
                  <div className="text-[#000] text-[16px] font-[300] mt-5 mx-2">
                    <p>
                      By clicking 'Yes, Approve Document,' you are confirming
                      your decision to approve this account. <br /> Once
                      approved, the account will be activated, allowing the user
                      to access all available <br /> services. Please ensure
                      that you have thoroughly reviewed the document and
                      verified that all <br /> information is accurate and
                      consistent with the user's profile. Approved applications
                      cannot <br /> be reversed without further review.
                    </p>
                  </div>
                  <div className="text-[#000] text-[20px] font-normal mt-5 mx-2">
                    <p>Are you sure you want to proceed?</p>
                  </div>
                </div>
              </div>

              <div className="flex items-center mt-5 mx-5">
                <div className="mr-5">
                  <button
                    onClick={handleCancelButtonCLick}
                    data-modal-toggle={id}
                    type="button"
                    className="text-[#FFF] hover:bg-[#074073] focus:outline-none font-medium rounded-[5px] text-md px-5 py-2 text-center 
                    bg-[#074073]"
                  >
                    No, cancel
                  </button>
                </div>
                <button
                  // onClick={handleDeleteAccount}
                  data-modal-toggle={id}
                  type="button"
                  className="text-[#074073] bg-[#FFFFFF] hover:bg-[#FFFFFF] border border-[#074073] focus:outline-none font-[500] rounded-[5px] text-md text-medium px-3 py-2 text-center"
                >
                  Yes, approve document
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountApproval;
