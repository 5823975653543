import { client } from "../client/client";

export const fetchSharesData = async (
  page,
  limit,
  query,
  status,
) => {
  try {
    const response = await client.get(
      `/api/v1/shares/members-with-shares?page=${page}&limit=${limit}&q=${query}&status=${status}`
    );
    return response?.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const exportAllShares = async (type, limit, status) => {
  const response = await client.get(
    `api/v1/shares/members-with-shares/export?format=${type}&limit=${limit}&status=${status}`,
    {
      headers: {
        "Content-Type": type === "pdf" ? "application/pdf" : "text/csv",
      },
      responseType: "arraybuffer",
    }
  );
  return response.data;
};

export const exportMemberTransPdf = async (memberId) => {
  const response = await client.get(
    `api/v1/shares/export/pdf/${memberId}`,
    {
      headers: {
        "Content-Type": "application/pdf",
        Accept: "*/*",
      },
      responseType: "arraybuffer",
    }
  );
  
  return response.data;
};

export const exportMemberTransCsv = async (memberId) => {
  const response = await client.get(
    `api/v1/shares/export/csv/${memberId}`,
    {
      headers: {
        "Content-Type": "text/csv",
        Accept: "*/*",
      },
      responseType: "arraybuffer",
    }
  );
  
  return response.data;
};


export const exportAllTransactions = async (type, limit) => {
  const response = await client.get(
    `api/v1/shares/members-with-shares/export?format=${type}&limit=${limit}`,
    {
      headers: {
        "Content-Type": type === "pdf" ? "application/pdf" : "text/csv",
      },
      responseType: "arraybuffer",
    }
  );
  return response.data;
};


export const exportSelectedShares = async (type, transactions) => {
  const response = await client.post(
    `/api/v1/shares/members-with-shares/export/selected?format=${type}`,
    transactions,
    { responseType: "arraybuffer" }
  );

  return response.data;
};

export const getSharesSummary = async (id) => {
  const response = await client.get(`/api/v1/shares/member/${id}/summary`);

  return response?.data;
};



export const getPersonalShareTranscations = async (
  page,
  limit,
  memberId
) => {
  const response = await client.get(
    `/api/v1/shares/transactions?page=${page}&limit=${limit}&memberId=${memberId}`
  );
  return response;
};