import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import FirstModal from "../../components/Modals/add_customer/personal_Info";
import SecondModal from "../../components/Modals/add_customer/email_verification";
import ThirdModal from "../../components/Modals/add_customer/phone_verification";
import ForthModal from "../../components/Modals/add_customer/identity_verification";
import PassportPhoto from "../../components/Modals/add_customer/passportPhoto";
import AddressInfo from "../../components/Modals/add_customer/addressInfo";
import FifthModal from "../../components/Modals/add_customer/additional_info";
import ReviewFinish from "../../components/Modals/add_customer/reviewFinish";
import LoadingModal from "../../components/Modals/add_customer/loadingModal";
import SuccessModal from "../../components/Modals/add_customer/successReadDoc";
import AccountCreatedSuccessful from "../../components/Modals/add_customer/accountCreatedSuccess";
import FailModal from "../../components/Modals/add_customer/failRead";
import AccountType from "../../components/Modals/add_customer/account-type";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  emailEntry,
  updateCustomer,
  getAddressInfo,
  exportAllCustomers,
  exportSelectedCustomers,
  getCustomers,
  getOldDate,
  uploadFile,
  createAccount,
  temporaryPassword,
} from "../../sdk/customers/customers";
import convertDateFormat from "../../hooks/convertDate";
import Calendar from "../../components/calendar/Calendar";
import moment from "moment";
import EditPersonalInfo from "../../components/Modals/edit-customer/EditPersonalInfo";
import { getProducts } from "../../sdk/products/products";
import { toast } from "react-toastify";
import { downloadCSV, downloadPDF } from "../../hooks/download";
import ExportMembers from "../../components/export/ExportMembers";

// Define the key names for localStorage
const LOCAL_STORAGE_KEYS = {
  LOCATION_FILTER: "locationFilter",
  STATUS: "status",
  SEARCH: "search",
  FROM_DATE: "fromDate",
  TO_DATE: "toDate",
};

const Customers = () => {
  const today = moment();

  const [refreshLoading, setRefreshLoading] = useState(false);
  const [displayedPage, setDisplayedPage] = useState(1);
  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(null);
  const [limit, setLimit] = useState(20);
  const [customerFocusedInput, setCustomerFocusedInput] = useState(null);
  const [fromDate, setFromDate] = useState(() => {
    const storedFromDate = localStorage.getItem(LOCAL_STORAGE_KEYS?.FROM_DATE);
    return storedFromDate ? moment(storedFromDate) : null;
  });

  const [toDate, setToDate] = useState(() => {
    const storedToDate = localStorage.getItem(LOCAL_STORAGE_KEYS?.TO_DATE);
    return storedToDate ? moment(storedToDate) : today;
  });

  const [status, setStatus] = useState(
    localStorage?.getItem(LOCAL_STORAGE_KEYS?.STATUS) || "Active"
  );
  const [locationFilter, setLocationFilter] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEYS?.LOCATION_FILTER) || ""
  );
  const [search, setSearch] = useState(
    localStorage?.getItem(LOCAL_STORAGE_KEYS?.SEARCH) || ""
  );
  const [totalNoOfCustomers, setTotalNoOfCustomers] = useState(null);
  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState("");
  const [id, setId] = useState(null);

  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [photoFile, setPhotoFile] = useState(null);
  const [frontFileUrl, setFrontFileUrl] = useState("");
  const [backFileUrl, setBackFileUrl] = useState("");
  const [passportFileUrl, setPassportFileUrl] = useState("");
  const [photoFileUrl, setPhotoFileUrl] = useState("");

  const submitPhotoFile = async () => {
    try {
      const photoUrl = await uploadFile(photoFile);
      setPhotoFileUrl(photoUrl);
      console.log("photoUrl", photoUrl);
    } catch (error) {
      console.error("Error submitting files:", error);
      toast.error(error?.message || "Error submitting files:", error);
    }
  };

  const submitFiles = async () => {
    setIsLoadingModal(true);
    try {
      let formData = new FormData();

      if (identificationType === "National Id") {
        const frontUrl = await uploadFile(frontFile);
        setFrontFileUrl(frontUrl);

        const backUrl = await uploadFile(backFile);
        setBackFileUrl(backUrl);

        formData.append("file", frontFile);
        formData.append("dob", "");
        formData.append("identification", "");
        formData.append("side", "");
        formData.append("userId", "");
        formData.append("firstname", "");
        formData.append("lastname", "");
      } else if (identificationType === "passport") {
        const passportUrl = await uploadFile(passportFile);
        setPassportFileUrl(passportUrl);

        formData.append("file", passportFile);
        formData.append("dob", "");
        formData.append("identification", "");
        formData.append("side", "");
        formData.append("userId", "");
        formData.append("firstname", "");
        formData.append("lastname", "");
      }

      const endpoint =
        identificationType === "National Id"
          ? "http://109.199.120.58:5300/api/v1/kyc-validation/kenya-id"
          : "http://109.199.120.58:5300/api/v1/kyc-validation/passport";

      const response = await axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { data } = response?.data;

      if (identificationType === "National Id") {
        const { idNumber, fullNames, dateOfBirth, sex } = data;
        const namesArray = fullNames.split(" ");

        const firstName = namesArray[0];
        const lastName = namesArray[namesArray?.length - 1];
        const middleName = namesArray
          ?.slice(1, namesArray.length - 1)
          ?.join(" ");

        setFirstName(firstName);
        setLastName(lastName);
        setGender(sex);
        setIdentification(idNumber);
        setMiddleName(middleName);
        setDob(dateOfBirth);
      } else if (identificationType === "passport") {
        const {
          passportNumber,
          fullName,
          dateOfBirth,
          dateOfIssue,
          dateOfExpiry,
          personalNumber,
        } = data;
        const namesArray = fullName.split(" ");

        const firstName = namesArray[0];
        const lastName = namesArray[namesArray?.length - 1];
        const middleName = namesArray
          ?.slice(1, namesArray.length - 1)
          ?.join(" ");

        setFirstName(firstName);
        setMiddleName(middleName);
        setLastName(lastName);
        // setPassportNumber(passportNumber);
        setIdentification(passportNumber);
        setDob(dateOfBirth);
        setDateOfIssue(dateOfIssue);
        setDateOfExpiry(dateOfExpiry);
        setPersonalNumber(personalNumber);
      }
      // Check for empty entries
      const isNationalIdValid = identificationType === "National Id";

      const isPassportValid = identificationType === "passport";

      if (isNationalIdValid || isPassportValid) {
        // Open success modal
        setIsSuccessModal(true);

        setTimeout(() => {
          setIsSuccessModal(false);

          closeIDlModal();

          openModal();
        }, 2000);
      } else {
        // Open failure modal
        setIsFailModal(true);
        // Ensure the ID modal remains open on failure
        openIDModal();
      }
    } catch (error) {
      console.error("Error submitting files:", error);
      toast.error(error?.message || "Error submitting files:", error);
      setIsFailModal(true);

      // Ensure the ID modal remains open on error
      openIDModal();
    } finally {
      setIsLoadingModal(false);
    }
  };

  const fetchProducts = async () => {
    const response = await getProducts();
    setProducts(response.data);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleLimitChange = (e) => {
    const newItemsPerPage = Number(e.target.value);
    setLimit(newItemsPerPage);
    setCurrentPage(1);
  };

  const [customers, setCustomers] = useState([]);

  const handleLocationChange = (e) => {
    setLocationFilter(e.target.value);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isloadingModal, setIsLoadingModal] = useState(false);

  const closeLoadingModal = () => {
    setIsLoadingModal(false);
  };

  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  const [isFailModal, setIsFailModal] = useState(false);
  const openFailModal = () => {
    setIsFailModal(true);
  };

  const closeFailModal = () => {
    setIsFailModal(false);
  };

  const [isContinueEnabled, setIsContinueEnabled] = useState(false);

  const handleNextButtonClick = async () => {
    if (isContinueEnabled) {
      await submitFiles();
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [kraPin, setKraPin] = useState("");
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [countryOfResidence, setCountryOfResidence] = useState("KENYA");
  const [county, setCounty] = useState("");
  const [subCounty, setSubCounty] = useState("");
  const [town, setTown] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [employment_type, setEmploymentType] = useState("Employed");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");

  const [passportNumber, setPassportNumber] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [dateOfExpiry, setDateOfExpiry] = useState("");
  const [personalNumber, setPersonalNumber] = useState("");

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedToday = `${year}-${month}-${day}`;

    setDob(formattedToday);
  }, []);

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const openEmailModal = () => {
    setEmailModalOpen(true);
  };
  const closeEmailModal = () => {
    setEmailModalOpen(false);
  };

  const [editCustomerOpen, setEditCustomerOpen] = useState(false);

  const handleOpenEditCustomerPersonalInfo = () => {
    setEditCustomerOpen(true);
  };

  const handleCloseEditCustomerPersonalInfo = () => {
    setEditCustomerOpen(false);
  };

  const [phoneNumber, setPhoneNumber] = useState("");

  const [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const openPhoneModal = () => {
    setPhoneModalOpen(true);
  };
  const closePhoneModal = () => {
    setPhoneModalOpen(false);
  };

  const [identification, setIdentification] = useState("");
  const [identificationType, setIdentificationType] = useState("National Id");
  const [country, setCountry] = useState("Kenya");

  const [isIDModalOpen, setIsIDModalOpen] = useState(false);
  const openIDModal = () => {
    setIsIDModalOpen(true);
  };
  const closeIDlModal = () => {
    setIsIDModalOpen(false);
  };

  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const openPhotoModal = () => {
    setIsPhotoModalOpen(true);
  };
  const closePhotoModal = () => {
    setIsPhotoModalOpen(false);
  };
  const [addressInfoModalOpen, setAddressInfoModalOpen] = useState(false);
  const openAddressInfoModal = () => {
    setAddressInfoModalOpen(true);
  };
  const closeAddressInfoModal = () => {
    setAddressInfoModalOpen(false);
  };

  const [addInfoModalOpen, setAddInfoModalOpen] = useState(false);
  const openAddInfoModal = () => {
    setAddInfoModalOpen(true);
  };
  const closeAddInfoModal = () => {
    setAddInfoModalOpen(false);
  };

  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const openReviewModal = () => {
    setReviewModalOpen(true);
  };

  const closeReviewModal = () => {
    setReviewModalOpen(false);
  };

  const [accountTypeModalOpen, setAccountTypeModalOpen] = useState(false);

  const handleOpenSetUpAccountEmail = () => {
    setEmailModalOpen(true);
  };

  const handleCloseSetUpAccountEmail = () => {
    setEmailModalOpen(false);
  };

  const handleCloseAccountType = () => {
    setAccountTypeModalOpen(false);
  };

  const handleOpenAccountType = () => {
    setAccountTypeModalOpen(true);
  };

  const handleContinueButtonClick = async () => {
    // Close the account type modal
    handleCloseAccountType();
    // Open the ID modal
    openIDModal();
  };

  const navigate = useNavigate();

  const navigateTo = (id) => {
    navigate(`/dashboard/customers/${id}`);
  };

  const fetchCustomers = async () => {
    const result = await getCustomers(
      limit,
      currentPage,
      convertDateFormat(fromDate),
      convertDateFormat(toDate),
      search,
      status,
      locationFilter
    );
    setDisplayedPage(result?.data?.meta?.currentPage);
    setTotalPages(result?.data?.meta?.totalPages);
    setCustomers(result?.data?.items);
    setTotalNoOfCustomers(result?.data?.meta?.totalItems);
  };
  useEffect(() => {
    fetchOldDated();
  }, []);

  const fetchOldDated = async () => {
    try {
      let page = 1;
      let allItems = [];
      let result;

      do {
        result = await getOldDate(page);
        const items = result?.data?.items || [];
        allItems = [...allItems, ...items];
        page++;
      } while (result?.data?.items?.length > 0);

      if (allItems?.length > 0) {
        const oldest = allItems?.reduce((prev, current) => {
          return new Date(prev?.createdAt) < new Date(current?.createdAt)
            ? prev
            : current;
        });

        setFromDate(oldest?.createdAt ? moment(oldest?.createdAt) : null);
      } else {
        setFromDate(today?.clone()?.startOf("month"));
      }
    } catch (error) {
      console.error("Error fetching old date data:", error);
    }
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    await fetchCustomers();
    setRefreshLoading(false);
  };

  useEffect(() => {
    fetchCustomers();
  }, [limit, currentPage, fromDate, toDate, search, status, locationFilter]);

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= Math.min(totalPages, 3); i++) {
      pages.push(
        <div
          key={i}
          className={`w-[36px] cursor-pointer h-[36px] rounded-[10px] flex items-center justify-center ${
            currentPage === i
              ? "bg-[#074073] text-white"
              : "bg-[#F5F5F5] text-[#074073]"
          }`}
          onClick={() => handlePageChange(i)}
        >
          <p>{i}</p>
        </div>
      );
    }

    return pages;
  };

  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (event, customer) => {
    if (
      selectedCustomers.some(
        (selectedCustomer) => selectedCustomer.id === customer.id
      )
    ) {
      setSelectedCustomers(
        selectedCustomers.filter(
          (selectedCustomer) => selectedCustomer.id !== customer.id
        )
      );
    } else {
      setSelectedCustomers([...selectedCustomers, customer]);
    }
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedCustomers(isChecked ? customers : []);
  };

  const handleExportAllCustomers = async (exportType, limit, status) => {
    try {
      const response = await exportAllCustomers(exportType, limit, status);
      exportType === "pdf"
        ? await downloadPDF(response, "members.pdf")
        : await downloadCSV(response, "members.csv");
    } catch (error) {
      console.error("Error during export:", error);
    }
  };

  const handleExportCustomers = async (exportType) => {
    try {
      const response = await exportSelectedCustomers(
        exportType,
        selectedCustomers
      );
      exportType === "pdf"
        ? await downloadPDF(response, "customers.pdf")
        : await downloadCSV(response, "customers.csv");
      setSelectAll(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const appendCountryCode = (number) => {
    if (number.startsWith("0")) {
      number = number.slice(1);
    }
    return "+254" + number;
  };

  const handleEmailEntry = async () => {
    const payload = {
      email,
      product_id: productId?.id,
    };

    try {
      const response = await emailEntry(payload);

      // setId(response?.data?.id);
      // console.log("Customer", response?.data?.id);
      if (response?.data?.id) {
        setId(response.data.id);
        // console.log("Customer ID set:", response.data.id);
      } else {
        console.error("ID not found in response:", response);
      }
    } catch (error) {
      toast.error(error?.message || "Error registering customer:", error);
      throw error;
    }
  };
  // Function to convert date format from DD.MM.YYYY to YYYY-MM-DD
  const convertToISODate = (dateString) => {
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    if (id) {
      // Proceed with the next steps once id is set
      handleFinishButtonClick();
    }
  }, [id]);
  const handleFinishButtonClick = async () => {
    const payload = {
      firstname: firstName,
      middlename: middleName,
      lastname: lastName,
      identification: identification,
      identification_type: identificationType,
      mobileno: appendCountryCode(phoneNumber),
      country_of_residence: countryOfResidence,
      dob: convertToISODate(dob),
      kraPin,
      occupation: jobTitle,
      income_range: monthlyIncome,
      citizenship: country,
      id_back_image: backFileUrl,
      id_front_image: frontFileUrl,
      passport_image: passportFileUrl,
      selfie_image: photoFileUrl,
      email,
      gender,
      employment_type,
      status: "Pending",
      temporary_password: true,
    };

    try {
      await updateCustomer(id, payload);
      await getAddressInfo({
        customer_id: id,
        county,
        subcounty: subCounty,
        physical_address: physicalAddress,
        city: town,
      });
      await createAccount({
        product_id: productId?.id,
        branch_id: "71cbca39-4872-48fa-9129-c4ef3b20fb21",
        customer_id: id,
      });
      await temporaryPassword({ email });
      // Close the current modal
      // closeReviewModal();
    } catch (error) {
      toast.error(
        error?.message || "Error updating member and creating account:",
        error
      );
    }
  };

  // Save filter settings to localStorage
  const saveFiltersToLocalStorage = () => {
    localStorage?.setItem(LOCAL_STORAGE_KEYS?.LOCATION_FILTER, locationFilter);
    localStorage?.setItem(LOCAL_STORAGE_KEYS?.STATUS, status);
    localStorage?.setItem(LOCAL_STORAGE_KEYS?.SEARCH, search);
    localStorage?.setItem(LOCAL_STORAGE_KEYS?.FROM_DATE, fromDate);
    localStorage?.setItem(LOCAL_STORAGE_KEYS?.TO_DATE, toDate);
  };

  // Load filter settings from localStorage
  const loadFiltersFromLocalStorage = () => {
    const storedLocationFilter = localStorage?.getItem(
      LOCAL_STORAGE_KEYS?.LOCATION_FILTER
    );
    const storedStatus = localStorage?.getItem(LOCAL_STORAGE_KEYS?.STATUS);
    const storedSearch = localStorage?.getItem(LOCAL_STORAGE_KEYS?.SEARCH);
    const storedFromDate = localStorage?.getItem(LOCAL_STORAGE_KEYS?.FROM_DATE);
    const storedToDate = localStorage?.getItem(LOCAL_STORAGE_KEYS?.TO_DATE);

    if (storedLocationFilter) setLocationFilter(storedLocationFilter);
    if (storedStatus) setStatus(storedStatus);
    if (storedSearch) setSearch(storedSearch);
    if (storedFromDate) setFromDate(moment(storedFromDate));
    if (storedToDate) setToDate(moment(storedToDate));
  };
  useEffect(() => {
    if (fromDate) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS?.FROM_DATE,
        fromDate.format("YYYY-MM-DD")
      );
    }
  }, [fromDate]);

  useEffect(() => {
    if (toDate) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS?.TO_DATE,
        toDate.format("YYYY-MM-DD")
      );
    }
  }, [toDate]);

  useEffect(() => {
    loadFiltersFromLocalStorage();
  }, []);

  useEffect(() => {
    saveFiltersToLocalStorage();
  }, [locationFilter, status, search, fromDate, toDate]);

  const handleCompleteRegistration = async () => {
    try {
      // Step 1: Call handleEmailEntry to register the customer and get customerID
      await handleEmailEntry();

      // Step 2: Ensure the id is set before proceeding
      if (id) {
        // Step 3: Call handleFinishButtonClick to update the customer details
        await handleFinishButtonClick();
      }

      // await closeReviewModal();
      // Open success modal
      await setIsAccountCreated(true);

      setTimeout(() => {
        setIsAccountCreated(false);
        closeReviewModal();
      }, 3000);
    } catch (error) {
      toast.error(
        error?.message || "Error in the complete registration process:",
        error
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anansi || Customers</title>
      </Helmet>
      <div className="w-[100%] bg-[#FFF] h-full overflow-y-auto">
        <div className="bg-[#FFF] mx-[20px]">
          <div className="mb-[20px] flex items-center justify-between w-full">
            <p className="text-[#074073] text-[20px] font-bold">Members</p>
            <button
              onClick={handleOpenSetUpAccountEmail}
              className="block text-white bg-[#074073] focus:outline-none focus:ring-[#074073] rounded-[30px] text-center w-[156px] h-[42px]"
              type="button"
            >
              <div className="flex items-center justify-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                    fill="white"
                  />
                </svg>
                <p className="text-[#FFF] text-[14px]">Add Member</p>
              </div>
            </button>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="relative w-full">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                >
                  <circle
                    cx="5.35294"
                    cy="5.35294"
                    r="4.85294"
                    stroke="#999999"
                  />
                  <line
                    x1="9.52933"
                    y1="8.82294"
                    x2="13.3529"
                    y2="12.6465"
                    stroke="#999999"
                  />
                </svg>
              </span>
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search for Account number, name, member ID, email address, or phone number"
                className="h-[35px] pl-10 border-[1px] border-[#CCCCCC] text-[14px] w-[60%] px-[10px] focus:outline-none"
                autoComplete="nope"
              />
            </div>
            <div className="flex items-center gap-[20px]">
              <button
                onClick={handleRefresh}
                className="block text-white bg-[#074073] focus:outline-none focus:ring-[#074073] rounded-[3px] text-center px-[20px] h-[40px]"
                type="button"
              >
                <div className="flex w-[120px] items-center justify-center gap-2">
                  {!refreshLoading && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                    >
                      <path
                        d="M14.5931 22.9583C12.232 22.9583 10.2318 22.1395 8.59252 20.5018C6.95402 18.8633 6.13477 16.8639 6.13477 14.5036C6.13477 12.1433 6.95402 10.1431 8.59252 8.50303C10.2318 6.86211 12.232 6.04166 14.5931 6.04166C16.0294 6.04166 17.3739 6.38281 18.6265 7.06511C19.88 7.74742 20.8901 8.69999 21.657 9.92282V6.04166H22.8653V12.3612H16.5458V11.1529H21.0166C20.3875 9.9486 19.5022 8.99684 18.3607 8.29761C17.2208 7.5992 15.965 7.24999 14.5931 7.24999C12.5792 7.24999 10.8674 7.95485 9.45768 9.36457C8.04796 10.7743 7.3431 12.4861 7.3431 14.5C7.3431 16.5139 8.04796 18.2257 9.45768 19.6354C10.8674 21.0451 12.5792 21.75 14.5931 21.75C16.1438 21.75 17.5434 21.3069 18.7921 20.4208C20.0407 19.5347 20.9167 18.3667 21.4202 16.9167H22.7022C22.1698 18.7259 21.1576 20.1852 19.6657 21.2944C18.1738 22.4037 16.4829 22.9583 14.5931 22.9583Z"
                        fill="white"
                      />
                    </svg>
                  )}
                  {refreshLoading && (
                    <svg
                      aria-hidden="true"
                      className="w-[20px] h-[20px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  )}
                  <p className="text-[#FFF] font-normal text-[16px]">REFRESH</p>
                </div>
              </button>
              <ExportMembers
                handleExportCustomers={handleExportCustomers}
                handleExportAllCustomers={handleExportAllCustomers}
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
                limit={limit}
                status={status}
              />
            </div>

            <SecondModal
              id_R="secondModal"
              closeEmailModal={closeEmailModal}
              emailModalOpen={emailModalOpen}
              openPhoneModal={openPhoneModal}
              openModal={openModal}
              email={email}
              setEmail={setEmail}
            />
            <ThirdModal
              id_R="thirdModal"
              closePhoneModal={closePhoneModal}
              phoneModalOpen={phoneModalOpen}
              phoneNumber={phoneNumber}
              handleOpenAccountType={handleOpenAccountType}
              setPhoneNumber={setPhoneNumber}
              openEmailModal={openEmailModal}
            />
            <AccountType
              id_R="initial"
              accountTypeModalOpen={accountTypeModalOpen}
              handleCloseAccountType={handleCloseAccountType}
              openPhoneModal={openPhoneModal}
              products={products}
              setProductId={setProductId}
              productId={productId}
              handleContinueButtonClick={handleContinueButtonClick}
            />
            <ForthModal
              id_R="forthModal"
              closeIDlModal={closeIDlModal}
              isIDModalOpen={isIDModalOpen}
              identificationType={identificationType}
              setIdentificationType={setIdentificationType}
              country={country}
              openModal={openModal}
              setCountry={setCountry}
              handleOpenAccountType={handleOpenAccountType}
              setIsLoadingModal={setIsLoadingModal}
              frontFile={frontFile}
              setFrontFile={setFrontFile}
              backFile={backFile}
              setBackFile={setBackFile}
              passportFile={passportFile}
              setPassportFile={setPassportFile}
              isContinueEnabled={isContinueEnabled}
              setIsContinueEnabled={setIsContinueEnabled}
              handleNextButtonClick={handleNextButtonClick}
            />
            <FirstModal
              id="firstModal"
              closeModalFunction={closeModal}
              isModalOpen={isModalOpen}
              openEmailModal={openEmailModal}
              firstName={firstName}
              lastName={lastName}
              middleName={middleName}
              gender={gender}
              identification={identification}
              dob={dob}
              openIDModal={openIDModal}
              openPhotoModal={openPhotoModal}
            />
            <PassportPhoto
              id_R="forthModal"
              closePhotoModal={closePhotoModal}
              isPhotoModalOpen={isPhotoModalOpen}
              openModal={openModal}
              openAddInfoModal={openAddInfoModal}
              openAddressInfoModal={openAddressInfoModal}
              photoFile={photoFile}
              setPhotoFile={setPhotoFile}
              submitPhotoFile={submitPhotoFile}
            />
            <AddressInfo
              id_R="forthModal"
              closeAddressInfoModal={closeAddressInfoModal}
              addressInfoModalOpen={addressInfoModalOpen}
              openPhotoModal={openPhotoModal}
              openAddInfoModal={openAddInfoModal}
              countryOfResidence={countryOfResidence}
              setCountryOfResidence={setCountryOfResidence}
              town={town}
              setTown={setTown}
              physicalAddress={physicalAddress}
              setPhysicalAddress={setPhysicalAddress}
              county={county}
              setCounty={setCounty}
              subCounty={subCounty}
              setSubCounty={setSubCounty}
            />
            <FifthModal
              id_R="forthModal"
              closeAddInfoModal={closeAddInfoModal}
              addInfoModalOpen={addInfoModalOpen}
              openPhoneModal={openPhoneModal}
              openAddressInfoModal={openAddressInfoModal}
              kraPin={kraPin}
              setKraPin={setKraPin}
              monthlyIncome={monthlyIncome}
              setMonthlyIncome={setMonthlyIncome}
              countryOfResidence={countryOfResidence}
              setCountryOfResidence={setCountryOfResidence}
              town={town}
              setTown={setTown}
              physicalAddress={physicalAddress}
              setPhysicalAddress={setPhysicalAddress}
              landmark={landmark}
              setLandmark={setLandmark}
              employment_type={employment_type}
              setEmploymentType={setEmploymentType}
              jobTitle={jobTitle}
              setJobTitle={setJobTitle}
              openReviewModal={openReviewModal}
            />
            <ReviewFinish
              id_R="forthModal"
              reviewModalOpen={reviewModalOpen}
              openReviewModal={openReviewModal}
              closeReviewModal={closeReviewModal}
              openAddInfoModal={openAddInfoModal}
              firstName={firstName}
              lastName={lastName}
              middleName={middleName}
              email={email}
              phoneNumber={phoneNumber}
              dob={dob}
              kraPin={kraPin}
              monthlyIncome={monthlyIncome}
              employment_type={employment_type}
              physicalAddress={physicalAddress}
              town={town}
              landmark={landmark}
              identification={identification}
              jobTitle={jobTitle}
              county={county}
              subCounty={subCounty}
              openModal={openModal}
              openIDModal={openIDModal}
              productId={productId}
              appendCountryCode={appendCountryCode}
              handleCompleteRegistration={handleCompleteRegistration}
            />
            <LoadingModal
              isloadingModal={isloadingModal}
              closeLoadingModal={closeLoadingModal}
            />
            <SuccessModal isSuccessModal={isSuccessModal} />
            <AccountCreatedSuccessful isAccountCreated={isAccountCreated} />
            <FailModal
              isFailModal={isFailModal}
              closeFailModal={closeFailModal}
            />
            {/* edit customer */}
            <EditPersonalInfo
              id_R="firstEditModal"
              editCustomerOpen={editCustomerOpen}
              handleCloseEditCustomerPersonalInfo={
                handleCloseEditCustomerPersonalInfo
              }
            />
          </div>
          <div className="h-[63px] flex items-center px-[10px] gap-[40px] w-full bg-[#FBF0E1] mt-4">
            <div className="flex items-center gap-[10px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M1.66699 4.58334C1.66699 4.25182 1.79869 3.93388 2.03311 3.69946C2.26753 3.46504 2.58547 3.33334 2.91699 3.33334H17.0837C17.4152 3.33334 17.7331 3.46504 17.9675 3.69946C18.202 3.93388 18.3337 4.25182 18.3337 4.58334C18.3337 4.91486 18.202 5.23281 17.9675 5.46723C17.7331 5.70165 17.4152 5.83334 17.0837 5.83334H2.91699C2.58547 5.83334 2.26753 5.70165 2.03311 5.46723C1.79869 5.23281 1.66699 4.91486 1.66699 4.58334ZM5.00033 9.58334C5.00033 9.25182 5.13202 8.93388 5.36644 8.69946C5.60086 8.46504 5.9188 8.33334 6.25033 8.33334H13.7503C14.0818 8.33334 14.3998 8.46504 14.6342 8.69946C14.8686 8.93388 15.0003 9.25182 15.0003 9.58334C15.0003 9.91486 14.8686 10.2328 14.6342 10.4672C14.3998 10.7016 14.0818 10.8333 13.7503 10.8333H6.25033C5.9188 10.8333 5.60086 10.7016 5.36644 10.4672C5.13202 10.2328 5.00033 9.91486 5.00033 9.58334ZM8.75033 13.3333C8.4188 13.3333 8.10086 13.465 7.86644 13.6995C7.63202 13.9339 7.50033 14.2518 7.50033 14.5833C7.50033 14.9149 7.63202 15.2328 7.86644 15.4672C8.10086 15.7016 8.4188 15.8333 8.75033 15.8333H11.2503C11.5818 15.8333 11.8998 15.7016 12.1342 15.4672C12.3686 15.2328 12.5003 14.9149 12.5003 14.5833C12.5003 14.2518 12.3686 13.9339 12.1342 13.6995C11.8998 13.465 11.5818 13.3333 11.2503 13.3333H8.75033Z"
                  fill="black"
                />
              </svg>
              <p>DATE</p>
              <Calendar
                startDate={fromDate}
                endDate={toDate}
                setStartDate={setFromDate}
                setEndDate={setToDate}
                focusedInput={customerFocusedInput}
                setFocusedInput={setCustomerFocusedInput}
              />
            </div>
            <div className="flex items-center gap-[5px]">
              <p>LOCATION</p>
              <input
                type="text"
                onChange={handleLocationChange}
                value={locationFilter}
                className="w-[180px] h-[30px] px-[10px] focus:outline-none"
                placeholder="Enter a locationFilter"
              />
            </div>
            <div className="flex items-center gap-[5px]">
              <p>ACCOUNT STATUS</p>
              <select
                name="status"
                id="status"
                className="w-[120px] h-[30px] px-[10px] focus:outline-none"
                value={status}
                onChange={handleStatusChange}
              >
                <option value="All">All</option>
                <option value="Active">Active</option>
                <option value="Closed">Closed</option>
                <option value="Incomplete">Incomplete</option>
                <option value="Pending">Pending</option>
                <option value="Suspended">Suspended</option>
              </select>
            </div>
          </div>
          <div className="h-[44px] bg-[#E3F8FF] w-full flex items-center mt-[2px]">
            <input
              type="checkbox"
              name="transaction"
              id="transaction"
              className="w-[7%] mt-2 flex items-center"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <p className="w-[10%] text-[14px] text-[#000000] mt-2">MEMBER ID</p>
            <p className="w-[15%] text-[14px] text-[#000000] mt-2">
              MEMBER NAME
            </p>
            <p className="w-[10%] text-[14px] text-[#000000]">ID NO.</p>
            <p className="w-[13%] text-[14px] text-[#000000]">PHONE NUMBER</p>
            <p className="w-[10%] text-[14px] text-[#000000]">LOCATION</p>
            <p className="w-[15%] text-[14px] text-[#000000]">DATE</p>
            <p className="w-[12%] text-[14px] text-[#000000]">STATUS</p>
            <p className="w-[10%] text-[14px] text-[#000000]">ACTION</p>
          </div>
          {customers &&
            customers?.map((customer) => (
              <div
                key={customer?.id}
                className="h-[44px] bg-[#fff] w-full flex items-center"
              >
                <input
                  type="checkbox"
                  name="customer"
                  id={`customer-${customer?.id}`}
                  className="w-[7%] flex"
                  onChange={(event) => handleCheckboxChange(event, customer)}
                  checked={selectedCustomers.some(
                    (selectedCustomer) => selectedCustomer?.id === customer?.id
                  )}
                />
                <p
                  onClick={() => navigateTo(customer?.id)}
                  className="w-[10%] text-[14px] cursor-pointer text-[#037BD1]"
                >
                  {customer?.public_id}
                </p>
                <p className="w-[15%] text-[14px] text-[#000000]">
                  {customer?.firstname?.charAt(0)?.toUpperCase() +
                    customer?.firstname?.slice(1)?.toLowerCase()}{" "}
                  {customer?.lastname?.charAt(0)?.toUpperCase() +
                    customer?.lastname?.slice(1)?.toLowerCase()}
                </p>
                <p className="w-[10%] text-[14px] text-[#000000]">
                  {customer?.identification}
                </p>
                <p className="w-[13%] text-[14px] text-[#000000]">
                  {customer?.mobileno}
                </p>
                <p className="w-[10%] truncate text-[14px] text-[#000000]">
                  {customer?.addresses[customer?.addresses?.length - 1]
                    ?.county || null}
                </p>
                <p className="w-[15%] text-[14px] text-[#000000]">
                  {new Date(customer?.createdAt)?.toISOString()?.split("T")[0]}
                </p>
                <div className="flex items-center gap-[5px] w-[12%]">
                  {customer?.status === "Active" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_6917_381)">
                        <path
                          d="M6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12Z"
                          fill="#0C722F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6917_381">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {customer?.status === "Suspended" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_6917_381)">
                        <path
                          d="M6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12Z"
                          fill="#FAB32B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6917_381">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {customer?.status === "Blocked" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_6917_381)">
                        <path
                          d="M6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12Z"
                          fill="#FF5757"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6917_381">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {customer?.status === "Pending" && (
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.75 8.625C4.0625 8.625 4.32825 8.5155 4.54725 8.2965C4.76625 8.0775 4.8755 7.812 4.875 7.5C4.875 7.1875 4.7655 6.92175 4.5465 6.70275C4.3275 6.48375 4.062 6.3745 3.75 6.375C3.4375 6.375 3.17175 6.4845 2.95275 6.7035C2.73375 6.9225 2.6245 7.188 2.625 7.5C2.625 7.8125 2.7345 8.07825 2.9535 8.29725C3.1725 8.51625 3.438 8.6255 3.75 8.625ZM7.5 8.625C7.8125 8.625 8.07825 8.5155 8.29725 8.2965C8.51625 8.0775 8.6255 7.812 8.625 7.5C8.625 7.1875 8.5155 6.92175 8.2965 6.70275C8.0775 6.48375 7.812 6.3745 7.5 6.375C7.1875 6.375 6.92175 6.4845 6.70275 6.7035C6.48375 6.9225 6.3745 7.188 6.375 7.5C6.375 7.8125 6.4845 8.07825 6.7035 8.29725C6.9225 8.51625 7.188 8.6255 7.5 8.625ZM11.25 8.625C11.5625 8.625 11.8282 8.5155 12.0472 8.2965C12.2662 8.0775 12.3755 7.812 12.375 7.5C12.375 7.1875 12.2655 6.92175 12.0465 6.70275C11.8275 6.48375 11.562 6.3745 11.25 6.375C10.9375 6.375 10.6717 6.4845 10.4527 6.7035C10.2337 6.9225 10.1245 7.188 10.125 7.5C10.125 7.8125 10.2345 8.07825 10.4535 8.29725C10.6725 8.51625 10.938 8.6255 11.25 8.625ZM7.5 15C6.4625 15 5.4875 14.803 4.575 14.409C3.6625 14.015 2.86875 13.4807 2.19375 12.8062C1.51875 12.1312 0.9845 11.3375 0.591 10.425C0.1975 9.5125 0.0005 8.5375 0 7.5C0 6.4625 0.197 5.4875 0.591 4.575C0.985 3.6625 1.51925 2.86875 2.19375 2.19375C2.86875 1.51875 3.6625 0.9845 4.575 0.591C5.4875 0.1975 6.4625 0.0005 7.5 0C8.5375 0 9.5125 0.197 10.425 0.591C11.3375 0.985 12.1312 1.51925 12.8062 2.19375C13.4812 2.86875 14.0157 3.6625 14.4097 4.575C14.8037 5.4875 15.0005 6.4625 15 7.5C15 8.5375 14.803 9.5125 14.409 10.425C14.015 11.3375 13.4807 12.1312 12.8062 12.8062C12.1312 13.4812 11.3375 14.0157 10.425 14.4097C9.5125 14.8037 8.5375 15.0005 7.5 15Z"
                        fill="#554D4D"
                      />
                    </svg>
                  )}
                  <p className="text-[14px] text-[#000000]">
                    {customer?.status}
                  </p>
                </div>
                <div className="flex items-center gap-[5px] w-[10%]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.4768 3.00406C16.6438 3.01906 16.7168 3.22306 16.5968 3.34206L8.27682 11.6621C8.18283 11.7561 8.11553 11.8734 8.08182 12.0021L7.08182 15.8321C7.04881 15.9586 7.04948 16.0916 7.08375 16.2179C7.11803 16.3441 7.18472 16.4592 7.27721 16.5517C7.36971 16.6442 7.48478 16.7109 7.61101 16.7451C7.73725 16.7794 7.87025 16.7801 7.99682 16.7471L11.8258 15.7471C11.9545 15.713 12.0719 15.6454 12.1658 15.5511L20.6038 7.11306C20.6302 7.08596 20.6638 7.06699 20.7007 7.05839C20.7375 7.0498 20.7761 7.05194 20.8117 7.06457C20.8474 7.07719 20.8787 7.09977 20.9019 7.12963C20.9252 7.15949 20.9394 7.19539 20.9428 7.23306C21.2939 10.5827 21.2738 13.9609 20.8828 17.3061C20.6598 19.2111 19.1288 20.7061 17.2308 20.9191C13.7545 21.3045 10.2462 21.3045 6.76982 20.9191C4.87082 20.7061 3.33982 19.2111 3.11682 17.3061C2.70455 13.7811 2.70455 10.22 3.11682 6.69506C3.33982 4.78906 4.87082 3.29406 6.76982 3.08206C9.99434 2.72472 13.247 2.69858 16.4768 3.00406Z"
                      fill="#074073"
                    />
                    <path
                      d="M17.8231 4.23702C17.8463 4.21374 17.8739 4.19527 17.9043 4.18266C17.9347 4.17006 17.9672 4.16357 18.0001 4.16357C18.033 4.16357 18.0656 4.17006 18.0959 4.18266C18.1263 4.19527 18.1539 4.21374 18.1771 4.23702L19.5911 5.65202C19.6378 5.69888 19.6641 5.76235 19.6641 5.82852C19.6641 5.89469 19.6378 5.95816 19.5911 6.00502L11.2981 14.3C11.2665 14.3313 11.2272 14.3538 11.1841 14.365L9.27012 14.865C9.22793 14.876 9.1836 14.8758 9.14152 14.8644C9.09944 14.853 9.06109 14.8307 9.03025 14.7999C8.99942 14.7691 8.97719 14.7307 8.96577 14.6886C8.95434 14.6465 8.95412 14.6022 8.96512 14.56L9.46512 12.646C9.47625 12.6029 9.49869 12.5636 9.53012 12.532L17.8231 4.23702Z"
                      fill="#074073"
                    />
                  </svg>
                </div>
              </div>
            ))}
        </div>
        <div className="h-[70px] bg-white w-[97%] px-[20px] mx-auto mb-[20px] flex items-center justify-between">
          <div className="flex items-center gap-[20px]">
            <p className="text-[#074073] text-[14px]">Rows per page</p>
            <select
              name="page"
              id="page"
              className="w-[71px] border-[1px] px-[5px] h-[30px]"
              value={limit}
              onChange={handleLimitChange}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
            <p className="text-[#074073] text-[14px]">
              {displayedPage} / {totalPages} ({totalNoOfCustomers} records)
            </p>
          </div>
          <div className="flex items-center gap-[15px]">
            <p
              onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
              className="text-[14px] text-[#074073] cursor-pointer"
            >
              Prev
            </p>
            {renderPages()}
            <div
              className={`w-[36px] cursor-pointer h-[36px] rounded-[10px] flex items-center justify-center ${
                Number(currentPage) > 3
                  ? "bg-[#074073] text-white"
                  : "bg-[#F5F5F5] text-[#074073]"
              }`}
            >
              <p>{Number(currentPage) > 3 ? currentPage : "..."}</p>
            </div>
            <p
              onClick={() =>
                handlePageChange(Math.min(currentPage + 1, totalPages))
              }
              className="text-[14px] cursor-pointer text-[#074073]"
            >
              Next
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
