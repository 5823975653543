import React, { useState, useEffect } from "react";

const EditMetaData = ({
  id_R,
  closeMetaData,
  isMetaDataOpen,
  identity,
  setIdentity,
  setViewIDOpen,
  setIsSaveChanges,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // Disable the button if identity is empty
    setIsButtonDisabled(identity.trim() === "");
  }, [identity]);

  const handleBackButtonClick = () => {
    // Close the current modal
    closeMetaData();

    // Open the previous modal

    setViewIDOpen(true);
  };
  const handleNextButtonClick = async () => {
    if (!isButtonDisabled) {
      // Close the current modal
      closeMetaData();

      // Open the next modal
      setIsSaveChanges(true);
    }
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          isMetaDataOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[54%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className="w-full flex flex-col mx-5">
              <div className="flex items-center justify-end">
                <button
                  onClick={closeMetaData}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">Edit metadata</p>
                  <div className="text-[#000] mt-[30px] text-[15px] font-[300]">
                    <p className="w-[99%]">
                      ⚠️ This option allows you to update the information
                      associated with the National ID, such as the ID number.
                      Only proceed with these changes if there is a valid reason
                      and sufficient evidence that the current details are
                      incorrect or outdated.
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-10"></div>
              </div>
              <div className="flex w-[45%] flex-col justify-center">
                <label
                  htmlFor="identity"
                  className="mb-1 text-[16px] font-normal text-[#000]"
                >
                  Identity document Number
                </label>
                <input
                  type="text"
                  id="identity"
                  name="identity"
                  placeholder="Enter your identity"
                  value={identity}
                  onChange={(e) => setIdentity(e.target.value)}
                  className="w-[90%] h-10 px-4 border border-[1px] border-[#000] rounded-[4px]"
                />
              </div>
              <div className="flex w-[95%] items-center justify-between mt-48 mb-10 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                </div>
                <button
                  onClick={handleNextButtonClick}
                  data-modal-toggle={id_R}
                  type="button"
                  className={`text-white focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center ${
                    isButtonDisabled
                      ? "bg-[#D9D9D9] cursor-not-allowed"
                      : "bg-[#074073] hover:bg-[#074073]"
                  }`}
                  disabled={isButtonDisabled}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMetaData;
