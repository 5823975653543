import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSharesSummary,
  getPersonalShareTranscations,
  exportMemberTransCsv,
  exportMemberTransPdf,
} from "../../sdk/shares/shares";
import { downloadCSV, downloadPDF } from "../../hooks/download";
import ExportPdfCsv from "../../components/export/ExportPdfCsv";
import { toast } from "react-toastify";

const ShareView = () => {
  const [sharesSummary, setSharesSummary] = useState(null);
  const [personalSharesTransactions, setPersonalSharesTransactions] =
    useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const editPopupRef = useRef(null);

  const navigate = useNavigate();
  const { memberId } = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (memberId) {
      fetchSharesSummary(memberId);
    }
  }, [memberId]);

  const fetchSharesSummary = async (memberId) => {
    try {
      const res = await getSharesSummary(memberId);
      setSharesSummary(res?.data);
    } catch (error) {
      console.error("Error fetching summary data:", error);
    }
  };

  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(null);
  const [limit, setLimit] = useState(10);
  const [displayedPage, setDisplayedPage] = useState(1);
  const [totalNoOfLoans, setTotalNoOfLoans] = useState(null);

  const fetchPersonalShareTranscations = async () => {
    try {
      const response = await getPersonalShareTranscations(
        currentPage,
        limit,
        memberId
      );

      setPersonalSharesTransactions(response?.data?.data?.transactions);
      setDisplayedPage(Number(response?.data?.data?.currentPage));

      setTotalPages(Math.ceil(Number(response?.data?.data?.totalPages)));
      setTotalNoOfLoans(response?.data?.data?.totalItems);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchPersonalShareTranscations();
  }, [currentPage, limit, memberId]);

  const toggleEditPopup = () => {
    setShowEditPopup(!showEditPopup);
  };

  const handleClickOutside = (event) => {
    if (editPopupRef.current && !editPopupRef.current.contains(event.target)) {
      setShowEditPopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    const newItemsPerPage = Number(e.target.value);
    setLimit(newItemsPerPage);
    setCurrentPage(1);
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= Math.min(totalPages, 3); i++) {
      pages.push(
        <div
          key={i}
          className={`w-[36px] cursor-pointer h-[36px] rounded-[10px] flex items-center justify-center ${
            currentPage === i
              ? "bg-[#074073] text-white"
              : "bg-[#F5F5F5] text-[#074073]"
          }`}
          onClick={() => handlePageChange(i)}
        >
          <p>{i}</p>
        </div>
      );
    }

    return pages;
  };

  const handleExportAllTransactions = async (exportType, memberId) => {
    let response;

    if (exportType === "pdf") {
      response = await exportMemberTransPdf(memberId);
      await downloadPDF(response, "Shares_transactions.pdf");
    } else if (exportType === "csv") {
      response = await exportMemberTransCsv(memberId);
      await downloadCSV(response, "shares_transactions.csv");
    }
  };

  return (
    <div>
      <div className="h-[118px] bg-[#074073] w-full flex items-center justify-between px-[20px]">
        <div className="flex items-center gap-[30px]">
          <div className="h-[80px] w-[80px] text-[32px] text-[#074073] rounded-[100%] bg-[#D3EAFF] flex items-center justify-center">
            {`${sharesSummary?.memberName?.split(" ")[0]?.charAt(0) || ""}${
              sharesSummary?.memberName
                ?.split(" ")
                [sharesSummary?.memberName?.split(" ").length - 1]?.charAt(0) ||
              "No Initials"
            }`.toUpperCase()}
          </div>
          <div className="flex flex-col">
            <p className="text-[#D3EAFF] text-[14px]">
              #{memberId || "No ID Available"}
            </p>
            <p className="text-white text-[24px]">
              {sharesSummary?.memberName
                ?.split(" ")
                .map(
                  (name) =>
                    name?.charAt(0)?.toUpperCase() +
                    name?.slice(1)?.toLowerCase()
                )
                .join(" ") || "No Member Name"}
            </p>
          </div>
        </div>
      </div>
      <div className="my-[10px] px-[20px]">
        <button
          onClick={handleBack}
          className="bg-[#074073] h-[40px] text-white text-[16px] w-[140px] rounded flex items-center justify-center gap-[10px] px-[20px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.78em"
            height="1em"
            viewBox="0 0 16 9"
          >
            <path
              fill="currentColor"
              d="M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5"
            />
            <path
              fill="currentColor"
              d="M6 8.5a.47.47 0 0 1-.35-.15l-3.5-3.5c-.2-.2-.2-.51 0-.71L5.65.65c.2-.2.51-.2.71 0c.2.2.2.51 0 .71L3.21 4.51l3.15 3.15c.2.2.2.51 0 .71c-.1.1-.23.15-.35.15Z"
            />
          </svg>
          Back
        </button>
      </div>
      <div className="p-[10px] h-[700px] overflow-y-auto">
        <div
          className="w-full mb-[10px] px-[12px] py-[5px] bg-white rounded-[10px]"
          style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <p className="text-[#074073] text-[16px]">Account</p>
          <div className="w-full flex justify-between mt-2">
            <div className="w-[85%] flex gap-[20px]">
              <div className="bg-image-1 h-[165px] bg-[#007F7B] !bg-[#007F7B] mb-[20px] rounded-[10px] w-[500px] p-[10px]">
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#FFFFFFB2] text-[12px]">Account Type</p>
                  <p className="text-[#FFFFFFB2] text-[12px]">Account Number</p>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#FFFFFF] text-[20px]">Shares</p>
                  <p className="text-[#FFFFFF] text-[20px]">
                    {sharesSummary?.accountNumber || "No Account Number"}
                  </p>
                </div>
                <div>
                  <p className="text-[#FFFFFFB2] text-[12px]">
                    Account Balance
                  </p>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-[5px]">
                    <p className="text-white font-bold">KES.</p>
                    <p
                      className=""
                      style={{ color: "white", fontSize: "16px" }}
                    >
                      <span className="text-[36px] text-white font-bold">
                        {Number(
                          sharesSummary?.totalBalance || "0.00"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[20px]">
                <div className="flex items-center">
                  <p>Created On</p>
                  <p className="text-right ml-32">
                    {sharesSummary?.memberCreatedAt
                      ? new Date(sharesSummary?.memberCreatedAt)
                          .toISOString()
                          .split("T")[0]
                      : "No Member Data"}
                  </p>
                </div>
                <div className="flex items-center">
                  <p>Shares Purchase Status</p>
                  <p className="text-right text-[#0C722F] ml-12">
                    {sharesSummary?.status || "No Status"}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="relative flex gap-2 w-[94] px-2 items-center justify-center cursor-pointer bg-[#316CE9] rounded-[5px]"
              style={{ height: "30px" }}
              onClick={toggleEditPopup}
            >
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.61581 0.681373C5.93181 0.302373 6.54731 0.561373 6.49731 1.05237L6.14431 4.50137H8.99981C9.09482 4.50141 9.18785 4.52851 9.26801 4.57951C9.34817 4.63051 9.41214 4.70329 9.45243 4.78933C9.49273 4.87537 9.50767 4.9711 9.49552 5.06533C9.48337 5.15956 9.44462 5.24837 9.38381 5.32137L4.38381 11.3214C4.06781 11.7004 3.45231 11.4414 3.50231 10.9504L3.85531 7.50137H0.999813C0.904806 7.50134 0.811775 7.47423 0.731615 7.42324C0.651455 7.37224 0.587483 7.29946 0.547191 7.21342C0.506899 7.12738 0.491955 7.03164 0.504108 6.93741C0.516261 6.84319 0.555008 6.75437 0.615813 6.68137L5.61581 0.681373Z"
                  fill="white"
                />
              </svg>
              <p className="text-white text-[12px] font-normal">Actions</p>
              <svg
                width="11"
                height="4"
                viewBox="0 0 11 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 4L0.736861 0.25L10.2631 0.250001L5.5 4Z"
                  fill="white"
                />
              </svg>
              {showEditPopup && (
                <div
                  ref={editPopupRef}
                  className="absolute right-0 top-full mt-2 w-[180px]  bg-white shadow-md border border-[#E0E0E0] rounded-md z-50"
                >
                  <div className="cursor-pointer hover:bg-gray-100 rounded px-2 py-2">
                    <p className="text-[12px] text-[#000000] font-[300]">
                      Extend purchase period
                    </p>
                  </div>
                  <div className="cursor-pointer hover:bg-gray-100 rounded px-2 py-2">
                    <p className="text-[12px] text-[#000000] font-[300]">
                      List Shares for Sale
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between w-[100%] mx-auto mx-auto">
            <div className="relative items-left justify-center">
              <p className="text-[#074073] text-[16px] font-[500]">
                Shares Purchase History
              </p>
            </div>
            <div className="flex gap-4">
              <ExportPdfCsv
                handleExportAllCustomers={handleExportAllTransactions}
                memberId={memberId}
              />
            </div>
          </div>

          <div className="flex flex-col w-full mt-3">
            <div className="w-full flex items-center bg-[#E3F8FF] px-[10px] min-h-[44px]">
              <div className="w-[10%]">
                <input type="checkbox" name="" id="" />
              </div>
              <p className="w-[20%] text-[#000]">TRANSACTION ID</p>
              <p className="w-[20%] text-[#000]">DATE OF TRANSACTION</p>
              <p className="w-[20%] text-[#000]">
                AMOUNT PAID
                <br />
                <span className="block ml-3">PER SHARE</span>
              </p>
              <p className="w-[20%] text-[#000]">
                Member's Total
                <br />
                <span className="block ml-2">Shares Value</span>
              </p>

              <p className="w-[14%] text-[#000]">STATUS</p>
            </div>
            <div className="h-[430px] overflow-y-auto">
              {personalSharesTransactions?.length > 0 ? (
                <>
                  {personalSharesTransactions?.map((transaction) => (
                    <div
                      key={transaction?.transactionid}
                      className="w-full flex cursor-pointer items-center bg-white px-[10px] min-h-[44px]"
                    >
                      <div className="w-[10%]">
                        <input
                          type="checkbox"
                          name="transaction"
                          id="transaction"
                        />
                      </div>
                      <p className="w-[20%] text-[#037BD1]">
                        {transaction?.transactionid}
                      </p>
                      <p className="w-[20%]">
                        {transaction?.purchasedate
                          ? new Date(transaction?.purchasedate)
                              .toISOString()
                              .split("T")[0]
                          : "N/A"}
                      </p>
                      <p className="w-[20%]">
                        KES &nbsp;
                        {Number(
                          transaction?.shareprice || "0.00"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                      <p className="w-[20%]">
                        KES &nbsp;
                        {Number(
                          transaction?.totalvalueofshares || "0.00"
                        )?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>

                      <div className="w-[14%] flex items-center gap-[10px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_7843_842)">
                            <path
                              d="M12.5 0C13.6016 0 14.6641 0.140625 15.6875 0.421875C16.7109 0.703125 17.6641 1.10938 18.5469 1.64062C19.4297 2.17188 20.2383 2.79688 20.9727 3.51562C21.707 4.23438 22.3359 5.04297 22.8594 5.94141C23.3828 6.83984 23.7852 7.79688 24.0664 8.8125C24.3477 9.82812 24.4922 10.8906 24.5 12C24.5 13.1016 24.3594 14.1641 24.0781 15.1875C23.7969 16.2109 23.3906 17.1641 22.8594 18.0469C22.3281 18.9297 21.7031 19.7383 20.9844 20.4727C20.2656 21.207 19.457 21.8359 18.5586 22.3594C17.6602 22.8828 16.7031 23.2852 15.6875 23.5664C14.6719 23.8477 13.6094 23.9922 12.5 24C11.3984 24 10.3359 23.8594 9.3125 23.5781C8.28906 23.2969 7.33594 22.8906 6.45312 22.3594C5.57031 21.8281 4.76172 21.2031 4.02734 20.4844C3.29297 19.7656 2.66406 18.957 2.14062 18.0586C1.61719 17.1602 1.21484 16.2031 0.933594 15.1875C0.652344 14.1719 0.507812 13.1094 0.5 12C0.5 10.8984 0.640625 9.83594 0.921875 8.8125C1.20312 7.78906 1.60938 6.83594 2.14062 5.95312C2.67188 5.07031 3.29688 4.26172 4.01562 3.52734C4.73438 2.79297 5.54297 2.16406 6.44141 1.64062C7.33984 1.11719 8.29688 0.714844 9.3125 0.433594C10.3281 0.152344 11.3906 0.0078125 12.5 0ZM19.5664 8.02734L17.9727 6.43359L10.25 14.1562L7.02734 10.9336L5.43359 12.5273L10.25 17.3438L19.5664 8.02734Z"
                              fill="#0C722F"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7843_842">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <p>{transaction?.status}</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className="mt-[10px]">No record of transaction</p>
              )}
              <div className="bg-white w-[98%] px-[10px] mx-auto mb-[20px] flex items-center justify-between mt-5">
                <div className="flex items-center gap-[20px]">
                  <p className="text-[#074073] text-[14px]">Rows per page</p>
                  <select
                    name="page"
                    id="page"
                    className="w-[71px] border-[1px] px-[5px] h-[30px]"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </select>
                  <p className="text-[#074073] text-[14px]">
                    {displayedPage} / {totalPages} ({totalNoOfLoans} records)
                  </p>
                </div>
                <div className="flex items-center gap-[15px]">
                  <p
                    onClick={() =>
                      handlePageChange(Math.max(currentPage - 1, 1))
                    }
                    className="text-[14px] text-[#074073] cursor-pointer"
                  >
                    Prev
                  </p>
                  {renderPages()}
                  <div
                    className={`w-[36px] cursor-pointer h-[36px] rounded-[10px] flex items-center justify-center ${
                      Number(currentPage) > 3
                        ? "bg-[#074073] text-white"
                        : "bg-[#F5F5F5] text-[#074073]"
                    }`}
                  >
                    <p>{Number(currentPage) > 3 ? currentPage : "..."}</p>
                  </div>
                  <p
                    onClick={() =>
                      handlePageChange(Math.min(currentPage + 1, totalPages))
                    }
                    className="text-[14px] cursor-pointer text-[#074073]"
                  >
                    Next
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareView;
