import React, { useEffect, useRef, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import {
  CheckingAccountsBar,
  SavingAccountsBar,
  LoanAccountsBar,
  TransactionsComplete,
  RejectedTransactions,
  ReversedTransactions,
  ErrorTransactions,
} from "../../components/charts/progressbar";
import BasicArea from "../../components/charts/areaChart";
import DonutChart from "../../components/charts/DonutChart";
import "../../App.css";
import BarGraph from "../../components/charts/BarChart";
import ZigChart from "../../components/charts/ZigChart";
import { LoanPortfolio } from "../../components/Grids/loanPortfolio";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  fetchTotalTransactionValue,
  fetchTotalTransactionValueByStatus,
} from "../../sdk/transactions/transactions";
import {
  getLineGraphReport,
  getAccountsPercent,
  getSharesTotal,
  getPercentValueShares,
  getSharesLineChart,
} from "../../sdk/transactions/transactions";
import convertDateFormat from "../../hooks/convertDate";
import Calendar from "../../components/calendar/Calendar";
import moment from "moment";
import { toast } from "react-toastify";
import { getLoansReport } from "../../sdk/loans/loans";
import { SharesPortfolio } from "../../components/Grids/sharesPortfolio";

const Dashboard = () => {
  const COLORS = ["#00203D", "#028C3F", "#00DEFD", "#622977", "#FFCE1E"];
  const today = moment();
  const [transactionValueByStatus, setTransactionValueByStatus] =
    useState(null);
  const [totalTransactionValue, setTotalTransactionValue] = useState(null);
  const [totalTransactionValueByStatus, setTotalTransactionValueByStatus] =
    useState(null);
  const [rejectedTransactionsValue, setRejectedTransactionsValue] =
    useState(null);
  const [failedTransactionsValue, setFailedTransactionsValue] = useState(null);
  const [reversedTransactionsValue, setReversedTransactionsValue] =
    useState(null);

  const [startDate, setStartDate] = useState(today.clone().startOf("month"));
  const [endDate, setEndDate] = useState(today);
  const [focusedInput, setFocusedInput] = useState(null);

  const [transactionStartDate, setTransactionStartDate] = useState(
    today.clone().startOf("month")
  );
  const [transactionEndDate, setTransactionEndDate] = useState(today);
  const [transactionFocusedInput, setTransactionFocusedInput] = useState(null);

  const [depositStartDate, setDepositStartDate] = useState(
    today.clone().startOf("month")
  );
  const [depositLastDate, setDepositLastDate] = useState(today);
  const [depositFocusedInput, setDepositFocusedInput] = useState(null);

  const [accountStartDate, setAccountStartDate] = useState(
    today.clone().startOf("month")
  );
  const [accountLastDate, setAccountLastDate] = useState(today);
  const [accountFocusedInput, setAccountFocusedInput] = useState(null);

  const getTotalTransactionValue = async () => {
    const response = await fetchTotalTransactionValue(
      "deposit",
      transactionStartDate,
      transactionEndDate
    );
    setTotalTransactionValue(response.data.allTotal);

    const type = await fetchTotalTransactionValue(
      "reversal",
      transactionStartDate,
      transactionEndDate
    );
    setReversedTransactionsValue(type.data.filteredTotal);
  };

  const getTotalTransactionValueByStatus = async () => {
    const final = await fetchTotalTransactionValueByStatus(
      "completed",
      transactionStartDate,
      transactionEndDate
    );
    setTransactionValueByStatus(final.data.filteredTotal);
    setTotalTransactionValueByStatus(final.data.filteredTotal);

    const rejected = await fetchTotalTransactionValueByStatus(
      "cancelled",
      transactionStartDate,
      transactionEndDate
    );
    setRejectedTransactionsValue(rejected.data.filteredTotal);

    const failed = await fetchTotalTransactionValueByStatus(
      "failed",
      transactionStartDate,
      transactionEndDate
    );
    setFailedTransactionsValue(failed.data.filteredTotal);
  };

  useEffect(() => {
    getTotalTransactionValueByStatus();
  }, [transactionStartDate, transactionEndDate]);

  useEffect(() => {
    getTotalTransactionValue();
  }, [transactionStartDate, transactionEndDate]);

  const [data, setData] = useState([]);


  useEffect(() => {
    const apiUrl =
      "http://109.199.120.58:5300/api/v1/report/general-statistics";
    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [lineGraph, setLineGraph] = useState();
  const [fromDate, setFromDate] = useState(today.clone().startOf("month"));
  const [customerFocusedInput, setCustomerFocusedInput] = useState(null);
  const [toDate, setToDate] = useState(today);
  const getLineGraphData = async () => {
    const response = await getLineGraphReport(
      convertDateFormat(fromDate),
      convertDateFormat(toDate)
    );
    setLineGraph(response.data);
  };

  const [accounts, setAccounts] = useState();
  const fetchAccounts = async () => {
    try {
      const result = await getAccountsPercent(
        convertDateFormat(fromDate),
        convertDateFormat(toDate)
      );
      setAccounts(result?.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };


  const [sharesStartDate, setSharesStartDate] = useState(today.clone().startOf("month"));
  const [sharesLastDate, setSharesLastDate] = useState(today);
  const [sharesFocusedInput, setSharesFocusedInput] = useState(null);
  const [shares, setShares] = useState();
  const [percentShare, setPercentShares] = useState();
  const [sharesLineChart, setSharesLineChart] = useState();
  const fetchShares = async () => {
    try {
      const result = await getSharesTotal(
        convertDateFormat(sharesStartDate),
        convertDateFormat(sharesLastDate)
      );
      setShares(result?.data);
    } catch (error) {
      console.error("Error fetching shares:", error);
    }
  };

  const fetchPercentValueShares = async () => {
    try {
      const result = await getPercentValueShares(
        convertDateFormat(sharesStartDate),
        convertDateFormat(sharesLastDate)
      );
      setPercentShares(result?.data);
    } catch (error) {
      console.error("Error fetching shares:", error);
    }
  };

  const fetchSharesLineChart = async () => {
    try {
      const result = await getSharesLineChart(
        convertDateFormat(sharesStartDate),
        convertDateFormat(sharesLastDate)
      );
      setSharesLineChart(result?.data);
    } catch (error) {
      console.error("Error fetching shares:", error);
    }
  };


  useEffect(() => {
    getLineGraphData();
    fetchAccounts();
    fetchShares();
    fetchPercentValueShares();
    fetchSharesLineChart();
  }, [fromDate, toDate, sharesStartDate, sharesLastDate]);

  const [loanReport, setLoanReport] = useState([]);
  const fetchLoanReport = async () => {
    try {
      const response = await getLoansReport();
      setLoanReport(response?.data?.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchLoanReport();
  }, []);
 
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anansi || Home</title>
      </Helmet>
      <div className="h-[40px] bg-[#BFE0FD] w-full flex items-center justify-center">
        <p>[Alerts (if any) go here]</p>
      </div>
      <div className="bg-[#F5F6F7] overflow-y-auto h-full px-[10px]">
        <div className="px-2 mt-3 text-[#074073] text-[16px] font-bold">
          Overview Dashboard
        </div>
        <div className="flex gap-[10px] w-[100%] bg-white rounded-[30px] mt-[10px]">
          <div className="w-[100%] h-[232px] bg-white rounded-[10px]">
            <div className="flex items-center justify-center mt-2">
              <p className="px-2 text-[16px] font-medium text-[#074073]">
                Assets
              </p>
              <div className="w-[100%] gap-[10px] flex items-center justify-center">
                Filter By Date
                <Calendar
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  endDate={endDate}
                  focusedInput={focusedInput}
                  setFocusedInput={setFocusedInput}
                />
              </div>
            </div>
            <div className="flex mt-3 px-2 gap-[10px]">
              <div className="w-[33%] h-[139px] rounded-[10px] bg-[#074073]">
                <div className="flex flex items-center px-2 justify-between">
                  <div className="flex flex items-center mt-2  gap-2">
                    <div className="w-[27px] h-[27px] bg-[#FFF] rounded-[30px] flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <path
                          d="M6.125 9.625C6.125 9.16087 6.30937 8.71575 6.63756 8.38756C6.96575 8.05937 7.41087 7.875 7.875 7.875H16.625C17.0891 7.875 17.5342 8.05937 17.8624 8.38756C18.1906 8.71575 18.375 9.16087 18.375 9.625V14.875C18.375 15.3391 18.1906 15.7842 17.8624 16.1124C17.5342 16.4406 17.0891 16.625 16.625 16.625H7.875C7.41087 16.625 6.96575 16.4406 6.63756 16.1124C6.30937 15.7842 6.125 15.3391 6.125 14.875V9.625Z"
                          stroke="#074073"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.875 7.875V6.125C14.875 5.66087 14.6906 5.21575 14.3624 4.88756C14.0342 4.55937 13.5891 4.375 13.125 4.375H4.375C3.91087 4.375 3.46575 4.55937 3.13756 4.88756C2.80937 5.21575 2.625 5.66087 2.625 6.125V11.375C2.625 11.8391 2.80937 12.2842 3.13756 12.6124C3.46575 12.9406 3.91087 13.125 4.375 13.125H6.125M10.5 12.25C10.5 12.7141 10.6844 13.1592 11.0126 13.4874C11.3408 13.8156 11.7859 14 12.25 14C12.7141 14 13.1592 13.8156 13.4874 13.4874C13.8156 13.1592 14 12.7141 14 12.25C14 11.7859 13.8156 11.3408 13.4874 11.0126C13.1592 10.6844 12.7141 10.5 12.25 10.5C11.7859 10.5 11.3408 10.6844 11.0126 11.0126C10.6844 11.3408 10.5 11.7859 10.5 12.25Z"
                          stroke="#074073"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="text-white text-[14px]">Cash</p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3.5 18.5L9.5 12.5L13.5 16.5L22 6.92L20.59 5.5L13.5 13.5L9.5 9.5L2 17L3.5 18.5Z"
                      fill="#03E450"
                    />
                  </svg>
                </div>
                <div className="flex justify-center mt-3 gap-2">
                  <p className="text-white text-[14px] mt-3">Ksh.</p>
                  <p className="text-white text-[26px]">
                    {Number(data?.data?.cash || "0.00")?.toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </p>
                </div>
                <div className="flex flex items-center mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M20.9582 18.8737L20.9364 10.4681L12.5308 10.4463C12.4433 10.4344 12.3542 10.4415 12.2696 10.4671C12.185 10.4927 12.1069 10.5361 12.0405 10.5945C11.9742 10.6529 11.9212 10.7248 11.885 10.8055C11.8489 10.8861 11.8306 10.9736 11.8312 11.062C11.8318 11.1504 11.8514 11.2376 11.8887 11.3177C11.926 11.3978 11.98 11.469 12.0472 11.5264C12.1143 11.5839 12.1931 11.6262 12.278 11.6506C12.363 11.6749 12.4522 11.6807 12.5396 11.6676L18.8383 11.6938L10.4938 20.0383C10.3781 20.154 10.3131 20.3109 10.3131 20.4745C10.3131 20.6381 10.3781 20.795 10.4938 20.9107C10.6095 21.0264 10.7664 21.0914 10.93 21.0914C11.0936 21.0914 11.2505 21.0264 11.3662 20.9107L19.7107 12.5662L19.7369 18.8649C19.7374 19.0286 19.803 19.1854 19.9192 19.3007C20.0353 19.4161 20.1926 19.4806 20.3563 19.48C20.52 19.4794 20.6767 19.4138 20.7921 19.2977C20.9074 19.1815 20.9719 19.0243 20.9713 18.8606L20.9582 18.8737Z"
                      fill="#03E450"
                    />
                  </svg>
                  <p className="text-white text-[14px]">+10%</p>
                </div>
              </div>
              <div className="w-[33%] h-[139px] rounded-[10px] border-2 bg-[#F8FDFE]">
                <div className="flex flex items-center px-2 justify-between">
                  <div className="flex flex items-center mt-2  gap-2">
                    <div className="w-[27px] h-[27px] bg-[#FFF] rounded-[30px] flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                      >
                        <circle cx="13.5" cy="13.5" r="13.5" fill="white" />
                        <path
                          d="M7 12.7241L8.9649 10.7646C9.22759 10.5025 9.54993 10.2927 9.91068 10.149C10.2714 10.0053 10.6624 9.93103 11.0579 9.93103H11.2M7 18H10.85L13.65 16.1379C13.65 16.1379 14.217 15.7984 15.05 15.2069C16.8 13.9655 15.05 12.0004 13.3 13.0345C11.8748 13.8768 10.5 14.5862 10.5 14.5862"
                          stroke="#074073"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.2002 14.2759V10.2414C11.2002 9.91215 11.3477 9.5964 11.6102 9.36359C11.8728 9.13079 12.2289 9 12.6002 9H19.6002C19.9715 9 20.3276 9.13079 20.5901 9.36359C20.8527 9.5964 21.0002 9.91215 21.0002 10.2414V13.9655C21.0002 14.2948 20.8527 14.6105 20.5901 14.8433C20.3276 15.0761 19.9715 15.2069 19.6002 15.2069H15.0502"
                          stroke="#074073"
                        />
                        <path
                          d="M19.2502 12.1096L19.2572 12.1028M12.9502 12.1096L12.9572 12.1028M16.1002 13.3448C15.7289 13.3448 15.3728 13.214 15.1102 12.9812C14.8477 12.7484 14.7002 12.4327 14.7002 12.1034C14.7002 11.7742 14.8477 11.4585 15.1102 11.2257C15.3728 10.9928 15.7289 10.8621 16.1002 10.8621C16.4715 10.8621 16.8276 10.9928 17.0901 11.2257C17.3527 11.4585 17.5002 11.7742 17.5002 12.1034C17.5002 12.4327 17.3527 12.7484 17.0901 12.9812C16.8276 13.214 16.4715 13.3448 16.1002 13.3448Z"
                          stroke="#074073"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="text-[#074073] text-[14px]">Loans</p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3.5 18.5L9.5 12.5L13.5 16.5L22 6.92L20.59 5.5L13.5 13.5L9.5 9.5L2 17L3.5 18.5Z"
                      fill="#03E450"
                    />
                  </svg>
                </div>
                <div className="flex justify-center mt-3 gap-1.5">
                  <p className="text-[#074073] text-[14px] mt-3">Ksh.</p>
                  <p className="text-[#074073] text-[26px]">
                    {Number(
                      loanReport?.total_loan_volume || "0.00"
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="flex flex items-center mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M20.9582 18.8737L20.9364 10.4681L12.5308 10.4463C12.4433 10.4344 12.3542 10.4415 12.2696 10.4671C12.185 10.4927 12.1069 10.5361 12.0405 10.5945C11.9742 10.6529 11.9212 10.7248 11.885 10.8055C11.8489 10.8861 11.8306 10.9736 11.8312 11.062C11.8318 11.1504 11.8514 11.2376 11.8887 11.3177C11.926 11.3978 11.98 11.469 12.0472 11.5264C12.1143 11.5839 12.1931 11.6262 12.278 11.6506C12.363 11.6749 12.4522 11.6807 12.5396 11.6676L18.8383 11.6938L10.4938 20.0383C10.3781 20.154 10.3131 20.3109 10.3131 20.4745C10.3131 20.6381 10.3781 20.795 10.4938 20.9107C10.6095 21.0264 10.7664 21.0914 10.93 21.0914C11.0936 21.0914 11.2505 21.0264 11.3662 20.9107L19.7107 12.5662L19.7369 18.8649C19.7374 19.0286 19.803 19.1854 19.9192 19.3007C20.0353 19.4161 20.1926 19.4806 20.3563 19.48C20.52 19.4794 20.6767 19.4138 20.7921 19.2977C20.9074 19.1815 20.9719 19.0243 20.9713 18.8606L20.9582 18.8737Z"
                      fill="#03E450"
                    />
                  </svg>
                  <p className="text-[#000] text-[14px] font-medium">+17%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 w-[100%] h-[145px] rounded-[10px] bg-white">
          <div className="text-[#074073] px-3 p-2 text-[15px] font-medium">
            <p>Manual Review</p>
          </div>
          <div className="px-2 justify-center flex gap-2">
            <div className="w-[16%] h-[100px] flex flex-col justify-between bg-[#EADEF9] px-2 rounded-[5px]">
              <div className="text-[#6F6F6F] text-[13px] ">
                <p className="">Pending new account</p>
                <p>applications</p>
              </div>
              <p className="text-[#000] text-[32px] font-bold">
                {Number(data?.data?.pendingAccounts || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
            </div>
            <div className="w-[16%] h-[100px] flex flex-col justify-between bg-[#EAFDEB] px-2 rounded-[5px]">
              <p className="text-[#6F6F6F] text-[13px]">Pending transactions</p>
              <p className="text-[#000] text-[32px] font-bold">
                {Number(
                  data?.data?.pendingTransactions || "0.00"
                )?.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            </div>
            <div className="w-[16%] flex flex-col justify-between h-[100px] bg-[#E4EBFB] px-2 rounded-[5px]">
              <div className="text-[#6F6F6F] text-[13px] ">
                <p>Pending new loan</p>
                <p>applications</p>
              </div>
              <p className="text-[#000] text-[32px] font-bold">
                {Number(data?.data?.pendingLoan || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
            </div>
            <div className="w-[16%] h-[100px] flex flex-col justify-between bg-[#F9DEDE] px-2 rounded-[5px]">
              <div className="text-[#6F6F6F] text-[13px] ">
                <p>Fraudulent flagged</p>
                <p>transactions</p>
              </div>
              <p className="text-[#000] text-[32px] font-bold">
                {Number(data?.data?.fraudCases || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
            </div>
            <div className="w-[16%] h-[100px] flex flex-col justify-between bg-[#F9EBDE] px-2 rounded-[5px]">
              <p className="text-[#6F6F6F] text-[13px]">
                Compliance violations
              </p>
              <p className="text-[#000] text-[32px] font-bold">
                {Number(data?.data?.violations || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
            </div>
            <div className="w-[16%] h-[100px] flex flex-col justify-between bg-[#F9F5DE] px-2 rounded-[5px]">
              <p className="text-[#6F6F6F] text-[13px]">Aged transactions</p>
              <p className="text-[#000] text-[32px] font-bold">
                {Number(data?.data?.agedTransactions || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="flex gap-[10px] w-[100%] mt-[20px]">
          <div className="w-[55%] h-[363px] bg-white rounded-[10px]">
            <div className="flex mt-2 justify-between px-2">
              <div className="mt-2 font-normal  text-[#074073] text-16">
                <p className="font-medium">Active Accounts</p>
              </div>
              <div className="gap-[10px] flex items-center justify-center">
                Filter By Date
                <Calendar
                  startDate={fromDate}
                  endDate={toDate}
                  setStartDate={setFromDate}
                  setEndDate={setToDate}
                  focusedInput={customerFocusedInput}
                  setFocusedInput={setCustomerFocusedInput}
                />
              </div>
            </div>
            <div className="flex items-center h-[300px] mt-[10px] pr-[20px]">
              <BasicArea datum={lineGraph} />
            </div>
          </div>
          <div className="w-[45%] h-[363px] px-2 bg-white rounded-[10px]">
            <div className="flex items-center w-[99%] justify-between">
              <p className="text-[15px] font-medium  text-[#074073] mt-3">
                Account Types
              </p>
            </div>
            <div className="flex items-center">
              <div className="chart-container">
                <DonutChart accounts={accounts} />
              </div>
              <div className="overflow-y-auto flex flex-col justify-center h-[320px]">
                {accounts &&
                  Object.entries(accounts).map(
                    ([name, { count, percentage }], index) => (
                      <div className="flex items-center gap-[10px]">
                        <div className="flex items-center mb-[10px] gap-[10px]">
                          <div
                            className="h-[20px] w-[20px]"
                            style={{ backgroundColor: `${COLORS[index]}` }}
                          ></div>
                          <p className="text-[#074073] text-[14px]">{name}</p>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
            {/* <div className="flex px-10 mt-20 justify-between border border-[#f00]">
              <div className="flex-col justify-center">
                <CheckingAccountsBar accounts={accounts} />
                <div className="mt-4 text-[#074073] text-center text-[14px] ">
                  <p>Checking</p>
                  <p>accounts</p>
                </div>
              </div>
              <div className="flex-col justify-center">
                <SavingAccountsBar accounts={accounts} />
                <div className="mt-4 text-[#074073] text-center text-[14px] ">
                  <p>Savings</p>
                  <p>accounts</p>
                </div>
              </div>
              <div className="flex-col justify-center">
                <LoanAccountsBar accounts={accounts} />
                <div className="mt-4 text-[#074073] text-center text-[14px] ">
                  <p>Loan</p>
                  <p>accounts</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex gap-[10px] w-[100%]  h-[400px] mt-[10px]">
          <div className="px-2 w-[55%] bg-white rounded-[10px]">
            <div className="flex justify-between mt-2">
              <div className="text-[#074073] font-medium text-[16px] mt-[5px]">
                <p>Transactions</p>
              </div>
              <div className="gap-[10px] flex items-center justify-center">
                Filter By Date
                <Calendar
                  startDate={transactionStartDate}
                  endDate={transactionEndDate}
                  setStartDate={setTransactionStartDate}
                  setEndDate={setTransactionEndDate}
                  focusedInput={transactionFocusedInput}
                  setFocusedInput={setTransactionFocusedInput}
                />
              </div>
            </div>
            <div className="w-[38%] rounded-[5px] transaction">
              <div className="p-3 mt-5 text-white text-[14px]">
                <p>Total transaction</p>
                <div className="flex justify-center mt-3 gap-2">
                  <p className="text-white text-[14px] mt-3">Ksh.</p>
                  <p className="text-white text-[26px]">
                    {Number(
                      totalTransactionValueByStatus || "0.00"
                    )?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex items-center px-2 mr-5 mt-10 justify-between">
              <div className="flex-col justify-center">
                <div className="flex-col flex items-center">
                  <TransactionsComplete
                    rate={
                      (transactionValueByStatus /
                        totalTransactionValueByStatus) *
                      100
                    }
                  />
                  <div className="mt-4 text-[#000000] text-center text-[14px] ">
                    <p>Transactions</p>
                    <p>completion rate</p>
                  </div>
                </div>
              </div>
              <div className="flex-col justify-center">
                <div className="flex-col flex items-center">
                  <RejectedTransactions
                    rate={
                      (rejectedTransactionsValue /
                        totalTransactionValueByStatus) *
                      100
                    }
                  />
                  <div className="mt-4 text-[#000000] text-center text-[14px] ">
                    <p>Transactions</p>
                    <p>rejection rate</p>
                  </div>
                </div>
              </div>
              <div className="flex-col justify-center">
                <div className="flex-col flex items-center">
                  <ReversedTransactions
                    rate={
                      (reversedTransactionsValue /
                        totalTransactionValueByStatus) *
                      100
                    }
                  />
                  <div className="mt-4 text-[#000000] text-center text-[14px] ">
                    <p>Transactions</p>
                    <p>reversal rate</p>
                  </div>
                </div>
              </div>
              <div className="flex-col justify-center">
                <div className="flex-col flex items-center">
                  <ErrorTransactions
                    rate={
                      (failedTransactionsValue /
                        totalTransactionValueByStatus) *
                      100
                    }
                  />
                  <div className="mt-4 text-[#000000] text-center text-[14px] ">
                    <p>Transactions</p>
                    <p>error rate</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-col w-[45%] bg-white rounded-[10px]">
            <div className="flex w-[100%] justify-between mt-2 pl-4 pr-2 mb-4">
              <div className="text-[#074073] text-[15px] mt-[5px]">
                <p className="font-medium">Deposits breakdown</p>
              </div>
              <div className="flex gap-[5px] items-center">
                Filter By Date
                <Calendar
                  startDate={depositStartDate}
                  endDate={depositLastDate}
                  setStartDate={setDepositStartDate}
                  setEndDate={setDepositLastDate}
                  focusedInput={depositFocusedInput}
                  setFocusedInput={setDepositFocusedInput}
                />
              </div>
            </div>
            <div className="w-[95%] h-[320px] mx-auto mt-[20px]">
              <BarGraph datum={data} />
            </div>
          </div>
        </div>
        <div className="mt-2 w-[100%] rounded-[10px] bg-white">
          <div className="p-2 font-medium text-[15px] text-[#074073]">
            Loan portfolio
          </div>
          <LoanPortfolio data={loanReport} />
        </div>
        {/* <div className="mt-2 w-[100%] mb-[20px] rounded-[10px] bg-white">
          <div className="h-full w-[60%] flex flex-col justify-between">
            <div>
              <div className="px-[20px]">
                <p className="text-[#074073] font-medium text-[15px] pt-[10px] mb-[10px]">
                  Bank Profitability
                </p>
                <div className="flex items-center gap-[20px]">
                  <div className="w-[167px] h-[94px] border-[#AFFCC9] border-[1px] rounded-[5px]">
                    <p className="text-[#074073] text-[15px] px-[5px] h-[20%]">
                      Interest Rate Spread
                    </p>
                    <div className="flex h-[70%] px-[10px] items-center gap-[10px]">
                      <p className="text-[26px]">6.23 %</p>
                      <div className="w-[32px] h-[24px] mb-[20px] bg-[#EBFFF2] rounded-[4px] flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_5960_2171)">
                            <path
                              d="M6.68057 3.77036L4.34736 1.44922L2.01415 3.77036C1.98662 3.79132 1.96392 3.81795 1.94758 3.84845C1.93124 3.87895 1.92164 3.91261 1.91944 3.94714C1.91723 3.98167 1.92247 4.01627 1.93481 4.0486C1.94714 4.08093 1.96627 4.11023 1.99091 4.13452C2.01555 4.15881 2.04513 4.17753 2.07763 4.1894C2.11013 4.20126 2.1448 4.20601 2.1793 4.20332C2.2138 4.20062 2.24731 4.19054 2.27757 4.17377C2.30784 4.15699 2.33414 4.13391 2.35471 4.10609L4.10583 2.36946V6.99C4.10583 7.05406 4.13127 7.11549 4.17657 7.16079C4.22187 7.20608 4.2833 7.23153 4.34736 7.23153C4.41142 7.23153 4.47285 7.20608 4.51815 7.16079C4.56345 7.11549 4.58889 7.05406 4.58889 6.99V2.36946L6.34001 4.10609C6.38549 4.15125 6.44705 4.17649 6.51115 4.17627C6.57524 4.17604 6.63662 4.15036 6.68178 4.10488C6.72694 4.0594 6.75219 3.99784 6.75196 3.93374C6.75173 3.86965 6.72606 3.80827 6.68057 3.76311V3.77036Z"
                              fill="#03E450"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5960_2171">
                              <rect
                                width="8.6952"
                                height="8.6952"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <p className="text-[#03E450] text-[16px]">1.18</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[167px] h-[94px] border-[#BFE0FD] border-[1px] rounded-[5px]">
                    <p className="text-[#074073] text-[15px] px-[5px] h-[20%]">
                      Deposit Rate
                    </p>
                    <div className="flex h-[70%] px-[10px] items-center gap-[10px]">
                      <p className="text-[26px]">15.23 %</p>
                      <div className="w-[32px] mb-[20px] h-[24px] bg-[#FFEBEB] rounded-[4px] flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_5966_2291)">
                            <path
                              d="M6.68057 4.92484L4.34736 7.24597L2.01415 4.92484C1.98662 4.90387 1.96392 4.87724 1.94758 4.84674C1.93124 4.81624 1.92164 4.78258 1.91944 4.74805C1.91723 4.71352 1.92247 4.67892 1.93481 4.64659C1.94714 4.61426 1.96627 4.58496 1.99091 4.56067C2.01555 4.53638 2.04513 4.51766 2.07763 4.5058C2.11013 4.49393 2.1448 4.48918 2.1793 4.49188C2.2138 4.49457 2.24731 4.50465 2.27757 4.52142C2.30784 4.5382 2.33414 4.56128 2.35471 4.5891L4.10583 6.32573V1.70519C4.10583 1.64114 4.13127 1.5797 4.17657 1.5344C4.22187 1.48911 4.2833 1.46366 4.34736 1.46366C4.41142 1.46366 4.47285 1.48911 4.51815 1.5344C4.56345 1.5797 4.58889 1.64114 4.58889 1.70519V6.32573L6.34001 4.5891C6.38549 4.54394 6.44705 4.5187 6.51115 4.51892C6.57524 4.51915 6.63662 4.54483 6.68178 4.59031C6.72694 4.63579 6.75219 4.69735 6.75196 4.76145C6.75173 4.82554 6.72606 4.88692 6.68057 4.93208V4.92484Z"
                              fill="#E40303"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5966_2291">
                              <rect
                                width="8.6952"
                                height="8.6952"
                                fill="white"
                                transform="matrix(1 0 0 -1 0 8.69519)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <p className="text-[##E40303] text-[16px]">1.18</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[167px] h-[94px] border-[#FCC1AF] border-[1px] rounded-[5px]">
                    <p className="text-[#074073] text-[15px] px-[5px] h-[20%]">
                      Loan Rate
                    </p>
                    <div className="flex h-[70%] px-[10px] items-center gap-[10px]">
                      <p className="text-[26px]">25.23 %</p>
                      <div className="w-[32px] mb-[20px] h-[24px] bg-[#EBFFF2] rounded-[4px] flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_5960_2171)">
                            <path
                              d="M6.68057 3.77036L4.34736 1.44922L2.01415 3.77036C1.98662 3.79132 1.96392 3.81795 1.94758 3.84845C1.93124 3.87895 1.92164 3.91261 1.91944 3.94714C1.91723 3.98167 1.92247 4.01627 1.93481 4.0486C1.94714 4.08093 1.96627 4.11023 1.99091 4.13452C2.01555 4.15881 2.04513 4.17753 2.07763 4.1894C2.11013 4.20126 2.1448 4.20601 2.1793 4.20332C2.2138 4.20062 2.24731 4.19054 2.27757 4.17377C2.30784 4.15699 2.33414 4.13391 2.35471 4.10609L4.10583 2.36946V6.99C4.10583 7.05406 4.13127 7.11549 4.17657 7.16079C4.22187 7.20608 4.2833 7.23153 4.34736 7.23153C4.41142 7.23153 4.47285 7.20608 4.51815 7.16079C4.56345 7.11549 4.58889 7.05406 4.58889 6.99V2.36946L6.34001 4.10609C6.38549 4.15125 6.44705 4.17649 6.51115 4.17627C6.57524 4.17604 6.63662 4.15036 6.68178 4.10488C6.72694 4.0594 6.75219 3.99784 6.75196 3.93374C6.75173 3.86965 6.72606 3.80827 6.68057 3.76311V3.77036Z"
                              fill="#03E450"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5960_2171">
                              <rect
                                width="8.6952"
                                height="8.6952"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <p className="text-[##03E450] text-[16px]">1.18</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center px-10 gap-[50px] mt-[20px]">
                <div className="bg-[#EBFFF2] rounded-[10px]">
                  <p className="text-[#106E0E] px-2 py-1 text-[14px]">
                    Interest Spread Rate
                  </p>
                </div>
                <p className="text-[#074073] text-[14px] mt-[0px]">
                  Deposit Rate
                </p>
                <p className="text-[#7C3C28] text-[14px] mt-[0px]">
                  Lending Rate
                </p>
              </div>
            </div>
            <div className="h-[340px] mt-10 w-full">
              <ZigChart datum={data} />
            </div>
          </div>
        </div> */}
        <div className="mt-2 mb-5 w-[100%] rounded-[10px] bg-white">
          <div className="flex items-center justify-center mt-2">
            <div className="p-2 font-medium text-[15px] text-[#074073]">
              Shares
            </div>
            <div className="w-[100%] gap-[10px] flex items-center justify-center">
              Filter By Date
              <Calendar
                  startDate={sharesStartDate}
                  endDate={sharesLastDate}
                  setStartDate={setSharesStartDate}
                  setEndDate={setSharesLastDate}
                  focusedInput={sharesFocusedInput}
                  setFocusedInput={setSharesFocusedInput}
                />
            </div>
          </div>
          <SharesPortfolio datum={data} sharesLineChart={sharesLineChart} shares={shares} percentShare={percentShare} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
