import React from "react";
import { Modal } from "rsuite";
import { capitalize } from "../../../helpers/capitalize";

const TransactionModal = ({
  transactionDetailsOpen,
  closeTransactionDetails,
  singleTransaction,
}) => {
  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    hour12: true,
  };
  return (
    <Modal
      size="lg"
      open={transactionDetailsOpen}
      onClose={closeTransactionDetails}
    >
      <div className="relative">
        <div
          onClick={closeTransactionDetails}
          className="absolute right-[0px] cursor-pointer text-[24px] w-[40px] h-[40px] top-[0px] flex items-center justify-center"
        >
          x
        </div>
        <div className="bg-[#E8FBFF] h-[134px] flex flex-col justify-center">
          <p className="text-[#037BD1] mb-[10px] text-[20px] text-center">
            {singleTransaction?.public_id}
          </p>
          <div className="flex items-center justify-center gap-[40px]">
            <div className="flex items-center gap-[10px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="19"
                viewBox="0 0 16 19"
                fill="none"
              >
                <path
                  d="M14.75 16.8612L2 9.5L14.75 2.13879L14.75 16.8612Z"
                  stroke="#FF3232"
                  stroke-width="2"
                />
              </svg>
              <p className="text-[#000] text-[14px]">Reverse transaction</p>
            </div>
            <div className="flex items-center gap-[10px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.36 6L12.76 8H18V14H14.64L14.24 12H7V6H12.36ZM14 4H5V21H7V14H12.6L13 16H20V6H14.4"
                  fill="#FFD00E"
                />
              </svg>
              <p className="text-[#000] text-[14px]">Flag transaction</p>
            </div>
          </div>
        </div>
        <div className="mt-[10px]">
          <p className="border-b-[2px] border-[#456F94] text-[#000] text-[16px] w-[20%]">
            Transaction details
          </p>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Transaction ID
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">
              {singleTransaction?.public_id}
            </p>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Transaction Date/Time
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">
              {singleTransaction?.createdAt &&
                new Intl.DateTimeFormat("en-US", dateOptions).format(
                  new Date(singleTransaction?.createdAt)
                )}
            </p>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Transaction Type
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">
              {capitalize(singleTransaction?.category)}
            </p>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Transaction Amount
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">
              KES{" "}
              {Number(singleTransaction?.amount || "0.00")?.toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </p>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Transaction Memo
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">
              {singleTransaction?.note ? singleTransaction?.note : "N/A"}
            </p>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Sender details
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">
              Acc:&nbsp;
              <span className="text-[#037BD1]">
                {singleTransaction?.sender_account_number}
              </span>
              &nbsp; Name:&nbsp;
              {singleTransaction?.sender_name}
            </p>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Recipient details
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">
              Acc:&nbsp;
              <span className="text-[#037BD1]">
                {singleTransaction?.receiver_account_number
                  ? singleTransaction?.receiver_account_number
                  : "N/A"}
              </span>
              &nbsp; Name:&nbsp;
              {singleTransaction?.receiver_name
                ? singleTransaction?.receiver_name
                : "N/A"}
            </p>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Transaction Status
            </p>
            <div className="text-[#000000] flex items-center gap-[10px] w-[50%] text-[15px]">
              <p className="text-[#0C722F]">{singleTransaction?.status}</p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_8121_2264)">
                    <path
                      d="M12 0C13.1016 0 14.1641 0.140625 15.1875 0.421875C16.2109 0.703125 17.1641 1.10938 18.0469 1.64062C18.9297 2.17188 19.7383 2.79688 20.4727 3.51562C21.207 4.23438 21.8359 5.04297 22.3594 5.94141C22.8828 6.83984 23.2852 7.79688 23.5664 8.8125C23.8477 9.82812 23.9922 10.8906 24 12C24 13.1016 23.8594 14.1641 23.5781 15.1875C23.2969 16.2109 22.8906 17.1641 22.3594 18.0469C21.8281 18.9297 21.2031 19.7383 20.4844 20.4727C19.7656 21.207 18.957 21.8359 18.0586 22.3594C17.1602 22.8828 16.2031 23.2852 15.1875 23.5664C14.1719 23.8477 13.1094 23.9922 12 24C10.8984 24 9.83594 23.8594 8.8125 23.5781C7.78906 23.2969 6.83594 22.8906 5.95312 22.3594C5.07031 21.8281 4.26172 21.2031 3.52734 20.4844C2.79297 19.7656 2.16406 18.957 1.64062 18.0586C1.11719 17.1602 0.714844 16.2031 0.433594 15.1875C0.152344 14.1719 0.0078125 13.1094 0 12C0 10.8984 0.140625 9.83594 0.421875 8.8125C0.703125 7.78906 1.10938 6.83594 1.64062 5.95312C2.17188 5.07031 2.79688 4.26172 3.51562 3.52734C4.23438 2.79297 5.04297 2.16406 5.94141 1.64062C6.83984 1.11719 7.79688 0.714844 8.8125 0.433594C9.82812 0.152344 10.8906 0.0078125 12 0ZM19.0664 8.02734L17.4727 6.43359L9.75 14.1562L6.52734 10.9336L4.93359 12.5273L9.75 17.3438L19.0664 8.02734Z"
                      fill="#0C722F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8121_2264">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Transaction Source
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">
              {singleTransaction?.platform
                ? singleTransaction?.platform
                : "N/A"}
            </p>
          </div>
          <div className="flex w-[80%] items-center mt-[10px]">
            <p className="text-[#000000] w-[50%] font-bold text-[15px]">
              Sender balance after transaction
            </p>
            <p className="text-[#000000] w-[50%] text-[15px]">N/A</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionModal;
