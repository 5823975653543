import React, { useState, useEffect } from "react";

const TwoFactorAuth = ({
  id_R,
  closeTwoFactorAuthModal,
  twoFactorAuthModalOpen,
  selectedUser,
}) => {
  const [editemail, setEditEmail] = useState("");

  useEffect(() => {
    if (selectedUser) {
      setEditEmail(selectedUser.email || "");
    }
  }, [selectedUser]);

  const handleEmailChange = (e) => {
    setEditEmail(e.target.value);
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          twoFactorAuthModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
          <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto h-[95vh]">
            <div className="rounded-t flex items-center">
              <div className="p-4 mt-2 h-[10vh]">
                <button
                  onClick={closeTwoFactorAuthModal}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
                      fill="black"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                  <p className="text-[20px] font-bold text-[#000] ml-5">
                    Manage Two-Factor Authentification
                  </p>
                </button>
              </div>
            </div>
            <div className="flex items-center w-[58vw] px-5">
              <div className="w-[95%] text-[#000] text-sm font-normal ">
                <p>
                  ⚠️ Two-factor authentication is enabled on default for all
                  user setup in the system. This ensures user
                </p>
                <p>accounts are more secure.</p>
              </div>
            </div>

            <div className="flex h-[22vh] px-5">
              <div className="flex flex-col justify-center">
                <label
                  htmlFor="email"
                  className="text-md font-[300] text-[#060606] flex items-center h-[6vh]"
                >
                  Below is the email used for 2FA for this account
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={selectedUser?.email}
                  value={editemail}
                  onChange={handleEmailChange}
                  className="w-[27vw] h-10 px-4 border border-[1px] border-[#000]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoFactorAuth;
