import React, { useState, useRef, useEffect } from "react";

const ViewID = ({
  document,
  ViewIDOpen,
  handleCloseViewID,
  customerInitials,
  customerStatus,
  handleEditIdentity,
  handleEditMetaData,
}) => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [documentFlagged, setDocumentFlagged] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const editPopupRef = useRef(null);

  const handleFlagDocument = () => {
    setDocumentFlagged(!documentFlagged);
  };

  const toggleEditPopup = () => {
    setShowEditPopup(!showEditPopup);
  };

  const handleClickOutside = (event) => {
    if (editPopupRef.current && !editPopupRef.current.contains(event.target)) {
      setShowEditPopup(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleString("en-GB", options);
  };

  return (
    <div
      className={`${
        ViewIDOpen ? "flex" : "hidden"
      } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
    >
      <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
        <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto w-[67vw] h-[95vh]">
          <div className="justify-end rounded-t flex items-end">
            <button
              onClick={handleCloseViewID}
              type="button"
              className="bg-transparent rounded-lg text-sm p-2.5 ml-auto inline-flex items-center"
              data-modal-toggle="modal"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.84 12L5.7 8.9L3.9 12H0.2L3.88 6.3L0.1 0.839999H3.94L6.08 3.92L7.88 0.839999H11.58L7.84 6.46L11.68 12H7.84Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
          <div className="flex items-center w-[58vw]">
            <div className="w-[10vw] flex items-center justify-center">
              <div className="w-[97px] h-[97px] rounded-full bg-[#BEF4FF] flex items-center justify-center text-[#074073] text-[36px] font-medium">
                {customerInitials}
              </div>
            </div>
            <div className="w-[30vw]">
              <p className="text-[#000] text-[20px] bold">
                {document?.firstname?.charAt(0)?.toUpperCase() +
                  document?.firstname?.slice(1)?.toLowerCase()}{" "}
                {"  "}
                {document?.lastname?.charAt(0)?.toUpperCase() +
                  document?.lastname?.slice(1)?.toLowerCase()}
              </p>
              <p className="text-[#074073] text-[15px] font-normal">
                {document?.identification_type || "N/A"}
              </p>
            </div>
            <div
              className="flex items-center justify-center gap-2 w-[20vw] cursor-pointer"
              onClick={() => handleFlagDocument()}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.36 4L10.76 6H16V12H12.64L12.24 10H5V4H10.36ZM12 2H3V19H5V12H10.6L11 14H18V4H12.4"
                  fill="#19C1E4"
                />
              </svg>
              {documentFlagged ? (
                <p className="text-[#074073] text-[14px] font-normal">
                  Verify Document
                </p>
              ) : (
                <p className="text-[#FF3232] text-[14px] font-normal">
                  Flag document
                </p>
              )}
            </div>
            <div
              className="relative flex items-center justify-center gap-2 w-[20vw] cursor-pointer"
              onClick={toggleEditPopup}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_14470_17424)">
                  <path
                    d="M11.375 2.625C11.598 2.62525 11.8125 2.71064 11.9747 2.86374C12.1369 3.01684 12.2345 3.22608 12.2475 3.44872C12.2606 3.67136 12.1882 3.89058 12.045 4.0616C11.9019 4.23262 11.6988 4.34253 11.4774 4.36888L11.375 4.375H4.375V16.625H16.625V9.625C16.6252 9.40198 16.7106 9.18747 16.8637 9.0253C17.0168 8.86313 17.2261 8.76554 17.4487 8.75247C17.6714 8.7394 17.8906 8.81184 18.0616 8.95498C18.2326 9.09812 18.3425 9.30117 18.3689 9.52263L18.375 9.625V16.625C18.3751 17.0665 18.2084 17.4917 17.9082 17.8155C17.608 18.1392 17.1965 18.3375 16.7562 18.3706L16.625 18.375H4.375C3.9335 18.3751 3.50825 18.2084 3.18452 17.9082C2.86079 17.608 2.66249 17.1965 2.62938 16.7562L2.625 16.625V4.375C2.62486 3.9335 2.79161 3.50825 3.09181 3.18452C3.39201 2.86079 3.80349 2.66249 4.24375 2.62938L4.375 2.625H11.375ZM16.8376 2.92512C16.9951 2.7682 17.2064 2.67709 17.4286 2.6703C17.6508 2.66352 17.8672 2.74156 18.034 2.88859C18.2007 3.03562 18.3053 3.24061 18.3263 3.46192C18.3474 3.68322 18.2835 3.90426 18.1475 4.08013L18.0749 4.16325L9.41237 12.8249C9.25491 12.9818 9.04362 13.0729 8.82141 13.0797C8.59921 13.0865 8.38275 13.0084 8.21601 12.8614C8.04926 12.7144 7.94473 12.5094 7.92365 12.2881C7.90257 12.0668 7.96652 11.8457 8.1025 11.6699L8.17513 11.5876L16.8376 2.92512Z"
                    fill="#19C1E4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_14470_17424">
                    <rect width="21" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p className="text-[#074073] text-[14px] font-normal">
                Edit document
              </p>
              {showEditPopup && (
                <div
                  ref={editPopupRef}
                  className="absolute left-0 top-full mt-2 w-[315px]  bg-white shadow-md border border-[#E0E0E0] rounded-md z-50"
                >
                  <div
                    className="cursor-pointer hover:bg-gray-100 rounded px-2 py-2"
                    onClick={() => handleEditIdentity()}
                  >
                    <p className="text-[14px] text-[#0187E8]">
                      Replace Document
                    </p>
                    <p className="text-[12px] text-[#000000] font-[300]">
                      Use this option if the user has provided a new or
                      corrected version of their National ID.
                    </p>
                  </div>
                  <div
                    className="cursor-pointer hover:bg-gray-100 rounded px-2 py-2"
                    onClick={() => handleEditMetaData()}
                  >
                    <p className="text-[14px] text-[#0187E8]">Edit Metadata</p>
                    <p className="text-[12px] text-[#000000] font-[300]">
                      This option allows you to update the information
                      associated with the National ID, such as the ID number.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="w-[25%] mx-[20px] h-[10vh] gap-4 flex items-end">
            <button
              className={`w-[50%] mt-1.5 h-[39px] ${
                activeTab === "tab1" ? "font-medium" : ""
              }`}
              onClick={() => setActiveTab("tab1")}
            >
              <p
                className={`text-[#000] text-[16px] ${
                  activeTab === "tab1" ? "font-medium" : ""
                }`}
              >
                Preview
              </p>
              {activeTab === "tab1" && (
                <div className="w-[100%] h-[4px] mt-3.5 bg-[#456F94]" />
              )}
            </button>
            <button
              className={`w-[50%] mt-1.5 h-[39px] ${
                activeTab === "tab2" ? "font-medium" : ""
              }`}
              onClick={() => setActiveTab("tab2")}
            >
              <p
                className={`text-[#000] text-[16px] ${
                  activeTab === "tab2" ? "font-medium" : ""
                }`}
              >
                Details
              </p>
              {activeTab === "tab2" && (
                <div className="w-[100%] h-[4px] mt-3.5 bg-[#456F94]" />
              )}
            </button>
          </div>

          {activeTab === "tab1" && (
            <div className="flex justify-between items-center mt-5 mx-auto w-[92%] h-[65%] border-[1px] border-[#DFDFDF] px-3">
              {document?.identification_type &&
              (document?.identification_type?.toLowerCase() === "national id" ||
                document?.identification_type === "National_Id") ? (
                <>
                  <div className="p-3 h-[88%] w-[49.5%] overflow-auto flex justify-center items-center border-[1px] border-[#DFDFDF]">
                    {document?.id_front_image ? (
                      <img src={document.id_front_image} alt="ID Front" />
                    ) : (
                      <span>No Front Image Available</span>
                    )}
                  </div>
                  <div className="p-3 h-[88%] w-[49.5%] overflow-auto flex justify-center items-center border-[1px] border-[#DFDFDF]">
                    {document?.id_back_image ? (
                      <img src={document.id_back_image} alt="ID Back" />
                    ) : (
                      <span>No Back Image Available</span>
                    )}
                  </div>
                </>
              ) : (
                <div className="p-5 h-[80%] w-[96%] overflow-auto flex justify-center items-center">
                  {document?.passport_image ? (
                    <img src={document.passport_image} alt="Passport" />
                  ) : (
                    <span>No Passport Image Available</span>
                  )}
                </div>
              )}
            </div>
          )}

          {activeTab === "tab2" && (
            <>
              <>
                <div className="w-[95%] mx-[20px] h-[15vh] flex items-end">
                  <div className="w-[30vw]">
                    <p className="text-[#000] text-[16px] font-medium">
                      Date & time uploaded
                    </p>
                    <p className="mt-3 text-[#4F4F4F] text-[16px] font-[300]">
                      {formatDateTime(document?.createdAt)}
                    </p>
                  </div>
                  <div className="w-[20vw]">
                    <p className="text-[#000] text-[16px] font-medium">
                      Document type
                    </p>
                    <p className="mt-3 text-[#4F4F4F] text-[16px] font-[300]">
                      {document?.identification_type || "N/A"}
                    </p>
                  </div>
                </div>

                <div className="w-[95%] mx-[20px] h-[15vh] flex items-end">
                  <div className="w-[30vw]">
                    <p className="text-[#000] text-[16px] font-medium">
                      Document ID Number
                    </p>
                    <p className="mt-3 text-[#4F4F4F] text-[16px] font-[300]">
                      {document?.identification}
                    </p>
                  </div>
                  <div className="w-[20vw]">
                    <p className="text-[#000] text-[16px] font-medium">
                      Document status
                    </p>
                    {documentFlagged ? (
                      <span className="flex gap-3 items-center mt-3">
                        <p className="text-[#FF3232] text-[16px] font-[300]">
                          Flagged
                        </p>
                        <svg
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.2458 3.69459L8.99575 2.10084C8.677 1.98042 8.32284 1.98042 8.00409 2.10084L3.75409 3.69459C3.20159 3.90709 2.83325 4.43126 2.83325 5.02626V8.35542C2.83325 11.8546 5.1495 15.1342 8.2945 16.0267C8.42909 16.0621 8.57075 16.0621 8.69825 16.0267C11.8503 15.1342 14.1666 11.8546 14.1666 8.35542V5.02626C14.1666 4.43126 13.7983 3.90709 13.2458 3.69459ZM10.4833 10.9763C10.207 11.2525 9.76075 11.2525 9.4845 10.9833L8.49992 10.0058L7.51534 10.9833C7.38289 11.1158 7.20326 11.1902 7.01596 11.1902C6.82866 11.1902 6.64903 11.1158 6.51659 10.9833C6.38414 10.8509 6.30974 10.6713 6.30974 10.484C6.30974 10.2967 6.38414 10.117 6.51659 9.98459L7.50117 9.00001L6.51659 8.01542C6.38414 7.88298 6.30974 7.70335 6.30974 7.51605C6.30974 7.32875 6.38414 7.14912 6.51659 7.01667C6.64903 6.88423 6.82866 6.80983 7.01596 6.80983C7.20326 6.80983 7.38289 6.88423 7.51534 7.01667L8.49992 8.00126L9.4845 7.01667C9.61694 6.88423 9.79658 6.80983 9.98388 6.80983C10.1712 6.80983 10.3508 6.88423 10.4833 7.01667C10.6157 7.14912 10.6901 7.32875 10.6901 7.51605C10.6901 7.70335 10.6157 7.88298 10.4833 8.01542L9.50575 9.00001L10.4833 9.97751C10.7595 10.2538 10.7595 10.7 10.4833 10.9763Z"
                            fill="#FF3232"
                          />
                        </svg>
                      </span>
                    ) : customerStatus === "Active" ? (
                      <span className="flex gap-3 items-center mt-3">
                        <p className="text-[#4F4F4F] text-[16px] font-[300]">
                          Verified
                        </p>
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.95 11.8581L10.908 6.90015L10.194 6.18715L5.95 10.4311L3.812 8.29215L3.098 9.00615L5.95 11.8581ZM7 17.9601C4.99133 17.3641 3.32233 16.1475 1.993 14.3101C0.663667 12.4728 -0.000666165 10.4028 5.01253e-07 8.10015V2.69215L7 0.0771484L14 2.69215V8.10015C14 10.4028 13.3357 12.4728 12.007 14.3101C10.6783 16.1475 9.00933 17.3651 7 17.9631"
                            fill="#00FF00"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="flex gap-3 items-center mt-3">
                        <p className="text-[#4F4F4F] text-[16px] font-[300]">
                          Unverified
                        </p>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.35259 15.75H14.6476C15.8026 15.75 16.5226 14.4975 15.9451 13.5L10.2976 3.7425C9.72009 2.745 8.28009 2.745 7.70259 3.7425L2.05509 13.5C1.47759 14.4975 2.19759 15.75 3.35259 15.75ZM9.00009 10.5C8.58759 10.5 8.25009 10.1625 8.25009 9.75V8.25C8.25009 7.8375 8.58759 7.5 9.00009 7.5C9.41259 7.5 9.75009 7.8375 9.75009 8.25V9.75C9.75009 10.1625 9.41259 10.5 9.00009 10.5ZM9.75009 13.5H8.25009V12H9.75009V13.5Z"
                            fill="#FAB32B"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
              </>
              <div className="w-[95%] mx-[20px] mt-12 h-[15vh] flex items-start">
                <div className="w-[30vw]">
                  <p className="text-[#000] text-[16px] font-medium">
                    Last modified
                  </p>
                  <p className="mt-3 text-[#4F4F4F] text-[16px] font-[300]">
                    {formatDateTime(document?.updatedAt)}
                  </p>
                  <p
                    className="text-[#19C1E4] text-[15px] font-normal cursor-pointer"
                    onClick={console.log("clicked")}
                  >
                    Audit document
                  </p>
                </div>
                <div className="w-[20vw] flex flex-col justify-between gap-3">
                  <p className="text-[#000] text-[16px] font-medium">
                    Expiry date
                  </p>
                  <p className="text-[#FF3232] text-[16px] font-[300]">
                    {document?.expiryDate || "N/A"}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewID;
