import React, { useState, useEffect } from "react";
import ProgressBar from "../../components/progressBar/ProgressBar";

const Modal = ({
  id,
  closeModalFunction,
  isModalOpen,
  openRolesModal,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  mobileno,
  setMobilenoName,
  userName,
  setUserName,
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const [isInputsFilled, setIsInputsFilled] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleNextButtonClick = () => {
    // Close the current modal
    closeModalFunction();
    // Open the next modal
    openRolesModal();
  };

  useEffect(() => {
    const areInputsFilled = !!(
      firstName &&
      lastName &&
      userName &&
      email &&
      mobileno
    );
    setIsInputsFilled(areInputsFilled);
  }, [firstName, lastName, userName, email, mobileno]);

  const handleCancelButton = () => {
    // Reset all input fields
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobilenoName("");
    setUserName("");

    // Close the modal
    closeModalFunction();
  };

  return (
    <>
      <div
        id={id}
        className={`${
          isModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[65%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="py-4 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add user
                </p>
              </div>
              <div className="mt-6 px-6">
                <ProgressBar step={1} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col justify-between">
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Setup basic user information
                  </p>
                  <div className="text-[#000] mt-[40px] text-sm font-normal">
                    <p>
                      To kick things off, we need some basic information about
                      the user you're adding. Please take a moment to provide
                      the essential details to ensure a smooth onboarding
                      process.
                    </p>
                  </div>
                </div>
                <div className="flex w-full justify-between mt-[40px] mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="nameInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      id="nameInput"
                      name="firstName"
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="w-[90%] h-10 px-4 border border-[1px] border-[#000]"
                    />
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="nameInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      id="nameInput"
                      name="lastName"
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="w-[90%] h-10 px-4 border border-[1px] border-[#000]"
                    />
                  </div>
                </div>
                <div className="flex w-full justify-between mt-[20px] mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="nameInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      id="nameInput"
                      name="userName"
                      placeholder="Enter your username"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      className="w-[90%] h-10 px-4 border border-[1px] border-[#000]"
                    />
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="email"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-[90%] h-10 px-4 border border-[1px] border-[#000]"
                    />
                  </div>
                </div>
                <div className="flex w-full justify-between mt-[20px] mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="phoneNumberInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Phone number
                    </label>
                    <input
                      type="text"
                      id="phoneNumberInput"
                      name="mobileno"
                      placeholder="Enter your phone number"
                      value={mobileno}
                      onChange={(e) => setMobilenoName(e.target.value)}
                      className="w-[90%] h-10 px-4 border border-[1px] border-[#000]"
                    />
                  </div>
                </div>
                <label className="flex gap-2 mt-10">
                  <input
                    type="checkbox"
                    name="myCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  Automatically create password
                </label>
              </div>
              <div className="flex w-[95%] items-center justify-between mt-8 mb-8 mr-5">
                <button
                  onClick={handleNextButtonClick}
                  // data-modal-toggle={id}
                  type="button"
                  className={`text-white ${
                    isInputsFilled && isChecked
                      ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                      : "bg-[#D9D9D9] pointer-events-none"
                  } block font-medium rounded-[5px] text-sm px-8 py-3.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                >
                  Next
                </button>
                <button
                  onClick={handleCancelButton}
                  data-modal-toggle={id}
                  type="button"
                  className="text-gray-500 bg-[#D9D9D9] hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[5px] border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
