import React, { useState } from "react";
import Navigation from "../../components/navigation/Navigation";
import { sendUserOtp, setAuthPassword } from "../../sdk/auth/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const NewPassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { auth } = useAuth();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = await JSON.parse(localStorage.getItem("auth"));
      let id = auth?.user?.id;

      if (newPassword === confirmNewPassword) {
        const result = await setAuthPassword(id, newPassword, currentPassword);
        if (
          result.data.statusCode === 200 ||
          result.data.status === 200 ||
          result.data.message === "Success" ||
          result.statusCode === 200
        ) {
          // send otp
          const final = await sendUserOtp(auth?.user?.id);
          if (final.statusCode === 200) {
            navigate("/verification");
          }
          console.log(final);
        }
      } else {
        toast.error("Passwords do not match");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const gradientStyle = {
    background: "linear-gradient(113deg, #031322 -0.58%, #074073 100%)",
  };
  return (
    <>
      <div className="z-50 relative">
        <Navigation />
      </div>
      <div
        className="w-[100%] h-[calc(100vh-100px)] min-h-[85vh] bg-[#F7FBFF] relative"
        style={gradientStyle}
      >
        <div className="absolute top-[-100px] z-50 right-[0px]">
          <svg
            width="502"
            height="457"
            viewBox="0 0 502 457"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g style={{ mixBlendMode: "lighten" }}>
              <path
                d="M325.872 76.2466C329.453 79.8275 329.453 85.6331 325.872 89.2139L73.5094 341.576C69.9286 345.157 64.1229 345.157 60.5421 341.576C56.9612 337.996 56.9612 332.19 60.5421 328.609L312.904 76.2467C316.485 72.6658 322.291 72.6658 325.872 76.2466Z"
                fill="url(#paint0_linear_7601_3910)"
              />
              <path
                d="M496.96 4.39531C499.388 6.82275 499.388 10.7584 496.96 13.1859L133.507 376.639C131.08 379.066 127.144 379.066 124.717 376.639C122.289 374.211 122.289 370.276 124.717 367.848L488.17 4.39531C490.597 1.96786 494.533 1.96786 496.96 4.39531Z"
                fill="url(#paint1_linear_7601_3910)"
              />
              <path
                d="M302.981 153.356C310.92 161.295 310.92 174.166 302.981 182.104L43.1222 441.964C35.1836 449.902 22.3125 449.902 14.3739 441.963C6.43527 434.025 6.43528 421.154 14.3739 413.215L274.233 153.356C282.172 145.418 295.043 145.418 302.981 153.356Z"
                fill="url(#paint2_linear_7601_3910)"
              />
              <path
                d="M190.974 167.345C196.072 172.443 196.072 180.709 190.974 185.807L106.744 270.037C101.646 275.135 93.381 275.135 88.283 270.037C83.1851 264.939 83.1851 256.673 88.283 251.575L172.513 167.345C177.611 162.247 185.876 162.247 190.974 167.345Z"
                fill="url(#paint3_linear_7601_3910)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_7601_3910"
                x1="303.499"
                y1="101.254"
                x2="48.1106"
                y2="334.884"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#074073" />
                <stop offset="1" stop-color="#19C1E4" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_7601_3910"
                x1="464.617"
                y1="38.5246"
                x2="92.4106"
                y2="347.963"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#074073" />
                <stop offset="1" stop-color="#19C1E4" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_7601_3910"
                x1="280.134"
                y1="182.046"
                x2="7.95909"
                y2="442.591"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#074073" />
                <stop offset="1" stop-color="#19C1E4" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_7601_3910"
                x1="183.681"
                y1="178.39"
                x2="87.7279"
                y2="272.05"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#074073" />
                <stop offset="1" stop-color="#19C1E4" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="flex items-center h-full justify-center relative">
          <div className="w-[50%] h-[624px] flex items-center">
            <div className="w-[100%] z-50 border-gray bg-white h-[100%] flex px-[20px] flex-col justify-center">
              <p className="text-[18px] text-[#074073] mb-[10px]">
                Set up your password
              </p>
              <p className="text-[15px] text-[#000] mb-[10px]">
                Welcome {auth?.user?.firstname}, create your password by filling
                in the fields below
              </p>
              <form onSubmit={handleSubmit}>
                <div className="flex gap-[5px] mb-[15px] w-full flex-col">
                  <label className="text-[#000]" htmlFor="username">
                    Current Password
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      placeholder="Enter password"
                      className="border w-full rounded-[5px] h-[50px] px-[20px] focus:outline-none"
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      className="absolute top-[50%] right-[10px] transform translate-y-[-50%] cursor-pointer"
                    >
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 16a5 5 0 0 1-5 5m-5-5a5 5 0 0 1 5-5m-3 13.654A13.38 13.38 0 0 0 16 25c7.18 0 13-6 13-9c0-1.336-1.155-3.268-3.071-5M19.5 7.47A13.49 13.49 0 0 0 16 7C8.82 7 3 13 3 16c0 1.32 1.127 3.22 3 4.935M7 25L25 7"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex gap-[5px] mb-[15px] w-full flex-col">
                  <label className="text-[#000]" htmlFor="password">
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Enter password"
                      className="border w-full rounded-[5px] h-[50px] px-[20px] focus:outline-none"
                    />
                    <span
                      onClick={toggleNewPasswordVisibility}
                      className="absolute top-[50%] right-[10px] transform translate-y-[-50%] cursor-pointer"
                    >
                      {showNewPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 16a5 5 0 0 1-5 5m-5-5a5 5 0 0 1 5-5m-3 13.654A13.38 13.38 0 0 0 16 25c7.18 0 13-6 13-9c0-1.336-1.155-3.268-3.071-5M19.5 7.47A13.49 13.49 0 0 0 16 7C8.82 7 3 13 3 16c0 1.32 1.127 3.22 3 4.935M7 25L25 7"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex gap-[5px] w-full flex-col">
                  <label className="text-[#000]" htmlFor="password">
                    Confirm New Password
                  </label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmNewPassword}
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      placeholder="Enter password"
                      className="border w-full rounded-[5px] h-[50px] px-[20px] focus:outline-none"
                    />
                    <span
                      onClick={toggleConfirmPasswordVisibility}
                      className="absolute top-[50%] right-[10px] transform translate-y-[-50%] cursor-pointer"
                    >
                      {showConfirmPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 16a5 5 0 0 1-5 5m-5-5a5 5 0 0 1 5-5m-3 13.654A13.38 13.38 0 0 0 16 25c7.18 0 13-6 13-9c0-1.336-1.155-3.268-3.071-5M19.5 7.47A13.49 13.49 0 0 0 16 7C8.82 7 3 13 3 16c0 1.32 1.127 3.22 3 4.935M7 25L25 7"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </div>
                <div className="h-[135px] bg-[#D3EAFF] w-full mt-[15px] rounded-[5px] p-[10px]">
                  <p className="text-[#000] text-[14px] mb-[10px]">
                    Your password must contain the requirements below:
                  </p>
                  <ul className="text-[#000] list-disc list-inside">
                    <li className="pl-[10px]">At least 8 characters</li>
                    <li className="pl-[10px]">At least 1 uppercase letter</li>
                    <li className="pl-[10px]">At least 1 lower letter</li>
                    <li className="pl-[10px]">A special character</li>
                  </ul>
                </div>
                <button className="bg-[#041422] mt-[30px] text-white rounded-[5px] w-[100%] h-[44px]">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
