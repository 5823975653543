import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";


const DonutChart = ({ loanData }) => {


  const COLORS = [
    "#074073",
    "#FF7070",
    "#88C1F4",
    "#00DEFD",
    "#622977",
    "#FFCE1E",
  ];

  const filteredData = loanData;

  return (
    <div className="flex items-center justify-center">
      <PieChart width={300} height={280}>
        <Pie
          data={loanData}
          // cx={240}
          // cy={150}
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          stroke="none"
        >
          {filteredData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          content={({ payload }) => {
            if (payload && payload.length > 0) {
              const { name, percentage, value } = payload[0].payload;
              return (
                <div
                  style={{
                    background: "rgba(230, 243, 255, 0.90)",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div>{name}</div>
                  <div>{`Percent: ${percentage}%`}</div>
                  <div>{`Count: ${value}`}</div>
                </div>
              );
            }
            return null;
          }}
        />
      </PieChart>
    </div>
  );
};

export default DonutChart;

