import React, { useEffect, useState } from "react";
import { Modal, Button } from "rsuite";
import { getModules, updateRolesPermissions } from "../../../sdk/users/modules";

const ManagePermissions = ({
  openPermissions,
  handleClosePermissions,
  role,
  modules,
  fetchRoles,
  handleClose,
}) => {
  const [roleId, setRoleId] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState(() =>
    role?.permissions?.map((p) => p?.id)
  );
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [roleUsers, setRoleUsers] = useState([]);

  useEffect(() => {
    setRoleId(role?.id);
    setRoleName(role?.name);
    setRoleDescription(role?.description);

    const resPermissions = role?.permissions?.map((p) => p?.id);
    setSelectedPermissions(resPermissions);

    const roleusers = role?.users?.map((u) => u?.id);
    setRoleUsers(roleusers);
  }, [role]);

  const handleCheckboxChange = (permissionId) => {
    const isSelected = selectedPermissions.includes(permissionId);
    if (isSelected) {
      setSelectedPermissions((prevSelected) =>
        prevSelected.filter((id) => id !== permissionId)
      );
    } else {
      setSelectedPermissions((prevSelected) => [...prevSelected, permissionId]);
    }
  };

  const handleUpdateRolesPermissions = async () => {
    const response = await updateRolesPermissions(
      roleId,
      roleName,
      roleDescription,
      roleUsers,
      selectedPermissions
    );
    handleClosePermissions();
    handleClose();
    fetchRoles();
  };
  return (
    <Modal size="lg" open={openPermissions} onClose={handleClosePermissions}>
      <div className="h-[550px]">
        <div className="flex items-center gap-[10px] mb-[40px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
              fill="black"
            />
          </svg>
          <p className="text-[#000] font-bold text-[18px]">
            Manage role permissions
          </p>
        </div>
        <p className="mb-[40px]">
          ⚠️ Adjust the permissions for this role to precisely tailor its
          access. Be mindful of the impact on user capabilities and ensure
          alignment with the role's responsibilities.
        </p>
        <p className="mb-[10px] text-[#000]">Select Permissions</p>
        <div className="border h-[350px] p-[10px] overflow-y-auto w-full">
          {modules &&
            modules?.map((module) => (
              <div key={module?.id}>
                <p className="text-[#000] font-bold">{module?.name}</p>
                {module?.permissions?.map((permission) => (
                  <div
                    key={permission?.id}
                    className="flex items-center my-[10px]"
                  >
                    <div className="w-[40%] mb-[20px]">
                      <p className="pl-[20px]">{permission?.name}</p>
                    </div>
                    <div className="w-[60%] flex items-center justify-center">
                      <input
                        type="checkbox"
                        className="h-[20px] w-[20px]"
                        name="permissions"
                        id="permissions"
                        checked={selectedPermissions?.includes(permission?.id)}
                        onChange={() => handleCheckboxChange(permission?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
      <Modal.Footer>
        <div className="flex w-full justify-between">
          <button
            onClick={handleUpdateRolesPermissions}
            className="bg-[#D9D9D9] h-[50px] px-[10px] hover:bg-[#074073] hover:text-white"
          >
            Save Changes
          </button>
          <button
            onClick={handleClosePermissions}
            className="bg-[#D9D9D9] h-[50px] px-[20px] hover:bg-[#FF3232] hover:text-white"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ManagePermissions;
