import { Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Dashboard from "./pages/dashboard/Dashboard";
import Customers from "./pages/customers/Customers";
import Loans from "./pages/loans/Loans";
import Reports from "./pages/reports/Reports";
import Shares from "./pages/shares/Shares";
import ShareView from "./pages/shares/ShareView";
import Transactions from "./pages/transactions/Transactions";
import Settings from "./pages/settings/Settings";
import UserManagement from "./pages/userManagement/UserManagement";
import Login from "./pages/login/Login";
import Customer from "./pages/customers/Customer";
import CustomerTransactions from "./pages/customers/CustomerTransactions";
import NewPassword from "./pages/set-password/NewPassword";
import Verification from "./pages/verification/Verification";
import RequireAuth from "./components/auth/RequireAuth";
import Unauthorized from "./components/auth/Unauthorized";
import Loan from "./pages/loans/Loan";

function App() {
  const ROLES = {
    master_admin: 2000,
    super_admin: 1999,
    customer_admin: 2001,
  };
  return (
    <main>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        <Route element={<MainLayout />} path="/dashboard">
          {/* dashboard main */}
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.customer_admin,
                  ROLES.master_admin,
                  ROLES.super_admin,
                ]}
              />
            }
          >
            <Route element={<Dashboard />} index />
          </Route>

          {/* customers */}
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.customer_admin,
                  ROLES.master_admin,
                  ROLES.super_admin,
                ]}
              />
            }
          >
            <Route element={<Customers />} path="/dashboard/customers" />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.customer_admin,
                  ROLES.master_admin,
                  ROLES.super_admin,
                ]}
              />
            }
          >
            <Route element={<Customer />} path="/dashboard/customers/:id" />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  ROLES.customer_admin,
                  ROLES.master_admin,
                  ROLES.super_admin,
                ]}
              />
            }
          >
            <Route
              element={<CustomerTransactions />}
              path="/dashboard/customers/:id/:accountId"
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.super_admin]} />}>
            <Route element={<Loans />} path="/dashboard/loans" />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.super_admin]} />}>
            <Route element={<Loan />} path="/dashboard/loans/:loanCode/:customerId" />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.super_admin]} />}>
            <Route element={<Shares />} path="/dashboard/shares" />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.super_admin]} />}>
            <Route element={<ShareView />} path="/dashboard/sharesview/:memberId" />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.super_admin]} />}>
            <Route element={<Reports />} path="/dashboard/reports" />
          </Route>

          <Route
            element={
              <RequireAuth
                allowedRoles={[ROLES.master_admin, ROLES.super_admin]}
              />
            }
          >
            <Route element={<Transactions />} path="/dashboard/transactions" />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.super_admin]} />}>
            <Route element={<Settings />} path="/dashboard/settings" />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.super_admin]} />}>
            <Route
              element={<UserManagement allowedRoles={[]} />}
              path="/dashboard/userManagement"
            />
          </Route>
        </Route>
      </Routes>
    </main>
  );
}

export default App;
