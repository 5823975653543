import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  const gradientStyle = {
    background: "#183651",
  };

  return (
    <div
      className="bg-white h-[100px] z-50 w-[100%] shadow md:shadow-sm"
      style={gradientStyle}
    >
      <div className="w-[80%] mx-auto flex items-center h-[100%] justify-between">
        <img src="/logo.png" alt="logo" className="w-[100px]" />
        <div className="flex items-center gap-[80px]">
          <NavLink className="text-white" to="#">
            Platform
          </NavLink>
          <NavLink className="text-white" to="#">
            Solutions
          </NavLink>
          <NavLink className="text-white" to="#">
            Company
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
