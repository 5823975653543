import React, { useState, useEffect } from "react";
import CustomerProgress from "../../../components/progressBar/CustomerProgress";

const FirstModal = ({
  id,
  closeModalFunction,
  isModalOpen,
  openIDModal,
  firstName,
  lastName,
  gender,
  middleName,
  dob,
  identification,
  openPhotoModal,
}) => {
  const [isInputsFilled, setIsInputsFilled] = useState(false);

  const handleBackButtonClick = () => {
    // Close the current modal
    closeModalFunction();

    // Open the next modal
    openIDModal();
  };

  const handleNextButtonClick = () => {
    // Close the current modal
    closeModalFunction();

    // Open the next modal
    openPhotoModal();
  };

  useEffect(() => {
    const areInputsFilled = !!(firstName && lastName && middleName);
    setIsInputsFilled(areInputsFilled);
  }, [firstName, lastName, middleName]);
  return (
    <>
      <div
        id={id}
        className={`${
          isModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="pt-4 px-2 rounded-t">
                <span className="sr-only">Close modal</span>
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add Member
                </p>
              </div>
              <div className="mt-[32px] px-6">
                <CustomerProgress step={4} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col justify-between">
              <div className="flex items-center justify-end">
                <button
                  onClick={closeModalFunction}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle={id}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <div className="mt-4 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Review Members’ details
                  </p>
                  <div className="text-[#000] mt-3 text-sm font-normal">
                    <p>
                      Please upload identity documents to verify the member's
                      identity and personal details. Ensure that the provided
                      identity document clearly matches the member's face.
                    </p>
                  </div>
                </div>
                <div className="flex justify-between w-full mt-10 mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="nameInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Identity document Number
                    </label>
                    <div className="flex items-center text-[#000000] rounded-[4px] px-3 w-[90%] h-10 bg-[#EAEAEA] text-[16px] font-[300]">
                      {identification}
                    </div>
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="nameInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      First name
                    </label>
                    <div className="flex items-center text-[#000000] rounded-[4px] px-3 w-[90%] h-10 bg-[#EAEAEA] text-[16px] font-[300]">
                      {firstName}
                    </div>
                  </div>
                </div>
                <div className="flex w-full justify-between mt-10 mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="nameInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Middle name
                    </label>
                    <div className="flex items-center text-[#000000] rounded-[4px] px-3 w-[90%] h-10 bg-[#EAEAEA] text-[16px] font-[300]">
                      {middleName}
                    </div>
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="nameInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Last name
                    </label>
                    <div className="flex items-center text-[#000000] rounded-[4px] px-3 w-[90%] h-10 bg-[#EAEAEA] text-[16px] font-[300]">
                      {lastName}
                    </div>
                  </div>
                </div>
                <div className="flex w-full justify-between mt-[50px] mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="dobInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Date of Birth
                    </label>
                    <div className="flex items-center text-[#000000] rounded-[4px] px-3 w-[90%] h-10 bg-[#EAEAEA] text-[16px] font-[300]">
                      {dob}
                    </div>
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="genderSelect"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Gender
                    </label>
                    <div className="flex items-center text-[#000000] rounded-[4px] px-3 w-[90%] h-10 bg-[#EAEAEA] text-[16px] font-[300]">
                      {gender}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-12 mb-8 mr-5">
                <div className="flex items-center gap-[20px]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id}
                    type="button"
                    className="text-[#000] bg-[#FF5757] hover:bg-[#FF5757] focus:outline-none font-medium rounded-[5px] text-sm px-5 py-2.5 text-center"
                  >
                    Back, this doesn’t look right, repeat
                  </button>
                </div>
                <button
                  onClick={handleNextButtonClick}
                  type="button"
                  className={`text-white ${
                    isInputsFilled
                      ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                      : "bg-[#D9D9D9] pointer-events-none"
                  } block font-medium rounded-[5px] text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                >
                  Continue, this looks right.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstModal;
