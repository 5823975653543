import React, { useRef, useState, useEffect } from "react";

const EditSelfie = ({
  id_R,
  closeSelfieModal,
  isSelfieModalOpen,
  selfieFile,
  setSelfieFile,
  openProceedModal,
}) => {
  const fileInputFront = useRef(null);

  const [isContinueEnabled, setIsContinueEnabled] = useState(false);

  useEffect(() => {
    setIsContinueEnabled(selfieFile !== null);
  }, [selfieFile]);

  const openFrontFilePicker = () => {
    fileInputFront.current.click();
  };

  const handlePhotoFileChange = (e) => {
    const file = e.target.files[0];
    setSelfieFile(file);
  };

  const handleBackButtonClick = () => {
    // Close the current modal
    closeSelfieModal();

    // Open the previous modal
  };
  const handleNextButtonClick = async () => {
    if (isContinueEnabled) {
      // Close the current modal

      // Open the next modal
      openProceedModal();
    }
  };

  const truncateFileName = (fileName) => {
    const maxLength = 12;
    const extensionIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    const truncatedName =
      nameWithoutExtension.length > maxLength
        ? `${nameWithoutExtension.substring(0, maxLength)}...`
        : nameWithoutExtension;
    return `${truncatedName}.${fileName.substring(extensionIndex + 1)}`;
  };
  return (
    <>
      <div
        id={id_R}
        className={`${
          isSelfieModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[62%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className="w-full flex flex-col mx-5">
              <div className="flex items-center justify-end">
                <button
                  onClick={closeSelfieModal}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Replace document
                  </p>
                  <div className="text-[#000] mt-[30px] text-[15px] font-[300]">
                    <p className="w-[99%]">
                      ⚠️ Use this option if the user has provided a new or
                      corrected version of their selfie photo. Replacing the
                      document will permanently delete the existing file from
                      the system. Ensure the new document is authentic and
                      properly verified before proceeding.
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-[60px]">
                  <div className="w-[70%]">
                    <p className="text-left text-[16px] mb-2 text-[#000]">
                      Upload passport size photo
                    </p>
                    <div
                      onClick={openFrontFilePicker}
                      className="w-[94%] h-[200px] rounded-[5px] bg-[#EAEAEA] border-[1px] border-[#000000] flex items-center justify-center flex-col cursor-pointer"
                    >
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.5625 29.2069V12.9881L17.1938 17.3569L15.8663 16.0106L22.5 9.375L29.1356 16.0106L27.8081 17.3588L23.4375 12.9881V29.2069H21.5625ZM12.405 35.625C11.5413 35.625 10.8206 35.3363 10.2431 34.7588C9.66562 34.1812 9.37625 33.46 9.375 32.595V28.0519H11.25V32.595C11.25 32.8838 11.37 33.1488 11.61 33.39C11.85 33.6313 12.1144 33.7512 12.4031 33.75H32.5969C32.8844 33.75 33.1488 33.63 33.39 33.39C33.6313 33.15 33.7512 32.885 33.75 32.595V28.0519H35.625V32.595C35.625 33.4588 35.3363 34.1794 34.7588 34.7569C34.1812 35.3344 33.46 35.6238 32.595 35.625H12.405Z"
                          fill="black"
                        />
                      </svg>
                      <input
                        type="file"
                        ref={fileInputFront}
                        onChange={handlePhotoFileChange}
                        style={{ display: "none" }}
                      />

                      <p className="text-#074073B8 text-16 text-center">
                        {!selfieFile ? (
                          <>
                            Drag and Drop or{" "}
                            <span style={{ color: "#0187E8" }}>
                              Choose file
                            </span>{" "}
                            here
                          </>
                        ) : (
                          truncateFileName(selfieFile.name)
                        )}
                      </p>
                      {/* <p className="text-#074073B8 text-16 text-center">
                      {!selfieFile
                        ? "Drag your file(s) here or browse"
                        : selfieFile.name}
                    </p> */}
                      {selfieFile && (
                        <p className="text-#074073B8 w-90% truncate text-16 text-center">
                          {selfieFile.type} (
                          {(selfieFile.size / 1024).toFixed(2)} KB)
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-[95%] items-center justify-between mt-24 mb-10 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                </div>
                <button
                  onClick={handleNextButtonClick}
                  data-modal-toggle={id_R}
                  type="button"
                  className={`text-white hover:bg-[#074073] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center ${
                    isContinueEnabled
                      ? "bg-[#074073]"
                      : "bg-[#D9D9D9] cursor-not-allowed"
                  }`}
                  disabled={!isContinueEnabled}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSelfie;
