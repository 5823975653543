import { client } from "../client/client";

export const getModules = async () => {
  const response = await client.get("/api/v1/modules");

  return response.data;
};

export const getRoles = async () => {
  const response = await client.get("api/v1/roles");

  return response.data;
};


export const getPermissions = async () => {
  const response = await client.get("api/v1/permissions");

  return response.data;
};

export const getRole = async (id) => {
  const response = await client.get(`api/v1/roles/${id}`);

  return response.data;
};

export const createRole = async (name, description, permission_ids) => {
  const response = await client.post("/api/v1/roles", {
    name,
    description,
    permission_ids,
  });

  return response.data;
};

export const deleteRole = async (id) => {
  const response = await client.delete(`/api/v1/roles/${id}`);

  return response.data;
};

export const updateRoleNameandDescription = async (id, updatedRole) => {
  const response = await client.patch(`/api/v1/roles/${id}`, updatedRole);

  return response.data;
};

export const updateRolesPermissions = async (
  id,
  name,
  description,
  user_ids,
  updatedPermissions
) => {
  const response = await client.patch(`/api/v1/roles/${id}`, {
    permission_ids: updatedPermissions,
    user_ids,
    name,
    description,
  });

  return response.data;
};

export const removeRoleUser = async (
  id,
  name,
  description,
  permission_ids,
  updatedUsers
) => {
  const response = await client.patch(`/api/v1/roles/${id}`, {
    user_ids: updatedUsers,
    name,
    description,
    permission_ids,
  });

  return response.data;
};

export const updateUsername = async ({
  id,
  email,
  username,
}) => {
  try {
    const { data } = await client.patch(`/api/v1/users/${id}`,
      {
        id,
        email,
        username,
      }
    );
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const updateContactInfo = async ({
  id,
  firstname,
  lastname,
  phone,
}) => {
  try {
    const { data } = await client.patch(`/api/v1/users/${id}`,
      {
        id,
        firstname,
        lastname,
        phone,
      }
    );
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const updateRole = async ({
  id,
  role_id,
}) => {
  try {
    const { data } = await client.patch(`/api/v1/users/${id}`,
      {
        id,
        role_id,
      }
    );
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};
