import { client } from "../client/client";

export const getProducts = async () => {
  try {
    const response = await client.get("/api/v1/product");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
