import React, { useEffect, useState } from "react";
import Navigation from "../../components/navigation/Navigation";
import OTPInput from "react-otp-input";
import { verifyUserOtp, sendUserOtp } from "../../sdk/auth/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";

const Verification = () => {
  const [otp, setOtp] = useState("");
  const gradientStyle = {
    background: "linear-gradient(113deg, #031322 -0.58%, #074073 100%)",
  };

  const [seconds, setSeconds] = useState(30);
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/dashboard";
  const { auth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  useEffect(() => {
    const fetchEmailFromLocalStorage = async () => {
      const storedAuth = await JSON.parse(localStorage.getItem("auth"));

      setEmail(storedAuth?.user?.email);
    };
    fetchEmailFromLocalStorage();
  }, []);

  const handleRestartTimer = async () => {
    await sendUserOtp(auth?.user?.id);
    setSeconds(30);
  };

  const handleLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const result = await verifyUserOtp(otp, email);
      if (result.message === "Success") {
        navigate(from, { replace: true });
        toast.success("Welcome to Anansi");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="z-50 relative">
        <Navigation />
      </div>
      <div
        className="w-[100%] h-[calc(100vh-100px)] bg-[#F7FBFF] relative"
        style={gradientStyle}
      >
        <div className="absolute top-[-100px] z-50 right-[0px]">
          <svg
            width="502"
            height="457"
            viewBox="0 0 502 457"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g style={{ mixBlendMode: "lighten" }}>
              <path
                d="M325.872 76.2466C329.453 79.8275 329.453 85.6331 325.872 89.2139L73.5094 341.576C69.9286 345.157 64.1229 345.157 60.5421 341.576C56.9612 337.996 56.9612 332.19 60.5421 328.609L312.904 76.2467C316.485 72.6658 322.291 72.6658 325.872 76.2466Z"
                fill="url(#paint0_linear_7601_3910)"
              />
              <path
                d="M496.96 4.39531C499.388 6.82275 499.388 10.7584 496.96 13.1859L133.507 376.639C131.08 379.066 127.144 379.066 124.717 376.639C122.289 374.211 122.289 370.276 124.717 367.848L488.17 4.39531C490.597 1.96786 494.533 1.96786 496.96 4.39531Z"
                fill="url(#paint1_linear_7601_3910)"
              />
              <path
                d="M302.981 153.356C310.92 161.295 310.92 174.166 302.981 182.104L43.1222 441.964C35.1836 449.902 22.3125 449.902 14.3739 441.963C6.43527 434.025 6.43528 421.154 14.3739 413.215L274.233 153.356C282.172 145.418 295.043 145.418 302.981 153.356Z"
                fill="url(#paint2_linear_7601_3910)"
              />
              <path
                d="M190.974 167.345C196.072 172.443 196.072 180.709 190.974 185.807L106.744 270.037C101.646 275.135 93.381 275.135 88.283 270.037C83.1851 264.939 83.1851 256.673 88.283 251.575L172.513 167.345C177.611 162.247 185.876 162.247 190.974 167.345Z"
                fill="url(#paint3_linear_7601_3910)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_7601_3910"
                x1="303.499"
                y1="101.254"
                x2="48.1106"
                y2="334.884"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#074073" />
                <stop offset="1" stop-color="#19C1E4" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_7601_3910"
                x1="464.617"
                y1="38.5246"
                x2="92.4106"
                y2="347.963"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#074073" />
                <stop offset="1" stop-color="#19C1E4" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_7601_3910"
                x1="280.134"
                y1="182.046"
                x2="7.95909"
                y2="442.591"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#074073" />
                <stop offset="1" stop-color="#19C1E4" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_7601_3910"
                x1="183.681"
                y1="178.39"
                x2="87.7279"
                y2="272.05"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#074073" />
                <stop offset="1" stop-color="#19C1E4" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="flex items-center h-full justify-center  relative">
          <div className="w-[50%] h-[624px] flex items-center">
            <div className="w-[100%] z-50 bg-white h-[100%] flex px-[20px] flex-col justify-center">
              <p className="text-[20px] text-[#074073] mb-[30px]">
                Additional security verification
              </p>
              <p className="text-[16px] text-[#000] mb-[10px]">
                For added security we need to further verify your account
              </p>
              <p className="text-[16px] text-[#000] mb-[10px]">
                We just sent an email to the address below:
              </p>
              <div className="h-[90px] bg-[#EDF6FF] w-full flex items-center gap-[20px] my-[20px] px-[20px]">
                <img src="/otp.png" alt="otp" />
                <p className="text-[15px] text-[#000]">
                  {email?.split("@")[0]}@*****************.com
                </p>
              </div>
              <p className="text-[16px] text-[#000] my-[10px]">
                Please enter the verification OTP code provided in the email to
                log in
              </p>
              <form onSubmit={handleLogin}>
                <div className="my-[10px] flex items-center w-full">
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    onPaste={true}
                    shouldAutoFocus
                    renderInput={(props) => (
                      <input
                        {...props}
                        style={{ width: "45px", height: "45px" }}
                      />
                    )}
                    renderSeparator={<span className="mx-2" />}
                    inputStyle="bg-white text-center text-[24px] rounded-[5px] text-[#000] border-[1px] border-[#000]"
                  />
                </div>
                <div className="mt-[30px] w-[65%] flex justify-between items-center">
                  <p className="text-[#074073] text-[14px]">
                    Didn’t receive a code?
                  </p>
                  {seconds === 0 ? (
                    <p className="cursor-pointer" onClick={handleRestartTimer}>
                      Resend
                    </p>
                  ) : (
                    <p>
                      Resend in
                      <span className="text-[#074073] text-[14px]">
                        00:{seconds}
                      </span>
                    </p>
                  )}
                </div>
                <div>
                  <button
                    type="submit"
                    className="bg-[#041422] mt-[30px] flex items-center justify-center gap-[10px] text-white rounded-[5px] w-[100%] h-[44px]"
                  >
                    {isLoading && (
                      <svg
                        aria-hidden="true"
                        className="w-[20px] h-[20px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    )}
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
