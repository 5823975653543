import React from "react";
import ProgressBar from "../../components/progressBar/ProgressBar";

const RolesModal = ({
  id_R,
  closeRolesModal,
  rolesModalOpen,
  openModal,
  openUserInfoModal,
  selectedRole,
  setSelectedRole,
  handleRoleChange,
  roles,
}) => {
  const handleBackButtonClick = () => {
    // Close the current modal
    closeRolesModal();

    // Open the next modal
    openModal();
  };
  const handleNextButtonClick = () => {
    // Close the current modal
    closeRolesModal();

    // Open the next modal
    openUserInfoModal();
  };

  const handleCancelButton = () => {
    // Reset all input fields
    setSelectedRole("");

    // Close the modal
    closeRolesModal();
  };
  return (
    <>
      <div
        id={id_R}
        className={`${
          rolesModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[65%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="py-4 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add user
                </p>
              </div>
              <div className="mt-8 px-6">
                <ProgressBar step={2} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col justify-between">
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Setup role for the user
                  </p>
                  <div className="text-[#000] mt-[40px] text-sm font-normal">
                    <p>
                      Great job on creating the user! Now, let's tailor their
                      experience by assigning a role. Provide the necessary
                      details below to ensure the user gets the right level of
                      access and responsibilities.
                    </p>
                  </div>
                </div>
                <div className="mt-16 text-[#000] text-[16px] font-medium">
                  <p>Role</p>
                </div>
                {roles.map((role) => (
                  <label
                    key={role.id}
                    className="flex items-center gap-2 mt-3 text-[16px] text-[#000]"
                  >
                    <input
                      type="radio"
                      name="role"
                      value={role?.id}
                      checked={selectedRole.id === role?.id}
                      onChange={handleRoleChange}
                      data-name={role?.name}
                    />
                    {role.name}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M7.79199 12.0417H9.20866V7.79169H7.79199V12.0417ZM8.50033 6.37502C8.70102 6.37502 8.86937 6.30702 9.00537 6.17102C9.14137 6.03502 9.20913 5.86691 9.20866 5.66669C9.20866 5.46599 9.14066 5.29765 9.00466 5.16165C8.86866 5.02565 8.70055 4.95788 8.50033 4.95835C8.29963 4.95835 8.13128 5.02635 7.99528 5.16235C7.85928 5.29835 7.79152 5.46646 7.79199 5.66669C7.79199 5.86738 7.85999 6.03573 7.99599 6.17173C8.13199 6.30773 8.3001 6.37549 8.50033 6.37502ZM8.50033 15.5834C7.52046 15.5834 6.59963 15.3973 5.73783 15.0252C4.87602 14.6531 4.12637 14.1485 3.48887 13.5115C2.85137 12.874 2.3468 12.1243 1.97516 11.2625C1.60352 10.4007 1.41746 9.47988 1.41699 8.50002C1.41699 7.52016 1.60305 6.59933 1.97516 5.73752C2.34727 4.87572 2.85184 4.12606 3.48887 3.48856C4.12637 2.85106 4.87602 2.34649 5.73783 1.97485C6.59963 1.60321 7.52046 1.41716 8.50033 1.41669C9.48019 1.41669 10.401 1.60274 11.2628 1.97485C12.1246 2.34696 12.8743 2.85153 13.5118 3.48856C14.1493 4.12606 14.6541 4.87572 15.0262 5.73752C15.3983 6.59933 15.5841 7.52016 15.5837 8.50002C15.5837 9.47988 15.3976 10.4007 15.0255 11.2625C14.6534 12.1243 14.1488 12.874 13.5118 13.5115C12.8743 14.149 12.1246 14.6538 11.2628 15.0259C10.401 15.398 9.48019 15.5838 8.50033 15.5834ZM8.50033 14.1667C10.0823 14.1667 11.4222 13.6177 12.5201 12.5198C13.618 11.4219 14.167 10.082 14.167 8.50002C14.167 6.91808 13.618 5.57815 12.5201 4.48023C11.4222 3.38231 10.0823 2.83335 8.50033 2.83335C6.91838 2.83335 5.57845 3.38231 4.48053 4.48023C3.38262 5.57815 2.83366 6.91808 2.83366 8.50002C2.83366 10.082 3.38262 11.4219 4.48053 12.5198C5.57845 13.6177 6.91838 14.1667 8.50033 14.1667Z"
                        fill="#32A9FF"
                      />
                    </svg>
                  </label>
                ))}
              </div>
              <div className="flex w-[95%] items-center justify-between mt-[80px] mb-[40px] mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                  <button
                    onClick={handleNextButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-white bg-[#074073] hover:bg-[#074073] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Next
                  </button>
                </div>
                <button
                  onClick={handleCancelButton}
                  data-modal-toggle={id_R}
                  type="button"
                  className="text-gray-500 bg-[#D9D9D9] hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[5px] border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RolesModal;
