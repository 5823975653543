import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCustomer,
  getCustomer,
  getCustomerAccounts,
  suspendCustomer,
  getPendingReasons,
  updateCustomer,
  uploadFile,
} from "../../sdk/customers/customers";
import { toast } from "react-toastify";
import EditPersonalInfo from "../../components/Modals/edit-customer/EditPersonalInfo";
import EditAdditionalInfo from "../../components/Modals/edit-customer/EditAdditionalInfo";
import ViewCustomerDocument from "../../components/Modals/edit-customer/ViewCustomerDocument";
import ViewID from "../../components/Modals/edit-customer/ViewID";
import EditIdentity from "../../components/Modals/edit-customer/Edit_identity";
import EditSelfie from "../../components/Modals/edit-customer/Edit_selfie";
import EditMetaData from "../../components/Modals/edit-customer/EditMetaData";
import ProceedModal from "../../components/Modals/edit-customer/ProceedModal";
import LoadingButtons from "../../components/Modals/edit-customer/LoadingButtons";
import ContinueModal from "../../components/Modals/edit-customer/ContinueModal";
import SaveChanges from "../../components/Modals/edit-customer/SaveChanges";
import DetailSavedSuccessfully from "../../components/Modals/edit-customer/DetailSavedSuccessfully";
import useOutsideClick from "../../hooks/useClickAway";
import UploadedKYCdocs from "../../components/Modals/pending_Account/uploadedKYCdoc";
import ViewSelfiePhoto from "../../components/Modals/pending_Account/viewSelfiePhoto";
import SeeIdentityDoc from "../../components/Modals/pending_Account/seeIdentityDoc";
import RejectionReason from "../../components/Modals/pending_Account/rejectionReason";
import NoteToReject from "../../components/Modals/pending_Account/noteToReject";
import RejectAccountApproval from "../../components/Modals/pending_Account/rejectAccountApproval";
import ApprovalNote from "../../components/Modals/pending_Account/approvalNote";
import AccountApproval from "../../components/Modals/pending_Account/accountApproval";
import CloseAccountSuccess from "../../components/success-modals/CloseAccountSuccess";
import EmailPrompt from "../../components/Modals/prompts/EmailPrompt";
import MobilePrompt from "../../components/Modals/prompts/MobilePrompt";
import OtpEmail from "../../components/Modals/edit-customer/OtpEmail";
import OtpMobile from "../../components/Modals/edit-customer/OtpMobile";
import SuspendCustomerPrompt from "../../components/Modals/prompts/SuspendCustomerPrompt";
import DeleteAccountPop from "../../components/Modals/suspend-account/DeleteAccountPop";
import SuspensionReason from "../../components/Modals/suspend-account/SuspensionReason";
import DocumentReason from "../../components/Modals/suspend-account/DocumentReason";
import SuspensionImplications from "../../components/Modals/suspend-account/SuspensionImplications";
import ReviewSuspension from "../../components/Modals/suspend-account/ReviewSuspension";

const Customer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [customer, setCustomer] = useState({});

  const [customerAccounts, setCustomerAccounts] = useState({});
  const [pendingReasons, setPendingReasons] = useState([]);

  const [colors, setColors] = useState([
    "#17bfe2",
    "#074073",
    "#17C6C6",
    "#914110",
    "#716B6B",
  ]);

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleString("en-GB", options);
  };

  const fetchCustomer = async (id) => {
    try {
      const res = await getCustomer(id);
      const customerData = res?.data;

      const documents = [
        {
          type: "Selfie Image",
          url: customerData.selfie_image,
          dateUploaded: customerData.createdAt,
          lastModified: customerData.updatedAt,
          documentIdNumber: "N/A",
        },
      ];

      setCustomer({
        ...customerData,
        documents,
      });
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const fetchPendingReasons = async (id) => {
    try {
      const res = await getPendingReasons(id);

      setPendingReasons(res?.data || []);
    } catch (error) {
      console?.error("Error fetching customer data:", error);
    }
  };

  const initials = `${customer.firstname?.slice(
    0,
    1
  )}${customer.lastname?.slice(0, 1)}`;

  const fetchCustomerAccounts = async (id) => {
    const res = await getCustomerAccounts(id);
    setCustomerAccounts(res.data);
  };

  const navigateToTransactions = (custId, id, account_number, color) => {
    navigate(`/dashboard/customers/${custId}/${id}`, {
      state: { account_number, color },
    });
  };

  useEffect(() => {
    fetchCustomer(id);
    fetchPendingReasons(id);
    fetchCustomerAccounts(id);
  }, [id]);

  const [showBalance, setShowBalance] = useState({});

  const toggleBalanceVisibility = (accountId) => {
    setShowBalance((prevVisibility) => ({
      ...prevVisibility,
      [accountId]: !prevVisibility[accountId],
    }));
  };

  const [editCustomerOpen, setEditCustomerOpen] = useState(false);

  const handleOpenEditCustomerPersonalInfo = () => {
    setEditCustomerOpen(true);
  };

  const handleCloseEditCustomerPersonalInfo = () => {
    setEditCustomerOpen(false);
  };

  const [editAdditionalInfoOpen, setEditAdditionalInfoOpen] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState(null);

  const [ViewCustomerDocumentOpen, setViewCustomerDocumentOpen] =
    useState(false);
  const [ViewIDOpen, setViewIDOpen] = useState(false);

  const handleOpenEditAdditionalInfo = () => {
    setEditAdditionalInfoOpen(true);
  };

  const handleViewCustomerDocument = (document) => {
    setSelectedDocument({
      url: document?.url,
      dateUploaded: document?.dateUploaded,
      type: document?.type,
      documentIdNumber: document?.documentIdNumber,
      lastModified: document?.lastModified,
    });
    setViewCustomerDocumentOpen(true);
  };

  const handleViewID = () => {
    setViewIDOpen(true);
  };

  const handleCloseViewCustomerDocument = () => {
    setSelectedDocument(null);
    setViewCustomerDocumentOpen(false);
  };

  const handleCloseViewID = () => {
    setViewIDOpen(false);
  };

  // const handleAuditDocument = () => {
  //   setViewCustomerDocumentOpen(false);
  // };

  const handleCloseEditAdditionalInfo = () => {
    setEditAdditionalInfoOpen(false);
  };

  const [identity, setIdentity] = useState("");

  useEffect(() => {
    if (customer?.identification) {
      setIdentity(customer?.identification);
    }
  }, [customer]);

  const [customerEditDetails, setCustomerEditDetails] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    gender: "",
    dob: "",
    citizenship: "",
    identification: "",
    email: "",
    mobile: "",
  });

  const [editAdditionalDetails, setEditAdditionalDetails] = useState({
    kra: "",
    occupation: "",
    income: "",
    country: "",
    county: "",
    subcounty: "",
    physical_address: "",
  });

  const handleEditCustomerDetails = async () => {
    const result = await updateCustomer(id, customerEditDetails);
    console.log(result);
    setCustomerEditDetails({});
    handleCloseEditCustomerPersonalInfo();
    fetchCustomer(id);
  };

  const handleEditAdditionalInformation = async () => {
    const result = await updateCustomer(id, {
      KraPin: editAdditionalDetails.kra,
      income_range: editAdditionalDetails.income,
      occupation: editAdditionalDetails.occupation,
      country_of_residence: editAdditionalDetails.country,
    });
    console.log(result);
    setCustomerEditDetails({});
    handleCloseEditAdditionalInfo();
    fetchCustomer(id);
  };

  const [closeAccountSuccessOpen, setCloseAccountSuccessOpen] = useState(false);

  const handleCloseAccountSuccess = () => {
    setCloseAccountSuccessOpen(false);
  };

  const handleDeleteAccount = async () => {
    await deleteCustomer(id);
    await setIsDeleteAccountOpen(false);

    await setCloseAccountSuccessOpen(true);

    setTimeout(() => {
      navigate("/dashboard/customers");
    }, 2000);
  };

  const [emailPromptOpen, setEmailPromptOpen] = useState(false);

  const handleOpenEmailPrompt = () => {
    setEmailPromptOpen(true);
  };
  const handleCloseEmailPrompt = () => {
    setEmailPromptOpen(false);
  };
  const [mobilePromptOpen, setMobilePromptOpen] = useState(false);

  const handleOpenMobilePrompt = () => {
    setMobilePromptOpen(true);
  };
  const handleCloseMobilePrompt = () => {
    setMobilePromptOpen(false);
  };

  const [otpEmailOpen, setOtpEmailOpen] = useState(false);

  const handleOpenEmailOtp = () => {
    setOtpEmailOpen(true);
  };
  const handleCloseEmailOtp = () => {
    setOtpEmailOpen(false);
  };

  const [otpMobileOpen, setOtpMobileOpen] = useState(false);

  const handleOpenMobileOtp = () => {
    setOtpMobileOpen(true);
  };
  const handleCloseMobileOtp = () => {
    setOtpMobileOpen(false);
  };

  const handleSuspendCustomer = async () => {
    const result = await suspendCustomer(id, "Suspended");
    fetchCustomer(id);
    fetchCustomerAccounts(id);
    handleCloseSuspendCustomerPrompt();
  };

  const [suspendCustomerPromptOpen, setSuspendCustomerPromptOpen] =
    useState(false);

  const handleCloseSuspendCustomerPrompt = () => {
    setSuspendCustomerPromptOpen(false);
  };

  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [selfieFile, setSelfieFile] = useState(null);
  const [frontFileUrl, setFrontFileUrl] = useState("");
  const [backFileUrl, setBackFileUrl] = useState("");
  const [passportFileUrl, setPassportFileUrl] = useState("");

  const [identificationType, setIdentificationType] = useState("National Id");
  const [country, setCountry] = useState("Kenya");
  const [isIDModalOpen, setIsIDModalOpen] = useState(false);
  const [isLoadingButtons, setIsLoadingButtons] = useState(false);
  const [isSavedSuccessfully, setIsSavedSuccessfully] = useState(false);
  const closeSavedModal = () => {
    setIsSavedSuccessfully(false);
  };

  const closeIDlModal = () => {
    setIsIDModalOpen(false);
  };

  const [isSelfieModalOpen, setIsSelfieModalOpen] = useState(false);
  const closeSelfieModal = () => {
    setIsSelfieModalOpen(false);
  };

  const [isMetaDataOpen, setIsMetaDataOpen] = useState(false);

  const closeMetaData = () => {
    setIsMetaDataOpen(false);
  };

  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const openDeleteAccountPop = () => {
    setIsDeleteAccountOpen(true);
  };
  const closeDeleteAccountPop = () => {
    setIsDeleteAccountOpen(false);
  };

  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);

  const closeProceedModal = () => {
    setIsProceedModalOpen(false);
  };

  const openProceedModal = () => {
    setIsProceedModalOpen(true);
  };
  const [isContinueModalOpen, setIsContinueModalOpen] = useState(false);

  const closeContinueModal = () => {
    setIsContinueModalOpen(false);
  };

  const [isSaveChanges, setIsSaveChanges] = useState(false);

  const closeSaveChanges = () => {
    setIsSaveChanges(false);
  };

  const submitSelfieFile = async () => {
    try {
      const selfieUrl = await uploadFile(selfieFile);
      console.log("photoUrl", selfieUrl);
      return selfieUrl;
    } catch (error) {
      console.error("Error submitting files:", error);
      toast.error(error?.message || "Error submitting files:", error);
      return null;
    }
  };

  const submitFiles = async () => {
    try {
      let frontUrl = "";
      let backUrl = "";
      let passportUrl = "";

      if (identificationType === "National Id") {
        if (frontFile) {
          frontUrl = await uploadFile(frontFile);
          setFrontFileUrl(frontUrl);
        }

        if (backFile) {
          backUrl = await uploadFile(backFile);
          setBackFileUrl(backUrl);
        }
      } else if (identificationType === "passport") {
        if (passportFile) {
          passportUrl = await uploadFile(passportFile);
          setPassportFileUrl(passportUrl);
        }
      }

      return { frontUrl, backUrl, passportUrl };
    } catch (error) {
      console.error("Error submitting files:", error);
      toast.error(error?.message || "Error submitting files:", error);
    }
  };

  const handleEditIdentity = () => {
    handleCloseViewID();

    setIsIDModalOpen(true);
  };

  const handleEditMetaData = () => {
    handleCloseViewID();

    setIsMetaDataOpen(true);
  };

  const handleEditSelfie = () => {
    setViewCustomerDocumentOpen(false);
    setIsSelfieModalOpen(true);
  };

  const handleSaveChangesButton = async () => {
    setIsSaveChanges(false);
    setIsProceedModalOpen(false);
    setIsLoadingButtons(true);

    try {
      if (identity) {
        await updateCustomer(id, {
          identification: identity,
        });

        await fetchCustomer(id);
      } else {
        throw new Error("Failed to update the identity number.");
      }

      setIsLoadingButtons(false);
      setIsSavedSuccessfully(true);

      setTimeout(() => {
        setIsSavedSuccessfully(false);
      }, 3000);
    } catch (error) {
      setIsLoadingButtons(false);
      toast.error(
        error?.message || "Error in the complete registration process:",
        error
      );
    }
  };

  const handleContinueButton = async () => {
    setIsSelfieModalOpen(false);
    setIsProceedModalOpen(false);
    setIsLoadingButtons(true);

    try {
      // Upload the selfie and get the URL
      const uploadedSelfieUrl = await submitSelfieFile();

      // Only proceed to update customer and fetch data if the selfie URL is set
      if (uploadedSelfieUrl) {
        await updateCustomer(id, {
          selfie_image: uploadedSelfieUrl,
        });

        await fetchCustomer(id);
      } else {
        throw new Error("Failed to upload selfie image.");
      }

      setIsLoadingButtons(false);
      setIsSavedSuccessfully(true);

      setTimeout(() => {
        setIsSavedSuccessfully(false);
      }, 3000);
    } catch (error) {
      setIsLoadingButtons(false);
      toast.error(
        error?.message || "Error in the complete registration process:",
        error
      );
    }
  };

  const handleNextButtonClick = async () => {
    setIsIDModalOpen(false);
    setIsContinueModalOpen(false);
    setIsLoadingButtons(true);

    try {
      const { frontUrl, backUrl, passportUrl } = await submitFiles();

      let updateData = {};

      if (identificationType === "National Id") {
        updateData = {
          id_front_image: frontUrl,
          id_back_image: backUrl,
          // passport_image: "", // Clear passport image if not used
        };
      } else if (identificationType === "passport") {
        updateData = {
          // id_front_image: "", // Clear ID images if not used
          // id_back_image: "",
          passport_image: passportUrl,
        };
      }

      // Update customer data with the relevant URLs
      await updateCustomer(id, updateData);

      await fetchCustomer(id);

      setIsLoadingButtons(false);
      setIsSavedSuccessfully(true);

      setTimeout(() => {
        setIsSavedSuccessfully(false);
      }, 2000);
    } catch (error) {
      setIsLoadingButtons(false);
      toast.error(
        error?.message || "Error in the complete registration process:",
        error
      );
    }
  };
  const handleBack = () => {
    navigate(-1);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenUploadedKYCModal = () => {
    setIsModalOpen(true);
  };

  const [viewSelfiePhotoOpen, setViewSelfiePhotoOpen] = useState(false);

  const handleOpenViewSelfiePhoto = () => {
    setIsModalOpen(false);

    setViewSelfiePhotoOpen(true);
  };

  const handleCloseViewSelfiePhoto = () => {
    setViewSelfiePhotoOpen(false);
  };

  const [seeIdentityDocOpen, setSeeIdentityDocOpen] = useState(false);

  const handleOpenSeeIdentityDoc = () => {
    setIsModalOpen(false);

    setSeeIdentityDocOpen(true);
  };

  const handleCloseSeeIdentityDoc = () => {
    setSeeIdentityDocOpen(false);
  };

  const [rejectionReason, setRejectionReason] = useState(false);
  const handleOpenRejectionReason = () => {
    setIsModalOpen(false);

    setRejectionReason(true);
  };

  const handleCloseRejectionReason = () => {
    setRejectionReason(false);
  };

  const [noteToReject, setNoteToReject] = useState(false);

  const handleOpenNoteToReject = () => {
    setRejectionReason(false);

    setNoteToReject(true);
  };

  const handleCloseNoteToReject = () => {
    setNoteToReject(false);
  };

  const [isRejectAccountApprovalOpen, setIsRejectAccountApprovalOpen] =
    useState(false);

  const openRejectAccountApproval = () => {
    setNoteToReject(false);

    setIsRejectAccountApprovalOpen(true);
  };
  const closeRejectAccountApproval = () => {
    setIsRejectAccountApprovalOpen(false);
  };

  const [approvalNote, setApprovalNote] = useState(false);
  const handleOpenApprovalNote = () => {
    setIsModalOpen(false);

    setApprovalNote(true);
  };

  const handleCloseApprovalNote = () => {
    setApprovalNote(false);
  };

  const [isAccountApprovalOpen, setIsAccountApprovalOpen] = useState(false);
  const openAccountApproval = () => {
    setApprovalNote(false);

    setIsAccountApprovalOpen(true);
  };

  const closeAccountApproval = () => {
    setIsAccountApprovalOpen(false);
  };

  return (
    <div>
      <div className="my-[10px] px-[20px]">
        <button
          onClick={handleBack}
          className="h-[40px] text-[#316CE9] text-[16px] w-[100px] rounded flex items-center justify-center gap-[10px] px-[20px]"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="12" height="12" rx="6" fill="#316CE9" />
            <path
              d="M2.625 5.625H10.125C10.2245 5.625 10.3198 5.66451 10.3902 5.73484C10.4605 5.80516 10.5 5.90054 10.5 6C10.5 6.09946 10.4605 6.19484 10.3902 6.26517C10.3198 6.33549 10.2245 6.375 10.125 6.375H2.625C2.52554 6.375 2.43016 6.33549 2.35984 6.26517C2.28951 6.19484 2.25 6.09946 2.25 6C2.25 5.90054 2.28951 5.80516 2.35984 5.73484C2.43016 5.66451 2.52554 5.625 2.625 5.625Z"
              fill="white"
            />
            <path
              d="M2.78043 6.00047L5.89068 9.10997C5.9611 9.18039 6.00065 9.27589 6.00065 9.37547C6.00065 9.47505 5.9611 9.57056 5.89068 9.64097C5.82027 9.71139 5.72476 9.75095 5.62518 9.75095C5.5256 9.75095 5.4301 9.71139 5.35968 9.64097L1.98468 6.26597C1.94976 6.23114 1.92205 6.18976 1.90315 6.1442C1.88424 6.09864 1.87451 6.0498 1.87451 6.00047C1.87451 5.95115 1.88424 5.90231 1.90315 5.85675C1.92205 5.81119 1.94976 5.76981 1.98468 5.73497L5.35968 2.35997C5.4301 2.28956 5.5256 2.25 5.62518 2.25C5.72476 2.25 5.82027 2.28956 5.89068 2.35997C5.9611 2.43039 6.00065 2.52589 6.00065 2.62547C6.00065 2.72506 5.9611 2.82056 5.89068 2.89097L2.78043 6.00047Z"
              fill="white"
            />
          </svg>
          <span className="underline">Back</span>
        </button>
      </div>

      <div className="h-[118px] bg-[#074073] w-full flex items-center justify-between px-[20px]">
        <div className="flex items-center gap-[30px]">
          <div className="h-[80px] w-[80px] text-[32px] text-[#074073] rounded-[100%] bg-[#D3EAFF] flex items-center justify-center">
            {customer?.firstname?.slice(0, 1)}
            {customer?.lastname?.slice(0, 1)}
          </div>
          <div className="flex flex-col">
            <p className="text-[#D3EAFF] text-[14px]">{customer?.public_id}</p>
            <p className="text-white text-[24px]">
              {customer?.firstname?.charAt(0)?.toUpperCase() +
                customer?.firstname?.slice(1)?.toLowerCase()}{" "}
              {customer?.lastname?.charAt(0)?.toUpperCase() +
                customer?.lastname?.slice(1)?.toLowerCase()}
            </p>
            <p className="text-[#D3EAFF] text-[14px]">{customer?.username}</p>
          </div>
          <div className="w-[120px] h-[44px] rounded-[30px] bg-[#054884] flex gap-[5px] justify-center items-center">
            <div className="flex items-center gap-[5px]">
              {customer?.status === "Active" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <g clip-path="url(#clip0_7769_947)">
                    <path
                      d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                      fill="#0C722F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7769_947">
                      <rect width="12" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              {customer?.status === "Pending" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <g clip-path="url(#clip0_7769_947)">
                    <path
                      d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                      fill="#FAB32B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7769_947">
                      <rect width="12" height="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <p className="text-white text-[12px] ml-2">{customer?.status}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[10px]">
          {/* <div
            onClick={handleOpenSuspendReason}
            className="w-[194px] h-[31px] cursor-pointer bg-[#FAB32B] gap-[10px] rounded-[40px] flex items-center p-[10px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M3.91136 18.375H17.0889C18.4364 18.375 19.2764 16.9137 18.6026 15.75L12.0139 4.36622C11.3401 3.20247 9.66011 3.20247 8.98636 4.36622L2.39761 15.75C1.72386 16.9137 2.56386 18.375 3.91136 18.375ZM10.5001 12.25C10.0189 12.25 9.62511 11.8562 9.62511 11.375V9.62497C9.62511 9.14372 10.0189 8.74997 10.5001 8.74997C10.9814 8.74997 11.3751 9.14372 11.3751 9.62497V11.375C11.3751 11.8562 10.9814 12.25 10.5001 12.25ZM11.3751 15.75H9.62511V14H11.3751V15.75Z"
                fill="white"
              />
            </svg>
            <p className="text-white text-[14px]">Suspend Customer</p>
          </div> */}
          <div
            onClick={openDeleteAccountPop}
            className="w-[194px] h-[31px] cursor-pointer bg-[#FF5757] gap-[10px] rounded-[40px] flex items-center p-[10px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5.83301 17.5C5.37467 17.5 4.98217 17.3367 4.65551 17.01C4.32884 16.6833 4.16579 16.2911 4.16634 15.8333V5H3.33301V3.33333H7.49967V2.5H12.4997V3.33333H16.6663V5H15.833V15.8333C15.833 16.2917 15.6697 16.6842 15.343 17.0108C15.0163 17.3375 14.6241 17.5006 14.1663 17.5H5.83301ZM7.49967 14.1667H9.16634V6.66667H7.49967V14.1667ZM10.833 14.1667H12.4997V6.66667H10.833V14.1667Z"
                fill="white"
              />
            </svg>
            <p className="text-white text-[14px]">Delete Member</p>
          </div>
        </div>
      </div>
      {customer?.status === "Pending" && (
        <div className="flex justify-center items-center h-full mt-2">
          <div className="bg-[#FFF6DD] rounded-[3px]  w-[98%] px-[8px]">
            <div className="text-[#000000] text-[14px] font-normal mt-3">
              This member's account is under pending status due to the reason
            </div>

            <div>
              {pendingReasons?.map((item) => (
                <div
                  key={item?.id}
                  className="text-[#000000] text-[14px] font-normal mt-2"
                >
                  {item?.reason}{" "}
                  <span
                    className="text-[#0187E8] font-semibold cursor-pointer"
                    onClick={handleOpenUploadedKYCModal}
                  >
                    REVIEW
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <UploadedKYCdocs
        id="firstModal"
        closeModalFunction={closeModal}
        isModalOpen={isModalOpen}
        firstName={customer?.firstname}
        lastName={customer?.lastname}
        middleName={customer?.middlename}
        gender={customer?.gender}
        identification={customer?.identification}
        dob={customer?.dob}
        handleOpenViewSelfiePhoto={handleOpenViewSelfiePhoto}
        handleOpenSeeIdentityDoc={handleOpenSeeIdentityDoc}
        handleOpenRejectionReason={handleOpenRejectionReason}
        handleOpenApprovalNote={handleOpenApprovalNote}
      />

      <ViewSelfiePhoto
        viewSelfiePhotoOpen={viewSelfiePhotoOpen}
        document={customer?.selfie_image}
        handleCloseViewSelfiePhoto={handleCloseViewSelfiePhoto}
      />

      <SeeIdentityDoc
        seeIdentityDocOpen={seeIdentityDocOpen}
        document={customer}
        handleCloseSeeIdentityDoc={handleCloseSeeIdentityDoc}
      />

      <RejectionReason
        id="firstModal"
        rejectionReason={rejectionReason}
        handleCloseRejectionReason={handleCloseRejectionReason}
        setIsModalOpen={setIsModalOpen}
        handleOpenNoteToReject={handleOpenNoteToReject}
      />

      <NoteToReject
        id="firstModal"
        noteToReject={noteToReject}
        handleCloseNoteToReject={handleCloseNoteToReject}
        openRejectAccountApproval={openRejectAccountApproval}
      />

      <RejectAccountApproval
        isRejectAccountApprovalOpen={isRejectAccountApprovalOpen}
        closeRejectAccountApproval={closeRejectAccountApproval}
      />

      <ApprovalNote
        approvalNote={approvalNote}
        handleCloseApprovalNote={handleCloseApprovalNote}
        openAccountApproval={openAccountApproval}
      />

      <AccountApproval
        isAccountApprovalOpen={isAccountApprovalOpen}
        closeAccountApproval={closeAccountApproval}
      />

      <div className="w-full bg-white flex justify-between px-[10px] py-[10px]">
        <div className="w-[50%] h-[670px] flex flex-col gap-[10px]">
          <div
            className="h-[344px] w-full rounded-[10px]"
            style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
          >
            <div className="flex flex-col gap-[30px] p-[10px] w-full">
              <div className="flex mb-[10px] items-center justify-between">
                <p className="text-[16px] text-[#074073]">
                  Personal Information
                </p>
                <div
                  onClick={handleOpenEditCustomerPersonalInfo}
                  className="w-[78px] border-[#074073] border-[1px] h-[31px] cursor-pointer rounded-[40px] bg-white flex justify-center items-center gap-[5px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17"
                      stroke="#074073"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 5.00011L19 8.00011M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z"
                      stroke="#074073"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="text-[12px] text-[#074073]">Edit</p>
                </div>
              </div>
              <div className="w-[96%] flex items-center">
                <div className="w-[80%]">
                  <p className="text-gray-400 text-[14px]">First Name</p>
                  <p className="text-[#000]">{customer?.firstname}</p>
                </div>
                <div className="w-[50%]">
                  <p className="text-gray-400 text-[14px]">Last Name</p>
                  <p>{customer?.lastname}</p>
                </div>
              </div>
              <div className="w-[96%] flex items-center">
                <div className="w-[80%]">
                  <p className="text-gray-400 text-[14px]">Email Address</p>
                  <p>{customer?.email}</p>
                </div>
                <div className="w-[50%]">
                  <p className="text-gray-400 text-[14px]">Phone Number</p>
                  <p>{customer?.mobileno}</p>
                </div>
              </div>
              <div className="w-[96%] flex items-center">
                <div className="w-[80%]">
                  <p className="text-gray-400 text-[14px]">Date of Birth</p>
                  <p>
                    {customer?.dob
                      ? new Date(customer?.dob)?.toISOString()?.split("T")[0]
                      : "N/A"}
                  </p>
                </div>
                <div className="w-[50%]">
                  <p className="text-gray-400 text-[14px]">Username</p>
                  <p>{customer?.username}</p>
                </div>
              </div>
              <div className="w-[96%] flex items-center">
                <div className="w-[80%]">
                  <p className="text-gray-400 text-[14px]">Citizenship</p>
                  <p className="">{customer?.citizenship}</p>
                </div>
                <div className="w-[50%]">
                  <p className="text-gray-400 text-[14px]">
                    Member National ID Number
                  </p>
                  <p>{customer?.identification}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="h-[320px] w-full rounded-[10px] p-[10px]"
            style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
          >
            <div className="flex mb-[20px] items-center justify-between">
              <p className="text-[16px] text-[#074073]">
                Additional Information
              </p>
              <div
                onClick={handleOpenEditAdditionalInfo}
                className="w-[78px] border-[#074073] cursor-pointer border-[1px] h-[31px] rounded-[40px] bg-white flex justify-center items-center gap-[5px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17"
                    stroke="#074073"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 5.00011L19 8.00011M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z"
                    stroke="#074073"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[12px] text-[#074073]">Edit</p>
              </div>
            </div>
            <div className="w-[96%] mb-[20px] flex items-center">
              <div className="w-[80%]">
                <p className="text-gray-400 text-[14px]">KRA PIN</p>
                <p className="">{customer?.kraPin || customer?.ssn}</p>
              </div>
              <div className="w-[50%]">
                <p className="text-gray-400 text-[14px]">Monthly Income</p>
                <p>
                  KES &nbsp;
                  {Number(customer?.income_range || "0.00")?.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </p>
              </div>
            </div>
            <div className="w-[96%] mb-[20px] flex items-center">
              <div className="w-[80%]">
                <p className="text-gray-400 text-[14px]">Employment Type</p>
                <p className="">{customer?.employment_type}</p>
              </div>
              <div className="w-[50%]">
                <p className="text-gray-400 text-[14px]">Job Title</p>
                <p className="">{customer?.occupation}</p>
              </div>
            </div>
            <div className="w-[96%] mb-[20px] flex items-center">
              <div className="w-[80%]">
                <p className="text-gray-400 text-[14px]">Country</p>
                <p className="">
                  {customer?.country_of_residence
                    ? customer?.country_of_residence
                    : "N/A"}
                </p>
              </div>
              <div className="w-[50%]">
                <p className="text-gray-400 text-[14px]">County</p>
                <p>
                  {customer?.addresses?.length > 0
                    ? customer.addresses[0]?.county ||
                      customer.addresses[0]?.town ||
                      "N/A"
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="w-[96%] flex items-center">
              <div className="w-[80%]">
                <p className="text-gray-400 text-[14px]">Sub-county</p>
                <p className="">
                  {customer?.addresses?.length > 0
                    ? customer.addresses[0]?.subcounty ||
                      customer.addresses[0]?.street ||
                      "N/A"
                    : "N/A"}
                </p>
              </div>
              <div className="w-[50%]">
                <p className="text-gray-400 text-[14px]">Physical Address</p>
                <p>
                  {customer?.addresses?.length > 0
                    ? customer.addresses[0]?.physical_address || "N/A"
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div
            className="h-[320px] w-full rounded-[10px] p-[10px]"
            style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
          >
            <div className="flex mb-[20px] items-center justify-between">
              <p className="text-[16px] text-[#074073]">
                Next of KIN information
              </p>
              <div
                // onClick={handleOpenEditAdditionalInfo}
                className="w-[78px] border-[#074073] cursor-pointer border-[1px] h-[31px] rounded-[40px] bg-white flex justify-center items-center gap-[5px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17"
                    stroke="#074073"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 5.00011L19 8.00011M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z"
                    stroke="#074073"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[12px] text-[#074073]">Edit</p>
              </div>
            </div>
            {customer?.nextOfKins && customer?.nextOfKins?.length > 0 ? (
              <div className="">
                <div className="w-[96%] mb-[20px] flex items-center">
                  <div className="w-[80%]">
                    <p className="text-gray-400 text-[14px]">Full Name</p>
                    <p className="">
                      {customer?.nextOfKins?.length > 0
                        ? customer.nextOfKins[0]?.name
                        : "N/A"}
                    </p>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-gray-400 text-[14px]">Date of Birth</p>
                    <p>
                      {customer?.nextOfKins?.length > 0
                        ? customer.nextOfKins[0]?.dateOfBirth
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="w-[96%] mb-[20px] flex items-center">
                  <div className="w-[80%]">
                    <p className="text-gray-400 text-[14px]">Relationship</p>
                    <p className="">
                      {customer?.nextOfKins?.length > 0
                        ? customer.nextOfKins[0]?.relationship
                        : "N/A"}
                    </p>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-gray-400 text-[14px]">Phone number</p>
                    <p className="">
                      {customer?.nextOfKins?.length > 0
                        ? customer.nextOfKins[0]?.phoneNumber
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="w-[96%] mb-[20px] flex items-center">
                  <div className="w-[80%]">
                    <p className="text-gray-400 text-[14px]">Location</p>
                    <p className="">
                      {customer?.nextOfKins?.length > 0
                        ? customer.nextOfKins[0]?.location
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <p>You don't have next of kin</p>
            )}
          </div>
          <div
            className="h-[320px] w-full rounded-[10px] p-[10px]"
            style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
          >
            <div className="flex mb-[20px] items-center justify-between">
              <p className="text-[16px] text-[#074073]">Documents</p>
            </div>
            <div className="flex mb-[20px] items-center justify-between">
              <p className="text-[16px]">
                {customer?.firstname} {customer?.lastname}
              </p>
            </div>
            <div className="w-[90%] mb-[20px] flex items-center">
              <div className="w-[70%]">
                <p className="text-[14px]">DATE UPLOADED</p>
              </div>
              <div className="w-[55%]">
                <p className="text-[14px]">DOCUMENT TYPE</p>
              </div>
              <div className="w-[40%] flex justify-center">
                <p className="text-[14px]">VIEW</p>
              </div>
            </div>
            <div className="w-[90%] mb-[20px] flex items-center">
              <div className="w-[70%]">
                <p className="text-[14px]">
                  {customer?.createdAt
                    ? formatDateTime(customer.createdAt)
                    : "N/A"}
                </p>
              </div>
              <div className="w-[55%]">
                <p className="text-[14px] text-[#0187E8]">
                  {customer?.identification_type
                    ? customer?.identification_type
                    : "N/A"}
                </p>
              </div>
              <div className="w-[40%] flex justify-center">
                <div
                  onClick={() => handleViewID()}
                  className="w-[78px] cursor-pointer h-[31px] bg-white flex justify-center items-center gap-[1px]"
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.8377 9.7875C19.1026 7.88603 17.8265 6.24164 16.167 5.05755C14.5075 3.87347 12.5374 3.20161 10.5002 3.125C8.46305 3.20161 6.49296 3.87347 4.83347 5.05755C3.17398 6.24164 1.89782 7.88603 1.16272 9.7875C1.11308 9.92482 1.11308 10.0752 1.16272 10.2125C1.89782 12.114 3.17398 13.7584 4.83347 14.9424C6.49296 16.1265 8.46305 16.7984 10.5002 16.875C12.5374 16.7984 14.5075 16.1265 16.167 14.9424C17.8265 13.7584 19.1026 12.114 19.8377 10.2125C19.8874 10.0752 19.8874 9.92482 19.8377 9.7875ZM10.5002 15.625C7.18772 15.625 3.68772 13.1687 2.41897 10C3.68772 6.83125 7.18772 4.375 10.5002 4.375C13.8127 4.375 17.3127 6.83125 18.5815 10C17.3127 13.1687 13.8127 15.625 10.5002 15.625Z"
                      fill="black"
                    />
                    <path
                      d="M10.5 6.25C9.75832 6.25 9.0333 6.46993 8.41661 6.88199C7.79993 7.29404 7.31928 7.87971 7.03545 8.56494C6.75162 9.25016 6.67736 10.0042 6.82206 10.7316C6.96675 11.459 7.3239 12.1272 7.84835 12.6517C8.3728 13.1761 9.04098 13.5333 9.76841 13.6779C10.4958 13.8226 11.2498 13.7484 11.9351 13.4645C12.6203 13.1807 13.206 12.7001 13.618 12.0834C14.0301 11.4667 14.25 10.7417 14.25 10C14.25 9.00544 13.8549 8.05161 13.1517 7.34835C12.4484 6.64509 11.4946 6.25 10.5 6.25ZM10.5 12.5C10.0055 12.5 9.5222 12.3534 9.11108 12.0787C8.69995 11.804 8.37952 11.4135 8.1903 10.9567C8.00108 10.4999 7.95158 9.99723 8.04804 9.51227C8.1445 9.02732 8.3826 8.58186 8.73223 8.23223C9.08187 7.8826 9.52732 7.6445 10.0123 7.54804C10.4972 7.45157 10.9999 7.50108 11.4567 7.6903C11.9135 7.87952 12.304 8.19995 12.5787 8.61107C12.8534 9.0222 13 9.50555 13 10C13 10.663 12.7366 11.2989 12.2678 11.7678C11.7989 12.2366 11.163 12.5 10.5 12.5Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {customer?.documents?.length > 0 &&
              customer.documents.map((document, index) => (
                <div
                  key={index}
                  className="w-[90%] mb-[20px] flex items-center"
                >
                  <div className="w-[70%]">
                    <p className="text-[14px]">
                      {formatDateTime(document?.dateUploaded) || "N/A"}
                    </p>
                  </div>
                  <div className="w-[55%]">
                    <p className="text-[14px] text-[#0187E8]">
                      {document?.type}
                    </p>
                  </div>
                  <div className="w-[40%] flex justify-center">
                    <div
                      onClick={() => handleViewCustomerDocument(document)}
                      className="w-[78px] cursor-pointer h-[31px] bg-white flex justify-center items-center gap-[1px]"
                    >
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.8377 9.7875C19.1026 7.88603 17.8265 6.24164 16.167 5.05755C14.5075 3.87347 12.5374 3.20161 10.5002 3.125C8.46305 3.20161 6.49296 3.87347 4.83347 5.05755C3.17398 6.24164 1.89782 7.88603 1.16272 9.7875C1.11308 9.92482 1.11308 10.0752 1.16272 10.2125C1.89782 12.114 3.17398 13.7584 4.83347 14.9424C6.49296 16.1265 8.46305 16.7984 10.5002 16.875C12.5374 16.7984 14.5075 16.1265 16.167 14.9424C17.8265 13.7584 19.1026 12.114 19.8377 10.2125C19.8874 10.0752 19.8874 9.92482 19.8377 9.7875ZM10.5002 15.625C7.18772 15.625 3.68772 13.1687 2.41897 10C3.68772 6.83125 7.18772 4.375 10.5002 4.375C13.8127 4.375 17.3127 6.83125 18.5815 10C17.3127 13.1687 13.8127 15.625 10.5002 15.625Z"
                          fill="black"
                        />
                        <path
                          d="M10.5 6.25C9.75832 6.25 9.0333 6.46993 8.41661 6.88199C7.79993 7.29404 7.31928 7.87971 7.03545 8.56494C6.75162 9.25016 6.67736 10.0042 6.82206 10.7316C6.96675 11.459 7.3239 12.1272 7.84835 12.6517C8.3728 13.1761 9.04098 13.5333 9.76841 13.6779C10.4958 13.8226 11.2498 13.7484 11.9351 13.4645C12.6203 13.1807 13.206 12.7001 13.618 12.0834C14.0301 11.4667 14.25 10.7417 14.25 10C14.25 9.00544 13.8549 8.05161 13.1517 7.34835C12.4484 6.64509 11.4946 6.25 10.5 6.25ZM10.5 12.5C10.0055 12.5 9.5222 12.3534 9.11108 12.0787C8.69995 11.804 8.37952 11.4135 8.1903 10.9567C8.00108 10.4999 7.95158 9.99723 8.04804 9.51227C8.1445 9.02732 8.3826 8.58186 8.73223 8.23223C9.08187 7.8826 9.52732 7.6445 10.0123 7.54804C10.4972 7.45157 10.9999 7.50108 11.4567 7.6903C11.9135 7.87952 12.304 8.19995 12.5787 8.61107C12.8534 9.0222 13 9.50555 13 10C13 10.663 12.7366 11.2989 12.2678 11.7678C11.7989 12.2366 11.163 12.5 10.5 12.5Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div
          className="w-[49%] p-[10px] flex flex-col rounded-[10px]"
          style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="flex items-center mb-[20px] w-full justify-between">
            <p className="text-[#074073] text-[16px]">Accounts</p>
            <div className="flex items-center">
              <button className="bg-[#074073] flex items-center gap-[10px] h-[44px] px-[10px] rounded-[5px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                    fill="white"
                  />
                </svg>
                <p className="text-white text-[12px]">Create new account</p>
              </button>
            </div>
          </div>
          {customerAccounts.length > 0 &&
            customerAccounts?.map((account, index) => (
              <div
                onClick={() =>
                  navigateToTransactions(
                    customer?.id,
                    account?.id,
                    account?.account_number,
                    colors[index]
                  )
                }
                style={{ backgroundColor: `${colors[index]}` }}
                className="bg-image-1 h-[165px] mb-[20px] cursor-pointer rounded-[10px] w-[100%] p-[10px]"
              >
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#FFFFFFB2] text-[12px]">Account Type</p>
                  <p className="text-[#FFFFFFB2] text-[12px]">Account Number</p>
                </div>
                <div className="w-full flex items-center justify-between">
                  <p className="text-[#FFFFFF] text-[20px]">
                    {account?.product?.name}
                  </p>
                  <p className="text-[#FFFFFF] text-[20px]">
                    {account?.account_number}
                  </p>
                </div>
                <div>
                  <p className="text-[#FFFFFFB2] text-[12px]">
                    Account Balance
                  </p>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-[5px]">
                    <p className="text-white">KES</p>
                    <p
                      className={showBalance[account.id] ? `blur` : ""}
                      style={{ color: "white", fontSize: "16px" }}
                    >
                      <span className="text-[36px] text-white font-bold">
                        {Number(account?.balance || "0.00")?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="bg-[#D3EAFF] w-[28px] h-[20px] rounded-[60%] flex items-center justify-center">
                    {showBalance[account.id] ? (
                      <svg
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleBalanceVisibility(account.id);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        >
                          <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0-4 0" />
                          <path d="M21 12c-2.4 4-5.4 6-9 6c-3.6 0-6.6-2-9-6c2.4-4 5.4-6 9-6c3.6 0 6.6 2 9 6" />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleBalanceVisibility(account.id);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="-2 -2 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="m15.398 7.23l1.472-1.472C18.749 6.842 20 8.34 20 10c0 3.314-4.958 5.993-10 6a14.734 14.734 0 0 1-3.053-.32l1.747-1.746c.426.044.862.067 1.303.066h.002c-.415 0-.815-.063-1.191-.18l1.981-1.982c.47-.202.847-.579 1.05-1.049l1.98-1.981A4 4 0 0 1 10.022 14C14.267 13.985 18 11.816 18 10c0-.943-1.022-1.986-2.602-2.77m-9.302 3.645A4 4 0 0 1 9.993 6C5.775 5.985 2 8.178 2 10c0 .896.904 1.877 2.327 2.644L2.869 14.1C1.134 13.028 0 11.585 0 10c0-3.314 4.984-6.017 10-6c.914.003 1.827.094 2.709.262l-1.777 1.776c-.29-.022-.584-.035-.88-.038c.282.004.557.037.823.096l-4.78 4.779zM19.092.707a1 1 0 0 1 0 1.414l-16.97 16.97a1 1 0 1 1-1.415-1.413L17.677.708a1 1 0 0 1 1.415 0z"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-[10px] justify-end w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                  >
                    <circle cx="4.5" cy="4.5" r="4.5" fill="#42F980" />
                  </svg>
                  <p className="text-white text-[14px]">Active</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* edit customer */}
      <EditPersonalInfo
        id_R="firstEditModal"
        editCustomerOpen={editCustomerOpen}
        customerEditDetails={customerEditDetails}
        setCustomerEditDetails={setCustomerEditDetails}
        handleEditCustomerDetails={handleEditCustomerDetails}
        handleCloseEditCustomerPersonalInfo={
          handleCloseEditCustomerPersonalInfo
        }
        handleOpenEmailPrompt={handleOpenEmailPrompt}
        handleOpenMobilePrompt={handleOpenMobilePrompt}
      />

      <DeleteAccountPop
        isDeleteAccountOpen={isDeleteAccountOpen}
        handleDeleteAccount={handleDeleteAccount}
        closeDeleteAccountPop={closeDeleteAccountPop}
      />

      <EditAdditionalInfo
        id_R="secondEditModal"
        editAdditionalInfoOpen={editAdditionalInfoOpen}
        editAdditionalDetails={editAdditionalDetails}
        setEditAdditionalDetails={setEditAdditionalDetails}
        handleEditAdditionalInformation={handleEditAdditionalInformation}
        handleCloseEditAdditionalInfo={handleCloseEditAdditionalInfo}
      />

      {ViewCustomerDocumentOpen && selectedDocument && (
        <ViewCustomerDocument
          document={selectedDocument}
          ViewCustomerDocumentOpen={ViewCustomerDocumentOpen}
          handleCloseViewCustomerDocument={handleCloseViewCustomerDocument}
          // openAuditDocumentModal={openAuditDocumentModal}
          customerInitials={initials}
          customerName={customer}
          customerStatus={customer?.status}
          handleEditSelfie={handleEditSelfie}
        />
      )}

      <ViewID
        document={customer}
        ViewIDOpen={ViewIDOpen}
        handleCloseViewID={handleCloseViewID}
        customerInitials={initials}
        customerStatus={customer?.status}
        handleEditIdentity={handleEditIdentity}
        handleEditMetaData={handleEditMetaData}
      />
      <EditIdentity
        id_R="forthModal"
        closeIDlModal={closeIDlModal}
        isIDModalOpen={isIDModalOpen}
        identificationType={identificationType}
        setIdentificationType={setIdentificationType}
        country={country}
        setCountry={setCountry}
        frontFile={frontFile}
        setFrontFile={setFrontFile}
        backFile={backFile}
        setBackFile={setBackFile}
        passportFile={passportFile}
        setPassportFile={setPassportFile}
        isContinueEnabled={isContinueEnabled}
        setIsContinueEnabled={setIsContinueEnabled}
        setIsContinueModalOpen={setIsContinueModalOpen}
      />
      <EditSelfie
        isSelfieModalOpen={isSelfieModalOpen}
        selfieFile={selfieFile}
        setSelfieFile={setSelfieFile}
        closeSelfieModal={closeSelfieModal}
        openProceedModal={openProceedModal}
      />
      <EditMetaData
        isMetaDataOpen={isMetaDataOpen}
        identity={identity}
        setIdentity={setIdentity}
        closeMetaData={closeMetaData}
        setViewIDOpen={setViewIDOpen}
        setIsSaveChanges={setIsSaveChanges}
      />
      <SaveChanges
        isSaveChanges={isSaveChanges}
        closeSaveChanges={closeSaveChanges}
        handleSaveChangesButton={handleSaveChangesButton}
      />
      <ProceedModal
        id="proceedModal"
        isProceedModalOpen={isProceedModalOpen}
        closeSelfieModal={closeSelfieModal}
        closeProceedModal={closeProceedModal}
        handleContinueButton={handleContinueButton}
      />
      <LoadingButtons isLoadingButtons={isLoadingButtons} />
      <ContinueModal
        isContinueModalOpen={isContinueModalOpen}
        closeContinueModal={closeContinueModal}
        handleNextButtonClick={handleNextButtonClick}
      />
      <DetailSavedSuccessfully isSavedSuccessfully={isSavedSuccessfully} />
      <CloseAccountSuccess
        closeAccountSuccessOpen={closeAccountSuccessOpen}
        handleCloseAccountSuccess={handleCloseAccountSuccess}
      />

      <EmailPrompt
        emailPromptOpen={emailPromptOpen}
        handleCloseEmailPrompt={handleCloseEmailPrompt}
        handleOpenEmailOtp={handleOpenEmailOtp}
      />

      <MobilePrompt
        mobilePromptOpen={mobilePromptOpen}
        handleCloseMobilePrompt={handleCloseMobilePrompt}
        handleOpenMobileOtp={handleOpenMobileOtp}
      />

      <OtpEmail
        id_R="otpemail"
        otpEmailOpen={otpEmailOpen}
        handleCloseEmailOtp={handleCloseEmailOtp}
        customerEditDetails={customerEditDetails}
        setCustomerEditDetails={setCustomerEditDetails}
      />
      <OtpMobile
        id_R="otpmobile"
        otpMobileOpen={otpMobileOpen}
        handleCloseMobileOtp={handleCloseMobileOtp}
        customerEditDetails={customerEditDetails}
        setCustomerEditDetails={setCustomerEditDetails}
      />
      <SuspendCustomerPrompt
        suspendCustomerPromptOpen={suspendCustomerPromptOpen}
        handleCloseSuspendCustomerPrompt={handleCloseSuspendCustomerPrompt}
        handleSuspendCustomer={handleSuspendCustomer}
      />
    </div>
  );
};

export default Customer;
