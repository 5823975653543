import React from "react";

const AccountCreatedSuccessful = ({ id, isAccountCreated }) => {
  return (
    <>
      <div
        id={id}
        className={`${
          isAccountCreated ? "flex" : "hidden"
        } flex items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="flex items-center justify-center min-h-[50%] w-full">
          <div className="relative bg-white shadow-lg rounded-md w-[90%] max-w-lg mx-auto">
            <div className="text-center">
              <div className="mt-4 mb-12 mx-auto items-center justify-center">
                <img
                  src="/success_image.png"
                  alt="Success"
                  className="mx-auto"
                />
                <div className="text-[#000] text-[15px] font-normal">
                  <p>Success!</p>
                  <p>Success! Account created successfully</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountCreatedSuccessful;
