import React from "react";

const SignInActivity = ({
  id_R,
  closeSignInAuthModal,
  SignInModalOpen,
  selectedUser,
}) => {
  return (
    <>
      <div
        id={id_R}
        className={`${
          SignInModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
          <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto h-[95vh]">
            <div className="px-4 mt-4 rounded-t flex items-center">
              <div className="flex items-center rounded-t h-[12vh]">
                <button
                  onClick={closeSignInAuthModal}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
                      fill="black"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                  <p className="text-[20px] font-bold text-[#000] ml-5">
                    Sign-in activity
                  </p>
                </button>
              </div>
            </div>
            <div className="flex items-start justify-center w-[58vw] h-[12vh]">
              <div className="w-[95%] text-[#000] text-sm font-normal ">
                <p>
                  ⚠️ These are the instances when the user has manually signed
                  in with their username and password in the
                </p>
                <p>past 7 days.</p>
              </div>
            </div>

            <div className="h-[20vh] px-5">
              <div className="flex items-center h-[6vh]">
                <div className="flex items-center font-medium text-[#000] text-[15px] w-[18vw]">
                  Date
                </div>
                <div className="flex items-center font-medium text-[#000] text-[15px] w-[12vw]">
                  Status
                </div>
                <div className="flex items-center font-medium text-[#000] text-[15px] w-[15vw]">
                  Failure reason
                </div>
              </div>
              <div className="flex items-center h-[5vh]">
                <div className="flex items-center font-[300] text-[#000] text-[14px] w-[18vw]">
                  November 22, 2023 at 11:30 AM
                </div>
                <div className="flex items-center font-medium text-[#0CBF08] text-[14px] w-[12vw]">
                  Success
                </div>
                <div className="flex items-center font-medium text-[#000] text-[15px] w-[15vw]"></div>
              </div>

              <div className="flex items-center h-[5vh]">
                <div className="flex items-center font-[300] text-[#000] text-[14px] w-[18vw]">
                  November 22, 2023 at 11:27 PM
                </div>
                <div className="flex items-center font-medium text-[#FF3232] text-[14px] w-[12vw]">
                  Failure
                </div>
                <div className="flex items-center font-[300] text-[#000] text-[14px] w-[15vw]">
                  Wrong password input
                </div>
              </div>

              <div className="flex items-center h-[5vh]">
                <div className="flex items-center font-[300] text-[#000] text-[14px] w-[18vw]">
                  November 22, 2023 at 11:30 AM
                </div>
                <div className="flex items-center font-medium text-[#0CBF08] text-[14px] w-[12vw]">
                  Success
                </div>
                <div className="flex items-center font-medium text-[#000] text-[15px] w-[15vw]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInActivity;
