import React, { useState, useEffect } from "react";
import CustomerProgress from "../../../components/progressBar/CustomerProgress";

const FifthModal = ({
  id_R,
  closeAddInfoModal,
  addInfoModalOpen,
  kraPin,
  setKraPin,
  monthlyIncome,
  setMonthlyIncome,
  jobTitle,
  setJobTitle,
  employment_type,
  setEmploymentType,
  openReviewModal,
  openAddressInfoModal,
}) => {
  const handleBackButtonClick = () => {
    // Close the current modal
    closeAddInfoModal();

    // Open the next modal
    openAddressInfoModal();
  };
  const handleNextButtonClick = () => {
    // Close the current modal
    closeAddInfoModal();

    // Open the next modal
    openReviewModal();
  };

  const [isInputsFilled, setIsInputsFilled] = useState(false);

  const handleCancelButton = () => {
    // Reset input fields
    setKraPin("");
    setMonthlyIncome("");
    setEmploymentType("");

    // Close the modal
    closeAddInfoModal();
  };
  useEffect(() => {
    const areInputsFilled = !!(kraPin && monthlyIncome);
    setIsInputsFilled(areInputsFilled);
  }, [kraPin, monthlyIncome]);
  return (
    <>
      <div
        id={id_R}
        className={`${
          addInfoModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="pt-4 px-2 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add Member
                </p>
              </div>
              <div className="mt-[32px] px-6">
                <CustomerProgress step={6} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col justify-between">
              <div className="flex items-center justify-end">
                <button
                  onClick={closeAddInfoModal}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Additional information
                  </p>
                  <div className="text-[#000] mt-5 text-[15px] font-normal">
                    <p className="w-[100%]">
                      Enter Member’s income details as well as verify Member’s
                      KRA PIN. KRA PIN will also verify Member’s personal
                      details.
                    </p>
                  </div>
                </div>

                <div className="flex w-full justify-between mt-12 mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="employment_typeInput"
                      className="mb-1 text-md font-normal text-[#000]"
                    >
                      Employment type
                    </label>
                    <select
                      name="county"
                      id="county"
                      value={employment_type}
                      onChange={(e) => setEmploymentType(e.target.value)}
                      className="w-[95%] h-10 px-4 rounded-[4px] border border-[1px] border-[#000]"
                    >
                      <option value="Employed">Employed</option>
                      <option value="SelfEmployed">Self Employed</option>
                    </select>
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="jobTitleInput"
                      className="mb-1 text-md font-normal text-[#000]"
                    >
                      Job Title
                    </label>
                    <input
                      type="text"
                      id="jobTitleInput"
                      name="jobTitle"
                      placeholder="Enter your job title"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      className="w-[95%] h-10 px-4 rounded-[4px] border border-[1px] border-[#000]"
                    />
                  </div>
                </div>

                <div className="flex w-[50%] flex-col mt-10 justify-center">
                  <label
                    htmlFor="incomeInput"
                    className="mb-1 text-md font-normal text-[#000]"
                  >
                    Monthly Income
                  </label>
                  <div className="flex items-center w-[95%] h-10 rounded-[4px] border border-[1px] border-[#000]">
                    <div className="flex items-center justify-center text-[#000] w-[20%] h-10 font-normal border-r border-t border-b  border-[#000]">
                      KES
                    </div>
                    <input
                      type="text"
                      id="incomeInput"
                      name="monthlyIncome"
                      placeholder="Enter your monthly income"
                      value={monthlyIncome}
                      onChange={(e) => setMonthlyIncome(e.target.value)}
                      className="w-[80%] h-9 px-4"
                    />
                  </div>
                </div>

                <div className="flex flex-col justify-center mt-10">
                  <div className="flex w-[48%] flex-col justify-center">
                    <label
                      htmlFor="kraPinInput"
                      className="mb-1 text-md font-normal text-[#000]"
                    >
                      KRA PIN
                    </label>
                    <div className="flex gap-4">
                      <input
                        type="text"
                        id="kraPinInput"
                        name="kraPin"
                        placeholder="Enter your KRA PIN"
                        value={kraPin}
                        onChange={(e) => setKraPin(e.target.value)}
                        className="w-[80%] h-10 px-4 rounded-[4px] border border-[1px] border-[#000]"
                      />

                      <button
                        type="button"
                        // onClick={handleVerifyKra}
                        className="text-white bg-[#074073] hover:bg-[#074073] focus:outline-none font-normal rounded-[4px] text-sm text-center px-10 h-10"
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-16 mb-8 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-normal rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                </div>
                <button
                  onClick={handleNextButtonClick}
                  type="button"
                  className={`text-white ${
                    isInputsFilled
                      ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                      : "bg-[#D9D9D9] pointer-events-none"
                  } block font-normal rounded-[5px] text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FifthModal;
