import { client } from "../client/client";

export const fetchTransactions = async (
  page,
  limit,
  type,
  status,
  leastAmount,
  mostAmount,
  fromDate,
  toDate,
  query
) => {
  try {
    const response = await client.get(
      `/api/v1/transaction/get-transactions?page=${page}&limit=${limit}${
        type ? `&type=${type}` : ""
      }&status=${status}&fromDate=${fromDate}&toDate=${toDate}&q=${query}&leastAmount=${leastAmount}&mostAmount=${mostAmount}`
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const fetchAdminUsers = async (page, limit, role, query) => {
  try {
    const response = await client.get(
      `http://109.199.120.58:5300/api/v1/users?page=${page}&limit=${limit}&role=${role}&q=${query}`
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const fetchTotalTransactionValue = async (type, fromDate, toDate) => {
  try {
    const response = await client.get(
      `http://109.199.120.58:5300/api/v1/transaction/completion-rate?page=1&limit=100000000&fromDate=${fromDate
        ?.toISOString()
        .substring(0, 10)}&toDate=${toDate
        ?.toISOString()
        .substring(0, 10)}&type=${type}`
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const fetchTotalTransactionValueByStatus = async (
  status,
  fromDate,
  toDate
) => {
  try {
    const response = await client.get(
      `http://109.199.120.58:5300/api/v1/transaction/completion-rate?page=1&limit=100000000&fromDate=${fromDate
        ?.toISOString()
        .substring(0, 10)}&toDate=${toDate
        ?.toISOString()
        .substring(0, 10)}&status=${status}&type=send`
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getAddressInfo = async ({
  email,
  status,
  username,
  firstname,
  lastname,
  phone,
  job_title,
  office_phone,
  department,
  country,
  county,
  subcounty,
  address,
  role_id,
}) => {
  try {
    const { data } = await client.post(
      `http://109.199.120.58:5300/api/v1/users`,
      {
        email,
        status,
        username,
        firstname,
        lastname,
        phone,
        job_title,
        office_phone,
        department,
        country,
        county,
        subcounty,
        address,
        role_id,
      }
    );
    return data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getSingleTransaction = async (id) => {
  const response = await client.get(`/api/v1/transaction/${id}`);

  return response.data;
};

export const getLineGraphReport = async (fromDate, toDate) => {
  try {
    const response = await client.get(
      `http://109.199.120.58:5300/api/v1/report/graph-bydate?fromDate=${fromDate}&toDate=${toDate}`
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || error;
  }
};

export const getAccountsPercent = async (fromDate, toDate) => {
  const response = await client.get(
    `api/v1/report/account-count?fromDate=${fromDate}&toDate=${toDate}`
  );

  return response.data;
};

export const getSharesTotal = async (startDate, endDate) => {
  const response = await client.get(
    `api/v1/shares/value-and-count?startDate=${startDate}&endDate=${endDate}`
  );
  return response?.data;
};

export const getSharesLineChart = async (startDate, endDate) => {
  const response = await client.get(
    `api/v1/shares/accounts-data?startDate=${startDate}&endDate=${endDate}`
  );
  return response?.data;
};

export const getPercentValueShares = async (startDate, endDate) => {
  const response = await client.get(
    `api/v1/shares/customer-statistics?startDate=${startDate}&endDate=${endDate}`
  );
  return response?.data;
};

export const exportSelectedTransactions = async (type, transactions) => {
  const response = await client.post(
    `/api/v1/transaction/export/selected?format=${type}`,
    transactions,
    { responseType: "arraybuffer" }
  );

  return response.data;
};

export const exportAllTransactions = async (type) => {
  const response = await client.get(
    `/api/v1/transaction/export?format=${type}`,
    {
      headers: {
        "Content-Type": type === "pdf" ? "application/pdf" : "text/csv",
      },
      responseType: "arraybuffer",
    }
  );
  return response.data;
};
