import React from "react";
import SharesLineChart from "../../components/charts/sharesLineChart";
import SharesDonutChart from "../charts/sharesDonutChart";

export const SharesPortfolio = ({ percentShare, shares, sharesLineChart }) => {
  const COLORS = ["#074073", "#FF5757"];  
  return (
    <div className="px-2 py-5 flex justify-between">
      <div className="flex-col w-[60%]">
        <div className="flex mt-4 gap-[10px] w-[100%]">
          <div className="w-[270px] h-[120px] border-[#AFFCC9] border-[1px] rounded-[5px]">
            <div className="flex flex items-center px-1 justify-between">
              <p className="text-[#074073] text-[15px] h-[20%]">
                Value Total shares
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3.5 18.5L9.5 12.5L13.5 16.5L22 6.92L20.59 5.5L13.5 13.5L9.5 9.5L2 17L3.5 18.5Z"
                  fill="#03E450"
                />
              </svg>
            </div>
            <div className="flex mt-2 items-center gap-[10px] px-1">
              <div className="w-[32px] h-[24px] mt-[8px] rounded-[4px] flex items-center justify-center">
                <p className="text-[#00000] text-[14px]">Ksh.</p>
              </div>
              <p className="text-black text-[26px] font-medium">
                {Number(shares?.totalValue || "0.00")?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="flex flex items-center w-[80px] mt-2 mx-2 bg-[#EBFFF2] rounded-[3px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M20.9582 18.8737L20.9364 10.4681L12.5308 10.4463C12.4433 10.4344 12.3542 10.4415 12.2696 10.4671C12.185 10.4927 12.1069 10.5361 12.0405 10.5945C11.9742 10.6529 11.9212 10.7248 11.885 10.8055C11.8489 10.8861 11.8306 10.9736 11.8312 11.062C11.8318 11.1504 11.8514 11.2376 11.8887 11.3177C11.926 11.3978 11.98 11.469 12.0472 11.5264C12.1143 11.5839 12.1931 11.6262 12.278 11.6506C12.363 11.6749 12.4522 11.6807 12.5396 11.6676L18.8383 11.6938L10.4938 20.0383C10.3781 20.154 10.3131 20.3109 10.3131 20.4745C10.3131 20.6381 10.3781 20.795 10.4938 20.9107C10.6095 21.0264 10.7664 21.0914 10.93 21.0914C11.0936 21.0914 11.2505 21.0264 11.3662 20.9107L19.7107 12.5662L19.7369 18.8649C19.7374 19.0286 19.803 19.1854 19.9192 19.3007C20.0353 19.4161 20.1926 19.4806 20.3563 19.48C20.52 19.4794 20.6767 19.4138 20.7921 19.2977C20.9074 19.1815 20.9719 19.0243 20.9713 18.8606L20.9582 18.8737Z"
                  fill="#03E450"
                />
              </svg>
              <p className="text-[#03E450] text-[12px] font-medium pr-2">
                +10.78%
              </p>
            </div>
          </div>
          <div className="w-[270px] h-[120px] border-[#BFE0FD] border-[1px] rounded-[5px]">
            <div className="flex flex items-center px-1 justify-between">
              <p className="text-[#074073] text-[15px] h-[20%]">
                Total number of shares held
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3.5 18.5L9.5 12.5L13.5 16.5L22 6.92L20.59 5.5L13.5 13.5L9.5 9.5L2 17L3.5 18.5Z"
                  fill="#03E450"
                />
              </svg>
            </div>
            <div className="flex mt-2 items-center gap-[10px] px-1">
              <p className="text-black text-[26px] font-medium">
                {Number(shares?.numberOfShares || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
            </div>
            <div className="flex flex items-center w-[80px] mt-2 mx-2 bg-[#EBFFF2] rounded-[3px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M20.9582 18.8737L20.9364 10.4681L12.5308 10.4463C12.4433 10.4344 12.3542 10.4415 12.2696 10.4671C12.185 10.4927 12.1069 10.5361 12.0405 10.5945C11.9742 10.6529 11.9212 10.7248 11.885 10.8055C11.8489 10.8861 11.8306 10.9736 11.8312 11.062C11.8318 11.1504 11.8514 11.2376 11.8887 11.3177C11.926 11.3978 11.98 11.469 12.0472 11.5264C12.1143 11.5839 12.1931 11.6262 12.278 11.6506C12.363 11.6749 12.4522 11.6807 12.5396 11.6676L18.8383 11.6938L10.4938 20.0383C10.3781 20.154 10.3131 20.3109 10.3131 20.4745C10.3131 20.6381 10.3781 20.795 10.4938 20.9107C10.6095 21.0264 10.7664 21.0914 10.93 21.0914C11.0936 21.0914 11.2505 21.0264 11.3662 20.9107L19.7107 12.5662L19.7369 18.8649C19.7374 19.0286 19.803 19.1854 19.9192 19.3007C20.0353 19.4161 20.1926 19.4806 20.3563 19.48C20.52 19.4794 20.6767 19.4138 20.7921 19.2977C20.9074 19.1815 20.9719 19.0243 20.9713 18.8606L20.9582 18.8737Z"
                  fill="#03E450"
                />
              </svg>
              <p className="text-[#03E450] text-[12px] font-medium pr-2">
                +5.69%
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex items-center justify-center w-[113px] mt-5 mx-2 bg-[#EBFFF2] rounded-[10px]">
          <p className="text-[#106E0E] text-[14px] font-medium p-[5px]">
            Shares Value
          </p>
        </div>
        <div className="h-[240px] mt-10 w-full">
          <SharesLineChart sharesLineChart={sharesLineChart} />
        </div>
      </div>

      <div className="w-[35%] bg-white bg-[#F9F9F9] rounded-[5px]">
        <p className="text-[#074073] text-[15px] font-medium px-2">
          Number of members with shares
        </p>
        <div className="flex flex-col items-center">
          <div className="mt-5">
            <SharesDonutChart percentShare={percentShare} />
          </div>
          <div className="overflow-y-auto flex justify-center">
            {percentShare &&
              Object.entries(percentShare).map(
                ([name, { count, percentage }], index) => (
                  <div
                    className="flex items-center w-[200px] gap-[10px]"
                    key={index}
                  >
                    <div className="flex items-center mb-[10px] gap-[10px]">
                      <div
                        className="h-[20px] w-[20px] rounded-full"
                        style={{ backgroundColor: `${COLORS[index]}` }}
                      ></div>
                      <p className="text-[#074073] text-[14px]">{name}</p>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
