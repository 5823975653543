import React, { useEffect, useState } from "react";
import { Modal, Button, Placeholder } from "rsuite";
import RoleProgress from "../../progressBar/RoleProgress";

const AddRole = ({
  id,
  closeModalFunction,
  isModalOpen,
  openPermissionsModal,
  roleName,
  setRoleName,
  roleDescription,
  setRoleDescription,
}) => {
  const [isInputsFilled, setIsInputsFilled] = useState(false);

  const handleNextButtonClick = () => {
    closeModalFunction();
    openPermissionsModal();
  };

  useEffect(() => {
    const areInputsFilled = !!(roleName && roleDescription);
    setIsInputsFilled(areInputsFilled);
  }, [roleName, roleDescription]);
  return (
    <div
      id={id}
      className={`${
        isModalOpen ? "flex" : "hidden"
      } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
    >
      <div className="relative flex  fixed min-h-[80%] w-[65%]">
        <div className="relative flex p-[10px] bg-white shadow w-full mx-auto">
          <div className=" w-[28%]">
            <div className="py-4 rounded-t">
              <p className="text-[20px] font-bold text-[#000] ml-5">
                Create a new role
              </p>
            </div>
            <div className="mt-6 px-6">
              <RoleProgress step={1} />
            </div>
          </div>
          <div className="w-[72%] flex flex-col justify-between">
            <div>
              <div className="mt-5 mx-auto items-center">
                <p className="text-[#000] text-xl font-bold">
                  Role information
                </p>
                <div className="text-[#000] mt-[40px] text-sm font-normal ">
                  <p>
                    Create a new role and specify its permissions to ensure
                    precise access control.
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[40px] justify-between mt-[50px] mr-4">
                <div className="flex flex-col justify-center">
                  <label
                    htmlFor="nameInput"
                    className="mb-1 text-md font-medium text-[#000]"
                  >
                    Role name
                  </label>
                  <input
                    type="text"
                    id="nameInput"
                    name="rolename"
                    placeholder="Enter role name"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    className="w-[90%] h-10 px-4 border border-[1px] border-[#000]"
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <label
                    htmlFor="nameInput"
                    className="mb-1 text-md font-medium text-[#000]"
                  >
                    Role Description
                  </label>
                  <textarea
                    type="text"
                    id="nameInput"
                    name="roledescription"
                    placeholder="Enter role description"
                    value={roleDescription}
                    onChange={(e) => setRoleDescription(e.target.value)}
                    className="w-[90%] h-[120px] px-4 border border-[1px] border-[#000]"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between w-[90%] mt-[140px] mb-8 mr-5">
              <button
                onClick={handleNextButtonClick}
                // data-modal-toggle={id}
                type="button"
                className={`text-white ${
                  isInputsFilled
                    ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                    : "bg-[#D9D9D9] pointer-events-none"
                } block font-medium rounded-[5px] text-sm px-8 py-3.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
              >
                Next
              </button>
              <button
                onClick={closeModalFunction}
                data-modal-toggle={id}
                type="button"
                className="text-gray-500 bg-[#D9D9D9] hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[5px] border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
