import React, { useState, useRef } from "react";
import SuspendAccountBar from "../../progressBar/SuspendAccount";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const DocumentReason = ({
  id_R,
  documentReasonOpen,
  handleCloseDocumentReason,
  handleOpenSuspensionImplications,
  handleOpenSuspendReason,
  note,
  setNote,
}) => {
  // const [reasons, setReasons] = useState("");
  const [isInputsFilled, setIsInputsFilled] = useState(false);

  const fileInputFront = useRef(null);
  const [frontFile, setFrontFile] = useState(null);

  const handleNextButtonClick = () => {
    handleCloseDocumentReason();
    handleOpenSuspensionImplications();
  };

  const handleBackButtonClick = () => {
    handleCloseDocumentReason();
    handleOpenSuspendReason();
  };

  // Function to open file picker
  const openFrontFilePicker = () => {
    fileInputFront.current.click();
  };

  // Function to handle file selection
  const handleFrontFileChange = (e) => {
    const file = e.target.files[0];
    setFrontFile(file);
  };

  // Function to delete the selected file
  const deleteFile = () => {
    setFrontFile(null); // Remove file
  };

  // Truncate file name for display
  const truncateFileName = (fileName) => {
    const maxLength = 20;
    const extensionIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    const truncatedName =
      nameWithoutExtension.length > maxLength
        ? `${nameWithoutExtension.substring(0, maxLength)}...`
        : nameWithoutExtension;
    return `${truncatedName}${fileName.substring(extensionIndex)}`;
  };

  const handleInputChange = (e) => {
    setNote(e.target.value);
    setIsInputsFilled(e.target.value.trim() !== "");
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          documentReasonOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[90%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="p-4 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-3">
                  Suspend Account
                </p>
              </div>
              <div className="mt-[10px] px-6">
                <SuspendAccountBar step={2} />
              </div>
            </div>
            <div className="w-[74%] flex flex-col">
              <div className="justify-end rounded-t flex items-end">
                <button
                  onClick={handleCloseDocumentReason}
                  type="button"
                  className="bg-transparent rounded-lg text-sm p-2.5 ml-auto inline-flex items-center"
                  data-modal-toggle="modal"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.84 12L5.7 8.9L3.9 12H0.2L3.88 6.3L0.1 0.839999H3.94L6.08 3.92L7.88 0.839999H11.58L7.84 6.46L11.68 12H7.84Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div className="w-[95%]">
                <div className="mt-10 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Document reason
                  </p>
                  <div className="text-[#000] mt-2 mb-[20px] text-[15px] font-normal">
                    <p>
                      Please provide further details regarding the reason you
                      selected for the suspension of the account. This
                      information will assist in better understanding the
                      situation and taking necessary actions in the future.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col mt-8 rounded-[4px]">
                  <textarea
                    className="w-full h-32 border-[0.7px] border-[#000000] rounded-[4px] p-2 resize-none"
                    placeholder="Enter your note here..."
                    value={note}
                    onChange={handleInputChange}
                  />
                </div>
                {/* <div className="overflow-y-auto gap-[20px] flex flex-col h-[200px] ">
                  <CKEditor
                    editor={ClassicEditor}
                    data={reasons}
                    onReady={(editor) => {
                      console.log(
                        "CKEditor5 React Component is ready to use!",
                        editor
                      );
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setReasons(data);
                    }}
                  />
                </div> */}

                {/* Div that opens the file picker */}
                <div
                  className="flex items-center mt-4 w-[379px] rounded-[2px] border-[1px] border-[#000000] cursor-pointer"
                  onClick={openFrontFilePicker}
                >
                  <div className="flex px-4 py-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7497 5.00065V14.584C13.7497 16.4257 12.258 17.9173 10.4163 17.9173C8.57467 17.9173 7.08301 16.4257 7.08301 14.584V4.16732C7.08301 3.61478 7.3025 3.08488 7.6932 2.69418C8.0839 2.30348 8.61381 2.08398 9.16634 2.08398C9.71888 2.08398 10.2488 2.30348 10.6395 2.69418C11.0302 3.08488 11.2497 3.61478 11.2497 4.16732V12.9173C11.2497 13.3757 10.8747 13.7507 10.4163 13.7507C9.95801 13.7507 9.58301 13.3757 9.58301 12.9173V5.00065H8.33301V12.9173C8.33301 13.4699 8.5525 13.9998 8.9432 14.3905C9.3339 14.7812 9.86381 15.0007 10.4163 15.0007C10.9689 15.0007 11.4988 14.7812 11.8895 14.3905C12.2802 13.9998 12.4997 13.4699 12.4997 12.9173V4.16732C12.4997 2.32565 11.008 0.833984 9.16634 0.833984C7.32467 0.833984 5.83301 2.32565 5.83301 4.16732V14.584C5.83301 17.1173 7.88301 19.1673 10.4163 19.1673C12.9497 19.1673 14.9997 17.1173 14.9997 14.584V5.00065H13.7497Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="text-[#000000] text-[16] font-normal">
                    <p>Attach a file (.pdf, .docx, .doc, .jpeg, .png)</p>
                  </div>
                </div>

                {/* Hidden input for file upload */}
                <input
                  type="file"
                  ref={fileInputFront}
                  onChange={handleFrontFileChange}
                  className="hidden"
                  accept=".pdf, .docx, .doc, .jpeg, .png"
                />

                {/* Display uploaded file */}
                {frontFile && (
                  <div className="mt-4 flex items-center space-x-3">
                    <div onClick={deleteFile}>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.69967 15.584L10.9997 12.284L14.2997 15.584L15.583 14.3007L12.283 11.0007L15.583 7.70065L14.2997 6.41732L10.9997 9.71732L7.69967 6.41732L6.41634 7.70065L9.71634 11.0007L6.41634 14.3007L7.69967 15.584ZM10.9997 20.1673C9.73162 20.1673 8.53995 19.9265 7.42467 19.445C6.3094 18.9634 5.33926 18.3105 4.51426 17.4861C3.68926 16.6611 3.03629 15.6909 2.55534 14.5757C2.0744 13.4604 1.83362 12.2687 1.83301 11.0007C1.83301 9.7326 2.07379 8.54093 2.55534 7.42565C3.0369 6.31037 3.68987 5.34023 4.51426 4.51523C5.33926 3.69023 6.3094 3.03726 7.42467 2.55632C8.53995 2.07537 9.73162 1.8346 10.9997 1.83398C12.2677 1.83398 13.4594 2.07476 14.5747 2.55632C15.69 3.03787 16.6601 3.69085 17.4851 4.51523C18.3101 5.34023 18.9634 6.31037 19.4449 7.42565C19.9265 8.54093 20.167 9.7326 20.1663 11.0007C20.1663 12.2687 19.9256 13.4604 19.444 14.5757C18.9625 15.6909 18.3095 16.6611 17.4851 17.4861C16.6601 18.3111 15.69 18.9643 14.5747 19.4459C13.4594 19.9275 12.2677 20.1679 10.9997 20.1673Z"
                          fill="#797979"
                        />
                      </svg>
                    </div>
                    <p className="text-sm">
                      {truncateFileName(frontFile?.name)}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-between mt-32 mb-8 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#074073] bg-[#7FB8EB]  rounded-[5px] text-[16px] font-[500] px-8 py-2.5"
                  >
                    Back
                  </button>
                </div>
                <button
                  onClick={handleNextButtonClick}
                  type="button"
                  className={`text-white ${
                    isInputsFilled
                      ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                      : "bg-[#D9D9D9] pointer-events-none"
                  } block font-medium rounded-[5px] text-sm px-10 py-2.5 text-center`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentReason;
