import React from "react";
import ProgressBar from "../../components/progressBar/ProgressBar";

const ReviewAndFinish = ({
  id_R,
  closeReviewModal,
  handleNextButtonClick,
  reviewModalOpen,
  openUserInfoModal,
  openModal,
  openRolesModal,
  firstName,
  lastName,
  email,
  userName,
  jobTitle,
  department,
  selectedRole,
}) => {
  const handleBackButtonClick = () => {
    // Close the current modal
    closeReviewModal();

    // Open the previous modal
    openUserInfoModal();
  };

  const handleEditRole = () => {
    // Close the current modal
    closeReviewModal();

    openRolesModal();
  };

  const handleEditCustomer = () => {
    // Close the current modal
    closeReviewModal();

    openModal();
  };
  return (
    <>
      <div
        id={id_R}
        className={`${
          reviewModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[65%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="p-4 rounded-t">
                <button
                  onClick={closeReviewModal}
                  type="button"
                  className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle={id_R}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
                      fill="black"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                  <p className="text-[20px] font-bold text-[#000] ml-5">
                    Add user
                  </p>
                </button>
              </div>
              <div className="mt-6 px-6">
                <ProgressBar step={4} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col justify-between">
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Review and finish
                  </p>
                  <div className="text-[#000] mt-[40px] text-sm font-normal">
                    <p>
                      Great work! You've provided all the necessary details for
                      the new user. Take a moment to review and confirm the
                      settings below:
                    </p>
                  </div>
                </div>
                <div className="flex justify-between mt-[40px] w-[95%]">
                  <div className="w-[60%]">
                    <div className="">
                      <p className="text-[#000] text-[16px] font-medium">
                        Username and email
                      </p>
                      <p className="text-[#4F4F4F] text-[16px] font-[300]">
                        {firstName} {lastName}
                      </p>
                      <p className="text-[#4F4F4F] text-[16px] font-[300]">
                        {userName}
                      </p>
                      <p className="text-[#4F4F4F] text-[16px] font-[300]">
                        {email}
                      </p>
                    </div>
                    <button
                      onClick={handleEditCustomer}
                      data-modal-toggle={id_R}
                      type="button"
                      className="text-[#19C1E4] focus:outline-none font-normal text-[15px] py-1.5"
                    >
                      Edit
                    </button>
                  </div>
                  <div className="w-[40%]">
                    <div className="">
                      <p className="text-[#000] text-[16px] font-medium">
                        Role
                      </p>
                      <p className="text-[#4F4F4F] text-[16px] font-[300]">
                        {selectedRole?.name}
                      </p>
                    </div>
                    <button
                      onClick={handleEditRole}
                      data-modal-toggle={id_R}
                      type="button"
                      className="text-[#19C1E4] focus:outline-none font-normal text-[15px] py-1.5"
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="">
                    <p className="text-[#000] text-[16px] font-medium">
                      Additional user information
                    </p>
                    <p className="text-[#4F4F4F] text-[16px] font-[300]">
                      Job Title: {jobTitle}
                    </p>
                    <p className="text-[#4F4F4F] text-[16px] font-[300]">
                      Department: {department}
                    </p>
                  </div>
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#19C1E4] focus:outline-none font-normal text-[15px] py-1.5"
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div className="flex w-[95%] items-center justify-between mt-16 mb-5 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                  <button
                    onClick={handleNextButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-white bg-[#074073] hover:bg-[#074073] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Finish
                  </button>
                </div>
                <button
                  onClick={closeReviewModal}
                  data-modal-toggle={id_R}
                  type="button"
                  className="text-gray-500 bg-[#D9D9D9] hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[5px] border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewAndFinish;
