import axios from "axios";
import { client } from "../client/client";

export const login = async (username, password) => {
  const response = await axios.post(
    "http://109.199.120.58:5300/api/v1/users/login",
    {
      username,
      password,
    }
  );

  return response.data;
};

export const setAuthPassword = async (id, password, current_password) => {
  const response = await client.patch(`/api/v1/users/updatepassword/${id}`, {
    password,
    current_password,
  });

  return response?.data;
};

export const sendUserOtp = async (userId) => {
  const response = await client.post("/api/v1/otp/user", {
    userId,
    isEmail: true,
  });

  return response.data;
};

export const verifyUserOtp = async (otp, email) => {
  const response = await client.post("/api/v1/otp/user/verify-otp", {
    otp,
    isEmail: true,
    email,
  });

  return response.data;
};
