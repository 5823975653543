import React from "react";
import { Dropdown, Space } from "antd";

const ExportPdfCsv = ({ handleExportAllCustomers, memberId }) => {
  // Export PDF function
  const exportPdf = async () => {
    await handleExportAllCustomers("pdf", memberId); // Pass memberId to handleExportAllCustomers
  };

  // Export CSV function
  const exportCSV = async () => {
    await handleExportAllCustomers("csv", memberId); // Pass memberId to handleExportAllCustomers
  };

  // Dropdown items for export options
  const items = [
    {
      label: "PDF",
      key: "0",
      onClick: exportPdf,
    },
    {
      label: "CSV",
      key: "1",
      onClick: exportCSV,
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Space
        style={{
          cursor: "pointer",
          backgroundColor: "green",
          height: "40px",
          padding: "0 10px",
          borderRadius: "5px",
          color: "white",
        }}
      >
        EXPORT
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M24 0v24H0V0z" />
            <path
              fill="currentColor"
              d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"
            />
          </g>
        </svg>
      </Space>
    </Dropdown>
  );
};

export default ExportPdfCsv;
