import React, { useState } from "react";

const RejectionReason = ({
  id,
  rejectionReason,
  handleCloseRejectionReason,
  setIsModalOpen,
  handleOpenNoteToReject,
}) => {
  const [isInputsFilled, setIsInputsFilled] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setIsInputsFilled(true);
  };

  const handleBackButtonClick = () => {
    // Close the current modal
    handleCloseRejectionReason();


    // Open the next modal
    setIsModalOpen(true);
  };

  return (
    <>
      <div
        id={id}
        className={`${
          rejectionReason ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[60%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className="flex flex-col justify-between w-full px-5">
              <div>
                <div className="flex items-center justify-end">
                  <button
                    onClick={handleCloseRejectionReason}
                    type="button"
                    className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle={id}
                  >
                    <svg
                      width="61"
                      height="43"
                      viewBox="0 0 61 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_13891_3896)">
                        <path
                          d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_13891_3896">
                          <rect width="61" height="43" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="mt-3 mx-auto">
                  <p className="text-[#000] text-xl font-bold">
                    Reason for rejection
                  </p>
                  <div className="text-[#000] mt-3 text-sm font-normal">
                    <p>
                      Please choose the most appropriate reason for the closure
                      of the account from the options below.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col mt-8 rounded-[4px] border-[0.7px] border-[#000000] p-4">
                  {/* Radio Button List */}
                  <form>
                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        value="1"
                        checked={selectedOption === "1"}
                        onChange={handleOptionChange}
                        className="mr-2 h-4 w-4"
                      />
                      Uploaded ID Document does not match with uploaded Selfie
                    </label>

                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        value="2"
                        checked={selectedOption === "2"}
                        onChange={handleOptionChange}
                        className="mr-2 h-4 w-4"
                      />
                      Uploaded ID Document is unclear or Illegible
                    </label>

                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        value="3"
                        checked={selectedOption === "3"}
                        onChange={handleOptionChange}
                        className="mr-2 h-4 w-4"
                      />
                      Uploaded ID Document does not match with the scanned
                      details
                    </label>

                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        value="4"
                        checked={selectedOption === "4"}
                        onChange={handleOptionChange}
                        className="mr-2 h-4 w-4"
                      />
                      Uploaded ID Document seemed to have been edited or
                      manipulated
                    </label>

                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        value="5"
                        checked={selectedOption === "5"}
                        onChange={handleOptionChange}
                        className="mr-2 h-4 w-4"
                      />
                      Uploaded ID is missing crucial sections that are required
                      for verification
                    </label>

                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        value="6"
                        checked={selectedOption === "6"}
                        onChange={handleOptionChange}
                        className="mr-2 h-4 w-4"
                      />
                      Uploaded ID document shows signs of being fake or
                      fraudulent
                    </label>

                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        value="7"
                        checked={selectedOption === "7"}
                        onChange={handleOptionChange}
                        className="mr-2 h-4 w-4"
                      />
                      Uploaded document is not a National ID, it is another type
                      of document
                    </label>

                    <label className="flex items-center mb-2">
                      <input
                        type="radio"
                        value="8"
                        checked={selectedOption === "8"}
                        onChange={handleOptionChange}
                        className="mr-2 h-4 w-4"
                      />
                      Other
                    </label>
                  </form>
                </div>
              </div>

              <div className="flex items-center justify-between mt-10 mb-8">
                <div className="flex items-center gap-[20px]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id}
                    type="button"
                    className="text-[#FFF] bg-[#D9D9D9] hover:bg-[#D9D9D9] focus:outline-none font-medium rounded-[5px] text-sm px-12 py-2.5 text-center"
                  >
                    Back
                  </button>
                </div>
                <button
                  onClick={handleOpenNoteToReject}
                  type="button"
                  className={`text-white ${
                    isInputsFilled
                      ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                      : "bg-[#D9D9D9] pointer-events-none"
                  } block font-medium rounded-[5px] text-sm px-12 py-2.5 text-center`}
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RejectionReason;
