import React from "react";

const ViewSelfiePhoto = ({
  document,
  viewSelfiePhotoOpen,
  handleCloseViewSelfiePhoto,
}) => {


  return (
    <div
      className={`${
        viewSelfiePhotoOpen ? "flex" : "hidden"
      } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
    >
      <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
        <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto w-[50vw] h-[80vh]">
          <div className="justify-end rounded-t flex items-end">
            <button
              onClick={handleCloseViewSelfiePhoto}
              type="button"
              className="bg-transparent rounded-lg text-sm p-2.5 ml-auto inline-flex items-center"
              data-modal-toggle="modal"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.84 12L5.7 8.9L3.9 12H0.2L3.88 6.3L0.1 0.839999H3.94L6.08 3.92L7.88 0.839999H11.58L7.84 6.46L11.68 12H7.84Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
          <div className="flex justify-center items-center mt-5 mx-auto w-[96%] h-[65%]">
            <div className="p-5 h-[80%] w-[90%] overflow-auto flex justify-center items-center">
              {document ? (
                <img
                  src={document}
                  alt="Selfie"
                  style={{
                    maxWidth: "none",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <span>No Selfie Image Available</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSelfiePhoto;
