import React from "react";
import moment from "moment";

const LoanProgress = ({ loanSummary }) => {
  // const addStatus = (index) => {
  //   return index === 0 ? "is-done" : "current";
  // };
  const addStatus = (index, paymentStatus) => {
    return index <= 2
      ? "is-done"
      : paymentStatus === "NOT PAID"
      ? "is-done"
      : "current";
  };

  const formattedDate = (date, index) => {
    const momentDate = moment(date);
    if (index === 0) {
      return `Loan applied on ${momentDate.format("Do MMMM YYYY")}`;
    } else if (index === 1) {
      return `Loan approved on ${momentDate.format(
        "Do MMMM YYYY"
      )} by Marcel Auja`;
    } else if (index === 2) {
      return `Loan disbursed: ${momentDate.format("Do MMMM YYYY")}`;
    } else {
      return momentDate.format("Do MMMM YYYY");
    }
  };

  const formatBalance = (amount) => {
    return Number(amount?.split(" ")[1]).toFixed(2);
  };
  return (
    <div className="h-[300px] w-[80%]">
      <div className="wrapper">
        <ul className="StepProgress">
          {loanSummary.map((schedule, index) => (
            <div
              key={index}
              className={`StepProgress-item flex w-full justify-between ${addStatus(
                index,
                schedule.paymentStatus // Assuming you have paymentStatus in your schedule object
              )}`}
            >
              <span className="text-[#074073] text-[14px]">
                {formattedDate(schedule.Schedule_Date, index)}
              </span>
              <span className="text-[#074073] text-[14px]">
                KES {formatBalance(schedule?.Schedule_Amount)}
              </span>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LoanProgress;
