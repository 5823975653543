import moment from "moment";

function convertDateFormat(inputDate) {
  const formattedDate = moment(inputDate).format("YYYY-MM-DD");
  return formattedDate;
}

export default convertDateFormat;

export const convertLoanDates = db_date => {
  const date = moment(db_date);
  return date.format('Do MMMM YYYY');
};

export const convertDateOfBirth = (input_date) => {
  const date = moment(input_date);
  return date.format('DD/MM/YYYY');
};

