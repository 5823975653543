import React from "react";

const EditPersonalInfo = ({
  id_R,
  editCustomerOpen,
  customerEditDetails,
  setCustomerEditDetails,
  handleEditCustomerDetails,
  handleCloseEditCustomerPersonalInfo,
  handleOpenEmailPrompt,
  handleOpenMobilePrompt,
}) => {
  const formatDate = (date) => {
    return new Date(date).toISOString().split("T")[0];
  };
  return (
    <>
      <div
        id={id_R}
        className={`${
          editCustomerOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
          <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto min-h-[80%] w-[55vw]">
            <div
              onClick={handleCloseEditCustomerPersonalInfo}
              className="absolute top-[5px] cursor-pointer right-[10px] text-[24px] flex items-center justify-center"
            >
              X
            </div>
            <div className="p-[20px]">
              <div className="flex items-center gap-[10px] mb-[30px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
                    fill="black"
                  />
                </svg>
                <p className="text-[20px] font-bold text-[#000]">
                  Edit Personal information
                </p>
              </div>
              <p className="text-[#000] text-[15px] mb-[20px]">
                ⚠️ Please be aware that changing your customer personal
                information is a sensitive operation. This action will impact
                how the customer will access all services. Certain details
                cannot be edited as they has been verified through legal
                identity documents.
              </p>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    value={customerEditDetails?.firstname}
                    onChange={(e) =>
                      setCustomerEditDetails((prev) => ({
                        ...prev,
                        firstname: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Last Name</label>
                  <input
                    type="text"
                    value={customerEditDetails?.lastname}
                    onChange={(e) =>
                      setCustomerEditDetails((prev) => ({
                        ...prev,
                        lastname: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Middle Name</label>
                  <input
                    type="text"
                    value={customerEditDetails?.middlename}
                    onChange={(e) =>
                      setCustomerEditDetails((prev) => ({
                        ...prev,
                        middlename: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Gender</label>
                  <select
                    type="text"
                    value={customerEditDetails?.gender}
                    onChange={(e) =>
                      setCustomerEditDetails((prev) => ({
                        ...prev,
                        gender: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Date of Birth</label>
                  <input
                    type="date"
                    value={
                      customerEditDetails?.dob &&
                      formatDate(customerEditDetails?.dob)
                    }
                    onChange={(e) =>
                      setCustomerEditDetails((prev) => ({
                        ...prev,
                        dob: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Citizenship</label>
                  <input
                    type="text"
                    value={customerEditDetails?.citizenship}
                    onChange={(e) =>
                      setCustomerEditDetails((prev) => ({
                        ...prev,
                        citizenship: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">National ID Number</label>
                  <input
                    type="text"
                    value={customerEditDetails?.identification}
                    onChange={(e) =>
                      setCustomerEditDetails((prev) => ({
                        ...prev,
                        identification: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Email</label>
                  <input
                    type="text"
                    readOnly
                    onClick={handleOpenEmailPrompt}
                    value={customerEditDetails?.email}
                    className="w-[90%] h-[40px] cursor-pointer px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Phone Number</label>
                  <input
                    type="text"
                    readOnly
                    onClick={handleOpenMobilePrompt}
                    value={customerEditDetails?.mobile}
                    className="w-[90%] h-[40px] px-[10px] cursor-pointer mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={handleEditCustomerDetails}
                  className="bg-[#D9D9D9] h-[50px] px-[20px] rounded-[5px]"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPersonalInfo;
