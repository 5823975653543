import React from "react";
import SuspendAccountBar from "../../progressBar/SuspendAccount";

const SuspensionImplications = ({
  id_R,
  suspensionImplicationsOpen,
  handleCloseSuspensionImplications,
  handleOpenReviewSuspension,
  handleOpenDocumentReason,
  setToggleState,
  toggleState,
  activeTab,
  setActiveTab,
}) => {
  const handleNextButtonClick = () => {
    handleCloseSuspensionImplications();
    handleOpenReviewSuspension();
  };

  const handleBackButtonClick = () => {
    handleCloseSuspensionImplications();
    handleOpenDocumentReason();
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          suspensionImplicationsOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="p-4 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-3">
                  Suspend Account
                </p>
              </div>
              <div className="mt-[10px] px-6">
                <SuspendAccountBar step={3} />
              </div>
            </div>
            <div className="w-[74%] flex flex-col">
              <div className="justify-end rounded-t flex items-end">
                <button
                  onClick={handleCloseSuspensionImplications}
                  type="button"
                  className="bg-transparent rounded-lg text-sm p-2.5 ml-auto inline-flex items-center"
                  data-modal-toggle="modal"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.84 12L5.7 8.9L3.9 12H0.2L3.88 6.3L0.1 0.839999H3.94L6.08 3.92L7.88 0.839999H11.58L7.84 6.46L11.68 12H7.84Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div className="w-[95%]">
                <div className="mt-10 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Account Suspension Implications
                  </p>
                  <div className="text-[#000000] mt-2 mb-[20px] text-[12px] font-[300]">
                    <p>
                      Please specify the actions you'd like to take regarding
                      the suspended account's access and transactions. Your
                      selections will determine the restrictions applied to the
                      account.
                    </p>
                  </div>
                </div>
                <div className="overflow-y-auto gap-[20px] flex flex-col">
                  <div className="border-[1px] border-[#000000] h-[200px] w-[600px] p-[20px] rounded-[5px]">
                    <div className="flex items-center w-full mb-[15px] justify-between">
                      <p>Disable online and mobile account access</p>
                      <div className="flex items-center gap-[5px] mt-4">
                        <div
                          className={`px-[10px] py-[2px] cursor-pointer ${
                            toggleState === "No"
                              ? "bg-[#DADADA]"
                              : "bg-transparent"
                          }`}
                          onClick={() => setToggleState("No")}
                        >
                          No
                        </div>
                        <div
                          className={`px-[10px] py-[2px] cursor-pointer ${
                            toggleState === "Yes"
                              ? "bg-[#DADADA]"
                              : "bg-transparent"
                          }`}
                          onClick={() => setToggleState("Yes")}
                        >
                          Yes
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center w-full justify-between">
                      <p>Freeze transactions</p>
                      <div className="flex items-center gap-[5px] mt-4">
                        <div
                          className={`px-[10px] py-[2px] cursor-pointer ${
                            activeTab === "No"
                              ? "bg-[#DADADA]"
                              : "bg-transparent"
                          }`}
                          onClick={() => setActiveTab("No")}
                        >
                          No
                        </div>
                        <div
                          className={`px-[10px] py-[2px] cursor-pointer ${
                            activeTab === "Yes"
                              ? "bg-[#DADADA]"
                              : "bg-transparent"
                          }`}
                          onClick={() => setActiveTab("Yes")}
                        >
                          Yes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between mt-32 mb-8 mr-12">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#074073] bg-[#7FB8EB]  rounded-[5px] text-[16px] font-[500] px-8 py-2.5"
                  >
                    Back
                  </button>
                </div>

                <button
                  onClick={handleNextButtonClick}
                  type="button"
                  className="text-white bg-[#074073] hover:bg-[#074073] focus:outline-none block font-medium rounded-[5px] text-[16px] px-10 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuspensionImplications;
