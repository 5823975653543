import React from "react";
import styled from "styled-components";

const SuspendAccountBar = ({ step }) => {
  return (
    <Wrapper>
      <StepWrapper>
        <CircleBar>
          <StepCircle active={step >= 1} complete={step >= 1}>
            {step >= 2 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 19 19"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 12L4.625 10.3333L7.875 13.6667L14.375 7L16 8.66667L7.875 17L3 12Z"
                  fill="white"
                />
              </svg>
            )}
          </StepCircle>
          <Bar active={step >= 2} />
        </CircleBar>
        <StepText active={step >= 1}>Reason for suspension</StepText>
      </StepWrapper>
      <StepWrapper>
        <CircleBar>
          {/* <StepCircle active={step >= 2} /> */}
          <StepCircle active={step >= 2} complete={step >= 1}>
            {step >= 3 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 19 19"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 12L4.625 10.3333L7.875 13.6667L14.375 7L16 8.66667L7.875 17L3 12Z"
                  fill="white"
                />
              </svg>
            )}
          </StepCircle>
          <Bar active={step >= 3} />
        </CircleBar>
        <StepText active={step >= 2}>Document reason</StepText>
      </StepWrapper>
      <StepWrapper>
        <CircleBar>
          <StepCircle active={step >= 3} complete={step >= 1}>
            {step >= 4 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 19 19"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 12L4.625 10.3333L7.875 13.6667L14.375 7L16 8.66667L7.875 17L3 12Z"
                  fill="white"
                />
              </svg>
            )}
          </StepCircle>
          <Bar active={step >= 3} />
        </CircleBar>
        <StepText active={step >= 3}>Account suspension implications</StepText>
      </StepWrapper>
      <StepWrapper>
        <CircleBar>
          <StepCircle active={step >= 4} />
          <LastBar />
        </CircleBar>
        <StepText active={step >= 4}>Review & finish</StepText>
      </StepWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 16px;
`;
const CircleBar = styled.div`
  align-items: center;
`;

const StepCircle = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 19px;
  border: 1px solid #fafafa;
  background-color: ${({ active }) => (active ? "#157D93" : "#FAFAFA")};
  margin-right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    display: ${({ complete }) => (complete ? "block" : "none")};
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Bar = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${({ active }) => (active ? "#6A6A6A" : "#6A6A6A")};
  margin-left: 8px;
`;

const LastBar = styled.div`
  width: 2px;
  height: 30px;
  background-color: "transparent";
  margin-left: 8px;
`;

const StepText = styled.div`
  text-align: left;
  font-size: 16px;
  color: ${({ active }) => (active ? "#000" : "#6A6A6A")};
  align-items: center;
  margin-bottom: 30px;
`;

export default SuspendAccountBar;
