import React, { useState } from "react";

const EditAdminRoles = ({
  id_R,
  closeEditRolesModal,
  editRolesModalOpen,
  roles,
  updateRole,
  selectedUser,
  getAdminUsers,
  closeAdminUserModal,
}) => {
  const [isRoleSelected, setIsRoleSelected] = useState(false);

  const handleRoleChange = (event) => {
    setSelectedEditRole(event.target.value);
    setIsRoleSelected(true);
  };

  const [selectedEditRole, setSelectedEditRole] = useState("");
  const handleSaveChanges = async () => {
    try {
      // Call getAddressInfo with the appropriate data
      const response = await updateRole({
        id: selectedUser?.id,
        role_id: selectedEditRole,
      });

      // Handle the response as needed
      console.log("Backend response:", response);
      closeEditRolesModal();
      closeAdminUserModal();
      getAdminUsers();
    } catch (error) {
      // Handle errors
      console.error("Error submitting data:", error);
    }
  };
  return (
    <>
      <div
        id={id_R}
        className={`${
          editRolesModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
          <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto h-[95vh]">
            <div className="rounded-t flex items-center">
              <div className="px-5 flex items-end h-[10vh] rounded-t">
                <button
                  onClick={closeEditRolesModal}
                  type="button"
                  className="bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle={id_R}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
                      fill="black"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                  <p className="text-[20px] font-bold text-[#000] ml-5">
                    Manage role
                  </p>
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center w-[58vw] h-[15vh]">
              <div className="w-[95%] text-[#000] text-sm font-normal ">
                <p>
                  ⚠️ Please be aware that changing your user role is a
                  significant operation that comes with different levels of
                </p>
                <p>
                  access and responsibilities. Ensure that you understand the
                  implications of the new role before proceeding.
                </p>
              </div>
            </div>

            <div className="px-5">
              <div className="text-[#000] text-[16px] font-medium">
                <p>Role</p>
              </div>
              {roles?.map((role) => (
                <label
                  key={role?.id}
                  className="flex items-center gap-2 mt-3 text-[16px] text-[#000] cursor-pointer"
                >
                  <input
                    type="radio"
                    name="role"
                    value={role?.id}
                    checked={selectedEditRole === role?.id}
                    onChange={handleRoleChange}
                  />
                  {role?.name}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M7.79199 12.0417H9.20866V7.79169H7.79199V12.0417ZM8.50033 6.37502C8.70102 6.37502 8.86937 6.30702 9.00537 6.17102C9.14137 6.03502 9.20913 5.86691 9.20866 5.66669C9.20866 5.46599 9.14066 5.29765 9.00466 5.16165C8.86866 5.02565 8.70055 4.95788 8.50033 4.95835C8.29963 4.95835 8.13128 5.02635 7.99528 5.16235C7.85928 5.29835 7.79152 5.46646 7.79199 5.66669C7.79199 5.86738 7.85999 6.03573 7.99599 6.17173C8.13199 6.30773 8.3001 6.37549 8.50033 6.37502ZM8.50033 15.5834C7.52046 15.5834 6.59963 15.3973 5.73783 15.0252C4.87602 14.6531 4.12637 14.1485 3.48887 13.5115C2.85137 12.874 2.3468 12.1243 1.97516 11.2625C1.60352 10.4007 1.41746 9.47988 1.41699 8.50002C1.41699 7.52016 1.60305 6.59933 1.97516 5.73752C2.34727 4.87572 2.85184 4.12606 3.48887 3.48856C4.12637 2.85106 4.87602 2.34649 5.73783 1.97485C6.59963 1.60321 7.52046 1.41716 8.50033 1.41669C9.48019 1.41669 10.401 1.60274 11.2628 1.97485C12.1246 2.34696 12.8743 2.85153 13.5118 3.48856C14.1493 4.12606 14.6541 4.87572 15.0262 5.73752C15.3983 6.59933 15.5841 7.52016 15.5837 8.50002C15.5837 9.47988 15.3976 10.4007 15.0255 11.2625C14.6534 12.1243 14.1488 12.874 13.5118 13.5115C12.8743 14.149 12.1246 14.6538 11.2628 15.0259C10.401 15.398 9.48019 15.5838 8.50033 15.5834ZM8.50033 14.1667C10.0823 14.1667 11.4222 13.6177 12.5201 12.5198C13.618 11.4219 14.167 10.082 14.167 8.50002C14.167 6.91808 13.618 5.57815 12.5201 4.48023C11.4222 3.38231 10.0823 2.83335 8.50033 2.83335C6.91838 2.83335 5.57845 3.38231 4.48053 4.48023C3.38262 5.57815 2.83366 6.91808 2.83366 8.50002C2.83366 10.082 3.38262 11.4219 4.48053 12.5198C5.57845 13.6177 6.91838 14.1667 8.50033 14.1667Z"
                      fill="#32A9FF"
                    />
                  </svg>
                </label>
              ))}
            </div>
            <div className="flex items-end justify-between h-[44vh] px-5">
              <button
                data-modal-toggle={id_R}
                type="button"
                className={`text-white ${
                  isRoleSelected ? "bg-[#074073]" : "bg-[#D9D9D9]"
                } focus:outline-none focus:ring-blue-300 rounded-[5px] text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10`}
                onClick={handleSaveChanges}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdminRoles;
