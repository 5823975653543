import { client } from "../client/client";

export const getLoansReport = async () => {
  const response = await client.get(
    "/api/v1/report/loans?page=1&limit=10&fromDate=2023-11-23%2000%3A00%3A00&toDate=2023-11-24%2023%3A59%3A59&status=Active&loan_type=Digital_Loan&repay_range=0-6000&type=report&loan_duration=0-1"
  );
  return response;
};

export const getLoansFilters = async (
  page,
  limit,
  fromDate,
  toDate,
  status,
  loan_type,
  repay_range,
  type,
  loan_duration,
  query
) => {
  const response = await client.get(
    `/api/v1/report/loans?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&loan_type=${loan_type}&repay_range=${repay_range}&type=${type}&loan_duration=${loan_duration}&q=${query}`
  );
  return response;
};

export const getSingleLoanCustomer = async (customer_id, loan_code) => {
  try {
    const response = await client.get(
      `/api/v1/report/loan?customer_id=${customer_id}&loan_code=${loan_code}`
    );
    console.log(response);
    return response?.data;
  } catch (error) {
    console.error("Error fetching single loan customer:", error);
    throw error;
  }
};

export const exportAllLoans = async (type, limit) => {
  const response = await client.get(
    `api/v1/report/loans/export?format=${type}&limit=${limit}`,
    {
      headers: {
        "Content-Type": type === "pdf" ? "application/pdf" : "text/csv",
      },
      responseType: "arraybuffer",
    }
  );
  return response.data;
};

export const exportSelectedLoans = async (type, transactions) => {
  const response = await client.post(
    `/api/v1/report/loans/export-selected?format=${type}`,
    transactions,
    { responseType: "arraybuffer" }
  );

  return response.data;
};