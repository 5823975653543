import React from "react";
import {
  BarChart,
  // Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  Label,
  // Area,
  ResponsiveContainer,
} from "recharts";

const BarGraph = ({ datum }) => {
  const data = datum?.data?.deposits;
  return (
    <ResponsiveContainer>
      <BarChart width={500} height={200} data={data} margin={{ left: 10 }}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" axisLine={{ stroke: "#D9D9D9" }} />
        <YAxis axisLine={{ stroke: "#D9D9D9" }}>
          <Label value="Amount" angle={-90} offset={-5} position="insideLeft" />
        </YAxis>
        <Tooltip />
        <Legend />
        <Bar dataKey="MPesa" fill="#0057FF" />
        <Bar dataKey="ATM" fill="#074073" />
        <Bar dataKey="Payroll" fill="#17C6C6" />
        <Bar dataKey="Others" fill="#19C1E4" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarGraph;
