import React from "react";
import CustomerProgress from "../../../components/progressBar/CustomerProgress";

const ReviewFinish = ({
  id_R,
  closeReviewModal,
  reviewModalOpen,
  firstName,
  lastName,
  middleName,
  email,
  phoneNumber,
  dob,
  kraPin,
  monthlyIncome,
  physicalAddress,
  county,
  subCounty,
  identification,
  openAddInfoModal,
  jobTitle,
  openIDModal,
  productId,
  appendCountryCode,
  handleCompleteRegistration,
}) => {
  const handleBackButtonClick = () => {
    // Close the current modal
    closeReviewModal();

    // Open the previous modal
    openAddInfoModal();
  };

  const handleEditAddInfo = () => {
    // Close the current modal
    closeReviewModal();

    openAddInfoModal();
  };

  const handleEditIDInfo = () => {
    // Close the current modal
    closeReviewModal();

    openIDModal();
  };
  return (
    <>
      <div
        id={id_R}
        className={`${
          reviewModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="pt-4 px-2 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add Member
                </p>
              </div>
              <div className="mt-[32px] px-6">
                <CustomerProgress step={7} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col">
              <div className="flex items-center justify-end">
                <button
                  onClick={closeReviewModal}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Review and finish
                  </p>
                  <div className="text-[#000] mt-5 text-sm font-normal ">
                    <p>
                      Great work! You've provided all the necessary details for
                      the new customer. Take a moment to review and confirm the
                      settings below:
                    </p>
                  </div>
                </div>
                <div className="flex justify-between mt-10 w-[100%]">
                  <div className="w-[65%]">
                    <div className="">
                      <p className="text-[#000] text-[16px] font-medium">
                        Personal information
                      </p>
                      <div className="flex gap-2 mt-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          First name
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {firstName}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Middle name
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {middleName}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Last name
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {lastName}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Verified Email
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {email}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Verified Phone Number
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {appendCountryCode(phoneNumber)}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Date of Birth{" "}
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {dob}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <div className="">
                      <p className="text-[#000] text-[16px] font-medium">
                        Additional information
                      </p>
                      <div className="flex gap-2 mt-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          KRA PIN
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {kraPin}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Monthly income
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {" "}
                          Ksh. {monthlyIncome}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Job Title
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {" "}
                          {jobTitle}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          County
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {" "}
                          {county}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Sub-county
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {" "}
                          {subCounty}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          Physical address
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {" "}
                          {physicalAddress}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={handleEditAddInfo}
                      data-modal-toggle={id_R}
                      type="button"
                      className="text-[#19C1E4] focus:outline-none font-normal text-[15px] py-1.5"
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className="flex justify-between mt-10 w-[100%]">
                  <div className="w-[65%]">
                    <div className="">
                      <p className="text-[#000] text-[16px] font-medium">
                        Verified Identity Information
                      </p>
                      <div className="flex gap-2 mt-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          National ID Number
                        </div>
                        <div className="text-[#4F4F4F] text-[16px] font-[300]">
                          {identification}
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={handleEditIDInfo}
                      data-modal-toggle={id_R}
                      type="button"
                      className="text-[#19C1E4] focus:outline-none font-normal text-[15px] py-1.5"
                    >
                      Edit
                    </button>
                  </div>
                  <div className="w-[50%]">
                    <div className="">
                      <p className="text-[#000] text-[16px] font-medium">
                        Account type
                      </p>
                      <div className="flex gap-2 mt-2">
                        <div className="text-[#9F9F9F] text-[16px] font-[300]">
                          {productId?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-[95%] items-center justify-between mt-16 mb-5 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                </div>
                <button
                  onClick={handleCompleteRegistration}
                  data-modal-toggle={id_R}
                  type="button"
                  className="text-white bg-[#074073] hover:bg-[#074073] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewFinish;
