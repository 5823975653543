import React, { useEffect, useState } from "react";
import { Modal, Button } from "rsuite";
import formatDate from "../../../hooks/formatDate";

const RoleHistory = ({ openRoleHistory, handleCloseRoleHistory, role }) => {
  return (
    <Modal open={openRoleHistory} size="lg" onClose={handleCloseRoleHistory}>
      <div className="h-[550px]">
        <div className="flex mb-[20px] items-center gap-[10px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
              fill="black"
            />
          </svg>
          <p className="text-[#000] text-[16px] font-bold">Role History</p>
        </div>
        <p className="text-[14px] mb-[40px]">
          ⚠️ This is a log or history of actions performed on the role.
        </p>
        <div className="flex items-center">
          <p className="w-[30%] text-[#000] font-bold">Date</p>
          <p className="w-[35%] text-[#000] font-bold">Action</p>
          <p className="w-[35%] text-[#000] font-bold">Performed By</p>
        </div>
        {role?.users?.length === 0 ? (
          <p>No users for the roles</p>
        ) : (
          <>
            {[1, 2, 3, 4].map((user) => (
              <div key={user} className="flex items-center mb-[20px]">
                <p className="w-[30%] text-[#000]">
                  November 22, 2023 at 11:30 AM
                </p>
                <p className="w-[35%] text-[#074073]">
                  Role name changed to Super Admin
                </p>
                <p className="w-[35%]">Marcel Auja</p>
              </div>
            ))}
          </>
        )}
      </div>
      <Modal.Footer>
        <div className="flex w-full justify-end">
          <button
            onClick={handleCloseRoleHistory}
            className="bg-[#D9D9D9] h-[50px] px-[20px] hover:bg-[#FF3232] hover:text-white"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RoleHistory;
