export const downloadPDF = (pdfContent, name) => {
  // Convert the PDF content string to a Uint8Array
  const blob = new Blob([pdfContent], { type: "application/pdf" });
  // Create a download link
  const downloadLink = document.createElement("a");
  downloadLink.href = window.URL.createObjectURL(blob);

  downloadLink.setAttribute("download", name);
  // Append the link to the body
  document.body.appendChild(downloadLink);
  // Trigger the download
  downloadLink.click();
  // Cleanup
  document.body.removeChild(downloadLink);
};

export const downloadCSV = (pdfContent, name) => {
  // Convert the PDF content string to a Uint8Array
  const blob = new Blob([pdfContent], { type: "text/csv" });
  // Create a download link
  const downloadLink = document.createElement("a");
  downloadLink.href = window.URL.createObjectURL(blob);

  downloadLink.setAttribute("download", name);
  // Append the link to the body
  document.body.appendChild(downloadLink);
  // Trigger the download
  downloadLink.click();
  // Cleanup
  document.body.removeChild(downloadLink);
};
