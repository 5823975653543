import React, { useRef } from "react";

const EditAdditionalInfo = ({
  id_R,
  editAdditionalInfoOpen,
  editAdditionalDetails,
  setEditAdditionalDetails,
  handleEditAdditionalInformation,
  handleCloseEditAdditionalInfo,
}) => {
  return (
    <>
      <div
        id={id_R}
        className={`${
          editAdditionalInfoOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
          <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto min-h-[80%] w-[55vw]">
            <div
              onClick={handleCloseEditAdditionalInfo}
              className="absolute top-[5px] right-[10px] cursor-pointer text-[24px] flex items-center justify-center"
            >
              X
            </div>
            <div className="p-[20px]">
              <div className="flex items-center gap-[10px] mb-[30px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
                    fill="black"
                  />
                </svg>
                <p className="text-[20px] font-bold text-[#000]">
                  Edit customer additional information
                </p>
              </div>
              <p className="text-[#000] text-[15px] mb-[20px]">
                ⚠️ All the additional information below is critical system
                therefore ensure you are providing correct information. Certain
                details cannot be edited as they has been verified through legal
                identity documents.
              </p>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">KRA PIN</label>
                  <input
                    type="text"
                    value={editAdditionalDetails.kra}
                    onChange={(e) =>
                      setEditAdditionalDetails((prev) => ({
                        ...prev,
                        kra: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Monthly Income</label>
                  <input
                    type="text"
                    value={editAdditionalDetails.income}
                    onChange={(e) =>
                      setEditAdditionalDetails((prev) => ({
                        ...prev,
                        income: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Source of Income</label>
                  <input
                    type="text"
                    value={editAdditionalDetails.occupation}
                    onChange={(e) =>
                      setEditAdditionalDetails((prev) => ({
                        ...prev,
                        occupation: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Physical Address</label>
                  <input
                    type="text"
                    value={editAdditionalDetails.physicalAddress}
                    onChange={(e) =>
                      setEditAdditionalDetails((prev) => ({
                        ...prev,
                        physicalAddress: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Landmark</label>
                  <input
                    type="text"
                    value={editAdditionalDetails.landmark}
                    onChange={(e) =>
                      setEditAdditionalDetails((prev) => ({
                        ...prev,
                        landmark: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex items-center mb-[20px]">
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Town</label>
                  <input
                    type="text"
                    value={editAdditionalDetails.town}
                    onChange={(e) =>
                      setEditAdditionalDetails((prev) => ({
                        ...prev,
                        town: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
                <div className="flex w-[50%] flex-col">
                  <label htmlFor="firstname">Country of Residence</label>
                  <input
                    type="text"
                    value={editAdditionalDetails.country}
                    onChange={(e) =>
                      setEditAdditionalDetails((prev) => ({
                        ...prev,
                        country: e.target.value,
                      }))
                    }
                    className="w-[90%] h-[40px] px-[10px] mt-[5px] border-[1px] border-[#9A9A9A] focus:outline-none"
                  />
                </div>
              </div>
              <div className="mt-[100px]">
                <button
                  onClick={handleEditAdditionalInformation}
                  className="bg-[#D9D9D9] h-[50px] px-[20px] rounded-[5px]"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdditionalInfo;
