import React from "react";

const DetailSavedSuccessfully = ({
  id,
  isSavedSuccessfully,
}) => {
  return (
    <>
      <div
        id={id}
        className={`${
          isSavedSuccessfully ? "flex" : "hidden"
        } flex items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="flex items-center justify-center min-h-[50%] w-full">
          <div className="relative bg-white shadow-lg rounded-sm w-[90%] max-w-md mx-auto">
            <div className="flex items-center justify-center mt-10">
              <svg
                width="53"
                height="53"
                viewBox="0 0 53 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="53" height="53" rx="26.5" fill="#BFDFFD" />
                <path
                  d="M11 32L20.7544 38.7761L39.43 17.9347"
                  stroke="#074073"
                  stroke-width="3"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="text-center">
              <div className="mt-4 mb-12 mx-auto flex flex-col items-center justify-center">
                <div className="text-[#060606] text-[16px] font-[600]">
                  <p>Changes saved successfully!</p>
                </div>
                <div className="mt-2 text-[#4A4747] text-[14px] font-normal">
                  <p>The document successfully updated.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailSavedSuccessfully;
