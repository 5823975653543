import React from "react";
import SuspendAccountBar from "../../progressBar/SuspendAccount";

const ReviewSuspension = ({
  id_R,
  reviewSuspensionOpen,
  handleCloseReviewSuspension,
  handleOpenSuspendReason,
  handleOpenDocumentReason,
  handleOpenSuspensionImplications,
  setIsSuspendAccountModalOpen,
  activeTab,
  toggleState,
  note,
  selectedOption,
  firstName,
  lastName,
  productName,
  accountNumber,
}) => {
  const handleNextButtonClick = () => {
    handleCloseReviewSuspension();
    setIsSuspendAccountModalOpen(true);
  };

  const openSuspensionReason = () => {
    handleCloseReviewSuspension();
    handleOpenSuspendReason();
  };
  const openDocumentReason = () => {
    handleCloseReviewSuspension();
    handleOpenDocumentReason();
  };
  const openSuspensionImplications = () => {
    handleCloseReviewSuspension();
    handleOpenSuspensionImplications();
  };

  const handleBackButtonClick = () => {
    handleCloseReviewSuspension();
    handleOpenSuspensionImplications();
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          reviewSuspensionOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="p-4 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-3">
                  Suspend Account
                </p>
              </div>
              <div className="mt-3 px-6">
                <SuspendAccountBar step={4} />
              </div>
            </div>
            <div className="w-[74%] flex flex-col">
              <div className="justify-end rounded-t flex items-end">
                <button
                  onClick={handleCloseReviewSuspension}
                  type="button"
                  className="bg-transparent rounded-lg text-sm p-2.5 ml-auto inline-flex items-center"
                  data-modal-toggle="modal"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.84 12L5.7 8.9L3.9 12H0.2L3.88 6.3L0.1 0.839999H3.94L6.08 3.92L7.88 0.839999H11.58L7.84 6.46L11.68 12H7.84Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div className="w-[95%]">
                <div className="mx-auto mt-10 items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Review and Finish
                  </p>
                  <div className="text-[#000] mt-5 mb-[10px] text-[15px] font-normal">
                    <p>
                      Please review the details below before finalizing the
                      suspension of the account. Ensure all information is
                      accurate and complete to proceed with the suspension
                      process.
                    </p>
                  </div>
                </div>
                <div className="overflow-y-auto gap-[20px] flex flex-col">
                  <div className="flex items-center justify-between mt-[20px] w-full">
                    <div className="w-[47%]">
                      <p className="text-[#000] mb-[5px] font-bold">
                        Account Details
                      </p>
                      <div>
                        <p className="text-[#4F4F4F]">
                          {productName} Account - #{accountNumber}
                        </p>
                        <p className="text-[#4F4F4F]">
                          {firstName?.charAt(0)?.toUpperCase() +
                            firstName?.slice(1)?.toLowerCase()}{" "}
                          {lastName?.charAt(0)?.toUpperCase() +
                            lastName?.slice(1)?.toLowerCase()}
                        </p>
                      </div>
                    </div>
                    <div className="w-[47%]">
                      <p className="text-[#000] mb-[5px] font-bold">
                        Account suspension reasons(s)
                      </p>
                      <div>
                        <p className="text-[#4F4F4F]">{selectedOption}</p>
                        <p
                          onClick={openSuspensionReason}
                          className="cursor-pointer text-[#157D93]"
                        >
                          Edit
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-[20px] w-full">
                    <div className="w-[47%]">
                      <p className="text-[#000] mb-[5px] font-bold">
                        Reason Documentation
                      </p>
                      <div>
                        <p className="text-[#4F4F4F]">{note}</p>
                        <p
                          onClick={openDocumentReason}
                          className="cursor-pointer text-[#157D93]"
                        >
                          Edit
                        </p>
                      </div>
                    </div>
                    <div className="w-[47%]">
                      <p className="text-[#000] mb-[5px] font-bold">
                        Account suspension implication(s)
                      </p>
                      <div>
                        <p className="text-[#4F4F4F] text-[14px] font-[300]">
                          Disable online and mobile account access{"  "}
                          <span style={{ color: "#0187E8" }}>
                            [{toggleState}]
                          </span>
                        </p>
                        <p className="text-[#4F4F4F] text-[14px] font-[300]">
                          Freeze transactions{"  "}
                          <span style={{ color: "#0187E8" }}>
                            [{activeTab}]
                          </span>
                        </p>
                        <p
                          onClick={openSuspensionImplications}
                          className="cursor-pointer text-[#157D93]"
                        >
                          Edit
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-20 mb-8 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#074073] bg-[#7FB8EB]  rounded-[5px] text-[16px] font-[500] px-8 py-2.5"
                  >
                    Back
                  </button>
                </div>

                <button
                  onClick={handleNextButtonClick}
                  type="button"
                  className="text-white bg-[#074073] hover:bg-[#074073] focus:outline-none block font-medium rounded-[5px] text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewSuspension;
