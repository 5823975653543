import React, { useState, useEffect } from "react";
import CustomerProgress from "../../../components/progressBar/CustomerProgress";

const ThirdModal = ({
  id_R,
  closePhoneModal,
  phoneModalOpen,
  openEmailModal,
  phoneNumber,
  handleOpenAccountType,
  setPhoneNumber,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const isPhoneValid = phoneNumber.length >= 10;

    setIsButtonDisabled(!isPhoneValid);
  }, [phoneNumber]);

  const handleBackButtonClick = () => {
    // Close the current modal
    closePhoneModal();

    // Open the next modal
    openEmailModal();
  };
  const handleNextButtonClick = () => {
    if (!isButtonDisabled) {
      // Close the current modal
      closePhoneModal();

      // Open the next modal
      handleOpenAccountType();
    }
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          phoneModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[30%]">
              <div className="pt-4 px-2 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add Member
                </p>
              </div>
              <div className="mt-[32px] px-6">
                <CustomerProgress step={2} />
              </div>
            </div>
            <div className="w-[70%] flex flex-col">
              <div className="flex items-center justify-end">
                <button
                  onClick={closePhoneModal}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <div className="mt-5  mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Enter and verify Member phone number
                  </p>
                  <div className="text-[#000] mt-[10px] text-[15px] font-normal">
                    <p className="w-[99%]">
                      Kindly share Member’s primary phone number for
                      verification.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col justify-center mt-[50px]">
                  <label
                    htmlFor="phoneNumberInput"
                    className="mb-1 text-md font-medium text-[#000]"
                  >
                    Phone number
                  </label>
                  <div className="flex gap-5">
                    <input
                      type="text"
                      id="phoneNumberInput"
                      name="mobileno"
                      placeholder="Enter your phone number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="w-[27vw] h-10 px-4 border border-[1px] border-[#000]"
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-[95%] items-center justify-between mt-64 mb-8 mr-5">
                <button
                  onClick={handleBackButtonClick}
                  data-modal-toggle={id_R}
                  type="button"
                  className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                >
                  Back
                </button>

                <button
                  onClick={handleNextButtonClick}
                  type="button"
                  className={`text-white focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center ${
                    isButtonDisabled
                      ? "bg-[#D9D9D9]"
                      : "bg-[#074073] hover:bg-[#074073]"
                  }`}
                  disabled={isButtonDisabled}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdModal;
