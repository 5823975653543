import React, { useState, useEffect } from "react";
import CustomerProgress from "../../../components/progressBar/CustomerProgress";

const AddressInfo = ({
  id_R,
  closeAddressInfoModal,
  addressInfoModalOpen,
  openAddInfoModal,
  countryOfResidence,
  setCountryOfResidence,
  town,
  setTown,
  physicalAddress,
  setPhysicalAddress,
  openPhotoModal,
  county,
  setCounty,
  subCounty,
  setSubCounty,
}) => {
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [counties, setCounties] = useState([]);
  const [subCounties, setSubCounties] = useState([]);
  const [isInputsFilled, setIsInputsFilled] = useState(false);

  useEffect(() => {
    fetch("http://109.199.120.58:5300/api/v1/country")
      .then((response) => response?.json())
      .then((data) => {
        console.log("API response data:", data); // Log the raw data from the API
        if (data && Array.isArray(data?.data)) {
          setCountries(data?.data);
        } else {
          console.error("Expected array but received:", typeof data?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    fetch("http://109.199.120.58:5300/api/v1/county")
      .then((response) => response.json())
      .then((data) => {
        // console.log("API response data:", data); // Log the raw data from the API
        if (data && Array.isArray(data?.data)) {
          setCounties(data?.data);
        } else {
          console.error("Expected array but received:", typeof data?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    const selectedCounty = counties?.find((c) => c?.county === county);
    if (selectedCounty) {
      setSubCounties(selectedCounty?.sub_counties);
    } else {
      setSubCounties([]);
    }
  }, [county, counties]);

  useEffect(() => {
    const filteredCountryList = countries
      .filter((country) => country?.name?.toLowerCase()?.toLowerCase())
      .sort((a, b) => a?.name?.localeCompare(b?.name));
    setFilteredCountries(filteredCountryList);
  }, [countries]);

  useEffect(() => {
    const areInputsFilled = !!(
      countryOfResidence &&
      county &&
      subCounty &&
      physicalAddress &&
      town
    );
    setIsInputsFilled(areInputsFilled);
  }, [countryOfResidence, county, subCounty, physicalAddress, town]);

  const handleBackButtonClick = () => {
    // Close the current modal
    closeAddressInfoModal();

    // Open the next modal
    openPhotoModal();
  };

  const handleNextButtonClick = () => {
    // Close the current modal
    closeAddressInfoModal();

    // Open the next modal
    openAddInfoModal();
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          addressInfoModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className="w-[28%]">
              <div className="pt-4 px-2 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add Member
                </p>
              </div>
              <div className="mt-[32px] px-6">
                <CustomerProgress step={5} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col">
              <div className="flex items-center justify-end">
                <button
                  onClick={closeAddressInfoModal}
                  type="button"
                  className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle={id_R}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Address information
                  </p>
                  <div className="text-[#000] mt-5 text-[15px] font-normal">
                    <p className="99%">
                      Please enter Member’s current address information. Ensure
                      to provide accurate address information.
                    </p>
                  </div>
                </div>

                <div className="flex w-[50%] mt-10 flex-col justify-center">
                  <label
                    htmlFor="countryInput"
                    className="mb-1 text-md font-medium text-[#000]"
                  >
                    Country of Residence
                  </label>
                  <select
                    id="countryInput"
                    name="countryOfResidence"
                    value={countryOfResidence}
                    onChange={(e) => setCountryOfResidence(e.target.value)}
                    className="w-[90%] h-10 px-4 rounded-[4px] border border-[1px] border-[#000]"
                  >
                    <option value="">Select country</option>
                    {filteredCountries?.map((country) => (
                      <option key={country?.id} value={country?.name}>
                        {country?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex w-full justify-between mt-10 mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="county"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      County
                    </label>
                    <select
                      name="county"
                      id="county"
                      value={county}
                      onChange={(e) => setCounty(e.target.value)}
                      className="w-[90%] h-10 px-4 rounded-[4px] border-[1px] border-[#000]"
                    >
                      <option value="">Select county</option>
                      {counties.map((county) => (
                        <option key={county?.id} value={county?.county}>
                          {county?.county}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="subCountyInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Sub-county
                    </label>

                    <select
                      name="subCounty"
                      id="subCounty"
                      value={subCounty}
                      onChange={(e) => setSubCounty(e.target.value)}
                      className="w-[90%] h-10 px-4 rounded-[4px] border-[1px] border-[#000]"
                    >
                      <option value="">Select sub-county</option>
                      {subCounties.map((subCounty) => (
                        <option key={subCounty} value={subCounty}>
                          {subCounty}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex w-full justify-between mt-10 mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="townInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Town
                    </label>
                    <input
                      type="text"
                      id="townInput"
                      value={town}
                      onChange={(e) => setTown(e.target.value)}
                      className="w-[90%] h-10 px-4 rounded-[4px] border border-[1px] border-[#000]"
                    />
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="physicalAddressInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Physical Address
                    </label>
                    <input
                      type="text"
                      id="physicalAddressInput"
                      value={physicalAddress}
                      onChange={(e) => setPhysicalAddress(e.target.value)}
                      className="w-[90%] h-10 px-4 rounded-[4px] border border-[1px] border-[#000]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-16 mb-10 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                </div>
                <button
                  onClick={handleNextButtonClick}
                  type="button"
                  className={`text-white ${
                    isInputsFilled
                      ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                      : "bg-[#D9D9D9] pointer-events-none"
                  } block font-medium rounded-[5px] text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressInfo;
