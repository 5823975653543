import React from "react";
import TopBar from "../components/topbar/TopBar";
import Leftbar from "../components/leftbar/Leftbar";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <div className="flex h-[100vh] w-[100vw]">
      <div className="w-[15%] h-[100vh]">
        <Leftbar />
      </div>
      <div className="w-[85%] h-[100vh]">
        <div className="h-[100px]">
          <TopBar />
        </div>
        <div
          className="overflow-y-auto"
          style={{ height: "calc(100vh - 100px)" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
