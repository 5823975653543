import React, { useEffect, useState } from "react";
import { Modal, Button } from "rsuite";
import formatDate from "../../../hooks/formatDate";

const ManageUsers = ({
  openUsers,
  handleCloseUsers,
  role,
  handleOpenDeleteRoleUser,
}) => {
  const handleOpenDeleteUser = async (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    handleOpenDeleteRoleUser();
  };

  return (
    <Modal open={openUsers} size="lg" onClose={handleCloseUsers}>
      <div className="w-full">
        <div
          onClick={handleCloseUsers}
          className="flex items-center cursor-pointer mb-[40px] gap-[10px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
              fill="black"
            />
          </svg>
          <p className="text-[16px] font-bold">Manage Role users</p>
        </div>
        <p className="mb-[30px] text-[#000] text-[14px]">
          ⚠️ This is list of users that have been assigned this role
        </p>
        <p className="mb-[30px] text-[#000] text-[14px]">
          There are {role?.users?.length} user(s) under this role
        </p>
        <div className="h-[430px] overflow-y-auto">
          <div className="flex items-center">
            <p className="w-[20%] text-[#000] font-bold">Userid</p>
            <p className="w-[25%] text-[#000] font-bold">Full name</p>
            <p className="w-[35%] text-[#000] font-bold">Date Assigned</p>
            <p className="w-[20%] text-[#000] font-bold">Action</p>
          </div>
          {role?.users?.length === 0 ? (
            <p>No users for the roles</p>
          ) : (
            <>
              {role?.users?.map((user) => (
                <div key={user?.id} className="flex items-center mb-[10px]">
                  <p className="w-[20%] text-[#19C1E4]">{user?.public_id}</p>
                  <p className="w-[25%]">
                    {user?.firstname} {user?.lastname}
                  </p>
                  <p className="w-[35%]">{formatDate(user?.createdAt)}</p>
                  <p
                    onClick={() => handleOpenDeleteUser(user)}
                    className="w-[20%] underline cursor-pointer text-[#19C1E4]"
                  >
                    Remove user
                  </p>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <Modal.Footer>
        <div className="flex w-full mt-[10px] justify-between">
          <button
            onClick={handleCloseUsers}
            className="bg-[#D9D9D9] rounded h-[50px] px-[10px] hover:bg-[#074073] hover:text-white"
          >
            Save Changes
          </button>
          <button
            onClick={handleCloseUsers}
            className="bg-[#D9D9D9] rounded h-[50px] px-[20px] hover:bg-[#FF3232] hover:text-white"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageUsers;
