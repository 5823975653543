import React from "react";
import "../../App.css";
import DonutChart from "../charts/Piechart";

function formatNumber(number) {
  const million = 1000000;

  if (number >= million) {
    return (
      (number / million).toLocaleString("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }) + "m"
    );
  } else {
    return (
      (number / 1000).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }) + "k"
    );
  }
}

export const LoanPortfolio = ({ data }) => {
  const COLORS = [
    "#074073",
    "#FF7070",
    "#88C1F4",
    "#00DEFD",
    "#622977",
    "#FFCE1E",
  ];
  const mapped = {
    active_loans: data?.active_loans,
    approved_loans: data?.approved_loans,
    defaulted_loans: data?.defaulted_loans,
    overdue_loans: data?.overdue_loans,
    pending_loans: data?.pending_loans,
    rejected_loans: data?.rejected_loans,
  };

  const totalLoans = Object.values(mapped).reduce(
    (acc, curr) => acc + (curr ? curr : 0),
    0
  );

  const loanData = Object.keys(mapped).map((key) => {
    let name = key.replace(/_/g, " "); // Replace underscores with spaces
    name = name.charAt(0).toUpperCase() + name.slice(1); // Capitalize the first letter
    const count = mapped[key] ? mapped[key] : 0;
    const percentage = ((count / totalLoans) * 100).toFixed(2); // Calculate percentage
    return {
      name: name,
      value: count,
      percentage: percentage,
    };
  });
  const nameMappings = {
    "Active loans": "Active loans",
    "Approved loans": "Approved loans",
    "Defaulted loans": "Defaulted loans",
    "Overdue loans": "Overdue loans",
    "Pending loans": "Pending loans",
    "Rejected loans": "Rejected loans"
};

const transformedData = {};

loanData.forEach(item => {
    const newName = nameMappings[item.name];
    if (newName) {
        transformedData[newName] = {
            count: item.value,
            percentage: item.percentage
        };
    }
});

  return (
    <div className="px-2 py-5 flex justify-between">
      <div className="flex-col w-[30%]">
        <div className="flex-col flex items-center justify-center w-[100%] bg-[#F7FBFF] rounded-[10px] border-[1px] border-[#88C1F4]">
          <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#BFE0FD]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                stroke="#466F94"
              />
              <path
                d="M16.4997 5.5C15.7703 5.5 15.0709 5.21027 14.5551 4.69454C14.0394 4.17882 13.7497 3.47935 13.7497 2.75M16.4997 7.33333C15.7703 7.33333 15.0709 7.62306 14.5551 8.13879C14.0394 8.65451 13.7497 9.35399 13.7497 10.0833M5.49967 5.5C6.22902 5.5 6.92849 5.21027 7.44422 4.69454C7.95994 4.17882 8.24967 3.47935 8.24967 2.75M5.49967 7.33333C6.22902 7.33333 6.92849 7.62306 7.44422 8.13879C7.95994 8.65451 8.24967 9.35399 8.24967 10.0833M4.58301 18.689H6.65467C7.58051 18.689 8.51826 18.7862 9.41934 18.9713C11.0281 19.3018 12.6834 19.3385 14.3052 19.0795C15.1008 18.9512 15.8818 18.7541 16.5895 18.4131C17.2275 18.1042 18.0094 17.6706 18.5347 17.1838C19.059 16.698 19.6053 15.9032 19.9922 15.2817C20.3258 14.7482 20.1645 14.0947 19.6383 13.6977C19.3427 13.4827 18.9865 13.3668 18.6208 13.3668C18.2552 13.3668 17.899 13.4827 17.6033 13.6977L15.9469 14.949C15.3053 15.4348 14.604 15.8812 13.7689 16.0142C13.6681 16.0297 13.5627 16.0444 13.4527 16.0572M13.4527 16.0572C13.4191 16.0611 13.3855 16.0648 13.3518 16.0682M13.4527 16.0572C13.5994 16.0176 13.7339 15.942 13.8441 15.8372C13.9822 15.7178 14.095 15.5718 14.1758 15.408C14.2566 15.2442 14.3038 15.0659 14.3146 14.8835C14.3253 14.7012 14.2995 14.5186 14.2385 14.3464C14.1775 14.1742 14.0827 14.016 13.9596 13.8811C13.8401 13.7485 13.701 13.635 13.5471 13.5447C10.9832 12.0147 6.99292 13.1798 4.58301 14.8894M13.4527 16.0572C13.4195 16.0645 13.3857 16.0682 13.3518 16.0682M13.3518 16.0682C12.799 16.1238 12.2421 16.125 11.689 16.0719"
                stroke="#466F94"
                stroke-linecap="round"
              />
              <path
                d="M4.58301 14.2083C4.58301 13.4489 3.9674 12.8333 3.20801 12.8333C2.44862 12.8333 1.83301 13.4489 1.83301 14.2083V18.7916C1.83301 19.551 2.44862 20.1666 3.20801 20.1666C3.9674 20.1666 4.58301 19.551 4.58301 18.7916V14.2083Z"
                stroke="#466F94"
              />
            </svg>
          </div>
          <div className="flex justify-center mt-3 gap-2">
            <p className="text-[#074073] text-[24px] font-bold">
              Ksh.{" "}
              {Number(data?.total_loan_volume || "0.00")?.toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </p>
          </div>
          <p className="mt-3 mb-3 text-[#074073] text-[12px]">
            Total loan volume
          </p>
        </div>
        <div className="flex mt-4 gap-[10px] w-[100%]">
          <div className="flex-col w-[90%] flex items-center justify-center px-3 border-[#E6E6E6] border-[1px] rounded-[10px]">
            <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#BFE0FD]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                  stroke="#466F94"
                />
                <path
                  d="M16.4997 5.5C15.7703 5.5 15.0709 5.21027 14.5551 4.69454C14.0394 4.17882 13.7497 3.47935 13.7497 2.75M16.4997 7.33333C15.7703 7.33333 15.0709 7.62306 14.5551 8.13879C14.0394 8.65451 13.7497 9.35399 13.7497 10.0833M5.49967 5.5C6.22902 5.5 6.92849 5.21027 7.44422 4.69454C7.95994 4.17882 8.24967 3.47935 8.24967 2.75M5.49967 7.33333C6.22902 7.33333 6.92849 7.62306 7.44422 8.13879C7.95994 8.65451 8.24967 9.35399 8.24967 10.0833M4.58301 18.689H6.65467C7.58051 18.689 8.51826 18.7862 9.41934 18.9713C11.0281 19.3018 12.6834 19.3385 14.3052 19.0795C15.1008 18.9512 15.8818 18.7541 16.5895 18.4131C17.2275 18.1042 18.0094 17.6706 18.5347 17.1838C19.059 16.698 19.6053 15.9032 19.9922 15.2817C20.3258 14.7482 20.1645 14.0947 19.6383 13.6977C19.3427 13.4827 18.9865 13.3668 18.6208 13.3668C18.2552 13.3668 17.899 13.4827 17.6033 13.6977L15.9469 14.949C15.3053 15.4348 14.604 15.8812 13.7689 16.0142C13.6681 16.0297 13.5627 16.0444 13.4527 16.0572M13.4527 16.0572C13.4191 16.0611 13.3855 16.0648 13.3518 16.0682M13.4527 16.0572C13.5994 16.0176 13.7339 15.942 13.8441 15.8372C13.9822 15.7178 14.095 15.5718 14.1758 15.408C14.2566 15.2442 14.3038 15.0659 14.3146 14.8835C14.3253 14.7012 14.2995 14.5186 14.2385 14.3464C14.1775 14.1742 14.0827 14.016 13.9596 13.8811C13.8401 13.7485 13.701 13.635 13.5471 13.5447C10.9832 12.0147 6.99292 13.1798 4.58301 14.8894M13.4527 16.0572C13.4195 16.0645 13.3857 16.0682 13.3518 16.0682M13.3518 16.0682C12.799 16.1238 12.2421 16.125 11.689 16.0719"
                  stroke="#466F94"
                  stroke-linecap="round"
                />
                <path
                  d="M4.58301 14.2083C4.58301 13.4489 3.9674 12.8333 3.20801 12.8333C2.44862 12.8333 1.83301 13.4489 1.83301 14.2083V18.7916C1.83301 19.551 2.44862 20.1666 3.20801 20.1666C3.9674 20.1666 4.58301 19.551 4.58301 18.7916V14.2083Z"
                  stroke="#466F94"
                />
              </svg>
            </div>
            <p className="mt-3 text-[#074073] text-[24px] font-bold">
              Ksh.{" "}
              {formatNumber(Number(data?.total_loan_outstanding || "0.00"))}
            </p>
            <div className="mt-3 mb-3 text-[#074073] text-[12px] ">
              <p>Total outstanding</p>
              <p>balance of loans</p>
            </div>
          </div>
          <div className="flex-col flex items-center justify-center px-3  w-[90%] border-[#E6E6E6] border-[1px] rounded-[10px]">
            <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#BFE0FD]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                  stroke="#466F94"
                />
                <path
                  d="M16.4997 5.5C15.7703 5.5 15.0709 5.21027 14.5551 4.69454C14.0394 4.17882 13.7497 3.47935 13.7497 2.75M16.4997 7.33333C15.7703 7.33333 15.0709 7.62306 14.5551 8.13879C14.0394 8.65451 13.7497 9.35399 13.7497 10.0833M5.49967 5.5C6.22902 5.5 6.92849 5.21027 7.44422 4.69454C7.95994 4.17882 8.24967 3.47935 8.24967 2.75M5.49967 7.33333C6.22902 7.33333 6.92849 7.62306 7.44422 8.13879C7.95994 8.65451 8.24967 9.35399 8.24967 10.0833M4.58301 18.689H6.65467C7.58051 18.689 8.51826 18.7862 9.41934 18.9713C11.0281 19.3018 12.6834 19.3385 14.3052 19.0795C15.1008 18.9512 15.8818 18.7541 16.5895 18.4131C17.2275 18.1042 18.0094 17.6706 18.5347 17.1838C19.059 16.698 19.6053 15.9032 19.9922 15.2817C20.3258 14.7482 20.1645 14.0947 19.6383 13.6977C19.3427 13.4827 18.9865 13.3668 18.6208 13.3668C18.2552 13.3668 17.899 13.4827 17.6033 13.6977L15.9469 14.949C15.3053 15.4348 14.604 15.8812 13.7689 16.0142C13.6681 16.0297 13.5627 16.0444 13.4527 16.0572M13.4527 16.0572C13.4191 16.0611 13.3855 16.0648 13.3518 16.0682M13.4527 16.0572C13.5994 16.0176 13.7339 15.942 13.8441 15.8372C13.9822 15.7178 14.095 15.5718 14.1758 15.408C14.2566 15.2442 14.3038 15.0659 14.3146 14.8835C14.3253 14.7012 14.2995 14.5186 14.2385 14.3464C14.1775 14.1742 14.0827 14.016 13.9596 13.8811C13.8401 13.7485 13.701 13.635 13.5471 13.5447C10.9832 12.0147 6.99292 13.1798 4.58301 14.8894M13.4527 16.0572C13.4195 16.0645 13.3857 16.0682 13.3518 16.0682M13.3518 16.0682C12.799 16.1238 12.2421 16.125 11.689 16.0719"
                  stroke="#466F94"
                  stroke-linecap="round"
                />
                <path
                  d="M4.58301 14.2083C4.58301 13.4489 3.9674 12.8333 3.20801 12.8333C2.44862 12.8333 1.83301 13.4489 1.83301 14.2083V18.7916C1.83301 19.551 2.44862 20.1666 3.20801 20.1666C3.9674 20.1666 4.58301 19.551 4.58301 18.7916V14.2083Z"
                  stroke="#466F94"
                />
              </svg>
            </div>
            <p className="mt-3 text-[#074073] text-[24px] font-bold">
              Ksh. {formatNumber(Number(data?.total_loan_deafulted || "0.00"))}
            </p>
            <div className="mt-3 mb-3 text-[#074073] text-[12px] ">
              <p>Total amount of</p>
              <p>loans defaulted</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-[38%] border border-[#f00]">
        <DonutChart loanData={loanData} />
      </div> */}
      <div className="flex items-center">
        <div className="">
          <DonutChart loanData={loanData} />
        </div>
        <div className="overflow-y-auto flex flex-col justify-center h-[320px]">
          {transformedData &&
            Object.entries(transformedData).map(
              ([name, { count, percentage }], index) => (
                <div className="flex items-center gap-[10px]" key={index}>
                  <div className="flex items-center mb-[10px] gap-[10px]">
                    <div
                      className="h-[20px] w-[20px]"
                      style={{ backgroundColor: `${COLORS[index]}` }}
                    ></div>
                    <p className="text-[#074073] text-[14px]">{name}</p>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
      <div className="w-[30%] bg-white">
        <p className="text-[#074073] text-[15px] font-medium px-2">
          Loans offered
        </p>
        <div className="px-2">
          <button className="w-full h-[49px] rounded-[30px] border-[#EB9973] text-[#074073]  text-[14px] mt-3 border-[0.5px]">
            Digital loans
          </button>
          <button className="w-full h-[49px] rounded-[30px] border-[#EB9973] text-[#074073]  text-[14px]  mt-3 border-[0.5px]">
            Business loans
          </button>
          <button className="w-full h-[49px] rounded-[30px] border-[#EB9973] text-[#074073]  text-[14px] mt-3 border-[0.5px]">
            Mortgage loans
          </button>
          <button className="w-full h-[49px] rounded-[30px] border-[#EB9973] text-[#074073]  text-[14px] mt-3 border-[0.5px]">
            Personal loans
          </button>
        </div>
      </div>
    </div>
  );
};
