import React, { useRef, useState } from "react";

const NoteToReject = ({
  id,
  noteToReject,
  handleCloseNoteToReject,
  openRejectAccountApproval,
}) => {
  const [isInputsFilled, setIsInputsFilled] = useState(false);
  const [note, setNote] = useState("");
  const fileInputFront = useRef(null);
  const [frontFile, setFrontFile] = useState(null);

  // Function to open file picker
  const openFrontFilePicker = () => {
    fileInputFront.current.click();
  };

  // Function to handle file selection
  const handleFrontFileChange = (e) => {
    const file = e.target.files[0];
    setFrontFile(file);
  };

  // Function to delete the selected file
  const deleteFile = () => {
    setFrontFile(null); // Remove file
  };

  // Truncate file name for display
  const truncateFileName = (fileName) => {
    const maxLength = 20;
    const extensionIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    const truncatedName =
      nameWithoutExtension.length > maxLength
        ? `${nameWithoutExtension.substring(0, maxLength)}...`
        : nameWithoutExtension;
    return `${truncatedName}${fileName.substring(extensionIndex)}`;
  };

  const handleInputChange = (e) => {
    setNote(e.target.value);
    setIsInputsFilled(e.target.value.trim() !== "");
  };

  return (
    <>
      <div
        id={id}
        className={`${
          noteToReject ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[60%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className="flex flex-col justify-between w-full px-5">
              <div>
                <div className="flex items-center justify-end">
                  <button
                    onClick={handleCloseNoteToReject}
                    type="button"
                    className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-toggle={id}
                  >
                    <svg
                      width="61"
                      height="43"
                      viewBox="0 0 61 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_13891_3896)">
                        <path
                          d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_13891_3896">
                          <rect width="61" height="43" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <div className="mt-3 mx-auto">
                  <p className="text-[#000] text-xl font-bold">
                    Add note on why you wish to reject this document
                  </p>
                  <div className="text-[#000] mt-3 text-sm font-normal">
                    <p>
                      Add any relevant comments or observations about your
                      decision. This can include details about why the <br />{" "}
                      document was rejected, any discrepancies noted, or any
                      actions taken to verify the user's identity. These notes{" "}
                      <br /> will be stored in the user's KYC record for future
                      reference and may assist in any follow-up actions.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col mt-8 rounded-[4px]">
                  <textarea
                    className="w-full h-32 border-[0.7px] border-[#000000] rounded-[4px] p-2 resize-none"
                    placeholder="Enter your note here..."
                    value={note}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Div that opens the file picker */}
                <div
                  className="flex items-center mt-4 w-[379px] rounded-[2px] border-[1px] border-[#000000] cursor-pointer"
                  onClick={openFrontFilePicker}
                >
                  <div className="flex px-4 py-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7497 5.00065V14.584C13.7497 16.4257 12.258 17.9173 10.4163 17.9173C8.57467 17.9173 7.08301 16.4257 7.08301 14.584V4.16732C7.08301 3.61478 7.3025 3.08488 7.6932 2.69418C8.0839 2.30348 8.61381 2.08398 9.16634 2.08398C9.71888 2.08398 10.2488 2.30348 10.6395 2.69418C11.0302 3.08488 11.2497 3.61478 11.2497 4.16732V12.9173C11.2497 13.3757 10.8747 13.7507 10.4163 13.7507C9.95801 13.7507 9.58301 13.3757 9.58301 12.9173V5.00065H8.33301V12.9173C8.33301 13.4699 8.5525 13.9998 8.9432 14.3905C9.3339 14.7812 9.86381 15.0007 10.4163 15.0007C10.9689 15.0007 11.4988 14.7812 11.8895 14.3905C12.2802 13.9998 12.4997 13.4699 12.4997 12.9173V4.16732C12.4997 2.32565 11.008 0.833984 9.16634 0.833984C7.32467 0.833984 5.83301 2.32565 5.83301 4.16732V14.584C5.83301 17.1173 7.88301 19.1673 10.4163 19.1673C12.9497 19.1673 14.9997 17.1173 14.9997 14.584V5.00065H13.7497Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="text-[#000000] text-[16] font-normal">
                    <p>Attach a file (.pdf, .docx, .doc, .jpeg, .png)</p>
                  </div>
                </div>

                {/* Hidden input for file upload */}
                <input
                  type="file"
                  ref={fileInputFront}
                  onChange={handleFrontFileChange}
                  className="hidden"
                  accept=".pdf, .docx, .doc, .jpeg, .png"
                />

                {/* Display uploaded file */}
                {frontFile && (
                  <div className="mt-4 flex items-center space-x-3">
                    <div onClick={deleteFile}>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.69967 15.584L10.9997 12.284L14.2997 15.584L15.583 14.3007L12.283 11.0007L15.583 7.70065L14.2997 6.41732L10.9997 9.71732L7.69967 6.41732L6.41634 7.70065L9.71634 11.0007L6.41634 14.3007L7.69967 15.584ZM10.9997 20.1673C9.73162 20.1673 8.53995 19.9265 7.42467 19.445C6.3094 18.9634 5.33926 18.3105 4.51426 17.4861C3.68926 16.6611 3.03629 15.6909 2.55534 14.5757C2.0744 13.4604 1.83362 12.2687 1.83301 11.0007C1.83301 9.7326 2.07379 8.54093 2.55534 7.42565C3.0369 6.31037 3.68987 5.34023 4.51426 4.51523C5.33926 3.69023 6.3094 3.03726 7.42467 2.55632C8.53995 2.07537 9.73162 1.8346 10.9997 1.83398C12.2677 1.83398 13.4594 2.07476 14.5747 2.55632C15.69 3.03787 16.6601 3.69085 17.4851 4.51523C18.3101 5.34023 18.9634 6.31037 19.4449 7.42565C19.9265 8.54093 20.167 9.7326 20.1663 11.0007C20.1663 12.2687 19.9256 13.4604 19.444 14.5757C18.9625 15.6909 18.3095 16.6611 17.4851 17.4861C16.6601 18.3111 15.69 18.9643 14.5747 19.4459C13.4594 19.9275 12.2677 20.1679 10.9997 20.1673Z"
                          fill="#797979"
                        />
                      </svg>
                    </div>
                    <p className="text-sm">
                      {truncateFileName(frontFile?.name)}
                    </p>
                  </div>
                )}
              </div>

              <div className="flex items-center justify-between mt-10 mb-8">
                <div className="flex items-center gap-[20px]">
                  <button
                    onClick={openRejectAccountApproval}
                    type="button"
                    className={`text-white ${
                      isInputsFilled
                        ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                        : "bg-[#D9D9D9] pointer-events-none"
                    } block font-medium rounded-[5px] text-sm px-12 py-2.5 text-center`}
                  >
                    Next
                  </button>
                </div>
                <button
                  onClick={handleCloseNoteToReject}
                  data-modal-toggle={id}
                  type="button"
                  className="text-[#FFF] bg-[#D9D9D9] hover:bg-[#D9D9D9] focus:outline-none font-medium rounded-[5px] text-sm px-12 py-2.5 text-center"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoteToReject;
