import React from "react";
import { Modal } from "rsuite";

const EmailPrompt = ({
  emailPromptOpen,
  handleCloseEmailPrompt,
  handleOpenEmailOtp,
}) => {
  const handleProceed = () => {
    handleCloseEmailPrompt();
    handleOpenEmailOtp();
  };

  return (
    <Modal size="sm" open={emailPromptOpen} onClose={handleCloseEmailPrompt}>
      <p className="text-center text-[20px] text-[#074073] font-bold">
        Warning
      </p>
      <div className="h-[300px] w-full flex flex-col gap-[40px] items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="115"
          height="115"
          viewBox="0 0 115 115"
          fill="none"
        >
          <g clip-path="url(#clip0_7909_7457)">
            <path
              d="M57.5002 9.5835C83.9645 9.5835 105.417 31.0358 105.417 57.5002C105.417 83.9645 83.9645 105.417 57.5002 105.417C31.0358 105.417 9.5835 83.9645 9.5835 57.5002C9.5835 31.0358 31.0358 9.5835 57.5002 9.5835ZM57.5002 71.8752C56.2293 71.8752 55.0106 72.38 54.1119 73.2786C53.2133 74.1772 52.7085 75.396 52.7085 76.6668C52.7085 77.9377 53.2133 79.1564 54.1119 80.0551C55.0106 80.9537 56.2293 81.4585 57.5002 81.4585C58.771 81.4585 59.9898 80.9537 60.8884 80.0551C61.787 79.1564 62.2918 77.9377 62.2918 76.6668C62.2918 75.396 61.787 74.1772 60.8884 73.2786C59.9898 72.38 58.771 71.8752 57.5002 71.8752ZM57.5002 28.7502C56.3265 28.7503 55.1938 29.1812 54.3167 29.9611C53.4397 30.741 52.8794 31.8156 52.742 32.9812L52.7085 33.5418V62.2918C52.7099 63.5131 53.1775 64.6878 54.0159 65.5759C54.8543 66.464 56.0001 66.9984 57.2193 67.07C58.4385 67.1415 59.6391 66.7449 60.5756 65.961C61.5122 65.1771 62.114 64.0652 62.2583 62.8525L62.2918 62.2918V33.5418C62.2918 32.271 61.787 31.0522 60.8884 30.1536C59.9898 29.255 58.771 28.7502 57.5002 28.7502Z"
              fill="#074073"
            />
          </g>
          <defs>
            <clipPath id="clip0_7909_7457">
              <rect width="115" height="115" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p className="w-[70%] text-center">
          Changing the email address will require revivification, do you wish to
          proceed?
        </p>
      </div>
      <div className="flex items-center w-[80%] mx-auto text-white justify-between">
        <button
          onClick={handleProceed}
          className="h-[50px] px-[30px] bg-[#074073] rounded-[5px]"
        >
          Proceed
        </button>
        <button
          onClick={handleCloseEmailPrompt}
          className="h-[50px] px-[30px] bg-[#17C6C6] rounded-[5px]"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default EmailPrompt;
