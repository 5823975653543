import React, { useState } from "react";
import RoleProgress from "../../progressBar/RoleProgress";

const SetPermissions = ({
  id,
  closePermissionsModal,
  permissionsModalOpen,
  openPermissionsModal,
  openReviewPermissionsModal,
  openModal,
  roleName,
  setRoleName,
  roleDescription,
  setRoleDescription,
  modules,
  selectedPermissions,
  setSelectedPermissions,
}) => {
  const [isInputsFilled, setIsInputsFilled] = useState(true);

  const handleCheckboxChange = (permissionId) => {
    const isSelected = selectedPermissions.includes(permissionId);

    if (isSelected) {
      // If permission is already selected, remove it
      setSelectedPermissions((prevSelected) =>
        prevSelected.filter((id) => id !== permissionId)
      );
    } else {
      // If permission is not selected, add it
      setSelectedPermissions((prevSelected) => [...prevSelected, permissionId]);
    }
  };

  const handleNextButtonClick = () => {
    closePermissionsModal();
    openReviewPermissionsModal();
  };

  const handleBackButtonClick = () => {
    closePermissionsModal();
    openModal();
  };
  return (
    <div
      id={id}
      className={`${
        permissionsModalOpen ? "flex" : "hidden"
      } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
    >
      <div className="relative flex fixed min-h-[80%] w-[65%]">
        <div className="relative flex bg-white shadow w-full mx-auto">
          <div className=" w-[28vw]">
            <div className="py-4 rounded-t">
              <p className="text-[20px] font-bold text-[#000] ml-5">
                Create a new role
              </p>
            </div>
            <div className="mt-6 px-6">
              <RoleProgress step={2} />
            </div>
          </div>
          <div className="w-[72vw] flex flex-col justify-between">
            <div>
              <div className="mt-5 mx-auto items-center">
                <p className="text-[#000] text-xl font-bold">
                  Set Permissions{" "}
                </p>
                <div className="text-[#000] mt-[40px] text-sm font-normal ">
                  <p>
                    Set permissions for the role you have just entered to ensure
                    precise access control.
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[10px] justify-between mt-[40px] mr-4">
                <p className="text-[#000] font-bold">Select Permissions</p>
                <div className="h-[320px] w-[90%] border-2 p-[10px] overflow-y-auto">
                  {modules &&
                    modules?.map((module) => (
                      <div key={module.id}>
                        <p className="text-[#000] font-bold">{module?.name}</p>
                        {module &&
                          module?.permissions.map((permission) => (
                            <div className="w-full flex items-center my-[10px]">
                              <div className="w-[40%] mb-[20px]">
                                <p className="pl-[20px]">{permission?.name}</p>
                              </div>
                              <div className="w-[60%] flex justify-center">
                                <input
                                  type="checkbox"
                                  className="h-[20px] w-[20px]"
                                  checked={selectedPermissions?.includes(
                                    permission?.id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(permission?.id)
                                  }
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="flex w-[90%] items-center justify-between mt-[60px] mb-8 pr-[15px]">
              <div className="flex items-center justify-between gap-[20px] w-[16vw]">
                <button
                  onClick={handleBackButtonClick}
                  data-modal-toggle={id}
                  type="button"
                  className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                >
                  Back
                </button>
                <button
                  onClick={handleNextButtonClick}
                  data-modal-toggle={id}
                  type="button"
                  className="text-white bg-[#074073] hover:bg-[#074073] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                >
                  Next
                </button>
              </div>
              <button
                onClick={closePermissionsModal}
                data-modal-toggle={id}
                type="button"
                className="text-gray-500 bg-[#D9D9D9] hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[5px] border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPermissions;
