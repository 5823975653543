import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Modal from "../../components/Modals/modals";
import { toast } from "react-toastify";
import RolesModal from "../../components/Modals/rolesModal";
import AdminUser from "../../components/Modals/editAdminUser/userAdmin";
import ManageUsername from "../../components/Modals/editAdminUser/manageUsername";
import ManageContactInfo from "../../components/Modals/editAdminUser/manageContactInfo";
import TwoFactorAuth from "../../components/Modals/editAdminUser/twoFactorAuth";
import SignInActivity from "../../components/Modals/editAdminUser/signInActivity";
import EditAdminRoles from "../../components/Modals/editAdminUser/editRoles";
import UserInfoModal from "../../components/Modals/userInfoModal";
import UserAddedSuccessfully from "../../components/Modals/userSuccessfullyAdded";
import ReviewAndFinish from "../../components/Modals/reviewAndFinishModal";
import { getAddressInfo } from "../../sdk/transactions/transactions";
import { NavLink } from "react-router-dom";
import AddRole from "../../components/Modals/permissions/AddRole";
import SetPermissions from "../../components/Modals/permissions/SetPermissions";
import FinishPermissions from "../../components/Modals/permissions/FinishPermissions";
import {
  deleteRole,
  getModules,
  getRole,
  getRoles,
  updateUsername,
  updateContactInfo,
  updateRole,
} from "../../sdk/users/modules";
import { fetchAdminUsers } from "../../sdk/transactions/transactions";
import ViewModal from "../../components/Modals/view-modal/ViewModal";
import ManageUsers from "../../components/Modals/manage-users/ManageUsers";
import ManageRole from "../../components/Modals/manage-role/ManageRole";
import ManagePermissions from "../../components/Modals/manage-permissions/ManagePermissions";
import DeleteRole from "../../components/Modals/delete-role/DeleteRole";
import AddRoleSuccess from "../../components/success-modals/AddRoleSuccess";
import DeleteRoleSuccess from "../../components/success-modals/DeleteRoleSuccess";
import DeleteUser from "../../components/Modals/delete-role-user/DeleteUser";
import DeleteRoleUserSuccess from "../../components/success-modals/DeleteRoleUserSuccess";
import RoleHistory from "../../components/Modals/role-history/RoleHistory";

const UserManagement = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [searchUsers, setSearchUsers] = useState("");
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rolesModalOpen, setRolesModalOpen] = useState(false);
  const [permissionsModalOpen, setPermissionsModalOpen] = useState(false);
  const [reviewPermissionsModalOpen, setReviewPermissionsModalOpen] =
    useState(false);
  const [userInfoModalOpen, setUserInfoModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  // const today = moment();
  // const [fromDate, setFromDate] = useState(today.clone().startOf("month"));
  // const [customerFocusedInput, setCustomerFocusedInput] = useState(null);
  // const [toDate, setToDate] = useState(today);

  const [modules, setModules] = useState([]);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({});

  const fetchModules = async () => {
    const result = await getModules();
    setModules(result.data);
  };

  const fetchRoles = async () => {
    const result = await getRoles();
    setRoles(result.data);
  };

  const fetchRole = async (id) => {
    const result = await getRole(id);
    setRole(result.data);
  };

  useEffect(() => {
    fetchModules();
    fetchRoles();
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileno, setMobilenoName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [country, setCountry] = useState("");
  const [county, setCounty] = useState("");
  const [subCounty, setSubCounty] = useState("");
  const [address, setAddress] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [popoverOpen, setPopoverOpen] = useState(null);

  const [selectedRole, setSelectedRole] = useState({
    id: "",
    name: "",
  });

  const [open, setOpen] = React.useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openRole, setOpenRole] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);
  const [openDeleteRole, setOpenDeleteRole] = useState(false);

  const handleOpenDeleteRole = () => {
    setOpenDeleteRole(true);
  };

  const handleCloseDeleteRole = () => {
    setOpenDeleteRole(false);
  };

  const handleOpenUsers = () => {
    setOpenUsers(true);
  };

  const handleCloseUsers = () => {
    setOpenUsers(false);
  };

  const handleOpenPermissions = () => {
    setOpenPermissions(true);
  };

  const handleClosePermissions = () => {
    setOpenPermissions(false);
  };

  const handleOpenRole = () => {
    setOpenRole(true);
  };

  const handleCloseRole = () => {
    setOpenRole(false);
  };

  const handleOpen = () => {
    setPopoverOpen(null);
    setOpen(true);
  };
  const handleClose = () => {
    setPopoverOpen(null);
    setOpen(false);
  };

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    const roleName = e.target.getAttribute("data-name") || "";

    setSelectedRole({
      id: roleId,
      name: roleName,
    });
  };

  const handleOpenPopover = (id) => {
    fetchRole(id);
    setPopoverOpen((prevPopover) => (prevPopover === id ? null : id));
  };

  const handleSearchUsers = (e) => {
    setSearchUsers(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const openPermissionsModal = () => {
    setPermissionsModalOpen(true);
  };
  const openReviewPermissionsModal = () => {
    setReviewPermissionsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closePermissionsModal = () => {
    setPermissionsModalOpen(false);
  };
  const closeReviewPermissionsModal = () => {
    setReviewPermissionsModalOpen(false);
  };

  const openRolesModal = () => {
    setRolesModalOpen(true);
  };

  const closeRolesModal = () => {
    setRolesModalOpen(false);
  };

  const openUserInfoModal = () => {
    setUserInfoModalOpen(true);
  };

  const closeUserInfoModal = () => {
    setUserInfoModalOpen(false);
  };

  const openReviewModal = () => {
    setReviewModalOpen(true);
  };

  const closeReviewModal = () => {
    setReviewModalOpen(false);
  };

  const appendCountryCode = (number) => {
    if (number.startsWith("0")) {
      number = number.slice(1);
    }
    return "+254" + number;
  };

  const handleNextButtonClick = async () => {
    try {
      const response = await getAddressInfo({
        email,
        status: "Active",
        username: userName,
        firstname: firstName,
        lastname: lastName,
        job_title: jobTitle,
        department: department,
        phone: appendCountryCode(mobileno),
        office_phone: officePhone,
        country,
        county,
        subcounty: subCounty,
        address,
        role_id: selectedRole?.id,
      });

      if (response.statusCode === 201) {
        closeReviewModal();
        getAdminUsers();
        setUserSuccessOpen(true);
        setTimeout(() => {
          setUserSuccessOpen(false);
        }, 2000);
      } else if (response.statusCode === 409) {
        toast.error(
          "The username or email you entered already exists. Please enter a different username or email."
        );
      }
    } catch (error) {
      const errorMessage =
        error?.message === "Unique constraint violation."
          ? "The username you entered already exists. Please enter a different username"
          : error?.message;
      toast.error(
        errorMessage ||
          "The username or email you entered already exists. Please enter a different username or email."
      );
    }
  };

  const checkPermission = (role, permission) => {
    return role.permissions.some(
      (rolePermission) => rolePermission.id === permission.id
    );
  };

  const popoverRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [addRoleSuccessOpen, setAddRoleSuccessOpen] = useState(false);
  const [userSuccessOpen, setUserSuccessOpen] = useState(false);
  const [deleteRoleSuccessOpen, setDeleteRoleSuccessOpen] = useState(false);

  const handleUserAddedSuccessfully = () => {
    setUserSuccessOpen(false);
  };
  const handleOpenAddRoleSuccess = () => {
    setAddRoleSuccessOpen(true);
  };
  const handleCloseAddRoleSuccess = () => {
    setAddRoleSuccessOpen(false);
  };
  const handleOpenDeleteRoleSuccess = () => {
    setDeleteRoleSuccessOpen(true);
  };
  const handleCloseDeleteRoleSuccess = () => {
    setDeleteRoleSuccessOpen(false);
  };

  const handleDeleteRole = async (id) => {
    const result = await deleteRole(id);

    fetchRoles();
    handleCloseDeleteRole();
    setDeleteRoleSuccessOpen(true);
    setTimeout(() => {
      setDeleteRoleSuccessOpen(false);
    }, 2000);
  };

  const [deleteRoleUserOpen, setDeleteRoleUserOpen] = useState(false);

  const handleOpenDeleteRoleUser = () => {
    setDeleteRoleUserOpen(true);
  };
  const handleCloseDeleteRoleUser = () => {
    setDeleteRoleUserOpen(false);
  };
  const [deleteRoleUserSuccessOpen, setDeleteRoleUserSuccessOpen] =
    useState(false);

  const handleOpenDeleteRoleUserSuccess = () => {
    setDeleteRoleUserSuccessOpen(true);
  };

  const handleCloseDeleteRoleUserSuccess = () => {
    setDeleteRoleUserSuccessOpen(false);
  };

  const handleManageUsers = (id) => {
    fetchRole(id);
    handleOpenUsers();
  };

  const [roleType, setRoleType] = useState("");
  const [adminUsers, setAdminUsers] = useState();
  const [displayedPage, setDisplayedPage] = useState(1);
  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(null);
  const [limit, setLimit] = useState(10);
  const [totalUsers, setTotalUsers] = useState([]);
  const getAdminUsers = async () => {
    const response = await fetchAdminUsers(
      currentPage,
      limit,
      roleType,
      searchUsers
      // convertDateFormat(fromDate),
      // convertDateFormat(toDate)
    );
    setAdminUsers(response.data.items);
    setDisplayedPage(response?.data?.meta?.currentPage);
    setTotalPages(response?.data?.meta?.totalPages);
    setTotalUsers(response?.data?.meta?.totalItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    getAdminUsers();
  }, [currentPage, limit, roleType, searchUsers]);

  const handleLimitChange = (e) => {
    const newItemsPerPage = Number(e.target.value);
    setLimit(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleRoleTypeChange = (e) => {
    setRoleType(e.target.value);
    setCurrentPage(1);
  };
  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= Math.min(totalPages, 3); i++) {
      pages.push(
        <div
          key={i}
          className={`w-[36px] cursor-pointer h-[36px] rounded-[10px] flex items-center justify-center ${
            currentPage === i
              ? "bg-[#074073] text-white"
              : "bg-[#F5F5F5] text-[#074073]"
          }`}
          onClick={() => handlePageChange(i)}
        >
          <p>{i}</p>
        </div>
      );
    }

    return pages;
  };

  const [selectedUser, setSelectedUser] = useState(null);
  const [adminUserModalOpen, setAdminUserModalOpen] = useState(false);
  const openAdminUserModal = () => {
    setAdminUserModalOpen(true);
  };
  const closeAdminUserModal = () => {
    setAdminUserModalOpen(false);
  };

  const [manageUsernameModalOpen, setManageUsernameModalOpen] = useState(false);
  const openUsernameModal = () => {
    setManageUsernameModalOpen(true);
  };
  const closeUsernameModal = () => {
    setManageUsernameModalOpen(false);
  };

  const [manageContaInfoModalOpen, setManageContaInfoModalOpen] =
    useState(false);
  const openContaInfoModal = () => {
    setManageContaInfoModalOpen(true);
  };
  const closeContaInfoModal = () => {
    setManageContaInfoModalOpen(false);
  };

  const [twoFactorAuthModalOpen, setTwoFactorAuthModalOpen] = useState(false);
  const openTwoFactorAuthModal = () => {
    setTwoFactorAuthModalOpen(true);
  };
  const closeTwoFactorAuthModal = () => {
    setTwoFactorAuthModalOpen(false);
  };

  const [SignInModalOpen, setSignInModalOpen] = useState(false);
  const openSignInModal = () => {
    setSignInModalOpen(true);
  };
  const closeSignInAuthModal = () => {
    setSignInModalOpen(false);
  };

  const [openRoleHistory, setOpenRoleHistory] = useState(false);

  const handleOpenRoleHistory = () => {
    setOpenRoleHistory(true);
  };
  const handleCloseRoleHistory = () => {
    setOpenRoleHistory(false);
  };

  const [editRolesModalOpen, setEditRolesModalOpen] = useState(false);
  const openEditRolesModal = () => {
    setEditRolesModalOpen(true);
  };
  const closeEditRolesModal = () => {
    setEditRolesModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anansi || Transactions</title>
      </Helmet>
      <div className="w-[100%] bg-[#F5F6F7] h-full overflow-y-auto">
        <div className="my-[5px]">
          <p className="text-[#074073] text-[20px] w-[97%] mx-auto font-bold">
            USER MANAGEMENT
          </p>
        </div>
        <div className="w-[98%] h-[59px] mx-[20px] gap-2 bg-[#466F94]">
          <button
            className="w-[50%] mt-1.5 h-[39px]"
            onClick={() => setActiveTab("tab1")}
          >
            <p className="text-[#FFF] text-[16px] font-medium">Users</p>
            {activeTab === "tab1" && (
              <div className="w-[100%] h-[8px] mt-3.5 bg-[#5FC5FF]" />
            )}
          </button>
          <button
            className="w-[50%] mt-1.5 h-[39px]"
            onClick={() => setActiveTab("tab2")}
          >
            <p className="text-[#FFF] text-[16px] font-medium">
              Roles & Permissions
            </p>
            {activeTab === "tab2" && (
              <div className="w-[100%] h-[8px] mt-3.5 bg-[#5FC5FF]" />
            )}
          </button>
        </div>

        {activeTab === "tab1" && (
          <div className="w-[95%] py-[20px] mx-[20px] bg-[#FFF]">
            <p className="text-[#074073] text-[20px] w-[97%] mx-auto font-bold">
              Manage users
            </p>
            <p className="text-[#000] text-[14px] w-[97%] mx-auto">
              Oversee and control user accounts, roles, and permissions
            </p>
            <div className="flex w-[78.5vw] mt-2 flex items-center justify-between">
              <button
                onClick={openModal}
                // className="block text-white bg-[#074073] focus:outline-none focus:ring-[#074073] rounded-[5px] mx-[20px] text-center w-[156px] h-[42px]" type="button" data-modal-toggle="defaultModal">
                className="block text-white bg-[#074073] focus:outline-none focus:ring-[#074073] rounded-[5px] mx-[20px] text-center w-[156px] h-[42px]"
                type="button"
              >
                <div className="flex items-center justify-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                      fill="white"
                    />
                  </svg>
                  <p className="text-[#FFF] text-[14px]">Add user</p>
                </div>
              </button>
              <select
                name="export"
                id="export"
                placeholder="Export"
                className="h-[35px] w-[120px] rounded-[5px] bg-[#0C722F] px-[5px] text-white focus:outline-none border-[1px]"
              >
                <option value="">EXPORT</option>
                <option value="pdf">PDF</option>
                <option value="csv">CSV</option>
              </select>
            </div>
            <Modal
              id="firstModal"
              closeModalFunction={closeModal}
              isModalOpen={isModalOpen}
              openRolesModal={openRolesModal}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              userName={userName}
              setUserName={setUserName}
              email={email}
              setEmail={setEmail}
              mobileno={mobileno}
              setMobilenoName={setMobilenoName}
            />
            <RolesModal
              id_R="secondModal"
              closeRolesModal={closeRolesModal}
              rolesModalOpen={rolesModalOpen}
              openUserInfoModal={openUserInfoModal}
              openModal={openModal}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              handleRoleChange={handleRoleChange}
              roles={roles}
            />
            <UserInfoModal
              id_U="thirdModal"
              closeUserInfoModal={closeUserInfoModal}
              userInfoModalOpen={userInfoModalOpen}
              openUserInfoModal={openUserInfoModal}
              openRolesModal={openRolesModal}
              openReviewModal={openReviewModal}
              jobTitle={jobTitle}
              setJobTitle={setJobTitle}
              department={department}
              setDepartment={setDepartment}
              officePhone={officePhone}
              setOfficePhone={setOfficePhone}
              country={country}
              setCountry={setCountry}
              county={county}
              setCounty={setCounty}
              subCounty={subCounty}
              setSubCounty={setSubCounty}
              address={address}
              setAddress={setAddress}
            />
            <ReviewAndFinish
              id_U="forthModal"
              closeReviewModal={closeReviewModal}
              handleNextButtonClick={handleNextButtonClick}
              reviewModalOpen={reviewModalOpen}
              openUserInfoModal={openUserInfoModal}
              openRolesModal={openRolesModal}
              openModal={openModal}
              firstName={firstName}
              lastName={lastName}
              userName={userName}
              email={email}
              jobTitle={jobTitle}
              department={department}
              selectedRole={selectedRole}
            />
            <AdminUser
              id_R="secondModal"
              closeAdminUserModal={closeAdminUserModal}
              adminUserModalOpen={adminUserModalOpen}
              selectedUser={selectedUser}
              openUsernameModal={openUsernameModal}
              openContaInfoModal={openContaInfoModal}
              openTwoFactorAuthModal={openTwoFactorAuthModal}
              openSignInModal={openSignInModal}
              openEditRolesModal={openEditRolesModal}
            />
            <ManageUsername
              id_R="secondModal"
              closeUsernameModal={closeUsernameModal}
              manageUsernameModalOpen={manageUsernameModalOpen}
              selectedUser={selectedUser}
              updateUsername={updateUsername}
              closeAdminUserModal={closeAdminUserModal}
              getAdminUsers={getAdminUsers}
            />
            <ManageContactInfo
              id_R="secondModal"
              closeContaInfoModal={closeContaInfoModal}
              manageContaInfoModalOpen={manageContaInfoModalOpen}
              selectedUser={selectedUser}
              updateContactInfo={updateContactInfo}
              closeAdminUserModal={closeAdminUserModal}
              getAdminUsers={getAdminUsers}
            />
            <TwoFactorAuth
              id_R="secondModal"
              closeTwoFactorAuthModal={closeTwoFactorAuthModal}
              twoFactorAuthModalOpen={twoFactorAuthModalOpen}
              selectedUser={selectedUser}
            />
            <SignInActivity
              id_R="secondModal"
              closeSignInAuthModal={closeSignInAuthModal}
              SignInModalOpen={SignInModalOpen}
              selectedUser={selectedUser}
            />
            <EditAdminRoles
              id_R="secondModal"
              closeEditRolesModal={closeEditRolesModal}
              editRolesModalOpen={editRolesModalOpen}
              roles={roles}
              updateRole={updateRole}
              selectedUser={selectedUser}
              getAdminUsers={getAdminUsers}
              closeAdminUserModal={closeAdminUserModal}
            />
            <UserAddedSuccessfully
              userSuccessOpen={userSuccessOpen}
              handleUserAddedSuccessfully={handleUserAddedSuccessfully}
            />
            <div className="relative mx-[20px] mt-5">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                >
                  <circle
                    cx="5.35294"
                    cy="5.35294"
                    r="4.85294"
                    stroke="#999999"
                  />
                  <line
                    x1="9.52933"
                    y1="8.82294"
                    x2="13.3529"
                    y2="12.6465"
                    stroke="#999999"
                  />
                </svg>
              </span>
              <input
                type="text"
                value={searchUsers}
                onChange={handleSearchUsers}
                placeholder="Search User id, user full ID, email"
                className="h-[35px] pl-10 border-[1px] border-[#CCCCCC] text-[14px] w-[60%] px-[10px] focus:outline-none"
                autoComplete="nope"
              />
            </div>
            <div className="w-[97%] px-[5px] py-[10px] bg-white mx-auto rounded-t-[10px] mt-[10px]">
              <div className="w-full flex items-center gap-[20px] my-[5px]">
                <div className="flex items-center justify-center border-b-[2px] border-[#8ACB8D] w-[10vw]">
                  <p className="text-[#8ACB8D] font-normal py-2 text-[14px]">
                    ALL USERS
                  </p>
                </div>
              </div>
              <div className="h-[63px] flex items-center px-[10px] gap-[40px] w-full bg-[#FBF0E1]">
                {/* <div className="flex items-center gap-[10px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M1.66699 4.58334C1.66699 4.25182 1.79869 3.93388 2.03311 3.69946C2.26753 3.46504 2.58547 3.33334 2.91699 3.33334H17.0837C17.4152 3.33334 17.7331 3.46504 17.9675 3.69946C18.202 3.93388 18.3337 4.25182 18.3337 4.58334C18.3337 4.91486 18.202 5.23281 17.9675 5.46723C17.7331 5.70165 17.4152 5.83334 17.0837 5.83334H2.91699C2.58547 5.83334 2.26753 5.70165 2.03311 5.46723C1.79869 5.23281 1.66699 4.91486 1.66699 4.58334ZM5.00033 9.58334C5.00033 9.25182 5.13202 8.93388 5.36644 8.69946C5.60086 8.46504 5.9188 8.33334 6.25033 8.33334H13.7503C14.0818 8.33334 14.3998 8.46504 14.6342 8.69946C14.8686 8.93388 15.0003 9.25182 15.0003 9.58334C15.0003 9.91486 14.8686 10.2328 14.6342 10.4672C14.3998 10.7016 14.0818 10.8333 13.7503 10.8333H6.25033C5.9188 10.8333 5.60086 10.7016 5.36644 10.4672C5.13202 10.2328 5.00033 9.91486 5.00033 9.58334ZM8.75033 13.3333C8.4188 13.3333 8.10086 13.465 7.86644 13.6995C7.63202 13.9339 7.50033 14.2518 7.50033 14.5833C7.50033 14.9149 7.63202 15.2328 7.86644 15.4672C8.10086 15.7016 8.4188 15.8333 8.75033 15.8333H11.2503C11.5818 15.8333 11.8998 15.7016 12.1342 15.4672C12.3686 15.2328 12.5003 14.9149 12.5003 14.5833C12.5003 14.2518 12.3686 13.9339 12.1342 13.6995C11.8998 13.465 11.5818 13.3333 11.2503 13.3333H8.75033Z"
                      fill="black"
                    />
                  </svg>
                  <p>DATE</p>
                  <Calendar
                    startDate={fromDate}
                    endDate={toDate}
                    setStartDate={setFromDate}
                    setEndDate={setToDate}
                    focusedInput={customerFocusedInput}
                    setFocusedInput={setCustomerFocusedInput}
                  />
                </div> */}
                <div className="flex items-center gap-[5px]">
                  <p>ROLE TYPE</p>
                  <select
                    name="type"
                    id="type"
                    className="w-[11vw] h-[30px] px-[10px] focus:outline-none"
                    onChange={handleRoleTypeChange}
                    value={roleType}
                  >
                    <option value="">All users</option>
                    {roles?.map((role) => (
                      <option key={role?.id} value={role?.name.split(" ")[0]}>
                        {role?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="h-[44px] bg-[#E3F8FF] w-full flex items-center mt-[2px]">
                <input
                  type="checkbox"
                  name="transaction"
                  id="transaction"
                  className="w-[10vw] mt-2 flex items-center"
                />
                <p className="w-[14vw] text-[14px] text-[#000000] mt-2">
                  USER ID
                </p>
                <p className="w-[22vw] text-[14px] text-[#000000]">FULL NAME</p>
                <p className="w-[45vw] text-[14px] text-[#000000]">EMAIL</p>
                <p className="w-[20vw] text-[14px] text-[#000000]">ROLE</p>
                <p className="w-[20vw] text-[14px] text-[#000000]">
                  CREATED ON
                </p>
                <p className="w-[22vw] text-[14px] text-[#000000]">STATUS</p>
              </div>
              {adminUsers &&
                adminUsers?.map((user) => (
                  <div
                    key={user?.id}
                    className="h-[44px] bg-[#fff] w-full flex items-center mt-[2px] cursor-pointer"
                    onClick={() => {
                      setSelectedUser(user);
                      openAdminUserModal();
                    }}
                  >
                    <input
                      type="checkbox"
                      name="transaction"
                      id="transaction"
                      className="w-[10vw] mt-2 flex"
                    />
                    <p className="w-[14vw] text-[#037BD1] text-[14px] text-[#000000] mt-2">
                      {user?.public_id}
                    </p>
                    <p className="w-[22vw] text-[14px] text-[#000000]">
                      {user?.firstname} {user?.lastname}
                    </p>
                    <p className="w-[45vw] text-[14px] text-[#000000]">
                      {user?.email}
                    </p>
                    <p className="w-[20vw] text-[14px] text-[#000000]">
                      {user?.role?.name}
                    </p>
                    <p className="w-[20vw] text-[14px] text-[#000000]">
                      {new Date(user?.createdAt).toISOString().split("T")[0]}
                    </p>
                    <div className="flex items-center mt-2 gap-[5px] w-[22vw]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_6917_381)">
                          <path
                            d="M6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12Z"
                            fill="#0C722F"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_6917_381">
                            <rect
                              width="12"
                              height="12"
                              fill="white"
                              transform="translate(0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className="text-[14px] text-[#000000]">
                        {user?.status}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="h-[70px] bg-white w-[97%] px-[20px] mx-auto mb-[20px] flex items-center justify-between">
              <div className="flex items-center gap-[20px]">
                <p className="text-[#074073] text-[14px]">Users per page</p>
                <select
                  name="page"
                  id="page"
                  className="w-[71px] border-[1px] px-[5px] h-[30px] focus:outline-none"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="50">50</option>
                  <option value="30">30</option>
                  <option value="20">20</option>
                  <option value="10">10</option>
                </select>
                <p className="text-[#074073] text-[14px]">
                  {displayedPage} / {totalPages} ({totalUsers} records)
                </p>
              </div>
              <div className="flex items-center gap-[15px]">
                <p
                  onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                  className="text-[14px] cursor-pointer text-[#074073]"
                >
                  Prev
                </p>
                {renderPages()}
                <div
                  className={`w-[36px] cursor-pointer h-[36px] rounded-[10px] flex items-center justify-center ${
                    Number(currentPage) > 3
                      ? "bg-[#074073] text-white"
                      : "bg-[#F5F5F5] text-[#074073]"
                  }`}
                >
                  <p>{Number(currentPage) > 3 ? currentPage : "..."}</p>
                </div>
                <p
                  onClick={() =>
                    handlePageChange(Math.min(currentPage + 1, totalPages))
                  }
                  className="text-[14px] cursor-pointer text-[#074073]"
                >
                  Next
                </p>
              </div>
            </div>
          </div>
        )}
        {activeTab === "tab2" && (
          <>
            <div className="px-[20px] mt-[10px]">
              <p className="text-[#074073] text-[16px]  font-bold ">
                Manage Roles
              </p>
              <p>
                Roles are reusable set of permissions that you can quickly apply
                to your staff users. Last updated by Marcel Auja November 19
                2023 11:38 AM
              </p>
              <button
                onClick={openModal}
                className="bg-[#074073] h-[45px] px-[20px] flex items-center my-[10px] rounded-[5px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z"
                    fill="white"
                  />
                </svg>
                <span className="text-white text-[16px]">
                  Create a new role
                </span>
              </button>
              <div className="h-[55px] w-[100%] px-[20px] bg-[#FBF0E1] flex items-center gap-[40px]">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M1.66602 4.58333C1.66602 4.25181 1.79771 3.93387 2.03213 3.69944C2.26655 3.46502 2.5845 3.33333 2.91602 3.33333H17.0827C17.4142 3.33333 17.7321 3.46502 17.9666 3.69944C18.201 3.93387 18.3327 4.25181 18.3327 4.58333C18.3327 4.91485 18.201 5.23279 17.9666 5.46721C17.7321 5.70163 17.4142 5.83333 17.0827 5.83333H2.91602C2.5845 5.83333 2.26655 5.70163 2.03213 5.46721C1.79771 5.23279 1.66602 4.91485 1.66602 4.58333ZM4.99935 9.58333C4.99935 9.25181 5.13104 8.93387 5.36547 8.69944C5.59989 8.46502 5.91783 8.33333 6.24935 8.33333H13.7493C14.0809 8.33333 14.3988 8.46502 14.6332 8.69944C14.8677 8.93387 14.9993 9.25181 14.9993 9.58333C14.9993 9.91485 14.8677 10.2328 14.6332 10.4672C14.3988 10.7016 14.0809 10.8333 13.7493 10.8333H6.24935C5.91783 10.8333 5.59989 10.7016 5.36547 10.4672C5.13104 10.2328 4.99935 9.91485 4.99935 9.58333ZM8.74935 13.3333C8.41783 13.3333 8.09989 13.465 7.86547 13.6994C7.63104 13.9339 7.49935 14.2518 7.49935 14.5833C7.49935 14.9148 7.63104 15.2328 7.86547 15.4672C8.09989 15.7016 8.41783 15.8333 8.74935 15.8333H11.2493C11.5809 15.8333 11.8988 15.7016 12.1332 15.4672C12.3677 15.2328 12.4993 14.9148 12.4993 14.5833C12.4993 14.2518 12.3677 13.9339 12.1332 13.6994C11.8988 13.465 11.5809 13.3333 11.2493 13.3333H8.74935Z"
                    fill="black"
                  />
                </svg>
                <p>Date/time</p>
                <input
                  type="date"
                  value={date}
                  className="border h-[30px] px-[20px] focus:outline-none"
                /> */}
              </div>
              <div>
                <div className="w-[100%] flex">
                  <div className="w-[30%] mt-[80px]">
                    {modules &&
                      modules?.map((module) => (
                        <div key={module.id} className="pb-[20px]">
                          <p className="text-[#000] font-bold mb-[10px]">
                            {module?.name}
                          </p>
                          {module?.permissions.map((permission) => (
                            <div className="py-[18px]">
                              <p className="pl-[30px]">{permission?.name}</p>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                  <div className="w-[70%] overflow-x-auto">
                    <div className="h-[80px] bg-[#E3F8FF] flex items-center">
                      {roles &&
                        roles?.map((role) => (
                          <div
                            key={role?.id}
                            className="flex relative justify-center items-center bg-[#E3F8FF] gap-[10px] h-full gap-[10px] min-w-[300px] p-[10px]"
                          >
                            {popoverOpen === role?.id && (
                              <div
                                ref={popoverRef}
                                className="absolute p-[10px] rounded-[5px] top-[0px] left-[0px] w-[140px] bg-white shadow-md z-50 h-[75px]"
                              >
                                <p
                                  className="cursor-pointer text-[#000]"
                                  onClick={handleOpen}
                                >
                                  View Role
                                </p>
                                <p
                                  onClick={handleOpenDeleteRole}
                                  className="cursor-pointer text-[#000]"
                                >
                                  Delete Role
                                </p>
                              </div>
                            )}
                            <div>
                              {role?.users?.length === 0 && <p>No Users</p>}
                              {role?.users?.length > 0 && (
                                <p
                                  onClick={() => handleManageUsers(role?.id)}
                                  className="text-[#2F94EE] underline cursor-pointer"
                                >
                                  {role?.users?.length} users
                                </p>
                              )}
                              <p className="text-[14px] font-bold">
                                {role?.name}
                              </p>
                            </div>
                            <svg
                              onClick={() => handleOpenPopover(role?.id)}
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              className="cursor-pointer"
                            >
                              <path
                                d="M10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12ZM10 6C10 6.53043 10.2107 7.03914 10.5858 7.41421C10.9609 7.78929 11.4696 8 12 8C12.5304 8 13.0391 7.78929 13.4142 7.41421C13.7893 7.03914 14 6.53043 14 6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4C11.4696 4 10.9609 4.21071 10.5858 4.58579C10.2107 4.96086 10 5.46957 10 6ZM10 18C10 18.5304 10.2107 19.0391 10.5858 19.4142C10.9609 19.7893 11.4696 20 12 20C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18C14 17.4696 13.7893 16.9609 13.4142 16.5858C13.0391 16.2107 12.5304 16 12 16C11.4696 16 10.9609 16.2107 10.5858 16.5858C10.2107 16.9609 10 17.4696 10 18Z"
                                fill="#4C4C4C"
                              />
                            </svg>
                          </div>
                        ))}
                    </div>
                    <div>
                      {modules &&
                        modules?.map((module) => (
                          <div className="pb-[20px]">
                            <p
                              className="text-[#000] mb-[10px]"
                              style={{ color: "transparent" }}
                            >
                              {module?.name}
                            </p>
                            {module?.permissions?.map((permission) => (
                              <div className="flex py-[18px] items-center">
                                {roles &&
                                  roles?.map((role) => (
                                    <div
                                      key={role?.id}
                                      className="min-w-[300px] flex justify-center items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        name="permissions"
                                        id="permissions"
                                        className="h-[20px] w-[20px]"
                                        readOnly
                                        checked={checkPermission(
                                          role,
                                          permission
                                        )}
                                      />
                                    </div>
                                  ))}
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddRole
              id="firstModal"
              closeModalFunction={closeModal}
              isModalOpen={isModalOpen}
              openPermissionsModal={openPermissionsModal}
              roleName={roleName}
              setRoleName={setRoleName}
              roleDescription={roleDescription}
              setRoleDescription={setRoleDescription}
            />
            <SetPermissions
              id="secondModal"
              closePermissionsModal={closePermissionsModal}
              permissionsModalOpen={permissionsModalOpen}
              openPermissionsModal={openPermissionsModal}
              roleName={roleName}
              openModal={openModal}
              openReviewPermissionsModal={openReviewPermissionsModal}
              setRoleName={setRoleName}
              roleDescription={roleDescription}
              setRoleDescription={setRoleDescription}
              modules={modules}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
            />
            <FinishPermissions
              id="thirdmodal"
              closeReviewPermissionsModal={closeReviewPermissionsModal}
              reviewPermissionsModalOpen={reviewPermissionsModalOpen}
              openReviewPermissionsModal={openReviewPermissionsModal}
              roleName={roleName}
              openPermissionsModal={openPermissionsModal}
              setRoleName={setRoleName}
              roleDescription={roleDescription}
              setRoleDescription={setRoleDescription}
              selectedPermissions={selectedPermissions}
              modules={modules}
              fetchRoles={fetchRoles}
              setAddRoleSuccessOpen={setAddRoleSuccessOpen}
            />
            <ViewModal
              open={open}
              handleClose={handleClose}
              role={role}
              handleOpenUsers={handleOpenUsers}
              handleOpenRole={handleOpenRole}
              handleOpenPermissions={handleOpenPermissions}
              handleOpenDeleteRole={handleOpenDeleteRole}
              handleOpenRoleHistory={handleOpenRoleHistory}
            />
            <ManageUsers
              openUsers={openUsers}
              handleCloseUsers={handleCloseUsers}
              role={role}
              handleOpenDeleteRoleUser={handleOpenDeleteRoleUser}
            />
            <ManageRole
              openRole={openRole}
              handleCloseRole={handleCloseRole}
              role={role}
              fetchRoles={fetchRoles}
              handleClose={handleClose}
            />
            <ManagePermissions
              openPermissions={openPermissions}
              handleClosePermissions={handleClosePermissions}
              role={role}
              modules={modules}
              fetchRoles={fetchRoles}
              handleClose={handleClose}
            />
            <DeleteRole
              openDeleteRole={openDeleteRole}
              handleCloseDeleteRole={handleCloseDeleteRole}
              role={role}
              handleDeleteRole={handleDeleteRole}
              handleClose={handleClose}
              handleOpenDeleteRoleSuccess={handleOpenDeleteRoleSuccess}
            />
            <AddRoleSuccess
              addRoleSuccessOpen={addRoleSuccessOpen}
              handleOpenAddRoleSuccess={handleOpenAddRoleSuccess}
              handleCloseAddRoleSuccess={handleCloseAddRoleSuccess}
            />
            <DeleteRoleSuccess
              deleteRoleSuccessOpen={deleteRoleSuccessOpen}
              handleOpenDeleteRoleSuccess={handleOpenDeleteRoleSuccess}
              handleCloseDeleteRoleSuccess={handleCloseDeleteRoleSuccess}
            />
            <DeleteUser
              deleteRoleUserOpen={deleteRoleUserOpen}
              handleOpenDeleteRoleUser={handleCloseDeleteRoleUser}
              handleCloseDeleteRoleUser={handleCloseDeleteRoleUser}
              role={role}
              handleCloseUsers={handleCloseUsers}
              handleClose={handleClose}
              fetchRoles={fetchRoles}
              handleOpenDeleteRoleUserSuccess={handleOpenDeleteRoleUserSuccess}
            />
            <DeleteRoleUserSuccess
              deleteRoleUserSuccessOpen={deleteRoleUserSuccessOpen}
              handleCloseDeleteRoleUserSuccess={
                handleCloseDeleteRoleUserSuccess
              }
            />
            <RoleHistory
              openRoleHistory={openRoleHistory}
              handleCloseRoleHistory={handleCloseRoleHistory}
              role={role}
            />
          </>
        )}
      </div>
    </>
  );
};

export default UserManagement;
