import React from "react";

const ContinueSuspendAcc = ({
  id,
  isContinueSuspendAccModalOpen,
  closeContinueSuspendAcc,
  handleOpenSuspendReason,
}) => {
  const handleCancelButtonCLick = () => {
    closeContinueSuspendAcc();
  };

  const handleContinueButtonCLick = () => {
    closeContinueSuspendAcc();
    handleOpenSuspendReason()
  };
  return (
    <>
      <div
        id={id}
        className={`${
          isContinueSuspendAccModalOpen ? "flex" : "hidden"
        } flex items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="flex items-center justify-center w-full">
          <div className="relative bg-white shadow-lg rounded-sm w-[90%] max-w-[36%] mx-auto">
            <div className="mb-5 mx-auto items-center justify-center">
              <div className="flex items-center justify-between">
                <button
                  onClick={closeContinueSuspendAcc}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm ml-auto inline-flex items-center "
                  data-modal-toggle={id}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="flex items-stretch mx-5">
                <div className="flex-grow py-2">
                  <div className="text-[#000000] text-[20px] font-normal mt-2 mx-5">
                    <p>Are you sure you want to suspend this account?</p>
                  </div>
                  <div className="text-[#000000] text-[15px] font-[300] mt-2 ml-5">
                    <p>
                      Suspending an account has various implications on the
                      <br /> Member accessing the account
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center mt-5 mx-5">
                <div className="mr-5">
                  <button
                    onClick={handleContinueButtonCLick}
                    data-modal-toggle={id}
                    type="button"
                    className="text-[#FFF] bg-[#FF3232] hover:bg-[#FF3232] focus:outline-none font-[500] rounded-[5px] text-md text-medium px-5 py-2 text-center"
                  >
                    Yes, continue
                  </button>
                </div>
                <button
                  onClick={handleCancelButtonCLick}
                  data-modal-toggle={id}
                  type="button"
                  className="text-[#074073] focus:outline-none font-[500] rounded-[5px] text-md px-5 py-2 text-center 
                    bg-[#7FB8EB]"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContinueSuspendAcc;
