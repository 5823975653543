import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

const SharesDonutChart = ({ percentShare }) => {
  const data = percentShare
    ? Object.entries(percentShare).map(([name, { count, percentage }]) => ({
        name,
        value: count,
        percentage,
      }))
    : [];

  const COLORS = ["#074073", "#FF5757"];

  return (
    <PieChart width={400} height={400} margin={{ right: 10 }}>
      <Pie
        data={data}
        // cx={240}
        // cy={150}
        innerRadius={80}
        outerRadius={120}
        fill="#8884d8"
        dataKey="value"
        stroke="none"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip
        content={({ payload }) => {
          if (payload && payload.length > 0) {
            const { name, percentage, value } = payload[0].payload;
            return (
              <div
                style={{
                  background: "rgba(230, 243, 255, 0.90)",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>{name}</div>
                <div>{`Percent: ${percentage}%`}</div>
                <div>{`Count: ${value}`}</div>
              </div>
            );
          }
          return null;
        }}
      />
      {/* <Legend align="right" layout="vertical" verticalAlign="top" /> */}
    </PieChart>
  );
};

export default SharesDonutChart;
