import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";

const RequireAuth = ({ allowedRoles }) => {
  const { auth, setAuth } = useAuth();
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  let timer;
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      
      resetTimer();
      
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      
      logoutAction();
    }, 600000); 
  };

  
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);
  const logoutAction = () => {
    localStorage.clear();
    setAuth({});
    window.location.pathname = "/";
  };

  const location = useLocation();

  return auth?.roles?.find((role) => allowedRoles.includes(role)) ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
