import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const LoadingButtons = ({ id, isLoadingButtons }) => {
  const controlsFirst = useAnimation();
  const controlsSecond = useAnimation();
  const controlsThird = useAnimation();

  useEffect(() => {
    if (isLoadingButtons) {
      controlsFirst.start({
        y: [0, -10, 0],
        transition: {
          duration: 0.6,
          repeat: Infinity,
          repeatType: "loop",
          ease: "easeInOut",
          delay: 0,
        },
      });

      controlsSecond.start({
        y: [0, -10, 0],
        transition: {
          duration: 0.6,
          repeat: Infinity,
          repeatType: "loop",
          ease: "easeInOut",
          delay: 0.2, // Delay to start after the first button
        },
      });

      controlsThird.start({
        y: [0, -10, 0],
        transition: {
          duration: 0.6,
          repeat: Infinity,
          repeatType: "loop",
          ease: "easeInOut",
          delay: 0.6, // Delay to start after the second button
        },
      });
    } else {
      controlsFirst.stop();
      controlsSecond.stop();
      controlsThird.stop();
    }
  }, [isLoadingButtons, controlsFirst, controlsSecond, controlsThird]);

  return (
    <>
      <div
        id={id}
        className={`${
          isLoadingButtons ? "flex" : "hidden"
        } flex items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="flex items-center justify-center min-h-[50%] w-full">
          <div className="relative bg-white shadow-lg p-6 rounded-sm w-[40%] max-w-sm mx-auto">
            <div className="flex items-center justify-center mt-10 mb-8">
              <motion.div
                className="bg-black w-2 h-2 rounded-full mx-2"
                animate={controlsFirst}
              />
              <motion.div
                className="bg-black w-2 h-2 rounded-full mx-2"
                animate={controlsSecond}
              />
              <motion.div
                className="bg-black w-2 h-2 rounded-full mx-2"
                animate={controlsThird}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadingButtons;
