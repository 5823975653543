import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ZigChart = ({datum}) => {
  const data = datum?.data?.bankProfitability;
  return (
    <ResponsiveContainer>
      <LineChart
        width={650}
        height={280}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="month" axisLine={{ stroke: "#D9D9D9" }} />
        <YAxis axisLine={{ stroke: "#D9D9D9" }} />
        <Tooltip />
        <Legend />
        <Line
          type="linear"
          name="Months"
          dataKey="value"
          stroke="#0CBF08"
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ZigChart;
