import React, { useEffect, useState } from "react";
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import { updateRoleNameandDescription } from "../../../sdk/users/modules";

const ManageRole = ({
  openRole,
  handleCloseRole,
  role,
  fetchRoles,
  handleClose,
}) => {
  const [roleId, setRoleId] = useState("");
  const [permissions, setpermissions] = useState([]);
  const [users, setUsers] = useState([]);

  const [updatedRoleName, setUpdatedRoleName] = useState(() => role?.name);
  const [updatedRoleDescription, setUpdatedRoleDescription] = useState(
    () => role?.description
  );

  useEffect(() => {
    setUpdatedRoleName(role?.name);
    setUpdatedRoleDescription(role?.description);
    setRoleId(role?.id);

    const res = role?.permissions?.map((p) => p?.id);
    const result = role?.users?.map((u) => u?.id);
    setpermissions(res);
    setUsers(result);
  }, [role]);

  const handleUpdateRoleNameandDescription = async () => {
    const results = await updateRoleNameandDescription(roleId, {
      name: updatedRoleName,
      description: updatedRoleDescription,
      permission_ids: permissions,
      user_ids: users,
    });

    console.log(results.data);
    handleCloseRole();
    handleClose();
    fetchRoles();
  };

  return (
    <Modal open={openRole} size="lg" onClose={handleCloseRole}>
      <div className="h-[550px]">
        <div className="flex mb-[40px] items-center gap-[10px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
              fill="black"
            />
          </svg>
          <p className="text-[#000] text-[18px] font-bold">
            Manage role name and description
          </p>
        </div>
        <p className="my-[10px]">
          ⚠️ Please be advised that altering the role name is a significant
          operation with potential impact on how the associated permissions are
          organized. Consider the implications carefully before proceeding
        </p>
        <div className="mt-[50px]">
          <form>
            <div className="mb-[30px]">
              <label
                htmlFor="rolename"
                className="mb-[10px] text-[#000] font-bold"
              >
                Role Name
              </label>
              <input
                type="text"
                value={updatedRoleName || ""}
                onChange={(e) => setUpdatedRoleName(e.target.value)}
                className="w-full border-[1px] px-[10px] focus:outline-none h-[40px]"
              />
            </div>
            <div>
              <label
                htmlFor="rolename"
                className="mb-[10px] text-[#000] font-bold"
              >
                Role Description
              </label>
              <textarea
                type="text"
                value={updatedRoleDescription || ""}
                onChange={(e) => setUpdatedRoleDescription(e.target.value)}
                className="w-full border-[1px] px-[10px] focus:outline-none h-[120px]"
              />
            </div>
          </form>
        </div>
      </div>
      <Modal.Footer>
        <div className="flex w-full justify-between">
          <button
            onClick={handleUpdateRoleNameandDescription}
            className="bg-[#D9D9D9] h-[50px] px-[10px] hover:bg-[#074073] hover:text-white"
          >
            Save Changes
          </button>
          <button
            onClick={handleCloseRole}
            className="bg-[#D9D9D9] h-[50px] px-[20px] hover:bg-[#FF3232] hover:text-white"
          >
            Cancel
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageRole;
