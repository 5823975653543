import React, { useState } from "react";

const SeeIdentityDoc = ({
  document,
  seeIdentityDocOpen,
  handleCloseSeeIdentityDoc,
}) => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <div
      className={`${
        seeIdentityDocOpen ? "flex" : "hidden"
      } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
    >
      <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
        <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto w-[67vw] h-[95vh]">
          <div className="justify-end rounded-t flex items-end">
            <button
              onClick={handleCloseSeeIdentityDoc}
              type="button"
              className="bg-transparent rounded-lg text-sm p-2.5 ml-auto inline-flex items-center"
              data-modal-toggle="modal"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.84 12L5.7 8.9L3.9 12H0.2L3.88 6.3L0.1 0.839999H3.94L6.08 3.92L7.88 0.839999H11.58L7.84 6.46L11.68 12H7.84Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>

          <div className="w-[25%] mx-[20px] h-[10vh] gap-4 flex items-end">
            {document?.identification_type &&
            (document?.identification_type?.toLowerCase() === "national id" ||
              document?.identification_type === "National_Id") ? (
              <>
                <button
                  className={`w-[50%] mt-1.5 h-[39px] ${
                    activeTab === "tab1" ? "font-medium" : ""
                  }`}
                  onClick={() => setActiveTab("tab1")}
                >
                  <p
                    className={`text-[#000] text-[16px] ${
                      activeTab === "tab1" ? "font-medium" : ""
                    }`}
                  >
                    FRONT
                  </p>
                  {activeTab === "tab1" && (
                    <div className="w-[100%] h-[4px] mt-3.5 bg-[#456F94]" />
                  )}
                </button>
                <button
                  className={`w-[50%] mt-1.5 h-[39px] ${
                    activeTab === "tab2" ? "font-medium" : ""
                  }`}
                  onClick={() => setActiveTab("tab2")}
                >
                  <p
                    className={`text-[#000] text-[16px] ${
                      activeTab === "tab2" ? "font-medium" : ""
                    }`}
                  >
                    BACK
                  </p>
                  {activeTab === "tab2" && (
                    <div className="w-[100%] h-[4px] mt-3.5 bg-[#456F94]" />
                  )}
                </button>
              </>
            ) : (
              <button
                className={`w-[50%] mt-1.5 h-[39px] ${
                  activeTab === "tab2" ? "font-medium" : ""
                }`}
                onClick={() => setActiveTab("tab2")}
              >
                <p
                  className={`text-[#000] text-[16px] ${
                    activeTab === "tab2" ? "font-medium" : ""
                  }`}
                >
                  PHOTO
                </p>
                {activeTab === "tab2" && (
                  <div className="w-[100%] h-[4px] mt-3.5 bg-[#456F94]" />
                )}
              </button>
            )}
          </div>

          <div className="flex justify-center overflow-auto items-center mt-10 mx-auto w-[80%] h-[65%] px-3">
            {document?.identification_type &&
            (document?.identification_type?.toLowerCase() === "national id" ||
              document?.identification_type === "National_Id") ? (
              <div className="">
                {activeTab === "tab1" && (
                  <>
                    <div className="p-8  w-[85%] h-[88%] overflow-auto flex overflow-auto justify-center items-center border-[1px] border-[#EAEAEA]">
                      {document?.id_front_image ? (
                        <img src={document?.id_front_image} alt="ID Front" />
                      ) : (
                        <span>No Front Image Available</span>
                      )}
                    </div>
                  </>
                )}

                {activeTab === "tab2" && (
                  <>
                    <div className="p-8  w-[85%] overflow-auto flex justify-center items-center border-[1px] border-[#EAEAEA]">
                      {document?.id_back_image ? (
                        <img src={document.id_back_image} alt="ID Back" />
                      ) : (
                        <span>No Back Image Available</span>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="p-5 h-[80%] w-[96%] overflow-auto flex justify-center items-center">
                {document?.passport_image ? (
                  <img src={document.passport_image} alt="Passport" />
                ) : (
                  <span>No Passport Image Available</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeIdentityDoc;
