import React from "react";
import CustomerProgress from "../../../components/progressBar/CustomerProgress";

const AccountType = ({
  id_R,
  accountTypeModalOpen,
  handleCloseAccountType,
  products,
  setProductId,
  productId,
  openPhoneModal,
  handleContinueButtonClick,
}) => {
  const handleCardClick = (id, name) => {
    setProductId({ id, name });
  };

  const handleBackButtonClick = () => {
    handleCloseAccountType();
    openPhoneModal();
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          accountTypeModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="pt-4 px-2 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add Member
                </p>
              </div>
              <div className="mt-[32px] px-6">
                <CustomerProgress step={3} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col">
              <div className="flex items-center justify-end">
                <button
                  onClick={handleCloseAccountType}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="mt-4 items-center justify-start">
                <p className="text-[#000] text-xl font-bold">
                  Setup Member acconut type
                </p>
                <div className="text-[#000] mt-[10px] text-[15px] font-normal">
                  <p>
                    Please select the appropriate account type the Member will
                    be opening with us.
                  </p>
                </div>
              </div>
              <div className="justify-start gap-5 flex flex-wrap mt-16">
                {products && products.length > 0 ? (
                  products.map((product) => (
                    <div
                      key={product?.id}
                      className={`w-[30%] border-[1px] p-[10px] flex flex-col items-center justify-between rounded-[2px] ${
                        productId.id === product?.id
                          ? "bg-[#CEF6FF] border-[#90ADF0]"
                          : "border-[#000000]"
                      }`}
                      onClick={() =>
                        handleCardClick(product?.id, product?.name)
                      }
                    >
                      <img
                        src={product?.image_url}
                        alt={product?.name}
                        className="w-[50px] h-[50px] object-cover rounded"
                      />
                      <p className="text-center font-bold">{product?.name}</p>
                      <p className="text-center text-[12px]">
                        {product?.description}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-[#000] text-lg font-semibold mt-5">
                    No products available
                  </p>
                )}
              </div>

              <div className="flex items-center justify-between mt-16 mb-8 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    data-modal-toggle={id_R}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                </div>
                <button
                  onClick={handleContinueButtonClick}
                  type="button"
                  className={`text-white ${
                    productId
                      ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                      : "bg-[#D9D9D9] pointer-events-none"
                  } block font-medium rounded-[5px] text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountType;
