import React, { useState, useEffect } from "react";
import ProgressBar from "../../components/progressBar/ProgressBar";

const UserInfoModal = ({
  id_U,
  closeUserInfoModal,
  userInfoModalOpen,
  openRolesModal,
  openReviewModal,
  jobTitle,
  setJobTitle,
  department,
  setDepartment,
  officePhone,
  setOfficePhone,
  country,
  setCountry,
  address,
  setAddress,
}) => {
  const [isInputsFilled, setIsInputsFilled] = useState(false);
  const [counties, setCounties] = useState([]);
  const [subCounties, setSubCounties] = useState([]);
  const [county, setCounty] = useState('');
  const [subCounty, setSubCounty] = useState('');

  const handleBackButtonClick = () => {
    // Close the current modal
    closeUserInfoModal();

    // Open the previous modal
    openRolesModal();
  };

  const handleNextButtonClick = () => {
    // Close the current modal
    closeUserInfoModal();

    // Open the next modal
    openReviewModal();
  };

  useEffect(() => {
    const areInputsFilled = !!(
      jobTitle &&
      department &&
      officePhone &&
      country &&
      county &&
      subCounty &&
      address
    );
    setIsInputsFilled(areInputsFilled);
  }, [jobTitle, department, officePhone, country, county, subCounty, address]);


  useEffect(() => {
    fetch("http://109.199.120.58:5300/api/v1/county")
       .then((response) => response?.json())
      .then((data) => {
        console.log('API response data:', data); // Log the raw data from the API
        if (data && Array.isArray(data?.data)) {
          setCounties(data?.data);
        } else {
          console.error("Expected array but received:", typeof data?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    const selectedCounty = counties?.find((c) => c.county === county);
    if (selectedCounty) {
      setSubCounties(selectedCounty?.sub_counties);
    } else {
      setSubCounties([]);
    }
  }, [county, counties]);

  console.log('counties:', counties);
  console.log('subCounties:', subCounties);

  const handleCancelButton = () => {
    // Reset all input fields
    setJobTitle("");
    setDepartment("");
    setOfficePhone("");
    setCountry("");
    setCounty("");
    setSubCounty("");
    setAddress("");
    setCounties([]);
    setSubCounties([]);

    // Close the modal
    closeUserInfoModal();
  };

  return (
    <>
      <div
        id={id_U}
        className={`${
          userInfoModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="flex fixed min-h-[80%] w-[65%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className=" w-[28%]">
              <div className="py-4 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add user
                </p>
              </div>
              <div className="mt-6 px-6">
                <ProgressBar step={3} />
              </div>
            </div>
            <div className="w-[72%] flex flex-col justify-between">
              <div>
                <div className="mt-5 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Additional user information
                  </p>
                  <div className="text-[#000] mt-[40px] text-sm font-normal">
                    <p>
                      Now, let's enrich the user profile by adding some optional
                      additional information. Feel free to provide the following
                      details to create a comprehensive user profile, but
                      remember, these are optional.
                    </p>
                  </div>
                </div>
                <div className="flex w-full justify-between mt-[40px] mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="jobTitleInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Job Title
                    </label>
                    <input
                      type="text"
                      id="jobTitleInput"
                      name="jobTitle"
                      placeholder="Enter your job title"
                      value={jobTitle}
                      onChange={(e) => setJobTitle(e.target.value)}
                      className="w-[90%] h-10 px-4 border-[1px] border-[#000]"
                    />
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="departmentInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Department
                    </label>
                    <input
                      type="text"
                      id="departmentInput"
                      name="department"
                      placeholder="Enter your department"
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                      className="w-[90%] h-10 px-4 border-[1px] border-[#000]"
                    />
                  </div>
                </div>
                <div className="flex flex-col w-[45%] justify-center mt-[20px]">
                  <label
                    htmlFor="officePhoneInput"
                    className="mb-1 text-md font-medium text-[#000]"
                  >
                    Office phone
                  </label>
                  <input
                    type="text"
                    id="officePhoneInput"
                    name="officePhone"
                    placeholder="Enter office phone"
                    value={officePhone}
                    onChange={(e) => setOfficePhone(e.target.value)}
                    className="w-[100%] h-10 px-4 border-[1px] border-[#000]"
                  />
                </div>
                <div className="flex w-full justify-between mt-[20px] mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="country"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Country
                    </label>

                    <input
                      type="text"
                      id="country"
                      name="country"
                      placeholder="Enter your country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className="w-[90%] h-10 px-4 border-[1px] border-[#000]"
                    />
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="county"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      County
                    </label>
                    <select
                      name="county"
                      id="county"
                      value={county}
                      onChange={(e) => setCounty(e.target.value)}
                      className="w-[90%] h-10 px-4 border-[1px] border-[#000]"
                    >
                      <option value="">Select county</option>
                      {counties.map((county) => (
                        <option key={county.id} value={county.county}>
                          {county.county}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex w-full justify-between mt-[20px] mr-4">
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="subCountyInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Sub-county
                    </label>

                    <select
                      name="subCounty"
                      id="subCounty"
                      value={subCounty}
                      onChange={(e) => setSubCounty(e.target.value)}
                      className="w-[90%] h-10 px-4 border-[1px] border-[#000]"
                    >
                      <option value="">Select sub-county</option>
                      {subCounties.map((subCounty) => (
                        <option key={subCounty} value={subCounty}>
                          {subCounty}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex w-[50%] flex-col justify-center">
                    <label
                      htmlFor="addressInput"
                      className="mb-1 text-md font-medium text-[#000]"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="addressInput"
                      name="address"
                      placeholder="Enter your address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="w-[90%] h-10 px-4 border border-[1px] border-[#000]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center w-[95%] justify-between mt-6 mb-6 mr-5">
                <div className="flex items-center justify-between w-[15vw]">
                  <button
                    onClick={handleBackButtonClick}
                    type="button"
                    className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Back
                  </button>
                  <button
                    onClick={handleNextButtonClick}
                    type="button"
                    className={`text-white ${
                      isInputsFilled
                        ? "bg-[#074073] hover:bg-[#074073] focus:outline-none"
                        : "bg-[#D9D9D9] pointer-events-none"
                    } block font-medium rounded-[5px] text-sm px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                  >
                    Next
                  </button>
                </div>
                <button
                  onClick={handleCancelButton}
                  data-modal-toggle={id_U}
                  type="button"
                  className="text-gray-500 bg-[#D9D9D9] hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[5px] border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfoModal;
