import React from "react";

const FailModal = ({ id, isFailModal, closeFailModal }) => {

    const handleTryAgainButtonClick = () => {
        // Close the current modal
        closeFailModal();
    
      };
  return (
    <>
      <div
        id={id}
        className={`${
          isFailModal ? "flex" : "hidden"
        } flex items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="flex items-center justify-center min-h-[50%] w-full">
          <div className="relative bg-white shadow-lg rounded-md w-[90%] max-w-lg mx-auto">
            <div className="text-center">
              <div className="mt-4 mb-12 mx-auto items-center justify-center">
                <img src="/error_image.png" alt="Success" className="mx-auto" />
                <div className="text-[#000] text-[15px] font-normal mt-10">
                  <p>
                    We couldn't read the document or it might be invalid. Please
                    try
                  </p>
                  <p>again.</p>
                </div>
                <div className="flex items-center justify-center mt-10">
                  <button
                    onClick={handleTryAgainButtonClick}
                    data-modal-toggle={id}
                    type="button"
                    className="text-[#FFF] bg-[#0086FF] hover:bg-[#0086FF] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                  >
                    Try Again
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FailModal;
