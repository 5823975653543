import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import { removeRoleUser } from "../../../sdk/users/modules";

const DeleteUser = ({
  deleteRoleUserOpen,
  handleOpenDeleteRoleUser,
  handleCloseDeleteRoleUser,
  role,
  handleCloseUsers,
  handleClose,
  fetchRoles,
  handleOpenDeleteRoleUserSuccess,
}) => {
  const [user, setUser] = useState("");
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [roleUsers, setRoleUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await JSON.parse(localStorage.getItem("user"));
        setUser(user);
      } catch (error) {
        console.error("Error fetching username:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setRoleId(role?.id);
    setRoleName(role?.name);
    setRoleDescription(role?.description);

    const perms = role?.permissions?.map((p) => p.id);
    setPermissions(perms);

    const roleusers = role?.users?.map((u) => u?.id);
    setRoleUsers(roleusers);
  }, [role]);

  const handleRemoveRoleUser = async () => {
    console.log(roleUsers);
    console.log(user.id);
    const filteredIds = roleUsers?.filter((u) => u !== user.id);
    console.log(filteredIds);

    const response = await removeRoleUser(
      roleId,
      roleName,
      roleDescription,
      permissions,
      filteredIds
    );
    console.log(response.data);

    handleCloseDeleteRoleUser();
    handleCloseUsers();
    handleClose();
    fetchRoles();
    handleOpenDeleteRoleUserSuccess();
  };

  return (
    <Modal
      open={deleteRoleUserOpen}
      onClose={handleCloseDeleteRoleUser}
      size="md"
    >
      <div className="h-[200px]">
        <p>Are you sure you want to remove this user from the role</p>
        <p className="my-[20px]">
          Removing {user?.username} from this role is an irreversible action and
          will result in the following consequences:
        </p>
        <p className="text-[#FF5757]">
          1 {user?.username} will lose all permissions associated with this
          role.
        </p>
        <p className="text-[#FF5757]">
          2 Any special permissions granted to {user?.username} through this
          role will be revoked.
        </p>
        <p className="text-[#FF5757]">
          3 The role's audit history related to {user?.username} will be
          permanently deleted.
        </p>
      </div>
      <div className="flex items-center gap-[20px] my-[20px]">
        <button
          onClick={handleRemoveRoleUser}
          className="bg-[#FF3232] rounded-sm h-[45px] px-[20px]"
        >
          Yes, Remove
        </button>
        <button
          onClick={handleCloseDeleteRoleUser}
          className="bg-[#7FB8EB] rounded-sm h-[45px] px-[20px]"
        >
          No, Cancel
        </button>
      </div>
    </Modal>
  );
};

export default DeleteUser;
