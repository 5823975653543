import React, { useState } from "react";
import RoleProgress from "../../progressBar/RoleProgress";
import { Link } from "react-router-dom";
import { createRole } from "../../../sdk/users/modules";

const FinishPermissions = ({
  id,
  closeReviewPermissionsModal,
  reviewPermissionsModalOpen,
  openReviewPermissionsModal,
  openPermissionsModal,
  roleName,
  setRoleName,
  roleDescription,
  setRoleDescription,
  selectedPermissions,
  modules,
  fetchRoles,
  setAddRoleSuccessOpen,
}) => {
  const [isInputsFilled, setIsInputsFilled] = useState(true);

  const handleCreateRole = async () => {
    const response = await createRole(
      roleName,
      roleDescription,
      selectedPermissions
    );
    if (response.statusCode === 201) {
      closeReviewPermissionsModal();
      fetchRoles();
      setAddRoleSuccessOpen(true);
      setTimeout(() => {
        setAddRoleSuccessOpen(false);
      }, 2000);
    }
  };

  const handleBackButtonClick = () => {
    closeReviewPermissionsModal();
    openPermissionsModal();
  };
  return (
    <div
      id={id}
      className={`${
        reviewPermissionsModalOpen ? "flex" : "hidden"
      } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
    >
      <div className="relative flex fixed min-h-[80%] w-[65%]">
        <div className="relative flex bg-white shadow w-full mx-auto">
          <div className=" w-[28%]">
            <div className="py-4 rounded-t">
              <p className="text-[20px] font-bold text-[#000] ml-5">
                Create a new role
              </p>
            </div>
            <div className="mt-6 px-6">
              <RoleProgress step={4} />
            </div>
          </div>
          <div className="w-[72%] flex flex-col justify-between">
            <div>
              <div className="mt-5 mx-auto items-center">
                <p className="text-[#000] text-xl font-bold">
                  Review and finish{" "}
                </p>
                <div className="text-[#000] w-[90%] mt-[40px] text-sm font-normal">
                  <p>
                    Great work! You've provided all the necessary details for
                    the new user. Take a moment to review and confirm the
                    settings below:
                  </p>
                </div>
              </div>
              <div className="flex justify-between w-[70%] my-[40px]">
                <div className="w-[50%]">
                  <h5>Role Name</h5>
                  <p className="text-[#4F4F4F]">{roleName}</p>
                  <Link to="edit">Edit</Link>
                </div>
                <div className="w-[50%]">
                  <h5>Role description</h5>
                  <p className="text-[#4F4F4F]">{roleDescription}</p>
                  <Link to="edit">Edit</Link>
                </div>
              </div>
              <div className="flex flex-col gap-[10px] justify-between mt-5 mr-4">
                <p>Permissions({selectedPermissions?.length})</p>
                <div className="border-2 h-[260px] w-[90%] p-[10px] overflow-y-auto">
                  {modules &&
                    modules?.map((module) => (
                      <div key={module?.id}>
                        <p className="text-[#000] font-bold">{module?.name}</p>
                        {module?.permissions?.map((permission) => (
                          <div
                            key={permission?.id}
                            className="flex items-center my-[10px]"
                          >
                            <div className="w-[40%] mb-[20px]">
                              <p className="pl-[20px]">{permission?.name}</p>
                            </div>
                            <div className="w-[60%] flex items-center justify-center">
                              <input
                                type="checkbox"
                                className="h-[20px] w-[20px]"
                                readOnly
                                name="permissions"
                                id="permissions"
                                checked={selectedPermissions.includes(
                                  permission?.id
                                )}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
              <div className="mt-[10px]">
                <Link to="edit-permissions">Edit</Link>
              </div>
            </div>
            <div className="flex w-[90%] items-center justify-between mt-[20px] mb-8 pr-[15px]">
              <div className="flex items-center gap-[20px] w-[15vw]">
                <button
                  onClick={handleBackButtonClick}
                  data-modal-toggle={id}
                  type="button"
                  className="text-[#000] bg-[#7FB8EB] hover:bg-[#7FB8EB] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                >
                  Back
                </button>
                <button
                  onClick={handleCreateRole}
                  data-modal-toggle={id}
                  type="button"
                  className="text-white bg-[#074073] hover:bg-[#074073] focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center"
                >
                  Finish
                </button>
              </div>
              <button
                onClick={closeReviewPermissionsModal}
                data-modal-toggle={id}
                type="button"
                className="text-gray-500 bg-[#D9D9D9] hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-[5px] border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishPermissions;
