import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCustomer,
  getCustomerSingleAccounts,
  getCustomerTransactions,
  totalSpentByCustomerPerMonth,
  suspendAccount,
} from "../../sdk/customers/customers";
import TransactionModal from "../../components/Modals/transactions/TransactionModal";
import SuspensionReason from "../../components/Modals/suspend-account/SuspensionReason";
import DocumentReason from "../../components/Modals/suspend-account/DocumentReason";
import SuspensionImplications from "../../components/Modals/suspend-account/SuspensionImplications";
import ContinueSuspendAcc from "../../components/Modals/suspend-account/ContinueSuspendAcc";
import ReviewSuspension from "../../components/Modals/suspend-account/ReviewSuspension";
import SuspendAccount from "../../components/Modals/suspend-account/SuspendAccount";
import { toast } from "react-toastify";

const CustomerTransactions = () => {
  const { id, accountId } = useParams();
  const [singleAccount, setSingleAccount] = useState({});

  const [transactions, setTransactions] = useState([]);
  const [customer, setCustomer] = useState({});
  const [spending, setSpending] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [singleTransaction, setSingleTransaction] = useState({});

  const fetchCustomer = async (id) => {
    const res = await getCustomer(id);
    setCustomer(res.data);
  };

  const fetchSpendingPerMonth = async (state) => {
    const res = await totalSpentByCustomerPerMonth(state.account_number);
    setSpending(res.data);
  };

  const fetchSingleAccount = async (custId, id) => {
    const res = await getCustomerSingleAccounts(id, custId);
    setSingleAccount(res.data);
  };

  const fetchTransactions = async (state) => {
    const res = await getCustomerTransactions(state.account_number);
    setTransactions(res.data);
  };

  useEffect(() => {
    fetchCustomer(id);
    fetchSingleAccount(id, accountId);
    fetchTransactions(state);
    fetchSpendingPerMonth(state);
  }, [id, accountId, state]);

  const [showBalance, setShowBalance] = useState(false);

  const toggleBalanceVisibility = () => {
    setShowBalance(!showBalance);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const [openTransaction, setOpenTransaction] = useState(false);

  const openTransactionModal = (transaction) => {
    setSingleTransaction(transaction);
    setOpenTransaction(true);
  };

  const closeTransactionModal = () => {
    setOpenTransaction(false);
  };
  const [selectedOption, setSelectedOption] = useState("");
  const [suspendReasonOpen, setSuspendReasonOpen] = useState(false);

  const handleCloseSuspendReason = () => {
    setSuspendReasonOpen(false);
  };

  const handleOpenSuspendReason = () => {
    setSuspendReasonOpen(true);
  };

  const [note, setNote] = useState("");
  const [documentReasonOpen, setDocumentReasonOpen] = useState(false);

  const handleCloseDocumentReason = () => {
    setDocumentReasonOpen(false);
  };

  const handleOpenDocumentReason = () => {
    setDocumentReasonOpen(true);
  };

  const [toggleState, setToggleState] = useState("Yes");
  const [activeTab, setActiveTab] = useState("Yes");
  const [suspensionImplicationsOpen, setSuspensionImplicationsOpen] =
    useState(false);

  const handleCloseSuspensionImplications = () => {
    setSuspensionImplicationsOpen(false);
  };

  const handleOpenSuspensionImplications = () => {
    setSuspensionImplicationsOpen(true);
  };

  const [isContinueSuspendAccModalOpen, setIsContinueSuspendAccModalOpen] =
    useState(false);

  const openContinueSuspendAcc = () => {
    setIsContinueSuspendAccModalOpen(true);
  };

  const closeContinueSuspendAcc = () => {
    setIsContinueSuspendAccModalOpen(false);
  };

  const [reviewSuspensionOpen, setReviewSuspensionOpen] = useState(false);

  const handleCloseReviewSuspension = () => {
    setReviewSuspensionOpen(false);
  };

  const handleOpenReviewSuspension = () => {
    setReviewSuspensionOpen(true);
  };
  const [isSuspendAccountModalOpen, setIsSuspendAccountModalOpen] =
    useState(false);

  const closeSuspendAccount = () => {
    setIsSuspendAccountModalOpen(false);
  };

  const handleSuspendAccount = async () => {
    const payload = {
      accountId: singleAccount?.product?.id,
      action: "suspend",
      reason: selectedOption,
      additionalDetails: note,
      disableOnlineAndMobileAccess: toggleState === "Yes" ? true : false,
      freezeTransactions: activeTab === "Yes" ? true : false,
      // attachments: [

      // ],
    };

    try {
      await suspendAccount(payload);
    } catch (error) {
      toast?.error(error?.message || "Error suspending account:", error);
    }
  };

  return (
    <div>
      <div className="h-[118px] bg-[#074073] w-full flex items-center justify-between px-[20px]">
        <div className="flex items-center gap-[30px]">
          <div className="h-[80px] w-[80px] rounded-[100%] bg-[#D3EAFF] text-[#074073] text-[32px] flex items-center justify-center">
            {customer?.firstname?.slice(0, 1)}
            {customer?.lastname?.slice(0, 1)}
          </div>
          <div className="flex flex-col ">
            <p className="text-[#D3EAFF] text-[14px]">{customer?.public_id}</p>
            <p className="text-white text-[24px]">
              {customer?.firstname?.charAt(0)?.toUpperCase() +
                customer?.firstname?.slice(1)?.toLowerCase()}{" "}
              {customer?.lastname?.charAt(0)?.toUpperCase() +
                customer?.lastname?.slice(1)?.toLowerCase()}
            </p>
            <p className="text-[#D3EAFF] text-[14px]">{customer?.username}</p>
          </div>
          <div className="w-[86px] h-[44px] rounded-[30px] bg-[#054884] flex gap-[5px] justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <g clip-path="url(#clip0_7769_947)">
                <path
                  d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                  fill="#0C722F"
                />
              </g>
              <defs>
                <clipPath id="clip0_7769_947">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className="text-white text-[12px]">{customer?.status}</p>
          </div>
        </div>
        <div className="flex flex-col gap-[10px]">
          <div
            onClick={openContinueSuspendAcc}
            className="w-[194px] cursor-pointer h-[31px] bg-[#FAB32B] gap-[10px] rounded-[40px] flex items-center p-[10px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M3.91136 18.375H17.0889C18.4364 18.375 19.2764 16.9137 18.6026 15.75L12.0139 4.36622C11.3401 3.20247 9.66011 3.20247 8.98636 4.36622L2.39761 15.75C1.72386 16.9137 2.56386 18.375 3.91136 18.375ZM10.5001 12.25C10.0189 12.25 9.62511 11.8562 9.62511 11.375V9.62497C9.62511 9.14372 10.0189 8.74997 10.5001 8.74997C10.9814 8.74997 11.3751 9.14372 11.3751 9.62497V11.375C11.3751 11.8562 10.9814 12.25 10.5001 12.25ZM11.3751 15.75H9.62511V14H11.3751V15.75Z"
                fill="white"
              />
            </svg>
            <p className="text-white text-[14px]">Suspend Account</p>
          </div>
          <div className="w-[194px] h-[31px] bg-[#FF5757] gap-[10px] rounded-[40px] flex items-center p-[10px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5.83301 17.5C5.37467 17.5 4.98217 17.3367 4.65551 17.01C4.32884 16.6833 4.16579 16.2911 4.16634 15.8333V5H3.33301V3.33333H7.49967V2.5H12.4997V3.33333H16.6663V5H15.833V15.8333C15.833 16.2917 15.6697 16.6842 15.343 17.0108C15.0163 17.3375 14.6241 17.5006 14.1663 17.5H5.83301ZM7.49967 14.1667H9.16634V6.66667H7.49967V14.1667ZM10.833 14.1667H12.4997V6.66667H10.833V14.1667Z"
                fill="white"
              />
            </svg>
            <p className="text-white text-[14px]">Close Account</p>
          </div>
        </div>
      </div>
      <div className="my-[10px] px-[20px]">
        <button
          onClick={handleBack}
          className="bg-[#074073] h-[40px] text-white text-[16px] w-[140px] rounded flex items-center justify-center gap-[10px] px-[20px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.78em"
            height="1em"
            viewBox="0 0 16 9"
          >
            <path
              fill="currentColor"
              d="M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5"
            />
            <path
              fill="currentColor"
              d="M6 8.5a.47.47 0 0 1-.35-.15l-3.5-3.5c-.2-.2-.2-.51 0-.71L5.65.65c.2-.2.51-.2.71 0c.2.2.2.51 0 .71L3.21 4.51l3.15 3.15c.2.2.2.51 0 .71c-.1.1-.23.15-.35.15Z"
            />
          </svg>
          Back
        </button>
      </div>
      <div className="p-[10px] h-[700px] overflow-y-auto">
        <div
          className="w-full mb-[10px] px-[10px] py-[5px] bg-white"
          style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <p className="text-[#074073] text-[16px]">Account</p>
          <div className="w-full flex gap-[20px]">
            <div
              style={{ backgroundColor: `${state.color}` }}
              className="bg-image-1 h-[165px] mb-[20px] rounded-[10px] w-[40%] p-[10px]"
            >
              <div className="w-full flex items-center justify-between">
                <p className="text-[#FFFFFFB2] text-[12px]">Account Type</p>
                <p className="text-[#FFFFFFB2] text-[12px]">Account Number</p>
              </div>
              <div className="w-full flex items-center justify-between">
                <p className="text-[#FFFFFF] text-[20px]">
                  {singleAccount?.product?.name}
                </p>
                <p className="text-[#FFFFFF] text-[20px]">
                  {singleAccount?.account_number}
                </p>
              </div>
              <div>
                <p className="text-[#FFFFFFB2] text-[12px]">Account Balance</p>
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-[5px]">
                  <p className="text-white font-bold">KES.</p>
                  <p
                    className={showBalance ? `blur` : ""}
                    style={{ color: "white", fontSize: "16px" }}
                  >
                    <span className="text-[36px] text-white font-bold">
                      {Number(singleAccount?.balance || "0.00")?.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </span>
                  </p>
                </div>
                <div className="bg-[#D3EAFF] w-[28px] h-[20px] rounded-[60%] flex items-center justify-center">
                  {showBalance ? (
                    <svg
                      onClick={toggleBalanceVisibility}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="cursor-pointer"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      >
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0-4 0" />
                        <path d="M21 12c-2.4 4-5.4 6-9 6c-3.6 0-6.6-2-9-6c2.4-4 5.4-6 9-6c3.6 0 6.6 2 9 6" />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      onClick={toggleBalanceVisibility}
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="-2 -2 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="m15.398 7.23l1.472-1.472C18.749 6.842 20 8.34 20 10c0 3.314-4.958 5.993-10 6a14.734 14.734 0 0 1-3.053-.32l1.747-1.746c.426.044.862.067 1.303.066h.002c-.415 0-.815-.063-1.191-.18l1.981-1.982c.47-.202.847-.579 1.05-1.049l1.98-1.981A4 4 0 0 1 10.022 14C14.267 13.985 18 11.816 18 10c0-.943-1.022-1.986-2.602-2.77m-9.302 3.645A4 4 0 0 1 9.993 6C5.775 5.985 2 8.178 2 10c0 .896.904 1.877 2.327 2.644L2.869 14.1C1.134 13.028 0 11.585 0 10c0-3.314 4.984-6.017 10-6c.914.003 1.827.094 2.709.262l-1.777 1.776c-.29-.022-.584-.035-.88-.038c.282.004.557.037.823.096l-4.78 4.779zM19.092.707a1 1 0 0 1 0 1.414l-16.97 16.97a1 1 0 1 1-1.415-1.413L17.677.708a1 1 0 0 1 1.415 0z"
                      />
                    </svg>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-[10px] justify-end w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                >
                  <circle cx="4.5" cy="4.5" r="4.5" fill="#42F980" />
                </svg>
                <p className="text-white text-[14px]">Active</p>
              </div>
            </div>
            <div className="w-[60%] flex flex-col gap-[20px]">
              <div className="w-[40%] flex items-center justify-between">
                <p>Created on</p>
                <p className="text-right">
                  {singleAccount?.createdAt
                    ? new Date(singleAccount?.createdAt)
                        .toISOString()
                        .split("T")[0]
                    : "N/A"}
                </p>
              </div>
              <div className="w-[40%] flex items-center justify-between">
                <p>Total spent this month</p>
                <p className="text-right">
                  {Number(spending?.total_spent_month).toFixed(2)}
                </p>
              </div>
              {/* <div className="w-[40%] flex items-center justify-between">
                <p>Limit status</p>
                <p className="text-right">No</p>
              </div> */}
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="w-full flex items-center bg-[#E3F8FF] px-[10px] min-h-[44px]">
              <div className="w-[10%]">
                <input type="checkbox" name="" id="" />
              </div>
              <p className="w-[20%] text-[#000]">TRANSACTION ID</p>
              <p className="w-[14%] text-[#000]">AMOUNT</p>
              <p className="w-[10%] text-[#000]">TYPE</p>
              <p className="w-[20%] text-[#000]">RECIPIENT</p>
              <p className="w-[14%] text-[#000]">DATE</p>
              <p className="w-[14%] text-[#000]">STATUS</p>
            </div>
            <div className="h-[430px] overflow-y-auto">
              {transactions.length > 0 ? (
                <>
                  {transactions.map((transaction) => (
                    <div
                      onClick={() => openTransactionModal(transaction)}
                      key={transaction?.id}
                      className="w-full flex cursor-pointer items-center bg-white px-[10px] min-h-[44px]"
                    >
                      <div className="w-[10%]">
                        <input
                          type="checkbox"
                          name="transaction"
                          id="transaction"
                        />
                      </div>
                      <p className="w-[20%] text-[#037BD1]">
                        {transaction?.public_id}
                      </p>
                      <p className="w-[14%]">
                        KES &nbsp;
                        {Number(transaction?.amount || "0.00")?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                      <p className="w-[10%]">{transaction?.type}</p>
                      <p className="w-[20%]">
                        {transaction?.receiver_name?.charAt(0)?.toUpperCase() +
                          transaction?.receiver_name?.slice(1)?.toLowerCase()}
                      </p>
                      <p className="w-[14%]">
                        {transaction?.createdAt
                          ? new Date(transaction?.createdAt)
                              .toISOString()
                              .split("T")[0]
                          : "N/A"}
                      </p>
                      <div className="w-[14%] flex items-center gap-[10px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_7843_842)">
                            <path
                              d="M12.5 0C13.6016 0 14.6641 0.140625 15.6875 0.421875C16.7109 0.703125 17.6641 1.10938 18.5469 1.64062C19.4297 2.17188 20.2383 2.79688 20.9727 3.51562C21.707 4.23438 22.3359 5.04297 22.8594 5.94141C23.3828 6.83984 23.7852 7.79688 24.0664 8.8125C24.3477 9.82812 24.4922 10.8906 24.5 12C24.5 13.1016 24.3594 14.1641 24.0781 15.1875C23.7969 16.2109 23.3906 17.1641 22.8594 18.0469C22.3281 18.9297 21.7031 19.7383 20.9844 20.4727C20.2656 21.207 19.457 21.8359 18.5586 22.3594C17.6602 22.8828 16.7031 23.2852 15.6875 23.5664C14.6719 23.8477 13.6094 23.9922 12.5 24C11.3984 24 10.3359 23.8594 9.3125 23.5781C8.28906 23.2969 7.33594 22.8906 6.45312 22.3594C5.57031 21.8281 4.76172 21.2031 4.02734 20.4844C3.29297 19.7656 2.66406 18.957 2.14062 18.0586C1.61719 17.1602 1.21484 16.2031 0.933594 15.1875C0.652344 14.1719 0.507812 13.1094 0.5 12C0.5 10.8984 0.640625 9.83594 0.921875 8.8125C1.20312 7.78906 1.60938 6.83594 2.14062 5.95312C2.67188 5.07031 3.29688 4.26172 4.01562 3.52734C4.73438 2.79297 5.54297 2.16406 6.44141 1.64062C7.33984 1.11719 8.29688 0.714844 9.3125 0.433594C10.3281 0.152344 11.3906 0.0078125 12.5 0ZM19.5664 8.02734L17.9727 6.43359L10.25 14.1562L7.02734 10.9336L5.43359 12.5273L10.25 17.3438L19.5664 8.02734Z"
                              fill="#0C722F"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7843_842">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <p>{transaction?.status}</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className="mt-[10px]">No record of transactions</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <TransactionModal
        transactionDetailsOpen={openTransaction}
        closeTransactionDetails={closeTransactionModal}
        singleTransaction={singleTransaction}
      />
      <ContinueSuspendAcc
        isContinueSuspendAccModalOpen={isContinueSuspendAccModalOpen}
        closeContinueSuspendAcc={closeContinueSuspendAcc}
        handleOpenSuspendReason={handleOpenSuspendReason}
      />
      <SuspensionReason
        id_R="suspension-reasons"
        suspendReasonOpen={suspendReasonOpen}
        handleCloseSuspendReason={handleCloseSuspendReason}
        handleOpenDocumentReason={handleOpenDocumentReason}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <DocumentReason
        id_R="document-reasons"
        documentReasonOpen={documentReasonOpen}
        handleCloseDocumentReason={handleCloseDocumentReason}
        handleOpenSuspensionImplications={handleOpenSuspensionImplications}
        handleOpenSuspendReason={handleOpenSuspendReason}
        note={note}
        setNote={setNote}
      />
      <SuspensionImplications
        id_R="suspension-implications"
        suspensionImplicationsOpen={suspensionImplicationsOpen}
        handleCloseSuspensionImplications={handleCloseSuspensionImplications}
        handleOpenReviewSuspension={handleOpenReviewSuspension}
        handleOpenDocumentReason={handleOpenDocumentReason}
        toggleState={toggleState}
        setToggleState={setToggleState}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <ReviewSuspension
        id_R="review-suspension"
        reviewSuspensionOpen={reviewSuspensionOpen}
        handleCloseReviewSuspension={handleCloseReviewSuspension}
        handleOpenSuspendReason={handleOpenSuspendReason}
        handleOpenDocumentReason={handleOpenDocumentReason}
        handleOpenSuspensionImplications={handleOpenSuspensionImplications}
        activeTab={activeTab}
        toggleState={toggleState}
        note={note}
        selectedOption={selectedOption}
        firstName={customer?.firstname}
        lastName={customer?.lastname}
        accountNumber={singleAccount?.account_number}
        productName={singleAccount?.product?.name}
        setIsSuspendAccountModalOpen={setIsSuspendAccountModalOpen}
      />
      <SuspendAccount
        isSuspendAccountModalOpen={isSuspendAccountModalOpen}
        closeSuspendAccount={closeSuspendAccount}
        handleSuspendAccount={handleSuspendAccount}
      />
    </div>
  );
};

export default CustomerTransactions;
