import React, { useState, useEffect } from "react";

const ManageContactInfo = ({
  id_R,
  closeContaInfoModal,
  manageContaInfoModalOpen,
  selectedUser,
  updateContactInfo,
  closeAdminUserModal,
  getAdminUsers,
}) => {
  const [editFirstName, setEditFisrtName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editMobileno, setEditMobileno] = useState("");
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      setEditFisrtName(selectedUser?.firstname || "");
      setEditLastName(selectedUser?.lastname || "");
      setEditMobileno(selectedUser?.phone || "");
    }
  }, [selectedUser]);

  const handleFirstNameChange = (e) => {
    setEditFisrtName(e.target.value);
    setChangesMade(true);
  };

  const handleLastNameChange = (e) => {
    setEditLastName(e.target.value);
    setChangesMade(true);
  };

  const handleEditMobilenoChange = (e) => {
    setEditMobileno(e.target.value);
    setChangesMade(true);
  };

  const handleSaveChanges = async () => {
    try {
      // Call getAddressInfo with the appropriate data
      const response = await updateContactInfo({
        id: selectedUser?.id,
        firstname: editFirstName,
        lastname: editLastName,
        phone: editMobileno,
      });

      // Handle the response as needed
      console.log("Backend response:", response);

      closeContaInfoModal();
      closeAdminUserModal();
      getAdminUsers();
    } catch (error) {
      // Handle errors
      console.error("Error submitting data:", error);
    }
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          manageContaInfoModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex items-center fixed min-h-screen max-w-[100%] h-full md:h-auto">
          <div className="relative justify-end bg-white shadow max-w-[100%] mx-auto h-[95vh]">
            <div className="rounded-t flex items-center">
              <div className="px-5 flex items-end h-[10vh] rounded-t">
                <button
                  onClick={closeContaInfoModal}
                  type="button"
                  className="bg-transparent  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-toggle={id_R}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M17.0874 7.48148H4.09029L10.0602 1.50698L8.54369 0L0 8.55026L8.54369 17.1005L10.0495 15.5935L4.09029 9.61905H17.0874V7.48148Z"
                      fill="black"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                  <p className="text-[20px] font-bold text-[#000] ml-5">
                    Manage contact information
                  </p>
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center w-[58vw] h-[15vh]">
              <div className="w-[95%] text-[#000] text-sm font-normal ">
                <p>
                  ⚠️ Please be aware that changing your username is a sensitive
                  operation. This action will impact how the user is
                </p>
                <p>identified across the platform.</p>
              </div>
            </div>

            <div className="flex items-center justify-between h-[15vh] px-5">
              <div className="flex flex-col justify-center">
                <label
                  htmlFor="nameInput"
                  className="mb-1 text-md font-medium text-[#000]"
                >
                  First name
                </label>
                <input
                  type="text"
                  id="nameInput"
                  name="firstName"
                  placeholder={selectedUser?.firstname}
                  value={editFirstName}
                  onChange={handleFirstNameChange}
                  className="w-[27vw] h-10 px-4 border border-[1px] border-[#000]"
                />
              </div>
              <div className="flex flex-col justify-center">
                <label
                  htmlFor="nameInput"
                  className="mb-1 text-md font-medium text-[#000]"
                >
                  Email
                </label>
                <input
                  type="text"
                  id="nameInput"
                  name="lastName"
                  placeholder={selectedUser?.lastname}
                  value={editLastName}
                  onChange={handleLastNameChange}
                  className="w-[27vw] h-10 px-4 border border-[1px] border-[#000]"
                />
              </div>
            </div>

            <div className="flex items-center justify-between h-[15vh] px-5">
              <div className="flex flex-col justify-center">
                <label
                  htmlFor="phoneNumberInput"
                  className="mb-1 text-md font-medium text-[#000]"
                >
                  Phone number
                </label>
                <input
                  type="text"
                  id="phoneNumberInput"
                  name="mobileno"
                  placeholder="Enter your phone number"
                  value={editMobileno}
                  onChange={handleEditMobilenoChange}
                  className="w-[27vw] h-10 px-4 border border-[1px] border-[#000]"
                />
              </div>
            </div>

            <div className="flex items-end justify-between h-[32vh] px-5">
              <button
                data-modal-toggle={id_R}
                type="button"
                className={`${
                  changesMade
                    ? "text-white bg-[#074073] hover:bg-[#074073]"
                    : "text-[#000] bg-[#D9D9D9] hover:bg-[#D9D9D9]"
                } focus:outline-none focus:ring-blue-300 rounded-[5px] text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10`}
                onClick={handleSaveChanges}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageContactInfo;
