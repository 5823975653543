import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Calendar from "../../components/calendar/Calendar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getLoansFilters,
  getLoansReport,
  exportAllLoans,
  exportSelectedLoans,
} from "../../sdk/loans/loans";
import { toast } from "react-toastify";
import ExportShares from "../../components/export/ExportShares";
import { downloadCSV, downloadPDF } from "../../hooks/download";

const Loans = () => {
  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const today = moment();
  const [fromDate, setFromDate] = useState(today.clone().startOf("month"));

  const [customerFocusedInput, setCustomerFocusedInput] = useState(null);
  const [toDate, setToDate] = useState(today);
  let [currentPage, setCurrentPage] = useState(1);
  let [totalPages, setTotalPages] = useState(null);
  const [limit, setLimit] = useState(10);
  const [displayedPage, setDisplayedPage] = useState(1);
  const [totalNoOfLoans, setTotalNoOfLoans] = useState(null);
  const [loanType, setLoanType] = useState("0");
  const [loanDuration, setLoanDuration] = useState("0-0");
  const [repayment, setRepayment] = useState("0-0");
  const [currentStatus, setCurrentStatus] = useState("All");
  const navigate = useNavigate();
  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
  };

  const navigateToLoan = (loanData) => {
    const [loanCode, customerId] = loanData;
    navigate(`/dashboard/loans/${loanCode}/${customerId}`);
  };

  const handleLoanDurationChange = (e) => {
    setLoanDuration(e.target.value);
  };

  const handleRepaymentChange = (e) => {
    setRepayment(e.target.value);
  };

  const handleStatusChange = (e) => {
    setCurrentStatus(e.target.value);
  };

  const [loanReport, setLoanReport] = useState([]);
  const [loanFilters, setLoanFilters] = useState([]);

  const fetchLoanReport = async () => {
    try {
      const response = await getLoansReport();
      setLoanReport(response?.data?.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const fetchLoanFilters = async () => {
    try {
      const response = await getLoansFilters(
        currentPage,
        limit,
        moment(fromDate).format("YYYY-MM-DD HH:mm:ss"),
        moment(toDate).format("YYYY-MM-DD HH:mm:ss"),
        currentStatus == "Approved" ? "Active" : currentStatus,
        loanType,
        repayment,
        "filter",
        loanDuration,
        query
      );
      setDisplayedPage(Number(response?.data?.data?.meta?.currentpage));

      setLoanFilters(
        response?.data?.data?.loan_data?.sort((a, b) => {
          return new Date(b?.loan_date) - new Date(a?.loan_date);
        })
      );
      setTotalPages(
        Math.ceil(
          Number(response.data.data.meta.itemcount) /
            Number(response.data.data.meta.itemsperpage)
        )
      );
      setTotalNoOfLoans(response.data.data.meta.itemcount);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchLoanFilters();
  }, [
    currentPage,
    limit,
    fromDate,
    toDate,
    currentStatus,
    loanType,
    repayment,
    loanDuration,
    query,
  ]);

  useEffect(() => {
    fetchLoanReport();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    const newItemsPerPage = Number(e.target.value);
    setLimit(newItemsPerPage);
    setCurrentPage(1);
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= Math.min(totalPages, 3); i++) {
      pages.push(
        <div
          key={i}
          className={`w-[36px] cursor-pointer h-[36px] rounded-[10px] flex items-center justify-center ${
            currentPage === i
              ? "bg-[#074073] text-white"
              : "bg-[#F5F5F5] text-[#074073]"
          }`}
          onClick={() => handlePageChange(i)}
        >
          <p>{i}</p>
        </div>
      );
    }

    return pages;
  };
  const [refreshLoading, setRefreshLoading] = useState(false);

  const handleRefetch = async () => {
    setRefreshLoading(true);
    await fetchLoanFilters();
    setRefreshLoading(false);
  };


  const [selectedLoans, setSelectedLoans] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (event, share) => {
    if (event.target.checked) {
      setSelectedLoans((prevSelectedShares) => [...prevSelectedShares, share]);
    } else {
      setSelectedLoans((prevSelectedShares) =>
        prevSelectedShares.filter(
          (selectedShare) => selectedShare.memberId !== share.memberId
        )
      );
    }
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedLoans(isChecked ? loanFilters : []);
  };

  const handleExportAllLoans = async (exportType, limit) => {
    const response = await exportAllLoans(exportType, limit);
    exportType === "pdf"
      ? await downloadPDF(response, "loans.pdf")
      : await downloadCSV(response, "loans.csv");
  };

  const handleExportLoans = async (exportType) => {
    try {
      const response = await exportSelectedLoans(exportType, selectedLoans);
      exportType === "pdf"
        ? await downloadPDF(response, "selected_members.pdf")
        : await downloadCSV(response, "selected_members.csv");
      setSelectAll(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anansi || Home</title>
      </Helmet>
      <div className="w-[100%] bg-[#FFF] h-full overflow-y-auto">
        <div className="bg-[#FFF] mx-[20px]">
          <div className="flex items-center text-[#074073] text-[20px] font-bold">
            Loans
          </div>
          <div className="flex items-center justify-end">
            <button
              className="block text-white bg-[#074073] focus:outline-none focus:ring-[#074073] rounded-[30px] text-center px-4 h-[42px]"
              type="button"
            >
              <div className="flex items-center justify-center gap-2">
                <p className="text-[#FFF] text-[14px]">
                  Create new laon application
                </p>
              </div>
            </button>
          </div>
          <div className="text-[#074073] text-[16px] font-[600]">Overview</div>
          <div className="flex gap-2 mt-4">
            <div className="flex-col flex items-center justify-center w-[28.7%] bg-[#E0FFEB] rounded-[10px] border-[1px] border-[#88F48A]">
              <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#BFE0FD]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                    stroke="#466F94"
                  />
                  <path
                    d="M16.4997 5.5C15.7703 5.5 15.0709 5.21027 14.5551 4.69454C14.0394 4.17882 13.7497 3.47935 13.7497 2.75M16.4997 7.33333C15.7703 7.33333 15.0709 7.62306 14.5551 8.13879C14.0394 8.65451 13.7497 9.35399 13.7497 10.0833M5.49967 5.5C6.22902 5.5 6.92849 5.21027 7.44422 4.69454C7.95994 4.17882 8.24967 3.47935 8.24967 2.75M5.49967 7.33333C6.22902 7.33333 6.92849 7.62306 7.44422 8.13879C7.95994 8.65451 8.24967 9.35399 8.24967 10.0833M4.58301 18.689H6.65467C7.58051 18.689 8.51826 18.7862 9.41934 18.9713C11.0281 19.3018 12.6834 19.3385 14.3052 19.0795C15.1008 18.9512 15.8818 18.7541 16.5895 18.4131C17.2275 18.1042 18.0094 17.6706 18.5347 17.1838C19.059 16.698 19.6053 15.9032 19.9922 15.2817C20.3258 14.7482 20.1645 14.0947 19.6383 13.6977C19.3427 13.4827 18.9865 13.3668 18.6208 13.3668C18.2552 13.3668 17.899 13.4827 17.6033 13.6977L15.9469 14.949C15.3053 15.4348 14.604 15.8812 13.7689 16.0142C13.6681 16.0297 13.5627 16.0444 13.4527 16.0572M13.4527 16.0572C13.4191 16.0611 13.3855 16.0648 13.3518 16.0682M13.4527 16.0572C13.5994 16.0176 13.7339 15.942 13.8441 15.8372C13.9822 15.7178 14.095 15.5718 14.1758 15.408C14.2566 15.2442 14.3038 15.0659 14.3146 14.8835C14.3253 14.7012 14.2995 14.5186 14.2385 14.3464C14.1775 14.1742 14.0827 14.016 13.9596 13.8811C13.8401 13.7485 13.701 13.635 13.5471 13.5447C10.9832 12.0147 6.99292 13.1798 4.58301 14.8894M13.4527 16.0572C13.4195 16.0645 13.3857 16.0682 13.3518 16.0682M13.3518 16.0682C12.799 16.1238 12.2421 16.125 11.689 16.0719"
                    stroke="#466F94"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.58301 14.2083C4.58301 13.4489 3.9674 12.8333 3.20801 12.8333C2.44862 12.8333 1.83301 13.4489 1.83301 14.2083V18.7916C1.83301 19.551 2.44862 20.1666 3.20801 20.1666C3.9674 20.1666 4.58301 19.551 4.58301 18.7916V14.2083Z"
                    stroke="#466F94"
                  />
                </svg>
              </div>
              <div className="flex justify-center mt-3 gap-2">
                <p className="text-[#074073] text-[24px] font-bold">
                  Ksh.{" "}
                  {Number(
                    loanReport?.total_loan_book || "0.00"
                  )?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
              <p className="mt-3 mb-3 text-[#074073] text-[12px]">Loan Book</p>
            </div>
            <div className="flex-col flex items-center justify-center w-[28.7%] bg-[#F7FBFF] rounded-[10px] border-[1px] border-[#88C1F4]">
              <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#BFE0FD]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                    stroke="#466F94"
                  />
                  <path
                    d="M16.4997 5.5C15.7703 5.5 15.0709 5.21027 14.5551 4.69454C14.0394 4.17882 13.7497 3.47935 13.7497 2.75M16.4997 7.33333C15.7703 7.33333 15.0709 7.62306 14.5551 8.13879C14.0394 8.65451 13.7497 9.35399 13.7497 10.0833M5.49967 5.5C6.22902 5.5 6.92849 5.21027 7.44422 4.69454C7.95994 4.17882 8.24967 3.47935 8.24967 2.75M5.49967 7.33333C6.22902 7.33333 6.92849 7.62306 7.44422 8.13879C7.95994 8.65451 8.24967 9.35399 8.24967 10.0833M4.58301 18.689H6.65467C7.58051 18.689 8.51826 18.7862 9.41934 18.9713C11.0281 19.3018 12.6834 19.3385 14.3052 19.0795C15.1008 18.9512 15.8818 18.7541 16.5895 18.4131C17.2275 18.1042 18.0094 17.6706 18.5347 17.1838C19.059 16.698 19.6053 15.9032 19.9922 15.2817C20.3258 14.7482 20.1645 14.0947 19.6383 13.6977C19.3427 13.4827 18.9865 13.3668 18.6208 13.3668C18.2552 13.3668 17.899 13.4827 17.6033 13.6977L15.9469 14.949C15.3053 15.4348 14.604 15.8812 13.7689 16.0142C13.6681 16.0297 13.5627 16.0444 13.4527 16.0572M13.4527 16.0572C13.4191 16.0611 13.3855 16.0648 13.3518 16.0682M13.4527 16.0572C13.5994 16.0176 13.7339 15.942 13.8441 15.8372C13.9822 15.7178 14.095 15.5718 14.1758 15.408C14.2566 15.2442 14.3038 15.0659 14.3146 14.8835C14.3253 14.7012 14.2995 14.5186 14.2385 14.3464C14.1775 14.1742 14.0827 14.016 13.9596 13.8811C13.8401 13.7485 13.701 13.635 13.5471 13.5447C10.9832 12.0147 6.99292 13.1798 4.58301 14.8894M13.4527 16.0572C13.4195 16.0645 13.3857 16.0682 13.3518 16.0682M13.3518 16.0682C12.799 16.1238 12.2421 16.125 11.689 16.0719"
                    stroke="#466F94"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.58301 14.2083C4.58301 13.4489 3.9674 12.8333 3.20801 12.8333C2.44862 12.8333 1.83301 13.4489 1.83301 14.2083V18.7916C1.83301 19.551 2.44862 20.1666 3.20801 20.1666C3.9674 20.1666 4.58301 19.551 4.58301 18.7916V14.2083Z"
                    stroke="#466F94"
                  />
                </svg>
              </div>
              <div className="flex justify-center mt-3 gap-2">
                <p className="text-[#074073] text-[24px] font-bold">
                  Ksh.{" "}
                  {Number(
                    loanReport?.total_loan_volume || "0.00"
                  )?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
              <p className="mt-3 mb-3 text-[#074073] text-[12px]">
                Total loan volume
              </p>
            </div>
            <div className="flex-col flex items-center justify-center w-[28.7%] rounded-[10px] border-[1px] border-[#E6E6E6]">
              <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#BFFDE3]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                    stroke="#466F94"
                  />
                  <path
                    d="M16.4997 5.5C15.7703 5.5 15.0709 5.21027 14.5551 4.69454C14.0394 4.17882 13.7497 3.47935 13.7497 2.75M16.4997 7.33333C15.7703 7.33333 15.0709 7.62306 14.5551 8.13879C14.0394 8.65451 13.7497 9.35399 13.7497 10.0833M5.49967 5.5C6.22902 5.5 6.92849 5.21027 7.44422 4.69454C7.95994 4.17882 8.24967 3.47935 8.24967 2.75M5.49967 7.33333C6.22902 7.33333 6.92849 7.62306 7.44422 8.13879C7.95994 8.65451 8.24967 9.35399 8.24967 10.0833M4.58301 18.689H6.65467C7.58051 18.689 8.51826 18.7862 9.41934 18.9713C11.0281 19.3018 12.6834 19.3385 14.3052 19.0795C15.1008 18.9512 15.8818 18.7541 16.5895 18.4131C17.2275 18.1042 18.0094 17.6706 18.5347 17.1838C19.059 16.698 19.6053 15.9032 19.9922 15.2817C20.3258 14.7482 20.1645 14.0947 19.6383 13.6977C19.3427 13.4827 18.9865 13.3668 18.6208 13.3668C18.2552 13.3668 17.899 13.4827 17.6033 13.6977L15.9469 14.949C15.3053 15.4348 14.604 15.8812 13.7689 16.0142C13.6681 16.0297 13.5627 16.0444 13.4527 16.0572M13.4527 16.0572C13.4191 16.0611 13.3855 16.0648 13.3518 16.0682M13.4527 16.0572C13.5994 16.0176 13.7339 15.942 13.8441 15.8372C13.9822 15.7178 14.095 15.5718 14.1758 15.408C14.2566 15.2442 14.3038 15.0659 14.3146 14.8835C14.3253 14.7012 14.2995 14.5186 14.2385 14.3464C14.1775 14.1742 14.0827 14.016 13.9596 13.8811C13.8401 13.7485 13.701 13.635 13.5471 13.5447C10.9832 12.0147 6.99292 13.1798 4.58301 14.8894M13.4527 16.0572C13.4195 16.0645 13.3857 16.0682 13.3518 16.0682M13.3518 16.0682C12.799 16.1238 12.2421 16.125 11.689 16.0719"
                    stroke="#466F94"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.58301 14.2083C4.58301 13.4489 3.9674 12.8333 3.20801 12.8333C2.44862 12.8333 1.83301 13.4489 1.83301 14.2083V18.7916C1.83301 19.551 2.44862 20.1666 3.20801 20.1666C3.9674 20.1666 4.58301 19.551 4.58301 18.7916V14.2083Z"
                    stroke="#466F94"
                  />
                </svg>
              </div>
              <div className="flex justify-center mt-3 gap-2">
                <p className="text-[#074073] text-[24px] font-bold">
                  {Number(loanReport?.active_loans || "0.00")?.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )}
                </p>
              </div>
              <p className="mt-3 mb-3 text-[#074073] text-[12px]">
                Active Loans
              </p>
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className="flex-col w-[14%] flex items-center justify-center px-3 border-[#E6E6E6] border-[1px] rounded-[10px]">
              <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#C7FDBF]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                    stroke="#466F94"
                  />
                  <path
                    d="M16.5002 5.5C15.7708 5.5 15.0713 5.21027 14.5556 4.69454C14.0399 4.17882 13.7502 3.47935 13.7502 2.75M16.5002 7.33333C15.7708 7.33333 15.0713 7.62306 14.5556 8.13879C14.0399 8.65451 13.7502 9.35399 13.7502 10.0833M5.50016 5.5C6.22951 5.5 6.92898 5.21027 7.44471 4.69454C7.96043 4.17882 8.25016 3.47935 8.25016 2.75M5.50016 7.33333C6.22951 7.33333 6.92898 7.62306 7.44471 8.13879C7.96043 8.65451 8.25016 9.35399 8.25016 10.0833M4.5835 18.689H6.65516C7.581 18.689 8.51875 18.7862 9.41983 18.9713C11.0286 19.3018 12.6839 19.3385 14.3057 19.0795C15.1013 18.9512 15.8823 18.7541 16.59 18.4131C17.228 18.1042 18.0099 17.6706 18.5352 17.1838C19.0595 16.698 19.6058 15.9032 19.9927 15.2817C20.3263 14.7482 20.165 14.0947 19.6388 13.6977C19.3432 13.4827 18.9869 13.3668 18.6213 13.3668C18.2557 13.3668 17.8995 13.4827 17.6038 13.6977L15.9474 14.949C15.3057 15.4348 14.6045 15.8812 13.7694 16.0142C13.6686 16.0297 13.5632 16.0444 13.4532 16.0572M13.4532 16.0572C13.4196 16.0611 13.386 16.0648 13.3523 16.0682M13.4532 16.0572C13.5999 16.0176 13.7344 15.942 13.8446 15.8372C13.9827 15.7178 14.0955 15.5718 14.1763 15.408C14.2571 15.2442 14.3043 15.0659 14.3151 14.8835C14.3258 14.7012 14.3 14.5186 14.239 14.3464C14.178 14.1742 14.0832 14.016 13.9601 13.8811C13.8406 13.7485 13.7015 13.635 13.5476 13.5447C10.9837 12.0147 6.99341 13.1798 4.5835 14.8894M13.4532 16.0572C13.42 16.0645 13.3862 16.0682 13.3523 16.0682M13.3523 16.0682C12.7995 16.1238 12.2426 16.125 11.6895 16.0719"
                    stroke="#466F94"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.5835 14.2085C4.5835 13.4491 3.96789 12.8335 3.2085 12.8335C2.4491 12.8335 1.8335 13.4491 1.8335 14.2085V18.7918C1.8335 19.5512 2.4491 20.1668 3.2085 20.1668C3.96789 20.1668 4.5835 19.5512 4.5835 18.7918V14.2085Z"
                    stroke="#466F94"
                  />
                </svg>
              </div>
              <p className="mt-3 text-[#074073] text-[24px] font-bold">
                {Number(loanReport.approved_loans || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
              <div className="mt-3 mb-3 text-[#074073] text-[12px] ">
                <p>Approved loans</p>
              </div>
            </div>
            <div className="flex-col w-[14%] flex items-center justify-center px-3 border-[#E6E6E6] border-[1px] rounded-[10px]">
              <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#FDF3BF]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                    stroke="#466F94"
                  />
                  <path
                    d="M16.5002 5.5C15.7708 5.5 15.0713 5.21027 14.5556 4.69454C14.0399 4.17882 13.7502 3.47935 13.7502 2.75M16.5002 7.33333C15.7708 7.33333 15.0713 7.62306 14.5556 8.13879C14.0399 8.65451 13.7502 9.35399 13.7502 10.0833M5.50016 5.5C6.22951 5.5 6.92898 5.21027 7.44471 4.69454C7.96043 4.17882 8.25016 3.47935 8.25016 2.75M5.50016 7.33333C6.22951 7.33333 6.92898 7.62306 7.44471 8.13879C7.96043 8.65451 8.25016 9.35399 8.25016 10.0833M4.5835 18.689H6.65516C7.581 18.689 8.51875 18.7862 9.41983 18.9713C11.0286 19.3018 12.6839 19.3385 14.3057 19.0795C15.1013 18.9512 15.8823 18.7541 16.59 18.4131C17.228 18.1042 18.0099 17.6706 18.5352 17.1838C19.0595 16.698 19.6058 15.9032 19.9927 15.2817C20.3263 14.7482 20.165 14.0947 19.6388 13.6977C19.3432 13.4827 18.9869 13.3668 18.6213 13.3668C18.2557 13.3668 17.8995 13.4827 17.6038 13.6977L15.9474 14.949C15.3057 15.4348 14.6045 15.8812 13.7694 16.0142C13.6686 16.0297 13.5632 16.0444 13.4532 16.0572M13.4532 16.0572C13.4196 16.0611 13.386 16.0648 13.3523 16.0682M13.4532 16.0572C13.5999 16.0176 13.7344 15.942 13.8446 15.8372C13.9827 15.7178 14.0955 15.5718 14.1763 15.408C14.2571 15.2442 14.3043 15.0659 14.3151 14.8835C14.3258 14.7012 14.3 14.5186 14.239 14.3464C14.178 14.1742 14.0832 14.016 13.9601 13.8811C13.8406 13.7485 13.7015 13.635 13.5476 13.5447C10.9837 12.0147 6.99341 13.1798 4.5835 14.8894M13.4532 16.0572C13.42 16.0645 13.3862 16.0682 13.3523 16.0682M13.3523 16.0682C12.7995 16.1238 12.2426 16.125 11.6895 16.0719"
                    stroke="#466F94"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.5835 14.2085C4.5835 13.4491 3.96789 12.8335 3.2085 12.8335C2.4491 12.8335 1.8335 13.4491 1.8335 14.2085V18.7918C1.8335 19.5512 2.4491 20.1668 3.2085 20.1668C3.96789 20.1668 4.5835 19.5512 4.5835 18.7918V14.2085Z"
                    stroke="#466F94"
                  />
                </svg>
              </div>
              <p className="mt-3 text-[#074073] text-[24px] font-bold">
                {Number(loanReport?.pending_loans || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
              <div className="mt-3 mb-3 text-[#074073] text-[12px] ">
                <p>Pending loan</p>
                <p>applications</p>
              </div>
            </div>
            <div className="flex-col w-[14%] flex items-center justify-center px-3 border-[#E6E6E6] border-[1px] rounded-[10px]">
              <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#FDBFBF]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                    stroke="#466F94"
                  />
                  <path
                    d="M16.5002 5.5C15.7708 5.5 15.0713 5.21027 14.5556 4.69454C14.0399 4.17882 13.7502 3.47935 13.7502 2.75M16.5002 7.33333C15.7708 7.33333 15.0713 7.62306 14.5556 8.13879C14.0399 8.65451 13.7502 9.35399 13.7502 10.0833M5.50016 5.5C6.22951 5.5 6.92898 5.21027 7.44471 4.69454C7.96043 4.17882 8.25016 3.47935 8.25016 2.75M5.50016 7.33333C6.22951 7.33333 6.92898 7.62306 7.44471 8.13879C7.96043 8.65451 8.25016 9.35399 8.25016 10.0833M4.5835 18.689H6.65516C7.581 18.689 8.51875 18.7862 9.41983 18.9713C11.0286 19.3018 12.6839 19.3385 14.3057 19.0795C15.1013 18.9512 15.8823 18.7541 16.59 18.4131C17.228 18.1042 18.0099 17.6706 18.5352 17.1838C19.0595 16.698 19.6058 15.9032 19.9927 15.2817C20.3263 14.7482 20.165 14.0947 19.6388 13.6977C19.3432 13.4827 18.9869 13.3668 18.6213 13.3668C18.2557 13.3668 17.8995 13.4827 17.6038 13.6977L15.9474 14.949C15.3057 15.4348 14.6045 15.8812 13.7694 16.0142C13.6686 16.0297 13.5632 16.0444 13.4532 16.0572M13.4532 16.0572C13.4196 16.0611 13.386 16.0648 13.3523 16.0682M13.4532 16.0572C13.5999 16.0176 13.7344 15.942 13.8446 15.8372C13.9827 15.7178 14.0955 15.5718 14.1763 15.408C14.2571 15.2442 14.3043 15.0659 14.3151 14.8835C14.3258 14.7012 14.3 14.5186 14.239 14.3464C14.178 14.1742 14.0832 14.016 13.9601 13.8811C13.8406 13.7485 13.7015 13.635 13.5476 13.5447C10.9837 12.0147 6.99341 13.1798 4.5835 14.8894M13.4532 16.0572C13.42 16.0645 13.3862 16.0682 13.3523 16.0682M13.3523 16.0682C12.7995 16.1238 12.2426 16.125 11.6895 16.0719"
                    stroke="#466F94"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.5835 14.2085C4.5835 13.4491 3.96789 12.8335 3.2085 12.8335C2.4491 12.8335 1.8335 13.4491 1.8335 14.2085V18.7918C1.8335 19.5512 2.4491 20.1668 3.2085 20.1668C3.96789 20.1668 4.5835 19.5512 4.5835 18.7918V14.2085Z"
                    stroke="#466F94"
                  />
                </svg>
              </div>
              <p className="mt-3 text-[#074073] text-[24px] font-bold">
                {Number(loanReport?.rejected_loans || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
              <div className="mt-3 mb-3 text-[#074073] text-[12px] ">
                <p>Rejected loans</p>
              </div>
            </div>
            <div className="flex-col w-[14%] flex items-center justify-center px-3 border-[#E6E6E6] border-[1px] rounded-[10px]">
              <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#FDF3BF]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                    stroke="#466F94"
                  />
                  <path
                    d="M16.5002 5.5C15.7708 5.5 15.0713 5.21027 14.5556 4.69454C14.0399 4.17882 13.7502 3.47935 13.7502 2.75M16.5002 7.33333C15.7708 7.33333 15.0713 7.62306 14.5556 8.13879C14.0399 8.65451 13.7502 9.35399 13.7502 10.0833M5.50016 5.5C6.22951 5.5 6.92898 5.21027 7.44471 4.69454C7.96043 4.17882 8.25016 3.47935 8.25016 2.75M5.50016 7.33333C6.22951 7.33333 6.92898 7.62306 7.44471 8.13879C7.96043 8.65451 8.25016 9.35399 8.25016 10.0833M4.5835 18.689H6.65516C7.581 18.689 8.51875 18.7862 9.41983 18.9713C11.0286 19.3018 12.6839 19.3385 14.3057 19.0795C15.1013 18.9512 15.8823 18.7541 16.59 18.4131C17.228 18.1042 18.0099 17.6706 18.5352 17.1838C19.0595 16.698 19.6058 15.9032 19.9927 15.2817C20.3263 14.7482 20.165 14.0947 19.6388 13.6977C19.3432 13.4827 18.9869 13.3668 18.6213 13.3668C18.2557 13.3668 17.8995 13.4827 17.6038 13.6977L15.9474 14.949C15.3057 15.4348 14.6045 15.8812 13.7694 16.0142C13.6686 16.0297 13.5632 16.0444 13.4532 16.0572M13.4532 16.0572C13.4196 16.0611 13.386 16.0648 13.3523 16.0682M13.4532 16.0572C13.5999 16.0176 13.7344 15.942 13.8446 15.8372C13.9827 15.7178 14.0955 15.5718 14.1763 15.408C14.2571 15.2442 14.3043 15.0659 14.3151 14.8835C14.3258 14.7012 14.3 14.5186 14.239 14.3464C14.178 14.1742 14.0832 14.016 13.9601 13.8811C13.8406 13.7485 13.7015 13.635 13.5476 13.5447C10.9837 12.0147 6.99341 13.1798 4.5835 14.8894M13.4532 16.0572C13.42 16.0645 13.3862 16.0682 13.3523 16.0682M13.3523 16.0682C12.7995 16.1238 12.2426 16.125 11.6895 16.0719"
                    stroke="#466F94"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.5835 14.2085C4.5835 13.4491 3.96789 12.8335 3.2085 12.8335C2.4491 12.8335 1.8335 13.4491 1.8335 14.2085V18.7918C1.8335 19.5512 2.4491 20.1668 3.2085 20.1668C3.96789 20.1668 4.5835 19.5512 4.5835 18.7918V14.2085Z"
                    stroke="#466F94"
                  />
                </svg>
              </div>
              <p className="mt-3 text-[#074073] text-[24px] font-bold">
                {Number(loanReport?.overdue_loans || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
              <div className="mt-3 mb-3 text-[#074073] text-[12px] ">
                <p>Overdue loans</p>
              </div>
            </div>
            <div className="flex-col w-[14%] flex items-center justify-center px-3 border-[#E6E6E6] border-[1px] rounded-[10px]">
              <div className="flex items-center justify-center mt-3 w-[32px] h-[32px] rounded-[30px] bg-[#FDBFBF]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M15.9628 9.54617C16.5 9.009 16.5 8.1455 16.5 6.41667C16.5 4.68783 16.5 3.82433 15.9628 3.28717M15.9628 9.54617C15.4257 10.0833 14.5622 10.0833 12.8333 10.0833H9.16667C7.43783 10.0833 6.57433 10.0833 6.03717 9.54617M15.9628 3.28717C15.4257 2.75 14.5622 2.75 12.8333 2.75H9.16667C7.43783 2.75 6.57433 2.75 6.03717 3.28717M6.03717 3.28717C5.5 3.82433 5.5 4.68783 5.5 6.41667C5.5 8.1455 5.5 9.009 6.03717 9.54617M11.9167 6.41667C11.9167 6.65978 11.8201 6.89294 11.6482 7.06485C11.4763 7.23676 11.2431 7.33333 11 7.33333C10.7569 7.33333 10.5237 7.23676 10.3518 7.06485C10.1799 6.89294 10.0833 6.65978 10.0833 6.41667C10.0833 6.17355 10.1799 5.94039 10.3518 5.76849C10.5237 5.59658 10.7569 5.5 11 5.5C11.2431 5.5 11.4763 5.59658 11.6482 5.76849C11.8201 5.94039 11.9167 6.17355 11.9167 6.41667Z"
                    stroke="#466F94"
                  />
                  <path
                    d="M16.5002 5.5C15.7708 5.5 15.0713 5.21027 14.5556 4.69454C14.0399 4.17882 13.7502 3.47935 13.7502 2.75M16.5002 7.33333C15.7708 7.33333 15.0713 7.62306 14.5556 8.13879C14.0399 8.65451 13.7502 9.35399 13.7502 10.0833M5.50016 5.5C6.22951 5.5 6.92898 5.21027 7.44471 4.69454C7.96043 4.17882 8.25016 3.47935 8.25016 2.75M5.50016 7.33333C6.22951 7.33333 6.92898 7.62306 7.44471 8.13879C7.96043 8.65451 8.25016 9.35399 8.25016 10.0833M4.5835 18.689H6.65516C7.581 18.689 8.51875 18.7862 9.41983 18.9713C11.0286 19.3018 12.6839 19.3385 14.3057 19.0795C15.1013 18.9512 15.8823 18.7541 16.59 18.4131C17.228 18.1042 18.0099 17.6706 18.5352 17.1838C19.0595 16.698 19.6058 15.9032 19.9927 15.2817C20.3263 14.7482 20.165 14.0947 19.6388 13.6977C19.3432 13.4827 18.9869 13.3668 18.6213 13.3668C18.2557 13.3668 17.8995 13.4827 17.6038 13.6977L15.9474 14.949C15.3057 15.4348 14.6045 15.8812 13.7694 16.0142C13.6686 16.0297 13.5632 16.0444 13.4532 16.0572M13.4532 16.0572C13.4196 16.0611 13.386 16.0648 13.3523 16.0682M13.4532 16.0572C13.5999 16.0176 13.7344 15.942 13.8446 15.8372C13.9827 15.7178 14.0955 15.5718 14.1763 15.408C14.2571 15.2442 14.3043 15.0659 14.3151 14.8835C14.3258 14.7012 14.3 14.5186 14.239 14.3464C14.178 14.1742 14.0832 14.016 13.9601 13.8811C13.8406 13.7485 13.7015 13.635 13.5476 13.5447C10.9837 12.0147 6.99341 13.1798 4.5835 14.8894M13.4532 16.0572C13.42 16.0645 13.3862 16.0682 13.3523 16.0682M13.3523 16.0682C12.7995 16.1238 12.2426 16.125 11.6895 16.0719"
                    stroke="#466F94"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.5835 14.2085C4.5835 13.4491 3.96789 12.8335 3.2085 12.8335C2.4491 12.8335 1.8335 13.4491 1.8335 14.2085V18.7918C1.8335 19.5512 2.4491 20.1668 3.2085 20.1668C3.96789 20.1668 4.5835 19.5512 4.5835 18.7918V14.2085Z"
                    stroke="#466F94"
                  />
                </svg>
              </div>
              <p className="mt-3 text-[#074073] text-[24px] font-bold">
                {Number(loanReport?.defaulted_loans || "0.00")?.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
              </p>
              <div className="mt-3 mb-3 text-[#074073] text-[12px] ">
                <p>Defaulted loans</p>
              </div>
            </div>
          </div>
          <div className="mt-8 flex items-center text-[#074073] text-[20px] font-bold">
            ACTIVE LOANS
          </div>
          <div className="flex items-center justify-between mt-2 w-[100%]">
            <div className="relative items-center justify-center">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                >
                  <circle
                    cx="5.35294"
                    cy="5.35294"
                    r="4.85294"
                    stroke="#999999"
                  />
                  <line
                    x1="9.52933"
                    y1="8.82294"
                    x2="13.3529"
                    y2="12.6465"
                    stroke="#999999"
                  />
                </svg>
              </span>
              <input
                type="text"
                value={query}
                onChange={handleSearch}
                placeholder="Search Loan id, customer name"
                className="h-[35px] pl-10 border-[1px] border-[#CCCCCC] text-[14px] rounded-[2px] w-[36vw] px-[10px] focus:outline-none"
              />
            </div>
            <div className="flex gap-4">
              <button
                onClick={handleRefetch}
                className="block text-white bg-[#074073] focus:outline-none focus:ring-[#074073] rounded-[3px] text-center w-[10vw] h-[35px]"
                type="button"
              >
                <div className="flex items-center justify-center gap-2">
                  {!refreshLoading && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                    >
                      <path
                        d="M14.5931 22.9583C12.232 22.9583 10.2318 22.1395 8.59252 20.5018C6.95402 18.8633 6.13477 16.8639 6.13477 14.5036C6.13477 12.1433 6.95402 10.1431 8.59252 8.50303C10.2318 6.86211 12.232 6.04166 14.5931 6.04166C16.0294 6.04166 17.3739 6.38281 18.6265 7.06511C19.88 7.74742 20.8901 8.69999 21.657 9.92282V6.04166H22.8653V12.3612H16.5458V11.1529H21.0166C20.3875 9.9486 19.5022 8.99684 18.3607 8.29761C17.2208 7.5992 15.965 7.24999 14.5931 7.24999C12.5792 7.24999 10.8674 7.95485 9.45768 9.36457C8.04796 10.7743 7.3431 12.4861 7.3431 14.5C7.3431 16.5139 8.04796 18.2257 9.45768 19.6354C10.8674 21.0451 12.5792 21.75 14.5931 21.75C16.1438 21.75 17.5434 21.3069 18.7921 20.4208C20.0407 19.5347 20.9167 18.3667 21.4202 16.9167H22.7022C22.1698 18.7259 21.1576 20.1852 19.6657 21.2944C18.1738 22.4037 16.4829 22.9583 14.5931 22.9583Z"
                        fill="white"
                      />
                    </svg>
                  )}
                  {refreshLoading && (
                    <svg
                      aria-hidden="true"
                      className="w-[20px] h-[20px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  )}
                  <p className="text-[#FFF] font-normal text-[16px]">REFRESH</p>
                </div>
              </button>
              <ExportShares
                handleExportAllShares={handleExportAllLoans}
                handleExportMembers={handleExportLoans}
                selectedMembers={selectedLoans}
                setSelectedMembers={setSelectedLoans}
                limit={limit}
              />
            </div>
          </div>
          <div className="mt-3 h-[112px] flex px-[10px] w-full bg-[#FBF0E1]">
            <div className=" w-[4vw] flex items-center justify-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="13"
                viewBox="0 0 18 13"
                fill="none"
              >
                <path
                  d="M0.666504 1.5835C0.666504 1.25198 0.7982 0.934033 1.03262 0.699612C1.26704 0.465192 1.58498 0.333496 1.9165 0.333496H16.0832C16.4147 0.333496 16.7326 0.465192 16.9671 0.699612C17.2015 0.934033 17.3332 1.25198 17.3332 1.5835C17.3332 1.91502 17.2015 2.23296 16.9671 2.46738C16.7326 2.7018 16.4147 2.8335 16.0832 2.8335H1.9165C1.58498 2.8335 1.26704 2.7018 1.03262 2.46738C0.7982 2.23296 0.666504 1.91502 0.666504 1.5835ZM3.99984 6.5835C3.99984 6.25198 4.13153 5.93403 4.36595 5.69961C4.60037 5.46519 4.91832 5.3335 5.24984 5.3335H12.7498C13.0814 5.3335 13.3993 5.46519 13.6337 5.69961C13.8681 5.93403 13.9998 6.25198 13.9998 6.5835C13.9998 6.91502 13.8681 7.23296 13.6337 7.46738C13.3993 7.7018 13.0814 7.8335 12.7498 7.8335H5.24984C4.91832 7.8335 4.60037 7.7018 4.36595 7.46738C4.13153 7.23296 3.99984 6.91502 3.99984 6.5835ZM7.74984 10.3335C7.41832 10.3335 7.10037 10.4652 6.86595 10.6996C6.63153 10.934 6.49984 11.252 6.49984 11.5835C6.49984 11.915 6.63153 12.233 6.86595 12.4674C7.10037 12.7018 7.41832 12.8335 7.74984 12.8335H10.2498C10.5814 12.8335 10.8993 12.7018 11.1337 12.4674C11.3681 12.233 11.4998 11.915 11.4998 11.5835C11.4998 11.252 11.3681 10.934 11.1337 10.6996C10.8993 10.4652 10.5814 10.3335 10.2498 10.3335H7.74984Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="flex items-center gap-[10px] w-[28vw] justify-center">
              <div className="flex-col">
                <p className="text-[12px] font-normal text-end text-[#000] mt-1">
                  Date issued
                </p>
                <p className="text-[12px] font-normal text-end text-[#000] mt-4">
                  Loan duration
                </p>
              </div>
              <div className="flex-col">
                <div className="flex items-center gap-[15px] w-[253px]">
                  <Calendar
                    startDate={fromDate}
                    endDate={toDate}
                    setStartDate={setFromDate}
                    setEndDate={setToDate}
                    focusedInput={customerFocusedInput}
                    setFocusedInput={setCustomerFocusedInput}
                  />
                </div>
                <div className="mt-2 flex items-center gap-[5px]">
                  <select
                    name="type"
                    id="type"
                    className="w-[203px] h-[30px] px-[10px] focus:outline-none"
                    onChange={handleLoanDurationChange}
                    value={loanDuration}
                  >
                    <option value="0-0">All</option>
                    <option value="0-1">1 months</option>
                    <option value="1-3">3 months</option>
                    <option value="3-6">6 months </option>
                    <option value="6-12">12 months </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center gap-[10px] w-[24vw] ">
              <div className="flex-col">
                <p className="text-[12px] font-normal text-end text-[#000]">
                  Loan type
                </p>
                <p className="text-[12px] font-normal text-end text-[#000] mt-7">
                  Status
                </p>
              </div>
              <div className="flex-col">
                <div className="flex items-center gap-[5px]">
                  <select
                    name="type"
                    id="type"
                    className="w-[11vw] h-[30px] px-[10px] focus:outline-none"
                    onChange={handleLoanTypeChange}
                    value={loanType}
                  >
                    <option value="0">All</option>
                    <option value="Digital_Loan">Digital loan</option>
                    <option value="Checkoff_Loan">Check-off loan</option>
                  </select>
                </div>
                <div className="mt-4 flex items-center gap-[5px]">
                  <select
                    name="type"
                    id="type"
                    className="w-[11vw] h-[30px] px-[10px] focus:outline-none"
                    onChange={handleStatusChange}
                    value={currentStatus}
                  >
                    <option value="Active">Current</option>
                    <option value="Approved">Approved</option>
                    <option value="All">All</option>
                    <option value="Defaulted">Defaulted</option>
                    <option value="Due">Due</option>
                    <option value="Pending">Pending</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="flex items-start justify-center w-[24vw] mt-5">
              <div className="flex items-center gap-[10px]">
                <p className="text-[12px] font-normal text-[#000]">
                  Repayment amount
                </p>
                <select
                  name="type"
                  id="type"
                  className="w-[9vw] h-[30px] px-[10px] focus:outline-none"
                  onChange={handleRepaymentChange}
                  value={repayment}
                >
                  <option value="0-0">All</option>
                  <option value="0-1000">KES 0- 1,000</option>
                  <option value="0-5000">KES 0 - 5,000</option>
                  <option value="0-10000">KES 0 - 10,000</option>
                  <option value="0-50000">KES 0 - 50,000</option>
                  <option value="0-100000">KES 0 - 100,000</option>
                  <option value="0-1000000">OVER KES 1,000,000</option>
                </select>
              </div>
            </div>
          </div>

          <div className="h-[44px] bg-[#E3F8FF] w-full flex items-center">
            <input
              type="checkbox"
              name="transaction"
              id="transaction"
              className="w-[10vw] flex items-center"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <p className="w-[16vw] text-[14px] text-[#000000]">LOAN ID</p>
            <p className="w-[30vw] text-[14px] text-[#000000]">CUSTOMER</p>
            <p className="w-[20vw] text-[14px] text-[#000000]">Loan Balance</p>
            <p className="w-[24vw] text-[14px] text-[#000000]">LOAN TYPE</p>
            <p className="w-[22vw] text-[14px] text-[#000000]">LOAN DURATION</p>
            <p className="w-[20vw] text-[14px] text-[#000000]">INTEREST %</p>
            <p className="w-[28vw] text-[14px] text-[#000000]">
              REPAYMENT AMOUNT
            </p>
            <p className="w-[24vw] text-[14px] text-[#000000]">DATE ISSUED</p>
            <p className="w-[22vw] text-[14px] text-[#000000]">STATUS</p>
          </div>

          {loanFilters?.length === 0 ? (
            <div className="mt-[20px]">
              <p>No record of loan transactions</p>
            </div>
          ) : (
            loanFilters?.map((loan) => (
              <div
                key={loan?.loan_code}
                className="h-[44px] bg-[#fff] w-full flex items-center mt-[2px] cursor-pointer"
              >
                <input
                  type="checkbox"
                  name="transaction"
                  id="transaction"
                  className="w-[10vw] flex"
                  onChange={(event) => handleCheckboxChange(event, loan)}
                  checked={selectedLoans.some(
                    (selectedLoan) =>
                      selectedLoan?.loan_code === loan?.loan_code
                  )}
                />
                <p
                  onClick={() =>
                    navigateToLoan([loan?.loan_code, loan?.customer_id])
                  }
                  className="w-[16vw] text-[#037BD1] text-[14px] text-[#000000]"
                >
                  #{loan?.loan_code}
                </p>
                <p className="w-[30vw] text-[14px] text-[#000000]">
                  {loan?.loan_name
                    .split(" ")
                    .map(
                      (name) =>
                        name?.charAt(0)?.toUpperCase() +
                        name?.slice(1)?.toLowerCase()
                    )
                    .join(" ")}
                </p>
                <p className="w-[20vw] text-[14px] text-[#000000]">
                  KES{" "}
                  {Number(loan?.loan_Balance || "0.00")?.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </p>
                <p className="w-[24vw] text-[14px] text-[#000000]">
                  {loan?.loan_type?.replace("_", " ")}
                </p>
                <p className="w-[22vw] text-[14px] text-[#000000]">
                  {loan?.loan_period} Days
                </p>
                <p className="w-[20vw] text-[14px] text-[#000000]">
                  {loan?.loan_interest_per}%&nbsp;{loan?.interest_key}
                </p>
                <p className="w-[28vw] text-[14px] text-[#000000]">
                  KES &nbsp;
                  {Number(loan?.loan_total_amount || "0.00")?.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </p>
                <p className="w-[24vw] text-[14px] text-[#000000]">
                  {loan?.loan_date}
                </p>
                <div className="flex items-center gap-[5px] w-[22vw]">
                  {loan?.loan_status === "Active" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_8496_1672)">
                        <path
                          d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                          fill="#0C722F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8496_1672">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {loan?.loan_status === "Paid-off" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_8496_1645)">
                        <path
                          d="M6 0C6.55078 0 7.08203 0.0703125 7.59375 0.210938C8.10547 0.351562 8.58203 0.554688 9.02344 0.820312C9.46484 1.08594 9.86914 1.39844 10.2363 1.75781C10.6035 2.11719 10.918 2.52148 11.1797 2.9707C11.4414 3.41992 11.6426 3.89844 11.7832 4.40625C11.9238 4.91406 11.9961 5.44531 12 6C12 6.55078 11.9297 7.08203 11.7891 7.59375C11.6484 8.10547 11.4453 8.58203 11.1797 9.02344C10.9141 9.46484 10.6016 9.86914 10.2422 10.2363C9.88281 10.6035 9.47852 10.918 9.0293 11.1797C8.58008 11.4414 8.10156 11.6426 7.59375 11.7832C7.08594 11.9238 6.55469 11.9961 6 12C5.44922 12 4.91797 11.9297 4.40625 11.7891C3.89453 11.6484 3.41797 11.4453 2.97656 11.1797C2.53516 10.9141 2.13086 10.6016 1.76367 10.2422C1.39648 9.88281 1.08203 9.47852 0.820312 9.0293C0.558594 8.58008 0.357422 8.10156 0.216797 7.59375C0.0761719 7.08594 0.00390625 6.55469 0 6C0 5.44922 0.0703125 4.91797 0.210938 4.40625C0.351562 3.89453 0.554688 3.41797 0.820312 2.97656C1.08594 2.53516 1.39844 2.13086 1.75781 1.76367C2.11719 1.39648 2.52148 1.08203 2.9707 0.820312C3.41992 0.558594 3.89844 0.357422 4.40625 0.216797C4.91406 0.0761719 5.44531 0.00390625 6 0ZM9.5332 4.01367L8.73633 3.2168L4.875 7.07812L3.26367 5.4668L2.4668 6.26367L4.875 8.67188L9.5332 4.01367Z"
                          fill="#0C722F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8496_1645">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {loan?.loan_status === "Overdue" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 19 20"
                      fill="none"
                    >
                      <path
                        d="M9.5 10.8906V6.14062V10.8906ZM17.2188 10C17.2188 12.0471 16.4055 14.0104 14.958 15.458C13.5104 16.9055 11.5471 17.7188 9.5 17.7188C7.45286 17.7188 5.48957 16.9055 4.04202 15.458C2.59447 14.0104 1.78125 12.0471 1.78125 10C1.78125 7.95286 2.59447 5.98957 4.04202 4.54202C5.48957 3.09447 7.45286 2.28125 9.5 2.28125C11.5471 2.28125 13.5104 3.09447 14.958 4.54202C16.4055 5.98957 17.2188 7.95286 17.2188 10Z"
                        fill="#FAB32B"
                      />
                      <path
                        d="M9.5 10.8906V6.14062M17.2188 10C17.2187 12.0471 16.4055 14.0104 14.958 15.458C13.5104 16.9055 11.5471 17.7188 9.5 17.7188C7.45286 17.7188 5.48957 16.9055 4.04202 15.458C2.59447 14.0104 1.78125 12.0471 1.78125 10C1.78125 7.95286 2.59447 5.98957 4.04202 4.54202C5.48957 3.09447 7.45286 2.28125 9.5 2.28125C11.5471 2.28125 13.5104 3.09447 14.958 4.54202C16.4055 5.98957 17.2188 7.95286 17.2188 10Z"
                        stroke="white"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0938 13.5625C10.0938 13.72 10.0312 13.871 9.91985 13.9823C9.8085 14.0937 9.65747 14.1562 9.5 14.1562C9.34253 14.1562 9.19151 14.0937 9.08016 13.9823C8.96881 13.871 8.90625 13.72 8.90625 13.5625C8.90625 13.405 8.96881 13.254 9.08016 13.1427C9.19151 13.0313 9.34253 12.9688 9.5 12.9688C9.65747 12.9688 9.8085 13.0313 9.91985 13.1427C10.0312 13.254 10.0938 13.405 10.0938 13.5625Z"
                        fill="#FAB32B"
                        stroke="white"
                      />
                    </svg>
                  )}
                  {loan?.loan_status === "Defaulted" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M13.246 3.19481L8.996 1.60105C8.67725 1.48064 8.32308 1.48064 8.00433 1.60105L3.75433 3.19481C3.20183 3.40731 2.8335 3.93147 2.8335 4.52647V7.85564C2.8335 11.3548 5.14975 14.6344 8.29475 15.5269C8.42933 15.5623 8.571 15.5623 8.6985 15.5269C11.8506 14.6344 14.1668 11.3548 14.1668 7.85564V4.52647C14.1668 3.93147 13.7985 3.40731 13.246 3.19481ZM10.4835 10.4765C10.2072 10.7527 9.761 10.7527 9.48475 10.4836L8.50016 9.50606L7.51558 10.4836C7.38314 10.616 7.20351 10.6904 7.0162 10.6904C6.8289 10.6904 6.64927 10.616 6.51683 10.4836C6.38439 10.3511 6.30998 10.1715 6.30998 9.98418C6.30998 9.79688 6.38439 9.61725 6.51683 9.48481L7.50141 8.50022L6.51683 7.51564C6.38439 7.3832 6.30998 7.20357 6.30998 7.01626C6.30998 6.82896 6.38439 6.64933 6.51683 6.51689C6.64927 6.38445 6.8289 6.31004 7.0162 6.31004C7.20351 6.31004 7.38314 6.38445 7.51558 6.51689L8.50016 7.50147L9.48475 6.51689C9.61719 6.38445 9.79682 6.31004 9.98412 6.31004C10.1714 6.31004 10.3511 6.38445 10.4835 6.51689C10.6159 6.64933 10.6903 6.82896 10.6903 7.01626C10.6903 7.20357 10.6159 7.3832 10.4835 7.51564L9.506 8.50022L10.4835 9.47772C10.7597 9.75397 10.7597 10.2002 10.4835 10.4765Z"
                        fill="#FF3232"
                      />
                    </svg>
                  )}
                  {loan?.loan_status === "Cleared" && (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_9348_2027)">
                        <path
                          d="M6 0C6.55078 0 7.08203 0.0703125 7.59375 0.210938C8.10547 0.351562 8.58203 0.554688 9.02344 0.820312C9.46484 1.08594 9.86914 1.39844 10.2363 1.75781C10.6035 2.11719 10.918 2.52148 11.1797 2.9707C11.4414 3.41992 11.6426 3.89844 11.7832 4.40625C11.9238 4.91406 11.9961 5.44531 12 6C12 6.55078 11.9297 7.08203 11.7891 7.59375C11.6484 8.10547 11.4453 8.58203 11.1797 9.02344C10.9141 9.46484 10.6016 9.86914 10.2422 10.2363C9.88281 10.6035 9.47852 10.918 9.0293 11.1797C8.58008 11.4414 8.10156 11.6426 7.59375 11.7832C7.08594 11.9238 6.55469 11.9961 6 12C5.44922 12 4.91797 11.9297 4.40625 11.7891C3.89453 11.6484 3.41797 11.4453 2.97656 11.1797C2.53516 10.9141 2.13086 10.6016 1.76367 10.2422C1.39648 9.88281 1.08203 9.47852 0.820312 9.0293C0.558594 8.58008 0.357422 8.10156 0.216797 7.59375C0.0761719 7.08594 0.00390625 6.55469 0 6C0 5.44922 0.0703125 4.91797 0.210938 4.40625C0.351562 3.89453 0.554688 3.41797 0.820312 2.97656C1.08594 2.53516 1.39844 2.13086 1.75781 1.76367C2.11719 1.39648 2.52148 1.08203 2.9707 0.820312C3.41992 0.558594 3.89844 0.357422 4.40625 0.216797C4.91406 0.0761719 5.44531 0.00390625 6 0ZM9.5332 4.01367L8.73633 3.2168L4.875 7.07812L3.26367 5.4668L2.4668 6.26367L4.875 8.67188L9.5332 4.01367Z"
                          fill="#0C722F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_9348_2027">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  {loan?.loan_status === "NPL" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                    >
                      <path
                        d="M9.5 10.8906V6.14062ZM17.2188 10C17.2187 12.0471 16.4055 14.0104 14.958 15.458C13.5104 16.9055 11.5471 17.7188 9.5 17.7188C7.45286 17.7188 5.48957 16.9055 4.04202 15.458C2.59447 14.0104 1.78125 12.0471 1.78125 10C1.78125 7.95286 2.59447 5.98957 4.04202 4.54202C5.48957 3.09447 7.45286 2.28125 9.5 2.28125C11.5471 2.28125 13.5104 3.09447 14.958 4.54202C16.4055 5.98957 17.2188 7.95286 17.2188 10Z"
                        fill="#FF3232"
                      />
                      <path
                        d="M9.5 10.8906V6.14062M17.2188 10C17.2187 12.0471 16.4055 14.0104 14.958 15.458C13.5104 16.9055 11.5471 17.7188 9.5 17.7188C7.45286 17.7188 5.48957 16.9055 4.04202 15.458C2.59447 14.0104 1.78125 12.0471 1.78125 10C1.78125 7.95286 2.59447 5.98957 4.04202 4.54202C5.48957 3.09447 7.45286 2.28125 9.5 2.28125C11.5471 2.28125 13.5104 3.09447 14.958 4.54202C16.4055 5.98957 17.2188 7.95286 17.2188 10Z"
                        stroke="white"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0938 13.5625C10.0938 13.72 10.0312 13.871 9.91985 13.9823C9.8085 14.0937 9.65747 14.1562 9.5 14.1562C9.34253 14.1562 9.19151 14.0937 9.08016 13.9823C8.96881 13.871 8.90625 13.72 8.90625 13.5625C8.90625 13.405 8.96881 13.254 9.08016 13.1427C9.19151 13.0313 9.34253 12.9688 9.5 12.9688C9.65747 12.9688 9.8085 13.0313 9.91985 13.1427C10.0312 13.254 10.0938 13.405 10.0938 13.5625Z"
                        fill="#FF3232"
                        stroke="white"
                      />
                    </svg>
                  )}
                  {loan?.loan_status === "Pending" && (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.75 8.625C4.0625 8.625 4.32825 8.5155 4.54725 8.2965C4.76625 8.0775 4.8755 7.812 4.875 7.5C4.875 7.1875 4.7655 6.92175 4.5465 6.70275C4.3275 6.48375 4.062 6.3745 3.75 6.375C3.4375 6.375 3.17175 6.4845 2.95275 6.7035C2.73375 6.9225 2.6245 7.188 2.625 7.5C2.625 7.8125 2.7345 8.07825 2.9535 8.29725C3.1725 8.51625 3.438 8.6255 3.75 8.625ZM7.5 8.625C7.8125 8.625 8.07825 8.5155 8.29725 8.2965C8.51625 8.0775 8.6255 7.812 8.625 7.5C8.625 7.1875 8.5155 6.92175 8.2965 6.70275C8.0775 6.48375 7.812 6.3745 7.5 6.375C7.1875 6.375 6.92175 6.4845 6.70275 6.7035C6.48375 6.9225 6.3745 7.188 6.375 7.5C6.375 7.8125 6.4845 8.07825 6.7035 8.29725C6.9225 8.51625 7.188 8.6255 7.5 8.625ZM11.25 8.625C11.5625 8.625 11.8282 8.5155 12.0472 8.2965C12.2662 8.0775 12.3755 7.812 12.375 7.5C12.375 7.1875 12.2655 6.92175 12.0465 6.70275C11.8275 6.48375 11.562 6.3745 11.25 6.375C10.9375 6.375 10.6717 6.4845 10.4527 6.7035C10.2337 6.9225 10.1245 7.188 10.125 7.5C10.125 7.8125 10.2345 8.07825 10.4535 8.29725C10.6725 8.51625 10.938 8.6255 11.25 8.625ZM7.5 15C6.4625 15 5.4875 14.803 4.575 14.409C3.6625 14.015 2.86875 13.4807 2.19375 12.8062C1.51875 12.1312 0.9845 11.3375 0.591 10.425C0.1975 9.5125 0.0005 8.5375 0 7.5C0 6.4625 0.197 5.4875 0.591 4.575C0.985 3.6625 1.51925 2.86875 2.19375 2.19375C2.86875 1.51875 3.6625 0.9845 4.575 0.591C5.4875 0.1975 6.4625 0.0005 7.5 0C8.5375 0 9.5125 0.197 10.425 0.591C11.3375 0.985 12.1312 1.51925 12.8062 2.19375C13.4812 2.86875 14.0157 3.6625 14.4097 4.575C14.8037 5.4875 15.0005 6.4625 15 7.5C15 8.5375 14.803 9.5125 14.409 10.425C14.015 11.3375 13.4807 12.1312 12.8062 12.8062C12.1312 13.4812 11.3375 14.0157 10.425 14.4097C9.5125 14.8037 8.5375 15.0005 7.5 15Z"
                        fill="#554D4D"
                      />
                    </svg>
                  )}
                  <p className="text-[14px] text-[#000000]">
                    {loan?.loan_status === "Cleared"
                      ? "Paid-off"
                      : loan.loan_status === "Active"
                      ? "Current"
                      : loan?.loan_status}
                  </p>
                </div>
              </div>
            ))
          )}
          <div className="h-[70px] bg-white w-[97%] px-[20px] mx-auto mb-[20px] flex items-center justify-between">
            <div className="flex items-center gap-[20px]">
              <p className="text-[#074073] text-[14px]">Rows per page</p>
              <select
                name="page"
                id="page"
                className="w-[71px] border-[1px] px-[5px] h-[30px]"
                value={limit}
                onChange={handleLimitChange}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
              <p className="text-[#074073] text-[14px]">
                {displayedPage} / {totalPages} ({totalNoOfLoans} records)
              </p>
            </div>
            <div className="flex items-center gap-[15px]">
              <p
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                className="text-[14px] text-[#074073] cursor-pointer"
              >
                Prev
              </p>
              {renderPages()}
              <div
                className={`w-[36px] cursor-pointer h-[36px] rounded-[10px] flex items-center justify-center ${
                  Number(currentPage) > 3
                    ? "bg-[#074073] text-white"
                    : "bg-[#F5F5F5] text-[#074073]"
                }`}
              >
                <p>{Number(currentPage) > 3 ? currentPage : "..."}</p>
              </div>
              <p
                onClick={() =>
                  handlePageChange(Math.min(currentPage + 1, totalPages))
                }
                className="text-[14px] cursor-pointer text-[#074073]"
              >
                Next
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loans;
