import React, { useState, useEffect } from "react";
import CustomerProgress from "../../../components/progressBar/CustomerProgress";

const SecondModal = ({
  id_R,
  closeEmailModal,
  emailModalOpen,
  openModal,
  openPhoneModal,
  email,
  setEmail,
}) => {
  const [emailError, setEmailError] = useState("");
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  useEffect(() => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is valid
    const isValid = emailRegex.test(email);

    // Update the state to enable/disable the "Next" button
    setIsNextButtonDisabled(!isValid);
  }, [email]);

  const handleNextButtonClick = () => {
    if (!isNextButtonDisabled) {
      // Close the current modal
      closeEmailModal();

      // Open the next modal
      openPhoneModal();
    }
  };

  return (
    <>
      <div
        id={id_R}
        className={`${
          emailModalOpen ? "flex" : "hidden"
        } items-center justify-center fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-50`}
      >
        <div className="relative flex fixed min-h-[80%] w-[70%]">
          <div className="relative flex bg-white shadow w-full mx-auto">
            <div className="w-[30%]">
              <div className="pt-4 px-2 rounded-t">
                <p className="text-[20px] font-bold text-[#000] ml-5">
                  Add Member
                </p>
              </div>
              <div className="mt-[32px] px-6">
                <CustomerProgress step={1} />
              </div>
            </div>
            <div className="w-[70%] flex flex-col">
              <div className="flex items-center justify-end">
                <button
                  onClick={closeEmailModal}
                  type="button"
                  className="bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle={id_R}
                >
                  <svg
                    width="61"
                    height="43"
                    viewBox="0 0 61 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_13891_3896)">
                      <path
                        d="M32.84 28L30.7 24.9L28.9 28H25.2L28.88 22.3L25.1 16.84H28.94L31.08 19.92L32.88 16.84H36.58L32.84 22.46L36.68 28H32.84Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13891_3896">
                        <rect width="61" height="43" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div>
                <div className="mt-6 mx-auto items-center">
                  <p className="text-[#000] text-xl font-bold">
                    Enter and verify Member email
                  </p>
                  <div className="text-[#000000] mt-[10px] text-sm font-normal">
                    <p>Kindly share Member’s primary email for verification.</p>
                  </div>
                </div>
                <div className="flex flex-col justify-center mt-[50px]">
                  <label
                    htmlFor="email"
                    className="mb-1 text-md font-medium text-[#000]"
                  >
                    Email
                  </label>
                  <div className="flex gap-5">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError(""); // Clear error when user starts typing
                      }}
                      className="w-[27vw] h-10 px-4 border border-[1px] border-[#000]"
                    />
                  </div>
                  {emailError && (
                    <p className="text-red-500 text-sm mt-1">{emailError}</p>
                  )}
                </div>
              </div>

              <div className="flex w-[95%] items-center justify-end mt-60 mb-10 mr-5">
                <button
                  onClick={handleNextButtonClick}
                  data-modal-toggle={id_R}
                  type="button"
                  className={`text-white ${
                    isNextButtonDisabled ? "bg-[#D9D9D9]" : "bg-[#074073]"
                  } focus:outline-none font-medium rounded-[5px] text-sm px-8 py-2.5 text-center`}
                  disabled={isNextButtonDisabled}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondModal;
