import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const CheckingAccountsBar = ({ accounts }) => {
  // const percentage = data?.data?.checkingAccounts.split("%")[0];
  const percentage = accounts && accounts["Checking Account"]?.percentage;
  return (
    <div className="w-[100px] h-[100px]">
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#88C1F4",
          pathColor: "#88C1F4",
        })}
      />
    </div>
  );
};

export const SavingAccountsBar = ({ accounts }) => {
  // const percentage = data?.data?.savingAccounts.split("%")[0];
  const percentage = accounts && accounts["Savings"]?.percentage;
  return (
    <div className="w-[100px] h-[100px]">
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#F4B588",
          pathColor: "#F4B588",
        })}
      />
    </div>
  );
};

export const LoanAccountsBar = ({ accounts }) => {
 const percentage = accounts && accounts["Digital Loan"]?.percentage;
  return (
    <div className="w-[100px] h-[100px]">
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#2BC96A",
          pathColor: "#2BC96A",
        })}
      />
    </div>
  );
};

export const TransactionsComplete = ({ rate }) => {
  const percentage = Number(rate || "0.00")?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Calculate the radius of the inner circle based on the strokeWidth of the outer circle

  return (
    <div className="relative w-[90px] h-[90px]">
      <CircularProgressbar
        value={percentage}
        // text={`${percentage}%`}
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#2BC96A",
          pathColor: "#32FF90",
          trailColor: "#D3D3D3",
        })}
      />
      {/* Render a smaller circle inside the CircularProgressbar */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70px] h-[70px] rounded-full bg-[#043168] flex items-center justify-center text-white text-xs">
        {percentage}%
      </div>
    </div>
  );
};

export const RejectedTransactions = ({ rate }) => {
  const percentage = Number(rate || "0.00")?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div className="relative w-[90px] h-[90px]">
      <CircularProgressbar
        value={percentage}
        // text={`${percentage}%`}
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#2BC96A",
          pathColor: "#FF3232",
          trailColor: "#C3FFDE",
        })}
      />
      {/* Render a smaller circle inside the CircularProgressbar */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70px] h-[70px] rounded-full bg-[#043168] flex items-center justify-center text-white text-xs">
        {percentage}%
      </div>
    </div>
  );
};

export const ReversedTransactions = ({ rate }) => {
  const percentage = Number(rate || "0.00")?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div className="relative w-[90px] h-[90px]">
      <CircularProgressbar
        value={percentage}
        // text={`${percentage}%`}
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#2BC96A",
          pathColor: "#FF9432",
          trailColor: "#C3FFDE",
        })}
      />
      {/* Render a smaller circle inside the CircularProgressbar */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70px] h-[70px] rounded-full bg-[#043168] flex items-center justify-center text-white text-xs">
        {percentage}%
      </div>
    </div>
  );
};

export const ErrorTransactions = ({ rate }) => {
  const percentage = Number(rate || "0.00")?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div className="relative w-[90px] h-[90px]">
      <CircularProgressbar
        value={percentage}
        // text={`${percentage}%`}
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: "butt",
          textColor: "#2BC96A",
          pathColor: "#FF3232",
          trailColor: "#C3FFDE",
        })}
      />
      {/* Render a smaller circle inside the CircularProgressbar */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70px] h-[70px] rounded-full bg-[#043168] flex items-center justify-center text-white text-xs">
        {percentage}%
      </div>
    </div>
  );
};
