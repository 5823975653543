export const loan_schedule = [
  {
    Schedule_Amount: "KES 90",
    Schedule_Date: "2024-02-28",
  },
  {
    Schedule_Amount: "KES 90",
    Schedule_Date: "2024-03-06",
  },
  {
    Schedule_Amount: "KES 90",
    Schedule_Date: "2024-03-13",
  },
  {
    Schedule_Amount: "KES 90",
    Schedule_Date: "2024-03-20",
  },
];
