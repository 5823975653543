import React from "react";
import { Modal } from "rsuite";

const DeleteRole = ({
  openDeleteRole,
  handleCloseDeleteRole,
  role,
  handleDeleteRole,
  handleClose,
  handleOpenDeleteRoleSuccess,
}) => {
  const cancelDelete = () => {
    handleCloseDeleteRole();
    handleClose();
  };
  return (
    <Modal
      size="md"
      open={openDeleteRole}
      onClose={handleCloseDeleteRole}
      className="sm-modal"
    >
      <div className="h-[200px]">
        <p className="text-[#000] font-bold text-[16px]">
          Are you sure you want to delete the role?
        </p>
        <p className="my-[20px]">
          Deleting a role is an irreversible action and will affect users
          associated with this role. Please consider the following before
          proceeding:
        </p>
        <p className="text-[#FF5757]">
          1 Users assigned to this role will lose associated permissions
        </p>
        <p className="text-[#FF5757]">
          2 Any permissions granted by this role will be revoked.
        </p>
        <p className="text-[#FF5757]">
          3 The role's audit history will be permanently deleted.
        </p>
      </div>
      <div className="flex items-center gap-[20px] my-[20px]">
        <button
          onClick={() => handleDeleteRole(role?.id)}
          className="bg-[#FF3232] rounded-sm h-[45px] px-[20px]"
        >
          Yes, Delete
        </button>
        <button
          onClick={cancelDelete}
          className="bg-[#7FB8EB] rounded-sm h-[45px] px-[20px]"
        >
          No, Cancel
        </button>
      </div>
    </Modal>
  );
};

export default DeleteRole;
