import React from "react";
import SelectProfile from "../profile/SelectProfile";

const TopBar = () => {
  return (
    <div className="h-full bg-white w-[100%] flex flex-col">
      <div className="flex h-[70px] w-[100%] px-[20px] items-center w-full justify-end">
        <div className="w-[36%] flex items-center justify-between mx-2">
          <div className="flex items-center gap-[50px]">
            <span className="w-[46px] h-[46px] bg-[#EFEDEC] rounded-[100%] flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M6 28.5V25.5H9V15C9 12.925 9.625 11.081 10.875 9.468C12.125 7.855 13.75 6.799 15.75 6.3V5.25C15.75 4.625 15.969 4.0935 16.407 3.6555C16.845 3.2175 17.376 2.999 18 3C18.625 3 19.1565 3.219 19.5945 3.657C20.0325 4.095 20.251 4.626 20.25 5.25V6.3C22.25 6.8 23.875 7.8565 25.125 9.4695C26.375 11.0825 27 12.926 27 15V25.5H30V28.5H6ZM18 33C17.175 33 16.4685 32.706 15.8805 32.118C15.2925 31.53 14.999 30.824 15 30H21C21 30.825 20.706 31.5315 20.118 32.1195C19.53 32.7075 18.824 33.001 18 33ZM12 25.5H24V15C24 13.35 23.4125 11.9375 22.2375 10.7625C21.0625 9.5875 19.65 9 18 9C16.35 9 14.9375 9.5875 13.7625 10.7625C12.5875 11.9375 12 13.35 12 15V25.5Z"
                  fill="#074073"
                />
              </svg>
            </span>
            <div className="flex items-center justify-center gap-[4px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                className="mr-[10px]"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 28.4998C22.4557 28.4998 28.5 22.4556 28.5 14.9999C28.5 7.54419 22.4557 1.49999 15 1.49999C7.54425 1.49999 1.5 7.54419 1.5 14.9999C1.5 22.4556 7.54425 28.4998 15 28.4998ZM15 29.9998C23.2845 29.9998 30 23.2843 30 14.9999C30 6.71545 23.2845 0 15 0C6.7155 0 0 6.71545 0 14.9999C0 23.2843 6.7155 29.9998 15 29.9998Z"
                  fill="#074073"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.5953 21.1441C17.3504 20.398 12.6706 20.3281 5.42665 21.1261C5.1882 21.1538 4.96837 21.2681 4.80926 21.447C4.65015 21.626 4.56294 21.8571 4.56432 22.0961C4.56432 22.3662 4.67721 22.6221 4.86789 22.7996C8.8219 26.4507 11.8178 28.1006 14.7463 28.111C17.6852 28.1214 20.8424 26.4847 25.1208 22.7732C25.2567 22.6528 25.3546 22.4956 25.4025 22.3208C25.4504 22.1461 25.4462 21.9612 25.3906 21.7888C25.3349 21.6163 25.2301 21.4636 25.0889 21.3494C24.9477 21.2351 24.7762 21.1632 24.5953 21.1441ZM5.21889 19.2487C12.6146 18.4336 17.4319 18.5073 24.7916 19.2657C25.3403 19.3227 25.8603 19.5377 26.288 19.8844C26.7158 20.2311 27.0327 20.6944 27.2 21.2176C27.3673 21.7409 27.3778 22.3014 27.2301 22.8304C27.0823 23.3595 26.7829 23.8342 26.3683 24.1964C22.0282 27.9627 18.4138 30.014 14.7406 29.9998C11.0569 29.9866 7.60193 27.9004 3.57866 24.1841C3.2906 23.917 3.06097 23.5937 2.90411 23.2342C2.74725 22.8748 2.66653 22.487 2.667 22.0951C2.66561 21.391 2.92523 20.7111 3.3961 20.1857C3.86698 19.6603 4.51602 19.3272 5.21889 19.2487Z"
                  fill="#074073"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.7275 14.8634C15.921 14.8634 17.0656 14.3893 17.9095 13.5454C18.7534 12.7015 19.2275 11.5569 19.2275 10.3635C19.2275 9.17001 18.7534 8.02543 17.9095 7.18152C17.0656 6.33762 15.921 5.86351 14.7275 5.86351C13.5341 5.86351 12.3895 6.33762 11.5456 7.18152C10.7016 8.02543 10.2275 9.17001 10.2275 10.3635C10.2275 11.5569 10.7016 12.7015 11.5456 13.5454C12.3895 14.3893 13.5341 14.8634 14.7275 14.8634ZM14.7275 16.3634C16.3188 16.3634 17.845 15.7313 18.9702 14.6061C20.0954 13.4809 20.7275 11.9548 20.7275 10.3635C20.7275 8.77219 20.0954 7.24608 18.9702 6.12087C17.845 4.99566 16.3188 4.36353 14.7275 4.36353C13.1362 4.36353 11.6101 4.99566 10.4849 6.12087C9.35968 7.24608 8.72754 8.77219 8.72754 10.3635C8.72754 11.9548 9.35968 13.4809 10.4849 14.6061C11.6101 15.7313 13.1362 16.3634 14.7275 16.3634Z"
                  fill="#074073"
                />
              </svg>
              <SelectProfile />
            </div>
          </div>
          <div className="flex items-center mx-auto gap-[10px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="30"
              viewBox="0 0 26 30"
              fill="none"
            >
              <path
                d="M12.8185 17.2404C11.7459 17.2415 10.6898 16.9358 9.7446 16.3508C8.79941 15.7658 7.9946 14.9196 7.40214 13.8879L8.94914 12.7018C9.37263 13.4387 9.94759 14.0431 10.6227 14.4613C11.2979 14.8794 12.0522 15.0982 12.8185 15.0982C13.5848 15.0982 14.3391 14.8794 15.0143 14.4613C15.6894 14.0431 16.2644 13.4387 16.6879 12.7018L18.2349 13.8879C17.6424 14.9196 16.8376 15.7658 15.8924 16.3508C14.9472 16.9358 13.8911 17.2415 12.8185 17.2404ZM16.5328 5.45467C16.1655 5.45467 15.8064 5.58035 15.501 5.81581C15.1956 6.05127 14.9576 6.38593 14.817 6.77749C14.6764 7.16905 14.6397 7.5999 14.7113 8.01558C14.783 8.43125 14.9599 8.81307 15.2196 9.11275C15.4793 9.41244 15.8102 9.61653 16.1705 9.69921C16.5307 9.78189 16.9041 9.73946 17.2435 9.57727C17.5828 9.41508 17.8729 9.14042 18.0769 8.78803C18.281 8.43564 18.3899 8.02134 18.3899 7.59753C18.3924 7.31533 18.3461 7.03539 18.2536 6.77411C18.1612 6.51283 18.0245 6.27547 17.8515 6.07591C17.6786 5.87636 17.4729 5.71862 17.2464 5.61195C17.02 5.50527 16.7774 5.4518 16.5328 5.45467ZM9.10421 5.45467C8.73691 5.45467 8.37785 5.58035 8.07244 5.81581C7.76704 6.05127 7.529 6.38593 7.38844 6.77749C7.24787 7.16905 7.2111 7.5999 7.28276 8.01558C7.35441 8.43125 7.53129 8.81307 7.79102 9.11275C8.05074 9.41244 8.38165 9.61653 8.7419 9.69921C9.10215 9.78189 9.47556 9.73946 9.81491 9.57727C10.1543 9.41508 10.4443 9.14042 10.6484 8.78803C10.8524 8.43564 10.9614 8.02134 10.9614 7.59753C10.9638 7.31533 10.9175 7.03539 10.825 6.77411C10.7326 6.51283 10.5959 6.27547 10.4229 6.07591C10.25 5.87636 10.0443 5.71862 9.81784 5.61195C9.5914 5.50527 9.34879 5.4518 9.10421 5.45467Z"
                fill="#074073"
              />
              <path
                d="M14.612 30L13 28.9286L16.7143 21.4286H22.2857C22.5297 21.429 22.7714 21.3739 22.9968 21.2663C23.2223 21.1588 23.4272 21.001 23.5997 20.8019C23.7723 20.6028 23.909 20.3664 24.0022 20.1063C24.0954 19.8461 24.1432 19.5672 24.1429 19.2857V4.28571C24.1432 4.00419 24.0954 3.72535 24.0022 3.46518C23.909 3.205 23.7723 2.96861 23.5997 2.76954C23.4272 2.57047 23.2223 2.41265 22.9968 2.30511C22.7714 2.19757 22.5297 2.14243 22.2857 2.14286H3.71429C3.4703 2.14243 3.22864 2.19757 3.00315 2.30511C2.77767 2.41265 2.57279 2.57047 2.40027 2.76954C2.22775 2.96861 2.09096 3.205 1.99776 3.46518C1.90456 3.72535 1.85678 4.00419 1.85714 4.28571V19.2857C1.85678 19.5672 1.90456 19.8461 1.99776 20.1063C2.09096 20.3664 2.22775 20.6028 2.40027 20.8019C2.57279 21.001 2.77767 21.1588 3.00315 21.2663C3.22864 21.3739 3.4703 21.429 3.71429 21.4286H12.0714V23.5714H3.71429C2.7292 23.5714 1.78445 23.1199 1.08789 22.3162C0.391326 21.5124 1.1614e-07 20.4224 1.1614e-07 19.2857V4.28571C-0.00012188 3.72287 0.0958676 3.16551 0.282485 2.64548C0.469102 2.12545 0.74269 1.65294 1.08762 1.25494C1.43254 0.856949 1.84205 0.541271 2.29275 0.325944C2.74344 0.110617 3.22648 -0.000140631 3.71429 1.34008e-07H22.2857C22.7735 -0.000140631 23.2566 0.110617 23.7073 0.325944C24.1579 0.541271 24.5675 0.856949 24.9124 1.25494C25.2573 1.65294 25.5309 2.12545 25.7175 2.64548C25.9041 3.16551 26.0001 3.72287 26 4.28571V19.2857C26 20.4224 25.6087 21.5124 24.9121 22.3162C24.2155 23.1199 23.2708 23.5714 22.2857 23.5714H17.7961L14.612 30Z"
                fill="#074073"
              />
            </svg>
            <div className="text-[#074073] font-normal text-[16px] w-[7vw]">
              <p>Need Help?</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-[40px] bg-[#BFE0FD] w-full flex items-center justify-center">
        <p>[Alerts (if any) go here]</p>
      </div> */}
    </div>
  );
};

export default TopBar;
