export const capitalize = (word) => {
  return word?.slice(0, 1).toUpperCase() + word?.slice(1).toLowerCase();
};

export const transactionType = (type) => {
  if (type === "send" || type === "receive") {
    return " - Transfer";
  } else {
    return " - " + type?.replace("_", " ");
  }
};
